import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import has from 'lodash/has';

import { BaseKeyPayload, PrepareReturn } from '../../types';
import { parseOffsetFromSearch } from '../../utils';
import { filterSlice } from '../filter/filter-slice';
import { limitSlice } from '../limit';

export type OffsetState = {
    [key: string]: number;
};

export const INITIAL_OFFSET = 0;
const INITIAL_STATE: OffsetState = {};

type OffsetPayload = BaseKeyPayload & {
    offset: number;
};

type SetOffsetReducer = CaseReducer<OffsetState, PayloadAction<OffsetPayload>>;
export const setOffsetReducer: SetOffsetReducer = (state, action) => {
    const { offset, key } = action.payload;
    return {
        ...state,
        [key]: offset,
    };
};

type ResetOffsetReducer = CaseReducer<OffsetState, PayloadAction<BaseKeyPayload>>;
export const resetOffsetReducer: ResetOffsetReducer = (state, action) => {
    const { key } = action.payload;
    return {
        ...state,
        [key]: INITIAL_OFFSET,
    };
};

type InitializeOffsetReducer = CaseReducer<OffsetState, PayloadAction<OffsetPayload>>;
export const initializeOffsetReducer: InitializeOffsetReducer = (state, action) => {
    const { key, offset } = action.payload;

    if (has(state, key)) {
        return state;
    }

    return {
        ...state,
        [key]: offset,
    };
};

const initializeOffsetPrepare = (key: string, search: string): PrepareReturn<OffsetPayload> => {
    const offset = parseOffsetFromSearch(search, INITIAL_OFFSET);

    return {
        payload: {
            key,
            offset,
        },
    };
};

export const offsetSlice = createSlice({
    name: 'offset',
    initialState: INITIAL_STATE,
    reducers: {
        setOffset: setOffsetReducer,
        resetOffset: resetOffsetReducer,
        initializeOffset: {
            reducer: initializeOffsetReducer,
            prepare: initializeOffsetPrepare,
        },
    },
    extraReducers: (builder) => {
        // reset offset when filter actions happen
        builder.addCase(filterSlice.actions.appendFilters, resetOffsetReducer);
        builder.addCase(filterSlice.actions.clearFilter, resetOffsetReducer);
        builder.addCase(filterSlice.actions.removeFilters, resetOffsetReducer);
        builder.addCase(filterSlice.actions.setFilter, resetOffsetReducer);
        builder.addCase(filterSlice.actions.setFieldFilter, resetOffsetReducer);
        builder.addCase(filterSlice.actions.clearFieldFilter, resetOffsetReducer);
        // reset offset when limit is set
        builder.addCase(limitSlice.actions.setLimit, resetOffsetReducer);
    },
});
