import { HierarchyNode } from 'd3-hierarchy';
import { TaxonomyNode } from './taxonomy';
import { FilterType, Operator } from './filter-argument';
import { AIOMeasurementStats } from './table';
import { Modality } from './generated-schema-types';

export interface FilterCategoryArguments {
    name: string;
    type: FilterType;
    description: string;
    referenceId: string;
    filterOperator?: Operator;
    measurementStats?: FilterMeasurementStats;
    taxonomy?: TaxonomyNode[];
    taxonomyCategories?: TaxonomyCategories;
    unit?: string;
    priorityOrder?: number;
    includeZeroes?: boolean;
    defaultFilterMin?: number;
    defaultFilterMax?: number;
    modality?: Modality[] | null;
    nullColoring?: string;
}

// TODO: Remove potreeValue from BKP server
interface ItemValue {
    value: string;
    color?: string;
}

export interface FilterItemCount {
    properties: ItemValue[];
    count: number;
    filterCount?: number;
    nonTaxonomyFilterCount?: number;
}

export interface FilterCategoryCounts {
    [alias: string]: FilterItemCount[];
}
export interface TaxonomyCategories {
    [alias: string]: FilterCategoryArguments;
}

// Filter State
export interface FilterItemState {
    name: string;
}

export interface SliderItemState {
    minRange: number;
    maxRange: number;
    min: number;
    max: number;
}

export interface FilterCategoryState {
    name: string;
    type: FilterType;
    description?: string;
    selectedItems?: FilterItemState[];
    slider?: SliderItemState;
    field?: string;
    items?: ItemCount[];
    marks?: Mark[];
    measurementStats?: FilterMeasurementStats;
    taxonomy?: HierarchyNode<TaxonomyNode>;
    taxonomyCategories?: TaxonomyCategories;
    unit?: string;
}

export type FilterState = FilterCategoryState[];

export interface FilterItemStateUpdate {
    referenceId: string;
    type: FilterType;
    itemName?: string;
    slider?: SliderItemState;
}

export interface CountMap {
    [alias: string]: number;
}

// Counts
export interface ItemCount {
    name: string;
    count?: number;
    filteredCount?: number;
    color?: string;
    minRange?: number;
    maxRange?: number;
}

export interface Mark {
    value: number;
    label: string;
}

export interface FilterMeasurementStats extends AIOMeasurementStats {
    step: number;
}

export interface HierarchyNodes {
    [alias: string]: HierarchyNode<TaxonomyNode>;
}

export interface HoveredNodes {
    [alias: string]: HierarchyNode<TaxonomyNode>;
}

export interface ItemCountQueryData {
    [alias: string]: ItemCount[];
}
