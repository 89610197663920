import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

import { Environment } from '~/src/types';
import { getSpaces } from '~/src/utils/theme/theme-helpers';
import { BKP_VERSION, DEV_ENV_VARS, ENVIRONMENT_NAME, IS_PRODUCTION } from '~/src/constants/environment';
import { Stack } from '~/src/components/bkp-ui/stack';
import { Typography } from '~/src/components/bkp-ui/typography';
import { ToggleMenu } from '~/src/components/bkp-ui/toggle-menu';
import { BkpLink } from '~/src/components/ui/bkp-link';
import { FeatureFlagsControl } from './feature-flags-control';

const allPages = [
    // TODO: Choose a random ID to go to
    { to: '/abcatlas', text: 'ABC Atlas Tool' },
    // TODO: Choose a random ID to go to
    { to: '/celltypes', text: 'Cell Types Landing' },
    // TODO: Choose a random ID to go to
    { to: '/celltypes/CCN201912132', text: 'Cell Types Explorer' },
    // TODO: Choose a random ID to go to
    { to: '/celltypes/CCN201912132/CS201912132_96', text: 'Cell Types Card' },
    { to: '/mapmycells/process', text: 'Community Data Mapping - Process' },
    { to: '/mapmycells/results', text: 'Community Data Mapping - Results' },
    { to: '/data/5IU4U8BP711TR6KZ843/2CD0HDC5PS6A58T0P6E/compare', text: 'Comparative Viewer' },
    { to: '/data/JGN327NUXRZSHEV88TN/donors', text: 'Donors' },
    { to: '/data/JGN327NUXRZSHEV88TN/donors/JGCXWER774NLNWX2NNR', text: 'Donor Info' },
    { to: '/data', text: 'Data Catalog' },
    { to: '/thiswonterror', text: 'Error (TODO)' },
    { to: '/login', text: 'Login (Must be logged out)' },
    { to: '/data/notfound/page', text: 'Not Found' },
    // TODO: Choose a random ID to go to
    { to: '/data/976C22IARSIYLTG9T08/summary', text: 'Project Summary' },
    // TODO: Choose a random ID to go to
    { to: '/data/976C22IARSIYLTG9T08/collections', text: 'Project Data Collections' },
    // TODO: Choose a random ID to go to
    { to: '/data/K1YP17A0QIKJOMOAIS4/specimens', text: 'Specimens' },
];

const getColorIntent = (
    environmentName: Environment
): 'info' | 'primary' | 'secondary' | 'inherit' | 'success' | 'error' | 'warning' => {
    switch (environmentName) {
        case 'prototype':
            return 'secondary';
        case 'development':
            return 'info';
        case 'mutation':
            return 'success';
        case 'staging':
            return 'warning';
        default:
            return 'primary';
    }
};

export const DevTools = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const theme = useTheme();
    const spaces = getSpaces(theme);

    // IMPORTANT: We don't want this showing up in production. Never remove this early return!
    if (IS_PRODUCTION) {
        return null;
    }

    const handleDevToolsClose = () => {
        setIsMenuOpen(false);
    };

    const label = `${ENVIRONMENT_NAME.toLocaleUpperCase()} v${BKP_VERSION}`;
    const color = getColorIntent(ENVIRONMENT_NAME);

    return (
        <ToggleMenu
            isOpen={isMenuOpen}
            onOpen={() => setIsMenuOpen(true)}
            onClose={() => setIsMenuOpen(false)}
            label={label}
            buttonProps={{ id: 'dev-tools', sdsType: 'secondary', sdsStyle: 'square', color }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <Grid
                container
                padding={spaces.l}
                spacing={spaces.m}
            >
                <Grid
                    item
                    xs={12}
                    md={6}
                >
                    <Typography stylePath="header.semibold.s">Environment Variables</Typography>
                    <Stack
                        direction="column"
                        spacing="xxxs"
                    >
                        {Object.entries(DEV_ENV_VARS).map(([key, value]) => (
                            <Stack
                                key={key}
                                direction="row"
                            >
                                <Typography
                                    stylePath="body.semibold.xs"
                                    color="text.secondary"
                                >
                                    {key}
                                </Typography>
                                <Typography stylePath="code.regular.xs">{value}</Typography>
                            </Stack>
                        ))}
                    </Stack>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={3}
                    // We want to close the popover when a quick link is chosen
                    onClick={handleDevToolsClose}
                >
                    <Typography stylePath="header.semibold.s">Quick Links</Typography>
                    <Stack
                        direction="column"
                        spacing="xxxs"
                    >
                        {allPages.map(({ to, text }) => (
                            <BkpLink
                                key={to}
                                to={to}
                            >
                                {text}
                            </BkpLink>
                        ))}
                    </Stack>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={3}
                >
                    <Typography stylePath="header.semibold.s">Feature Flags Control</Typography>
                    <Stack
                        direction="column"
                        spacing="xxs"
                    >
                        <Typography variant="body2">
                            Toggle features with the available feature flags. To apply the changes and refresh the app
                            with the activated features, please{' '}
                            <BkpLink onClick={() => window.location.reload()}>refresh the page</BkpLink>
                        </Typography>
                        <FeatureFlagsControl />
                    </Stack>
                </Grid>
            </Grid>
        </ToggleMenu>
    );
};
