import { Table, TableRow, CellHeader, CellComponent, TableHeader } from '@czi-sds/components';
import { ColumnDef, flexRender, getCoreRowModel, Row, useReactTable } from '@tanstack/react-table';
import { useVirtualizer, VirtualItem } from '@tanstack/react-virtual';
import { useCallback, useRef, useState } from 'react';
import { Skeleton, styled } from '@mui/material';
import Refresh from '@mui/icons-material/Refresh';
import { Button } from '../button';

// feels like it gave the elements enough space
const defaultElementSize = 35;

interface SDSTableProps<T> {
    columns: Array<ColumnDef<T>>;
    data: T[];
    columnTooltips: Record<string, string>;
    stripedRows?: boolean;
    paginate?: boolean;
    overscan?: number;
    fetchPageSize?: number;
    fetchNextPage?: (i: number, j: number) => void;
    isItemLoaded?: (i: number) => boolean;
}

const StyledTableHeader = styled(TableHeader)(({ theme }) => ({
    position: 'sticky',
    width: '100%',
    top: 0,
    zIndex: 1,
    background: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
}));

export function SdsTable<T>({
    columns,
    data,
    columnTooltips,
    stripedRows,
    paginate,
    overscan = 2,
    fetchPageSize = 25,
    fetchNextPage,
    isItemLoaded,
}: SDSTableProps<T>) {
    const tableRef = useRef<HTMLDivElement>(null);
    const [showBtn, setShowBtn] = useState(false);

    const table = useReactTable<T>({
        defaultColumn: {
            minSize: 0,
            size: 0,
        },
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: paginate,
    });

    const { rows } = table.getRowModel();

    const rowVirtualizer = useVirtualizer({
        count: rows.length,
        getScrollElement: () => tableRef.current,
        estimateSize: () => defaultElementSize,
        overscan,
    });

    const renderRow = useCallback(
        (vRow: VirtualItem<Element>) => {
            const row = rows[vRow.index] as Row<T>;
            const striped = stripedRows && vRow.index % 2 === 0;
            const isLoaded = !isItemLoaded || isItemLoaded(vRow.index);
            return (
                <TableRow
                    data-index={vRow.index}
                    ref={(node) => rowVirtualizer.measureElement(node)}
                    key={row.id}
                    style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        position: 'absolute',
                        transform: `translateY(${vRow.start}px)`,
                        width: '100%',
                        background: striped ? 'rgba(0,0,0,0.1)' : '',
                    }}
                >
                    {row.getVisibleCells().map((cell) => (
                        <CellComponent
                            key={cell.id}
                            width={cell.column.getSize() !== 0 ? cell.column.getSize() : undefined}
                        >
                            {isLoaded ? (
                                flexRender(cell.column.columnDef.cell, cell.getContext())
                            ) : (
                                <Skeleton width="100%" />
                            )}
                        </CellComponent>
                    ))}
                </TableRow>
            );
        },
        [isItemLoaded, rowVirtualizer, rows, stripedRows]
    );

    const handleScroll = useCallback(
        (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
            if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight) {
                if (paginate) setShowBtn(true);
                // fetch more results when we reach the end of the page
                if (!paginate && fetchNextPage) {
                    fetchNextPage(data.length - 1, fetchPageSize);
                }
            } else {
                setShowBtn(false);
            }
        },
        [data.length, fetchNextPage, fetchPageSize, paginate]
    );

    return (
        <div
            ref={tableRef}
            style={{ overflow: 'auto', height: '100%' }}
            onScroll={handleScroll}
        >
            <Table style={{ display: 'grid' }}>
                <StyledTableHeader>
                    {table.getHeaderGroups().map((headerGroup) =>
                        headerGroup.headers.map((header) => (
                            <CellHeader
                                key={header.id}
                                style={{
                                    minWidth: header.column.columnDef.minSize,
                                    width: header.getSize() !== 0 ? header.getSize() : undefined,
                                }}
                                tooltipText={columnTooltips[header.id]}
                                shouldShowTooltipOnHover
                                tooltipProps={{ placement: 'bottom', sdsStyle: 'light' }}
                            >
                                {flexRender(header.column.columnDef.header, header.getContext())}
                            </CellHeader>
                        ))
                    )}
                </StyledTableHeader>
                <tbody
                    style={{
                        display: 'grid',
                        height: `${rowVirtualizer.getTotalSize()}px`,
                        position: 'relative',
                    }}
                >
                    {rowVirtualizer.getVirtualItems().map((vRow) => renderRow(vRow))}
                    {showBtn ? (
                        <Button
                            variant="contained"
                            sx={{
                                position: 'fixed',
                                bottom: '24px',
                                left: '50%',
                            }}
                            onClick={() => {
                                setShowBtn(false);
                                fetchNextPage?.(data.length - 1, fetchPageSize);
                            }}
                            startIcon={<Refresh />}
                        >
                            Load More
                        </Button>
                    ) : null}
                </tbody>
            </Table>
        </div>
    );
}
