import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { Grid } from '~/src/components/bkp-ui/grid';
import { Stack } from '~/src/components/bkp-ui/stack';
import { Typography } from '~/src/components/bkp-ui/typography';
import { InfoIconButton } from '~/src/components/ui/buttons/info-icon-button';
import { getSpaces } from '~/src/utils/theme/theme-helpers';
import { Themes } from '~/src/services/theme/theme-slice';
import { BkpLink } from '~/src/components/ui/bkp-link';
import { ThemeToggle } from './theme-toggle';
import { HelpMenu } from './help-menu';
import { AccountMenu } from './account-menu';
import { DevTools } from './dev-tools/dev-tools';
import { NavMenu, NavMenuProps } from './nav-menu';
import { LinkData } from './types';
import SkipContent from './skip-content';

const StyledAppHeader = styled(Grid)(({ theme }) => {
    const spaces = getSpaces(theme);
    return {
        borderBottom: `1px solid ${theme.palette.divider}`,
        paddingInline: spaces.xxl,
        paddingBlock: spaces.xs,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    };
});

export interface ApplicationHeaderProps {
    title: string;
    theme: Themes;
    showInfoButton?: boolean;
    navOptions: NavMenuProps[];
    helpLinks: LinkData[];
    termsLinks: LinkData[];
    onThemeChange: () => void;
    onInfoClick: () => void;
}

/**
 * @name ApplicationHeader
 * @description Application header component.
 * @param {string} title - application title.
 * @param {Themes} theme - application theme (light or dark).
 * @param {boolean} showInfoButton - Flag to show info button, only used on ABC Atlas (for now).
 * @param {NavMenuProps[]} navOptions - Top-level navigation options.
 * @param {LinkData[]} helpLinks - Help links to display in the help menu.
 * @param {LinkData[]} termsLinks - Terms links to display in the help menu.
 * @param {() => void} onThemeChange - callback function for theme change.
 * @param {() => void} onInfoClick - Callback function for info button click.
 * @returns {JSX.Element}
 * @todo remove Help menu from header when info panel is fully integrated globally
 */
export const ApplicationHeader: FC<ApplicationHeaderProps> = ({
    title,
    theme,
    showInfoButton,
    navOptions,
    helpLinks,
    termsLinks,
    onThemeChange,
    onInfoClick,
}) => (
    <StyledAppHeader container>
        <SkipContent />
        <Grid
            item
            padding="xxs"
            display="flex"
            alignItems="center"
            // Logo and dev tools are first on all screen sizes
            order={{ xs: 1 }}
        >
            <BkpLink
                href="/"
                underline="none"
            >
                <Typography
                    id="application-logo"
                    color="text.primary"
                    stylePath="body.semibold.s"
                >
                    {title}
                </Typography>
            </BkpLink>
        </Grid>
        <Grid
            item
            // TODO: The paddings aren't being respected...
            padding="xxs"
            display="flex"
            justifyContent="flex-end"
            // Take up the most space possible, the logo and buttons stay self-sized
            flexGrow={1}
            // Nav menu is last on small screens, second on medium and above
            order={{ xs: 3, md: 2 }}
        >
            <Stack
                direction="row"
                alignItems="center"
                spacing="m"
                component="nav"
                flexWrap="wrap"
                paddingRight="m"
            >
                <DevTools />
                {navOptions.map((navOption) => (
                    <NavMenu
                        key={navOption.title}
                        title={navOption.title}
                        links={navOption.links}
                    />
                ))}
            </Stack>
        </Grid>
        <Grid
            item
            padding="xxs"
            // Put next to the logo on xs through md screens, otherwise last on the menu bar
            order={{ xs: 2, md: 3 }}
        >
            <Stack
                direction="row"
                alignItems="center"
                spacing="xxxs"
            >
                <ThemeToggle
                    theme={theme}
                    onClick={onThemeChange}
                />
                {showInfoButton ? (
                    <InfoIconButton
                        onClick={onInfoClick}
                        tooltip="Info"
                        iconSize="medium"
                    />
                ) : (
                    <HelpMenu
                        helpLinks={helpLinks}
                        termsLinks={termsLinks}
                    />
                )}
                <AccountMenu />
            </Stack>
        </Grid>
    </StyledAppHeader>
);
