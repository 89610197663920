import { combineReducers } from '@reduxjs/toolkit';

import { errorSlice } from '../error';
import { limitSlice } from '../limit';
import { offsetSlice } from '../offset';
import {
    specimensSummaryReferenceIdsSlice,
    specimensTilePropertyReferenceIdsSlice,
} from '../specimens-view-properties';
import { specimensCurrentViewSlice } from '../specimens-current-view';
import { specimenTaxonomySlice } from '../specimen-taxonomy/specimen-taxonomy-slice';
import { cellTypeCardSearchInputSlice } from '../cell-type-card-search';
import { filterSlice } from '../filter/filter-slice';
import { filterViewSlice } from '../filter/filter-view-slice';
import { HoverSlice } from '../filter/filter-hover-slice';
import { filterColumnWidthsSlice } from '../filter/filter-column-widths-slice';
import { filterPropertyReferenceIdsSlice } from '../filter/filter-property-reference-ids-slice';
import { filterColumnSyncSlice } from '../filter/filter-column-sync-slice';
import { pageKeySlice } from '../page-key';
import { sortSlice } from '../sort';
import { displayPropertyReferenceIdsSlice } from '../table/display-property-reference-ids-slice';
import { facetPropertiesSlice } from '../table';
import { displayColumnWidthsSlice } from '../table/display-column-widths-slice';
import { filterAllOptionsSlice } from '../filter/filter-all-options-slice';
import { themeSlice } from '../theme';
import { undoableExplorePageReducer } from '../explore-visualizations/explore-page-slice';
import { infoPanelSlice } from '../info-panel/info-panel-slice';

export const rootReducer = combineReducers({
    // App-wide Slices
    pageKey: pageKeySlice.reducer,
    theme: themeSlice.reducer,
    errors: errorSlice.reducer,
    // Generic Filter Slices
    filter: filterSlice.reducer,
    filterView: filterViewSlice.reducer,
    filterColumnWidths: filterColumnWidthsSlice.reducer,
    filterPropertyReferenceIds: filterPropertyReferenceIdsSlice.reducer,
    filterColumnSync: filterColumnSyncSlice.reducer,
    filterAllOptions: filterAllOptionsSlice.reducer,
    // Generic Pagination Slices
    sort: sortSlice.reducer,
    limit: limitSlice.reducer,
    offset: offsetSlice.reducer,
    // Generic Table Slices
    displayPropertyReferenceIds: displayPropertyReferenceIdsSlice.reducer,
    facetProperties: facetPropertiesSlice.reducer,
    displayColumnWidths: displayColumnWidthsSlice.reducer,
    // Specimen Specific Slices
    specimensCurrentView: specimensCurrentViewSlice.reducer,
    specimensTilePropertyReferenceIds: specimensTilePropertyReferenceIdsSlice.reducer,
    specimensSummaryReferenceIds: specimensSummaryReferenceIdsSlice.reducer,
    specimenTaxonomy: specimenTaxonomySlice.reducer,
    // Explore Slices
    filterHover: HoverSlice.reducer, // delete me after lane's stuff merges - this starts to touch getFilterMetadata...
    explorePage: undoableExplorePageReducer,
    // Miscellaneous Slices
    cellTypeCardSearchInput: cellTypeCardSearchInputSlice.reducer,
    infoPanel: infoPanelSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
