import { VIZ_CAPABILITY } from '~/src/constants/explore';
import type { ProjectInfoFragment } from '~/src/types/generated-schema-types';
import { AvailableResource } from '~/src/components/complex/data-catalog-table/resource-tags';
import { ResourceType } from '~/src/components/complex/data-catalog-table/constants';
import { AD_REFERENCE_ID } from '~/src/constants/strings';

const addResourceIf = (condition: boolean, resource: AvailableResource) => (condition ? [resource] : []);

/**
 * @returns array of available resources based on the project info data
 */
export const getAvailableResources = (project: ProjectInfoFragment): AvailableResource[] => {
    if (!project) {
        return [];
    }

    return [
        ...addResourceIf(!!project.capabilities?.includes(VIZ_CAPABILITY), ResourceType.abcAtlas),
        ...addResourceIf(
            !!project.highlightedWebResources?.length ||
                !!project.dataCollection?.some((dataCollection) => !!dataCollection?.webResources?.length),
            ResourceType.webResources
        ),
        // @todo when API supports "hasDonorsData" capability, remove the hardcoding of determining if the project has donor data
        ...addResourceIf(project.referenceId === AD_REFERENCE_ID, ResourceType.donorsData),
        ...addResourceIf(project.hasSpecimen, ResourceType.specimensData),
        ...addResourceIf(!!project.protocol?.length, ResourceType.protocols),
        ...addResourceIf(!!project.publication?.length, ResourceType.publications),
    ];
};
