import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { THEME_KEY } from '../../constants/local-storage';

export type Themes = 'light' | 'dark';

const determineInitialTheme = (): Themes => {
    // If they have something in local storage that's a theme they set manually and we want to use that
    if (localStorage.getItem(THEME_KEY)) {
        return localStorage.getItem(THEME_KEY) as Themes;
    }

    const prefersDarkTheme = window.matchMedia('(prefers-color-scheme: dark)');
    if (prefersDarkTheme.matches) {
        return 'dark';
    }

    return 'light';
};

const initialState = determineInitialTheme();

type ToggleThemeReducer = CaseReducer<Themes, PayloadAction<Themes>>;
const toggleThemeReducer: ToggleThemeReducer = (state) => {
    if (state === 'light') {
        return 'dark';
    }

    return 'light';
};

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        toggleTheme: toggleThemeReducer,
    },
});
