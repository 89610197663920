import { createAction, PayloadAction } from '@reduxjs/toolkit';
import TagManager from 'react-gtm-module';
import { GOOGLE_TAG_DATA_LAYER_NAME } from '../../../constants/analytics';
import { type RootState } from '../../redux';
import { createAnalyticsAction } from '../create-analytics-action';

const mapMyCellsUploadSuccessAction = createAction(
    'gtm/map-my-cells-upload-success',
    (algorithmStatus: string): { payload: string } => ({
        payload: algorithmStatus,
    })
);

const sendAnalyticsMMCUploadSuccess = (_state: RootState, action: PayloadAction<{ algorithmStatus: string }>) => {
    const {
        payload: { algorithmStatus },
    } = action;

    TagManager.dataLayer({
        dataLayerName: GOOGLE_TAG_DATA_LAYER_NAME,
        dataLayer: {
            event: 'mapMyCellsUploadSuccess',
            algorithmStatus,
        },
    });
};

export const mmcUploadSuccessAnalyticsAction = createAnalyticsAction(
    mapMyCellsUploadSuccessAction,
    sendAnalyticsMMCUploadSuccess
);

interface MMCDownloadInfo {
    fileId: string;
    algorithm: string;
}

const mapMyCellsDownloadAction = createAction(
    'gtm/map-my-cells-download',
    (data: MMCDownloadInfo): { payload: MMCDownloadInfo } => ({
        payload: {
            fileId: data.fileId,
            algorithm: data.algorithm,
        },
    })
);

const sendAnalyticsMMCDownload = (_state: RootState, action: PayloadAction<{ fileId: string; algorithm: string }>) => {
    const {
        payload: { fileId, algorithm },
    } = action;

    TagManager.dataLayer({
        dataLayerName: GOOGLE_TAG_DATA_LAYER_NAME,
        dataLayer: {
            event: 'mapMyCellsDownload',
            fileId,
            algorithm,
        },
    });
};

export const mmcDownloadAnalyticsAction = createAnalyticsAction(mapMyCellsDownloadAction, sendAnalyticsMMCDownload);
