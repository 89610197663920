import { useEffect, useRef } from 'react';

export const useKeyPressed = (...keys: string[]) => {
    const keyPressed = useRef(false);

    useEffect(() => {
        const downHandler = (e: KeyboardEvent) => {
            if (keys.includes(e.key)) {
                keyPressed.current = true;
            }
        };

        const upHandler = (e: KeyboardEvent) => {
            if (keys.includes(e.key)) {
                keyPressed.current = false;
            }
        };

        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);

        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
        };
    });

    return keyPressed.current;
};
