import { ApolloClient, InMemoryCache, useQuery } from '@apollo/client';
import { BKP_CMS_URL } from '~/src/constants/environment';

export const cmsClient = new ApolloClient({
    uri: `${BKP_CMS_URL}/graphql`,
    cache: new InMemoryCache(),
});

/**
 * NOTE: Default to using query hook generated by cms-codegen-types and pass in the cmsClient
 *       Don't use this hook directly unless the generated hook isn't doing what you need
 *
 * This hook is a wrapper around Apollo's useQuery hook that uses the CMS client. The default client in the project
 * continues to be the bkp-server client, so whenever we want to talk to the CMS we'll import this hook instead.
 *
 * @param query GraphQL query to execute
 * @param options Apollo client options
 * @returns Query result
 */
export const useCmsQuery: typeof useQuery = (query, options) =>
    useQuery(query, {
        ...options,
        client: cmsClient,
    });
