export const ColorByType = {
    quantitative: 'QUANTITATIVE',
    metadata: 'METADATA',
} as const;

export const QuantitativeFilterType = {
    gene: 'GENE',
    numeric: 'NUMERIC',
} as const;

export const AnnotationColorAttribute = {
    Fill: 'fill',
    Stroke: 'stroke',
} as const;

export const AnnotationColorOption = {
    Default: 'default',
    Custom: 'custom',
    None: 'none',
} as const;
