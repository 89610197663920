import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { IconProps } from '@mui/material/Icon';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { DisplayPropertyTooltip } from '~/src/components/ui/display-property-tooltip';

interface InfoIconButtonProps {
    disabled?: boolean;
    iconSize?: IconProps['fontSize'];
    size?: IconButtonProps['size'];
    tooltip: string;
    ariaLabel?: string;
    onClick: () => void;
}

/**
 * Generic info icon button component with tooltip. Intended to be used throughout the app to open the info panel.
 */
export const InfoIconButton = ({
    disabled,
    iconSize = 'inherit',
    size = 'small',
    tooltip,
    ariaLabel = `${tooltip} button`,
    onClick,
}: InfoIconButtonProps) => (
    <DisplayPropertyTooltip title={tooltip}>
        <span>
            <IconButton
                disabled={disabled}
                onClick={onClick}
                size={size}
                aria-label={ariaLabel}
            >
                <InfoIcon
                    color="inherit"
                    fontSize={iconSize}
                />
            </IconButton>
        </span>
    </DisplayPropertyTooltip>
);
