import DarkModeIconOutlined from '@mui/icons-material/DarkModeOutlined';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { Button } from '~/src/components/bkp-ui/button';
import { Themes } from '~/src/services/theme/theme-slice';

const selectThemeIcon = (theme: Themes) => {
    if (theme === 'light') {
        return <DarkModeIconOutlined />;
    }

    return <DarkModeIcon />;
};

export interface ThemeToggleProps {
    theme: Themes;
    onClick: () => void;
}

export const ThemeToggle = ({ theme, onClick }: ThemeToggleProps) => (
    <Button
        sdsStyle="icon"
        sdsType="secondary"
        aria-label="Toggle theme"
        icon={selectThemeIcon(theme)}
        onClick={onClick}
    />
);
