// We don't destructure the `process.env` variables here due to Storybook stories throwing an error when doing so
/* eslint-disable prefer-destructuring */
import { Environment } from '../types/dev';
import { getURLString } from '../utils/get-url-string/get-url-string';
import {
    BKP_COGNITO_REGION,
    BKP_COGNITO_USER_POOL_ID,
    BKP_COGNITO_WEB_CLIENT_ID,
    BKP_COGNITO_OAUTH_DOMAIN,
    BKP_COGNITO_OAUTH_SCOPE,
    BKP_COGNITO_OAUTH_REDIRECT_SIGN_IN,
    BKP_COGNITO_OAUTH_REDIRECT_SIGN_OUT,
    BKP_COGNITO_OAUTH_RESPONSE_TYPE,
    BKP_COGNITO_AUTH_DOMAIN,
} from './cognito';

// We want to fall back to production for BKP_ENV_NAME in case it's not provided so that the dev info badge is not displayed
export const BKP_ENV_NAME = process.env.BKP_ENV_NAME ?? 'production';
export const BKP_URL = process.env.BKP_URL;
export const BKP_CMS_URL = process.env.BKP_CMS_URL;
export const BKP_GTM = process.env.BKP_GTM;
export const BKP_VERSION = process.env.npm_package_version;

export const ENVIRONMENT_NAME = BKP_ENV_NAME as Environment;
export const IS_PRODUCTION = ENVIRONMENT_NAME === 'production';
export const GTM_IS_PRODUCTION = BKP_GTM === 'production';
export const IS_DEVELOPMENT = ENVIRONMENT_NAME === 'development';

export const DISPLAY_METADATA_URL_BASE = IS_PRODUCTION
    ? 'https://bkp-display-names-prod.s3-us-west-2.amazonaws.com/display-names.json'
    : 'https://bkp-display-names.s3-us-west-2.amazonaws.com/display-names.json';

// Adding ?crossorigin to the url fixes cors is a s3 cors workaround.
// https://stackoverflow.com/questions/17533888/s3-access-control-allow-origin-header#answer-33536480
export const DISPLAY_METADATA_URL = getURLString({
    origin: DISPLAY_METADATA_URL_BASE,
    search: 'crossorigin',
});

export const DEV_ENV_VARS: Record<string, string> = {
    'Environment Name': ENVIRONMENT_NAME,
    'Is Production?': IS_PRODUCTION.toLocaleString(),
    'BKP API URL': BKP_URL,
    'Google Tag Manager Environment': BKP_GTM ?? 'None',
    'GTM Is Production?': GTM_IS_PRODUCTION.toLocaleString(),
    'Display Metadata URL': DISPLAY_METADATA_URL,
    'Cognito Region': BKP_COGNITO_REGION,
    'Cognito User Pool ID': BKP_COGNITO_USER_POOL_ID,
    'Cognito Web Client ID': BKP_COGNITO_WEB_CLIENT_ID,
    'Cognito OAuth Domain': BKP_COGNITO_OAUTH_DOMAIN,
    'Cognito OAuth Scope': BKP_COGNITO_OAUTH_SCOPE,
    'Cognito OAuth Redirect Sign In': BKP_COGNITO_OAUTH_REDIRECT_SIGN_IN,
    'Cognito OAuth Redirect Sign Out': BKP_COGNITO_OAUTH_REDIRECT_SIGN_OUT,
    'Cognito OAuth Response Type': BKP_COGNITO_OAUTH_RESPONSE_TYPE,
    'Cognito Cookie Storage Domain': BKP_COGNITO_AUTH_DOMAIN,
};

// The dev tools flag is mostly useless, as it's not actually used because we hide dev tools based on whether
// we're in production or not. It serves as our example flag that's always available so that we don't have to
// update the feature flag tests every time we remove a feature flag
export const BKP_FF_DEV_TOOLS = process.env.BKP_FF_DEV_TOOLS;
export const BKP_FF_NEW_DATA_CATALOG = process.env.BKP_FF_NEW_DATA_CATALOG;
export const BKP_FF_PROGRAM_PAGE = process.env.BKP_FF_PROGRAM_PAGE;
