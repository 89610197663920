import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { DarkHeader } from '../../components/complex/dark-header';
import { BkpLink } from '../../components/ui/bkp-link';

export const NotFound = () => (
    <>
        <DarkHeader title="404: Page not found" />
        <Box
            px={5}
            py={2}
        >
            <Typography variant="h3">The requested URL was not found.</Typography>
            <Typography variant="body1">
                <BkpLink to="/">Head back to the home page.</BkpLink>
            </Typography>
        </Box>
    </>
);
