import { createSelector } from 'reselect';
import { selectPageKey } from '../page-key';

import { type RootState } from '../redux';

const selectSpecimensCurrentViewState = (state: RootState) => state.specimensCurrentView;

export const selectSpecimensCurrentView = createSelector(
    [selectPageKey, selectSpecimensCurrentViewState],
    (specimenKey, specimenOffsetState) => specimenOffsetState[specimenKey]
);
