import Skeleton from '@mui/material/Skeleton';
import { Stack } from '~/src/components/bkp-ui/stack';
import { Grid } from '~/src/components/bkp-ui/grid';
import { TextSkeleton, TextSkeletonVariant } from '../component-skeletons/text-skeleton';

export const ProgramPageSkeleton = () => (
    <Stack direction="column">
        <Skeleton
            variant="rectangular"
            style={{ width: '100%', minHeight: '280px', height: '32vh' }}
        />
        <Grid
            id="introduction"
            spacing="xxl"
            padding="xxl"
            width={{ sm: '100%', md: '70%' }}
            container
            style={{ margin: 'auto' }}
        >
            <Grid
                item
                sm={12}
                md={3}
            >
                <Stack
                    direction="column"
                    spacing="xs"
                >
                    <TextSkeleton />
                    <TextSkeleton />
                    <TextSkeleton />
                </Stack>
            </Grid>
            <Grid
                item
                sm={12}
                md={9}
            >
                <Stack
                    direction="column"
                    spacing="m"
                >
                    <TextSkeleton variant={TextSkeletonVariant.header} />
                    <TextSkeleton lines={8} />
                    <Skeleton
                        height={52}
                        width={165}
                    />
                </Stack>
            </Grid>
        </Grid>
    </Stack>
);
