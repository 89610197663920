import gql from 'graphql-tag';

export const metadataProps = `{
    shortName
    longName
    description
}`;

export const displayFrag = `{
    value
    metadata ${metadataProps}
}`;

export const webLinkProps = `{
    text
    url
}`;

export const webLinkFrag = `{
    value ${webLinkProps}
    metadata ${metadataProps}
}`;

export const contactFrag = `{
    value {
        email ${webLinkProps}
        name
    }
    metadata ${metadataProps}
}`;

export const programFrag = `{
    value {
        programLink ${webLinkProps}
        subProgramLink ${webLinkProps}
    }
    metadata ${metadataProps}
}`;

export const projectFrag = `{
    value {
        program
        subProgram
        projectLink ${webLinkProps}
    }
    metadata ${metadataProps}
}`;

export const publicationFrag = `{
    value {
        year
        name
        doiLink ${webLinkProps}
        pubMedLink ${webLinkProps}
    }
    metadata ${metadataProps}
}`;

export const DISPLAY_PROPERTY_TYPE_PROJECT = 'PROJECT';

export const EMPTY_QUERY = gql`
    query emptyQuery {
        _empty
    }
`;
