import get from 'lodash/get';

/**
 * Like lodash.get, but accepts function as accessor
 */
export const access = <D, V>(
    obj: D,
    accessor: keyof D | string | number | Array<string | number> | ((datum: D) => V),
    defaultVal?: V
): V => {
    if (typeof accessor === 'function') {
        return accessor(obj) ?? defaultVal;
    }
    return get(obj, accessor, defaultVal);
};
