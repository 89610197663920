import Skeleton from '@mui/material/Skeleton';
import { TextSkeleton } from './component-skeletons/text-skeleton';

// pre-built skeletons made for individual components for UI consistency
// let's add more skeletons as we need them
export const SkeletonVariant = {
    text: 'text',
    textBlockSmall: 'textBlock-s',
    textBlockMedium: 'textBlock-m',
    textBlockLarge: 'textBlock-l',
    image: 'image',
} as const;
export type SkeletonVariantType = (typeof SkeletonVariant)[keyof typeof SkeletonVariant];

export const SkeletonMode = {
    page: 'page',
    component: 'component',
} as const;
export type SkeletonModeType = (typeof SkeletonMode)[keyof typeof SkeletonMode];

const TextSkeletonLines = {
    [SkeletonVariant.textBlockSmall]: 3,
    [SkeletonVariant.textBlockMedium]: 5,
    [SkeletonVariant.textBlockLarge]: 7,
};

export const getSkeleton = (variant?: SkeletonVariantType) => {
    switch (variant) {
        case SkeletonVariant.text:
            return <TextSkeleton />;
        case (SkeletonVariant.textBlockSmall, SkeletonVariant.textBlockMedium, SkeletonVariant.textBlockLarge):
            return <TextSkeleton lines={TextSkeletonLines[variant]} />;
        case SkeletonVariant.image:
            return (
                <Skeleton
                    variant="rectangular"
                    width="100%"
                    height="100%"
                />
            );
        default:
            return <Skeleton />;
    }
};
