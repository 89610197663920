import { castCridToFeatureType } from './cast-crid-to-feature-type';
import { FilterType, TableFacetProperty, cRID } from '../../types';
import { SPECIMEN_ID_TITLE, TABLE_DEFAULT_MODALITY } from '../../constants/strings';

export const castCridToFacetedProperty = (crid: cRID): TableFacetProperty => ({
    referenceId: crid.registry.referenceId,
    featureType: castCridToFeatureType(crid, SPECIMEN_ID_TITLE),
    type: FilterType.ANNOTATION,
    filterOperator: null,
    modality: [{ name: TABLE_DEFAULT_MODALITY.toLowerCase() }],
});
