import { ComponentType, forwardRef } from 'react';
import { useTheme } from '@mui/material/styles';
import type { SdsSpacesType } from '~/src/types/styles';
import { getSpaces } from '~/src/utils/theme/theme-helpers';

const BASE_SPACING_PROPS = [
    'padding',
    'paddingInline',
    'paddingBlock',
    'paddingTop',
    'paddingBottom',
    'paddingLeft',
    'paddingRight',
    'margin',
    'marginInline',
    'marginBlock',
    'marginTop',
    'marginBottom',
    'marginLeft',
    'marginRight',
];

type ResponsiveStyleValue<T> = T | { [key: string]: T | null };

export type SpacesType = SdsSpacesType | 'none';

type SpacingProps<K extends string> = {
    [key in K]?: SpacesType;
};

type BaseSpacingProps = SpacingProps<
    | 'padding'
    | 'paddingInline'
    | 'paddingBlock'
    | 'paddingTop'
    | 'paddingBottom'
    | 'paddingLeft'
    | 'paddingRight'
    | 'margin'
    | 'marginInline'
    | 'marginBlock'
    | 'marginTop'
    | 'marginBottom'
    | 'marginLeft'
    | 'margingRight'
>;

type GeneratedSpacingProps<K extends string> = ResponsiveStyleValue<BaseSpacingProps & SpacingProps<K>>;

/**
 * Higher-order component that applies SDS spacings as styling prop values to a wrapped component.
 * @template P - The type of props for the wrapped component: padding, margin.
 * @template K - The type of additional spacing props to be added, usually unique to the wrapped component (e.g. `spacing` in Stack).
 * @param WrappedComponent component to be wrapped.
 * @param additionalProps spacing props to be added.
 * @returns the wrapped component with spacing props.
 */
export const withSpacing = <P extends object, K extends string = never>(
    WrappedComponent: ComponentType<P>,
    additionalProps?: K[]
) => {
    const WithSpacingComponent = forwardRef<unknown, P & GeneratedSpacingProps<K>>((componentProps, ref) => {
        const theme = useTheme();
        const spaces = {
            ...getSpaces(theme),
            none: 0,
        };

        const spacingPropKeys = (additionalProps || []).concat(BASE_SPACING_PROPS as K[]);

        const appliedSpacingProps = spacingPropKeys.reduce((acc, prop: keyof GeneratedSpacingProps<K>) => {
            if (prop in componentProps) {
                if (typeof componentProps[prop] === 'string') {
                    // spacing value is passed in directly
                    return {
                        ...acc,
                        [prop]: spaces[componentProps[prop] as SpacesType],
                    };
                }
                // spacing value is passed in as a ResponsiveStyleValue, e.g.: { xs: "none", sm: "s" }
                const responsiveStyle = componentProps[prop] as { [key: string]: SpacesType | null };
                if (responsiveStyle && typeof responsiveStyle === 'object') {
                    return {
                        ...acc,
                        [prop]: Object.keys(responsiveStyle).reduce(
                            (breakPointAcc, breakpoint) => ({
                                ...breakPointAcc,
                                [breakpoint]: spaces[responsiveStyle[breakpoint] as SpacesType],
                            }),
                            {}
                        ),
                    };
                }
            }
            return acc;
        }, {} as GeneratedSpacingProps<K>);

        return (
            <WrappedComponent
                {...(componentProps as P)}
                {...appliedSpacingProps}
                ref={ref}
            />
        );
    });

    WithSpacingComponent.displayName = `WithSpacing(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

    return WithSpacingComponent;
};
