import { ELLIPSIS } from '../../constants/unicode';

export type TruncateBoundaryOptions = {
    regex: RegExp;
    limit?: number;
};

export interface TruncateTextOptions {
    boundary?: TruncateBoundaryOptions;
    charLimit?: number;
    showEllipsis?: boolean;
}

export const truncateText = (text: string, options: TruncateTextOptions = {}) => {
    const { showEllipsis = true, boundary, charLimit = 600 } = options;

    const ellipsis = showEllipsis ? `${ELLIPSIS}` : '';

    // default truncation
    if (!boundary) {
        return text.length <= charLimit
            ? // If the length of text is under the limit, do not truncate
              text
            : `${text.slice(0, charLimit)}${ellipsis}`;
    }

    // Truncate text by custom boundary.
    const boundaryLimit = boundary.limit ?? 2;
    const textMatch = text.match(boundary.regex);
    const textTruncatedByMaxSentences = textMatch?.slice(0, boundaryLimit).join('') ?? '';

    // If truncation is still above charlimit, truncate at charlimit
    if (textTruncatedByMaxSentences.length >= charLimit) {
        return `${text.slice(0, charLimit)}${ellipsis}`;
    }

    // If truncation does not modify the text, then show text.
    if (textTruncatedByMaxSentences === text) {
        return text;
    }

    // If the length of the max sentences allowed is under the limit,
    // truncate at sentence limit.
    return `${textTruncatedByMaxSentences}${ellipsis}`;
};

/**
 * Helper function to truncate text from the middle with an ellipsis (e.g. "Hello, world!" -> "Hello...d!")
 *
 * @param text The text to truncate in the middle
 * @param maxLength The maximum length of the truncated text
 * @returns The new string, truncated in the middle with an ellipsis
 */
export const truncateTextAtMiddle = (text: string, maxLength: number): string => {
    if (text.length <= maxLength) return text;

    const halfLength = Math.floor(maxLength / 2);
    const left = text.slice(0, halfLength);
    const right = text.slice(-halfLength);

    return `${left}${ELLIPSIS}${right}`;
};
