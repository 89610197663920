import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const StyledActionText = styled(Typography)(({ theme }) => ({
    cursor: 'pointer',
    textDecoration: 'underline',
    fontWeight: 'bold',
    fontSize: theme.typography.button.fontSize,
    display: 'block',
    marginTop: theme.spacing(0.5),
    '&: hover': {
        opacity: 0.8,
    },
}));
