/* eslint-disable no-restricted-imports */
import {
    getSpaces as sdsGetSpaces,
    getTypography as sdsGetTypography,
    getShadows as sdsGetShadows,
    getCorners as sdsGetCorners,
    SDSAppTheme,
} from '@czi-sds/components';
import type { CommonThemeProps, Typography } from '@czi-sds/components';

/**
 * This is a wrapper around the getSpaces function from SDS.
 * This provides a fallback so we don't have to write it in every usage.
 *
 * @param theme theme object retrieved from MUI theme
 * @returns SDS Spaces schema object that returns the spacing values
 */
export const getSpaces = (theme: CommonThemeProps['theme']) => {
    const spacings = sdsGetSpaces({ theme });
    return (
        spacings || {
            default: '12px',
            xxxs: '2px',
            xxs: '4px',
            xs: '6px',
            s: '8px',
            m: '12px',
            l: '16px',
            xl: '24px',
            xxl: '40px',
        }
    );
};

/**
 * This is a wrapper around the getTypography function from SDS.
 * This provides a fallback so we don't have to write it in every usage.
 *
 * @param theme theme object retrieved from MUI theme
 * @returns SDS Typography object
 */
export const getTypography = (theme: CommonThemeProps['theme']): Typography => {
    const typography = sdsGetTypography({ theme });
    return typography || SDSAppTheme.typography;
};

/**
 * This is a wrapper around the sdsGetSemanticComponentColors function from SDS.
 * @param theme theme object retrieved from MUI theme
 * @returns SDS semantic component Colors object
 */
export const getSemanticComponentColors = (theme: CommonThemeProps['theme']) => theme!.palette.sds!.component!;

/**
 * This is a wrapper around the sdsGetSemanticComponentColors function from SDS.
 * @param theme theme object retrieved from MUI theme
 * @returns SDS semantic component Colors object
 */
export const getSemanticTextColors = (theme: CommonThemeProps['theme']) => theme!.palette.sds!.text!;

/**
 * This is a wrapper around the getShadows function from SDS.
 * This provides a fallback so we don't have to write it in every usage.
 *
 * @param theme theme object retrieved from MUI theme
 * @returns SDS Shadows object
 */
export const getShadows = (theme: CommonThemeProps['theme']) => {
    const colors = sdsGetShadows({ theme });
    return colors || SDSAppTheme.shadows;
};

/**
 * This is a wrapper around the getCorners function from SDS.
 * This provides a fallback so we don't have to write it in every usage.
 *
 * @param theme theme object retrieved from MUI theme
 * @returns SDS Corners object
 */
export const getCorners = (theme: CommonThemeProps['theme']) => {
    const corners = sdsGetCorners({ theme });
    return corners || SDSAppTheme.corners;
};
