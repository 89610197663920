import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import isEmpty from 'lodash/isEmpty';

import { HTML_SANITIZE_REG_EXP, SPACE_OR_COMMA_REGEX } from '../../constants/regex';
import { CellTypeCardSearchOptionBase, HighlightKey, HighlightScoreTuple } from '../../types';

const query = gql`
    query searchCellTypes($q: String!, $species: [String!]) {
        searchCellTypes(q: $q, species: $species)
    }
`;

export const getTopHighlightOption = (keys: HighlightKey[], entry: Record<HighlightKey, string[]>): string[] => {
    const key: HighlightKey = keys.find((prop) => !isEmpty(entry[prop]));

    // Solr highlighting comes as HTML, but it's an anti-pattern in react
    // to render raw HTML. https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml
    // However, the Solr highlighting does a nice job at curating the values in
    // which we should highlight. So, we scrub the HTML and keep the curated text
    // to do our own react-ified highlighting downstream.
    return key && entry[key].map((val) => val.replace(HTML_SANITIZE_REG_EXP, ''));
};

// Takes a solr score header (qf)
// eg "label^2.5 has_exact_synonym^2.5 aliases^2.5 prefLabel^4.5 definition^1 tags^1 "
// and parses it into a sorted list of keys, desc x highest score
export const parseHighlightParam = (str: string): HighlightKey[] =>
    str
        .split(SPACE_OR_COMMA_REGEX)
        .filter(Boolean)
        .map((entry: string) => {
            const [key, score] = entry.split('^');
            return [key, Number(score)];
        })
        .sort(([, scoreA]: HighlightScoreTuple, [, scoreB]: HighlightScoreTuple) => scoreB - scoreA)
        .map(([key]: HighlightScoreTuple) => key);

export const useSearchCellTypes = (q: string, species: string[]) => {
    const queryOptions = {
        variables: { q, species },
        skip: !q || !species,
    };
    const res = useQuery(query, queryOptions);

    const weightSortedHighlightKeys: HighlightKey[] = useMemo(
        () => parseHighlightParam(res.data?.searchCellTypes?.responseHeader?.params?.qf || ''),
        [res.data?.searchCellTypes?.responseHeader?.params?.qf]
    );

    const options: CellTypeCardSearchOptionBase[] =
        res.data?.searchCellTypes?.response?.docs
            // Filter out non AIBS options, indicated by accession_id presence
            ?.filter((doc: CellTypeCardSearchOptionBase) => doc.accession_id?.length)
            // Add top highlight info to each doc
            ?.map((doc: CellTypeCardSearchOptionBase) => ({
                ...doc,
                topHighlightOption: getTopHighlightOption(
                    weightSortedHighlightKeys,
                    res.data?.searchCellTypes?.highlighting[doc.id]
                ),
            })) || [];

    return { ...res, options };
};
