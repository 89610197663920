import { createSelector } from 'reselect';
import { type RootState } from '../redux';
import { SpecimensSummaryReferenceIds } from './specimens-summary-properties-slice';
import { selectSpecimensTaxonomyCategories } from '../specimen-taxonomy/selectors';
import { PropertyReferenceIds } from '../table/property-names-slice';
import { selectPageKey } from '../page-key';

const selectSpecimensTilePropertyReferenceIdsState = (state: RootState): PropertyReferenceIds =>
    state.specimensTilePropertyReferenceIds;

const selectSpecimensSummaryReferenceIdState = (state: RootState): SpecimensSummaryReferenceIds =>
    state.specimensSummaryReferenceIds;

export const selectSpecimensTilePropertyReferenceIds = createSelector(
    [selectPageKey, selectSpecimensTilePropertyReferenceIdsState],
    (specimenKey, specimensTileColumnsState) => specimensTileColumnsState[specimenKey]
);

export const selectSpecimensSummaryReferenceIds = createSelector(
    [selectPageKey, selectSpecimensSummaryReferenceIdState],
    (specimenKey, specimensSummaryReferenceIds) => specimensSummaryReferenceIds[specimenKey]
);

export const selectNonRemovableOptionNames = createSelector(
    [selectSpecimensTaxonomyCategories],
    (specimenTaxonomyCategories) => specimenTaxonomyCategories.map(({ field }) => field)
);

export const selectSpecimensTilePropertyReferenceIdsQueryOption = createSelector(
    [selectSpecimensTilePropertyReferenceIds],
    (tileProperties) => ({ tileProperties })
);
