import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const StyledHeaderContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(4, 5),
}));

export const StyledHeaderText = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
}));
