import { useState, useCallback, MouseEvent } from 'react';

import { Vec2, vec2 } from '@alleninstitute/vis-geometry';
import { useEvent } from '../use-event';

export const transformToVec2 = (value: vec2 | number): vec2 => {
    if (typeof value === 'number') {
        return [value, value];
    }
    return value;
};

export type DragHandleCallback = (size: vec2) => void;

export interface UseDragHandleProps {
    /**
     * initial values to be used
     * pass in vec2 for different width/heights limits: [width, height]
     * OR pass in single number to be used for both widths and heights
     */
    startSize: vec2 | number;
    /**
     * use as min and max limits for the width and height calculation
     * pass in vec2 for different width/heights limits: { min: [min for width, min for height] }
     * OR pass in single number to be used for both widths and heights
     */
    bounds: {
        min: vec2 | number;
        max: vec2 | number;
    };
    /**
     * by default the drag handler calculates values on natural direction,
     * if set to true, the values are calculated against natural direction
     */
    reverseAnchor?: {
        x?: boolean;
        y?: boolean;
    };
    /**
     * callback with the updated values when the size is changed during drag
     */
    onResize?: DragHandleCallback;
    onResizeStart?: () => void;
    onResizeEnd?: () => void;
}

/**
 * This hook calculates the resized value based on the drag gesture globally
 * @returns onDragStart, basic usage is to be called at "onMousedown" event
 */
export const useDragHandle = ({
    bounds: boundsProps,
    startSize,
    reverseAnchor,
    onResize,
    onResizeStart,
    onResizeEnd,
}: UseDragHandleProps) => {
    const [anchorOptions, setAnchorOptions] = useState({
        size: transformToVec2(startSize),
        startPosition: [0, 0] as vec2,
        isStarted: false,
    });

    const bounds = {
        min: transformToVec2(boundsProps.min),
        max: transformToVec2(boundsProps.max),
    };

    const handleStart = (event: MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setAnchorOptions((prev) => ({
            size: prev.size,
            startPosition: [event.clientX, event.clientY],
            isStarted: true,
        }));
        onResizeStart?.();
    };

    const handleEnd = useCallback(
        (event: MouseEvent<HTMLElement>) => {
            event.preventDefault();
            if (!anchorOptions.isStarted) {
                return;
            }

            setAnchorOptions((previous) => ({
                ...previous,
                isStarted: false,
            }));

            onResizeEnd?.();
        },
        [anchorOptions.isStarted, onResizeEnd]
    );

    const handleMove = useCallback(
        (event: MouseEvent) => {
            event.preventDefault();
            if (!anchorOptions.isStarted) {
                return;
            }

            const { size, startPosition } = anchorOptions;
            const { clientX, clientY } = event;

            const currentPosition: vec2 = [
                reverseAnchor?.x ? document.body.offsetWidth - (clientX - document.body.offsetLeft) : clientX,
                reverseAnchor?.y ? document.body.offsetHeight - (clientX - document.body.offsetTop) : clientY,
            ];
            const intendedSize = Vec2.sub(Vec2.add(size, currentPosition), startPosition);
            const clampedSize = Vec2.min(Vec2.max(intendedSize, bounds.min), bounds.max);

            setAnchorOptions((previous) => ({
                ...previous,
                size: clampedSize,
                startPosition: currentPosition,
            }));

            onResize(clampedSize);
        },
        [anchorOptions, onResize, bounds.min, bounds.max, reverseAnchor?.x, reverseAnchor?.y]
    );

    // We don't need to expose handleMove or handleEnd to the user because those are handled automatically
    // by event listeners, but handleStart needs to be connected to a specific DOM element
    useEvent('mousemove', handleMove);
    useEvent('mouseup', handleEnd);

    return { handleStart };
};
