import { useMemo } from 'react';
import { FilterType, ImageDimension } from '../../../types';
import { useDisplayPropertiesQuery } from '../../view-properties';

export const useSpecimensImageDimensionsQuery = (projectReferenceId: string, currentImageRefIds: string[]) => {
    const res = useDisplayPropertiesQuery(projectReferenceId);
    const data: ImageDimension[] = useMemo(
        () =>
            res.data?.getDisplayProperty.displayFeatures
                .filter(
                    (feature) =>
                        feature.type === FilterType.IMAGE &&
                        currentImageRefIds?.includes(feature.featureType.referenceId)
                )
                .map((feature) => ({
                    referenceId: feature.featureType.referenceId,
                    height: feature.dimensions.height,
                    width: feature.dimensions.width,
                })),
        [res.data, currentImageRefIds]
    );

    return { ...res, data };
};
