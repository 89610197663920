import { createSelector } from 'reselect';

import { FilterArgument } from '../../types';
import { TaxonomyData } from '../../types/taxonomy';
import { selectPageKey } from '../page-key';
import { type RootState } from '../redux';
import { selectFacetProperties } from '../table/selectors';
import { SpecimenTaxonomyState } from './specimen-taxonomy-slice';

export const selectSpecimenTaxonomy = (state: RootState): SpecimenTaxonomyState => state.specimenTaxonomy;

export const selectSpecimensTaxonomy = createSelector(
    [selectSpecimenTaxonomy, selectPageKey],
    (specimenTaxonomy, specimenKey) => specimenTaxonomy[specimenKey]
);

export const selectSpecimensTaxonomyCategories = createSelector(
    [selectSpecimensTaxonomy, selectFacetProperties],
    (currentSpecimenTaxonomy, specimensFacetProperties) => {
        const categoryNames = currentSpecimenTaxonomy.reduce((categories: string[], taxonomy: TaxonomyData) => {
            const innerTaxonomyCategories = [
                ...new Set(taxonomy.taxonomyNodes.map((node) => node.featureTypeTitle)),
            ].filter((n) => n !== null);
            categories.push(...innerTaxonomyCategories);
            return categories;
        }, []);

        const taxonomyCategories: FilterArgument[] = categoryNames.map((category: string) => {
            const details = specimensFacetProperties.find((property) => property.featureType.title === category);
            return {
                field: details?.featureType.referenceId,
                operator: details?.filterOperator,
                value: category,
            };
        });

        return taxonomyCategories;
    }
);
