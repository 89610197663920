import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { InfoPanelState, InfoPanelContent } from './types';
import { DEFAULT_INFO_PANEL_TITLE } from './constants';

const INITIAL_STATE: InfoPanelState = {
    isOpen: false,
    title: DEFAULT_INFO_PANEL_TITLE,
    content: [],
};

type ToggleInfoPanelReducer = CaseReducer<
    InfoPanelState,
    PayloadAction<{
        title: string;
        content: InfoPanelContent;
    }>
>;

export const toggleInfoPanelReducer: ToggleInfoPanelReducer = (state, action) => {
    const { title, content } = action.payload;
    const isSameInfoPanel = state.title === title;

    if (isSameInfoPanel && state.isOpen) {
        // close the panel if the requested panel is already opened
        return INITIAL_STATE;
    }

    return {
        ...state,
        isOpen: true,
        title,
        content,
    };
};

type CloseInfoPanelReducer = CaseReducer<InfoPanelState>;
export const closeInfoPanelReducer: CloseInfoPanelReducer = () => INITIAL_STATE;

type UpdateInfoPanelReducer = CaseReducer<
    InfoPanelState,
    PayloadAction<{
        title: string;
        content: InfoPanelContent;
    }>
>;

export const updateInfoPanelReducer: UpdateInfoPanelReducer = (state, action) => {
    if (!state.isOpen) {
        return INITIAL_STATE;
    }
    const { title, content } = action.payload;

    return {
        ...state,
        isOpen: true,
        title,
        content,
    };
};

export const infoPanelSlice = createSlice({
    name: 'infoPanel',
    initialState: INITIAL_STATE,
    reducers: {
        toggleInfoPanel: toggleInfoPanelReducer,
        closeInfoPanel: closeInfoPanelReducer,
        updateInfoPanel: updateInfoPanelReducer,
    },
});
