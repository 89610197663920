import { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { NotFound } from '../not-found';
import { CELL_TYPE_PAGE_DISPLAY_NAME } from '../../constants/strings';

const CellTypeLanding = lazy(() => import('./cell-type-landing'));
const CellTypeExplorer = lazy(() => import('./cell-type-explorer'));
const CellTypeCard = lazy(() => import('./cell-type-card'));

export const CellTypesSEO = () => (
    <Helmet>
        <title>{CELL_TYPE_PAGE_DISPLAY_NAME}</title>
        <meta
            name="description"
            content={CELL_TYPE_PAGE_DISPLAY_NAME}
        />
        <meta
            name="keywords"
            content={CELL_TYPE_PAGE_DISPLAY_NAME}
        />
    </Helmet>
);

export const CellTypes = () => (
    <>
        <CellTypesSEO />
        <Routes>
            <Route
                path="/"
                element={<CellTypeLanding />}
            />
            <Route
                path="taxonomies/:taxId/celltype/:cellTypeId"
                element={<CellTypeCard />}
            />
            <Route
                path=":taxId/:cellTypeId"
                element={<CellTypeCard />}
            />
            <Route
                path="taxonomies/:taxId/celltype/:cellTypeId"
                element={
                    <Navigate
                        replace
                        to=":taxId/:cellTypeId"
                    />
                }
            />
            <Route
                path="/:taxId"
                element={<CellTypeExplorer />}
            />
            <Route
                path="*"
                element={<NotFound />}
            />
        </Routes>
    </>
);
