// COLORS
export const CONSECUTIVE_NUMBER_REGEX = /[\d|.]+/g;
export const HEX_RGB_REGEX = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

// TEXT REGEX
export const SPACE_HYPHEN_GLOBAL_REGEX = /[ |-]+/g;
export const URI_FROM_DATASET_NAME_REGEX = /^[a-z|A-Z]+_/; // Finds all letters up to the first "_"
export const SENTENCE_REGEX = /[^.?!]+([.!?]?)+[\])'"`’”]*/gm;
// https://stackoverflow.com/a/31093903 - Different from SENTENCE_REGEX because in some cases we only want the first one,
// but the SENTENCE_REGEX doesn't handle things like 2.1 million well. Instead of updating the existing one (and risk breaking)
// the code depending on it, we have this new one for single sentences.
export const FIRST_SENTENCE_REGEX = /^.*?[.!?](?:\s|$)/gm;
export const COMMA_REGEX = /[^,]+,?/gm;
export const SPACE_OR_COMMA_REGEX = /[ ,]+/;
export const REMOVE_NON_INITIAL_GRAPHQL_UNSAFE_NAME_CHARS = /[^_0-9A-Za-z]/g; // based on https://spec.graphql.org/June2018/#sec-Names
export const HTML_SANITIZE_REG_EXP = /<\/?[^>]+(>|$)/g; // based on https://stackoverflow.com/questions/5002111/how-to-strip-html-tags-from-string-in-javascript/5002161#5002161

export const FLOAT_REGEX = /[-]?([0-9]*([.][0-9]*)?)/;

// adapted from https://stackoverflow.com/questions/46370725/how-to-do-email-validation-using-regular-expression-in-typescript
export const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
