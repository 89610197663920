import { PayloadAction } from '@reduxjs/toolkit';
import TagManager from 'react-gtm-module';
import { GOOGLE_TAG_DATA_LAYER_NAME } from '../../../constants/analytics';
import { FilterArgument } from '../../../types';
import { filterSlice } from '../../filter/filter-slice';
import { type RootState } from '../../redux';
import { createAnalyticsAction } from '../create-analytics-action';

const sendAnalyticsInventoryFilterAdd = (_state: RootState, action: PayloadAction<FilterArgument>) => {
    const {
        type,
        payload: { field, value },
    } = action;

    TagManager.dataLayer({
        dataLayerName: GOOGLE_TAG_DATA_LAYER_NAME,
        dataLayer: {
            event: 'inventoryFilterAdd',
            actionType: type,
            field,
            value,
        },
    });
};

export const inventoryFilterAddAction = createAnalyticsAction(
    filterSlice.actions.appendFilters,
    sendAnalyticsInventoryFilterAdd
);
