interface ZIndexMap {
    [elType: string]: number;
}

export const zIndexMap: ZIndexMap = {
    appHeader: 1201,
    errorReport: 1100,
    inventoryControlPanel: 970,
    summaryHeader: 970,
    minimumRaised: 1,
    colorScaleMenu: 705,
    colorScaleMenuModal: 700,
    selectedCellSummaryResize: 999,
    reglCanvas: 1,
    reglCanvasContainer: 0,
    reglOverlayContainer: 4,
    vizFrameHeaderItems: 1000,
    // standard recommended zIndex for popOver from https://mui.com/material-ui/customization/z-index/
    popOver: 1300,
    collapsibleSidebarBase: 1000,
    collapsibleSidebarButton: 1002,
    collapsibleResizeHitTarget: 1001,
};
