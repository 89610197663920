import { Middleware } from '@reduxjs/toolkit';

import {
    fatalErrorAction,
    inventoryFilterAddAction,
    inventoryFilterClearFieldAction,
    inventoryFilterRemoveAction,
    projectSummaryVisitedAction,
    specimensFilterRemoveAction,
    specimensMetadataDownloadAction,
    specimensPropertyAddAction,
    specimensPropertyRemoveAction,
    webResourceClickAction,
    abcAtlasImageDownloadAnalyticsAction,
    mmcUploadSuccessAnalyticsAction,
    mmcDownloadAnalyticsAction,
} from './actions';
import {
    shareVisActionAnalyticsAction,
    citeProjectAnalyticsAction,
    citeAbcAtlasAnalyticsAction,
} from './actions/share-and-citation';
import { AnalyticsAction } from './create-analytics-action';

type DataLayerActions = AnalyticsAction[];

// List all data layer actions and analytics calls.
const dataLayerActions: DataLayerActions = [
    fatalErrorAction,
    inventoryFilterAddAction,
    inventoryFilterClearFieldAction,
    inventoryFilterRemoveAction,
    projectSummaryVisitedAction,
    webResourceClickAction,
    specimensPropertyAddAction,
    specimensPropertyRemoveAction,
    specimensFilterRemoveAction,
    specimensMetadataDownloadAction,
    abcAtlasImageDownloadAnalyticsAction,
    shareVisActionAnalyticsAction,
    citeAbcAtlasAnalyticsAction,
    citeProjectAnalyticsAction,
    mmcUploadSuccessAnalyticsAction,
    mmcDownloadAnalyticsAction,
];

export const tagManagerMiddleware: Middleware = (store) => (next) => (action) => {
    next(action);

    // Iterate across all data layer actions.
    // Make analytics call if an action matches.
    dataLayerActions.forEach(({ gtmAction, dataLayer }) => {
        if (gtmAction.match(action)) {
            dataLayer(store.getState(), action);
        }
    });
};
