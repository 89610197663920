import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { BaseKeyPayload } from '../../types';

const INITIAL_STATE = '';

type SetKeyReducer = CaseReducer<string, PayloadAction<BaseKeyPayload>>;
export const setKeyReducer: SetKeyReducer = (_state, action) => {
    const { key } = action.payload;
    return key;
};

export const createKeySlice = (name: string) =>
    createSlice({
        name,
        initialState: INITIAL_STATE,
        reducers: {
            setKey: setKeyReducer,
        },
    });
