import { styled } from '@mui/material/styles';
import { Tag as SdsTag } from '@czi-sds/components';
import type { TagProps as SdsTagProps } from '@czi-sds/components';
import { shouldForwardProp } from '~/src/utils/styled/shouldForwardProp';
import { getSemanticTextColors } from '~/src/utils/theme/theme-helpers';

type StyledTagProps = { isSecondary: boolean; tagColorType: SdsTagProps['tagColor'] };
export const StyledTag = styled(SdsTag, {
    shouldForwardProp: shouldForwardProp<StyledTagProps>(['isSecondary', 'tagColorType'] as const),
})<StyledTagProps>(({ theme, isSecondary, tagColorType }) => {
    if (!isSecondary || typeof tagColorType !== 'string') {
        return {};
    }
    // The existing Secondary Tag text color is not using the ideal semantic color, so we need to override it to work with dark theme.
    // https://github.com/chanzuckerberg/sci-components/blob/28e4c7c2f24870388c019d08dd0850f17b819cfc/packages/components/src/core/Tag/style.ts#L214
    const textColors = getSemanticTextColors(theme);
    const textColor = tagColorType === 'accent' ? textColors.base.accent : textColors[tagColorType];

    return {
        '.MuiChip-label': {
            color: textColor,
        },
    };
});

/**
 * This is the Tag component with styles applied following the SDS.
 */
export const Tag = ({ ...restProps }: SdsTagProps) => (
    <StyledTag
        isSecondary={restProps.sdsType === 'secondary'}
        tagColorType={restProps.tagColor}
        {...restProps}
    />
);
