import { createAction, PayloadAction } from '@reduxjs/toolkit';
import TagManager from 'react-gtm-module';
import { GOOGLE_TAG_DATA_LAYER_NAME } from '../../../constants/analytics';
import { type RootState } from '../../redux';

import { createAnalyticsAction } from '../create-analytics-action';

export const DONOR_PROPERTY_ADD = 'PROPERTY_ADD';
export const DONOR_PROPERTY_REMOVE = 'PROPERTY_REMOVE';
export type DONOR_ADD_REMOVE_TYPE = typeof DONOR_PROPERTY_ADD | typeof DONOR_PROPERTY_REMOVE;

interface DonorPropertyAddRemoveData {
    field: string;
    type: DONOR_ADD_REMOVE_TYPE;
}

const analyticsAddAction = createAction(
    'gtm/donor-property-add',
    (field: string): { payload: DonorPropertyAddRemoveData } => ({
        payload: {
            field,
            type: DONOR_PROPERTY_ADD,
        },
    })
);

const analyticsRemoveAction = createAction(
    'gtm/donor-property-remove',
    (field: string): { payload: DonorPropertyAddRemoveData } => ({
        payload: {
            field,
            type: DONOR_PROPERTY_REMOVE,
        },
    })
);

const sendAnalyticsDonorPropertyAddRemove = (_state: RootState, action: PayloadAction<DonorPropertyAddRemoveData>) => {
    const {
        type,
        payload: { field, type: value },
    } = action;

    const eventType = value === DONOR_PROPERTY_ADD ? 'add' : 'remove';

    TagManager.dataLayer({
        dataLayerName: GOOGLE_TAG_DATA_LAYER_NAME,
        dataLayer: {
            event: `property-${eventType}`,
            actionType: type,
            field,
            value,
        },
    });
};

export const donorPropertyAddAction = createAnalyticsAction(analyticsAddAction, sendAnalyticsDonorPropertyAddRemove);

export const donorPropertyRemoveAction = createAnalyticsAction(
    analyticsRemoveAction,
    sendAnalyticsDonorPropertyAddRemove
);
