export enum TaxonomyType {
    CLASS_HIERARCHY = 'CLASS_HIERARCHY',
}

export interface TaxonomyData {
    referenceId: string;
    description: string;
    taxonomyNodes: TaxonomyNode[];
    title: string;
    type: TaxonomyType;
}

export interface TaxonomyNode {
    color: string;
    description: string;
    featureTypeTitle: string;
    featureTypeReferenceId: string;
    parentReferenceId: string;
    priorityOrder: number;
    referenceId: string;
    shortTitle: string;
    title: string;
    taxon: TaxonomyTaxon;
}

export interface TaxonomyTaxon {
    referenceId: string;
    symbol: string;
}
