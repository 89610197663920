import { PayloadAction } from '@reduxjs/toolkit';
import TagManager from 'react-gtm-module';
import { GOOGLE_TAG_DATA_LAYER_NAME } from '../../../constants/analytics';
import { filterSlice, FiltersPayload } from '../../filter/filter-slice';
import { type RootState } from '../../redux';
import { createAnalyticsAction } from '../create-analytics-action';

const sendAnalyticsSpecimensFilterRemove = (_state: RootState, action: PayloadAction<FiltersPayload>) => {
    const {
        payload: {
            filterArguments: [{ field, value }],
        },
    } = action;

    TagManager.dataLayer({
        dataLayerName: GOOGLE_TAG_DATA_LAYER_NAME,
        dataLayer: {
            event: 'userHasRemovedFilterInSession',
            actionType: 'userHasRemovedFilterInSession',
            field,
            value,
        },
    });
};

export const specimensFilterRemoveAction = createAnalyticsAction(
    filterSlice.actions.removeFilters,
    sendAnalyticsSpecimensFilterRemove
);
