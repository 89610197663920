{
    "pageLinks": [
        {
            "title": "Knowledge",
            "links": [{ "title": "Cell Type Knowledge Explorer", "url": "/celltypes" }]
        },
        {
            "title": "Data",
            "links": [{ "title": "Projects", "url": "/data" }]
        },
        {
            "title": "Tools",
            "links": [
                { "title": "ABC Atlas", "url": "/abcatlas" },
                { "title": "MapMyCells", "url": "/mapmycells/process" }
            ]
        }
    ],
    "helpLinks": [
        {
            "title": "Allen Institute for Brain Science",
            "url": "https://alleninstitute.org/division/brain-science/"
        },
        {
            "title": "Feedback",
            "url": "https://docs.google.com/forms/d/e/1FAIpQLSeIaEalEJM-sz4PYMMXNtWvLXdGceqTdfP_Qvp3DIv7uEHSFg/viewform"
        },
        { "title": "Community Forum", "url": "https://community.brain-map.org/c/how-to/mapmycells/20" }
    ],
    "termsLinks": [
        { "title": "Citation Policy", "url": "https://alleninstitute.org/legal/citation-policy/" },
        { "title": "Privacy Policy", "url": "https://www.alleninstitute.org/legal/privacy-policy/" },
        { "title": "Terms of Use", "url": "https://www.alleninstitute.org/legal/terms-use/" }
    ]
}
