import { useCallback, useState } from 'react';

import { BlockingNotification } from '~/src/components/ui/blocking-notification';
import { POSITIVE_TERMS_ACKNOWLEDGED_VALUE, TERMS_OF_USE_STORAGE_KEY } from '~/src/constants/local-storage';
import { TermsOfUseContent } from '../../components/complex/terms-of-use-content';

export const TermsOfUse = () => {
    const localStorageData = localStorage.getItem(TERMS_OF_USE_STORAGE_KEY);
    const [termsAcknowledged, setTermsAcknowledged] = useState(localStorageData);

    const onAcknowledge = useCallback(() => {
        localStorage.setItem(TERMS_OF_USE_STORAGE_KEY, POSITIVE_TERMS_ACKNOWLEDGED_VALUE);
        setTermsAcknowledged(POSITIVE_TERMS_ACKNOWLEDGED_VALUE);
    }, []);

    if (termsAcknowledged === POSITIVE_TERMS_ACKNOWLEDGED_VALUE) {
        return null;
    }

    return (
        <BlockingNotification>
            <TermsOfUseContent onAcknowledge={onAcknowledge} />
        </BlockingNotification>
    );
};
