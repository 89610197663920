import { GTM_IS_PRODUCTION } from './environment';

export const GOOGLE_TAG_MANAGER_ID = 'GTM-WBQ6TP6';
export const GOOGLE_TAG_DATA_LAYER_NAME = 'dataLayer';
export const GOOGLE_TAG_MANAGER_ENVIRONMENT = GTM_IS_PRODUCTION ? 'env-1' : 'env-3';
export const GOOGLE_TAG_MANAGER_AUTH = GTM_IS_PRODUCTION ? 'aQwTZq7ZsU9O-yBSoxh5gA' : 'i0PZssTVVz5KXrg06Ph4KQ';

export const INVENTORY_FILTER_MENU__GTM = 'inventory-filter-menu__GTM';
export const INVENTORY_FILTER_VALUE__GTM = 'inventory-filter-value__GTM';
export const INVENTORY_PROJECT_LINK__GTM = 'inventory-project-link__GTM';
export const INVENTORY_SORT_MENU__GTM = 'inventory-sort-menu__GTM';
export const INVENTORY_SPECIMENS_LINK__GTM = 'inventory-specimens-link__GTM';
export const PROJECT_ASSOCIATION_LINK__GTM = 'project-association-link__GTM';
export const PROJECT_PUBLISHING_LINK__GTM = 'project-publishing-link__GTM';
export const PROJECT_PROTOCOL_LINK__GTM = 'project-protocol-link__GTM';
export const PROJECT_SPECIMENS_LINK__GTM = 'project-specimens-link__GTM';
