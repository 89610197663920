import { Layout as ReactGridLayout } from 'react-grid-layout';
import { SvgIconProps } from '@mui/material/SvgIcon';

import { DoubleHorizontalIcon } from '../components/ui/icons/layouts/double-horizontal-icon';
import { DoubleVerticalIcon } from '../components/ui/icons/layouts/double-vertical-icon';
import { QuadrupleBottomIcon } from '../components/ui/icons/layouts/quadruple-bottom-icon';
import { QuadrupleIcon } from '../components/ui/icons/layouts/quadruple-icon';
import { QuadrupleLeftIcon } from '../components/ui/icons/layouts/quadruple-left-icon';
import { QuadrupleRightIcon } from '../components/ui/icons/layouts/quadruple-right-icon';
import { QuadrupleTopIcon } from '../components/ui/icons/layouts/quadruple-top-icon';
import { SingleIcon } from '../components/ui/icons/layouts/single-icon';
import { TripleBottomIcon } from '../components/ui/icons/layouts/triple-bottom-icon';
import { TripleLeftIcon } from '../components/ui/icons/layouts/triple-left-icon';
import { TripleRightIcon } from '../components/ui/icons/layouts/triple-right-icon';
import { TripleTopIcon } from '../components/ui/icons/layouts/triple-top-icon';
import { SingleViewIcon } from '../components/ui/icons/layouts/single-view';
import { DoubleViewIcon } from '../components/ui/icons/layouts/double-view';
import { TripViewIcon } from '../components/ui/icons/layouts/trip-view';
import { QuadSideViewIcon } from '../components/ui/icons/layouts/quad-side-view';
import { QuadViewIcon } from '../components/ui/icons/layouts/quad-view';
/*
    Guide to Layouts
    - Naming: Each layout is named by how many items it has and the position of the biggest item
    - `columns`: The number of columns necessary to support that particular layout. All layouts fit between 1 and 3 columns
    - `compactType`: A `react-grid-layout` setting that controls which direction to favor when moving items that are in the space
                     of the item you're currently dragging. Trial and error is an easy way to determine what each layout needs
    - `layout`: The `react-grid-layout` settings for drawing each of the items correctly. The number of layouts will correspond to the
                variable name of the layout
    - `icon`: SVG icon associated with the layout
    - `tooltip`: Text used for the button tooltip explaining the layout
*/

export type CompactType = 'horizontal' | 'vertical';
export type VisualizationLayoutConfig = {
    columns: 1 | 2 | 3;
    compactType: CompactType;
    layout: ReactGridLayout[];
    icon: (props: SvgIconProps) => JSX.Element;
    tooltip: string;
};

export enum Layout {
    Single = 'Single',
    DoubleVertical = 'Double Vertical',
    DoubleHorizontal = 'Double Horizontal',
    TripleLeft = 'Triple (Left)',
    TripleRight = 'Triple (Right)',
    TripleBottom = 'Triple (Bottom)',
    TripleTop = 'Triple (Top)',
    Quadruple = 'Quadruple',
    QuadrupleRight = 'Quadruple (Right)',
    QuadrupleBottom = 'Quadruple (Bottom)',
    QuadrupleTop = 'Quadruple (Top)',
    QuadrupleLeft = 'Quadruple (Left)',
}

export enum LayoutGroup {
    Single = 'Single',
    Double = 'Double',
    Triple = 'Triple',
    Quadruple = 'Quadruple',
}

const SINGLE_TOOLTIP = 'Single visualization';
const DOUBLE_TOOLTIP = 'Two visualizations';
const TRIPLE_TOOLTIP = 'Three visualizations';
const QUADRUPLE_TOOLTIP = 'Four visualizations';

export const singleLayout: VisualizationLayoutConfig = {
    columns: 1,
    compactType: 'horizontal',
    layout: [{ i: 'a', x: 0, y: 0, w: 1, h: 1 }],
    icon: SingleIcon,
    tooltip: SINGLE_TOOLTIP,
};

export const doubleHorizontalLayout: VisualizationLayoutConfig = {
    columns: 2,
    compactType: 'horizontal',
    layout: [
        { i: 'a', x: 0, y: 0, w: 1, h: 1 },
        { i: 'b', x: 1, y: 0, w: 1, h: 1 },
    ],
    icon: DoubleHorizontalIcon,
    tooltip: DOUBLE_TOOLTIP,
};

export const doubleVerticalLayout: VisualizationLayoutConfig = {
    columns: 1,
    compactType: 'vertical',
    layout: [
        { i: 'a', x: 0, y: 0, w: 1, h: 1 },
        { i: 'b', x: 0, y: 1, w: 1, h: 1 },
    ],
    icon: DoubleVerticalIcon,
    tooltip: DOUBLE_TOOLTIP,
};

export const tripleLeftLayout: VisualizationLayoutConfig = {
    columns: 2,
    compactType: 'horizontal',
    layout: [
        { i: 'a', x: 0, y: 0, w: 1, h: 2 },
        { i: 'b', x: 1, y: 0, w: 1, h: 1 },
        { i: 'c', x: 1, y: 1, w: 1, h: 1 },
    ],
    icon: TripleLeftIcon,
    tooltip: TRIPLE_TOOLTIP,
};

export const tripleRightLayout: VisualizationLayoutConfig = {
    columns: 2,
    compactType: 'horizontal',
    layout: [
        { i: 'a', x: 1, y: 0, w: 1, h: 2 },
        { i: 'b', x: 0, y: 0, w: 1, h: 1 },
        { i: 'c', x: 0, y: 1, w: 1, h: 1 },
    ],
    icon: TripleRightIcon,
    tooltip: TRIPLE_TOOLTIP,
};

export const tripleBottomLayout: VisualizationLayoutConfig = {
    columns: 2,
    compactType: 'vertical',
    layout: [
        { i: 'a', x: 1, y: 1, w: 2, h: 1 },
        { i: 'b', x: 0, y: 0, w: 1, h: 1 },
        { i: 'c', x: 1, y: 0, w: 1, h: 1 },
    ],
    icon: TripleBottomIcon,
    tooltip: TRIPLE_TOOLTIP,
};

export const tripleTopLayout: VisualizationLayoutConfig = {
    columns: 2,
    compactType: 'vertical',
    layout: [
        { i: 'a', x: 0, y: 0, w: 2, h: 1 },
        { i: 'b', x: 0, y: 1, w: 1, h: 1 },
        { i: 'c', x: 1, y: 1, w: 1, h: 1 },
    ],
    icon: TripleTopIcon,
    tooltip: TRIPLE_TOOLTIP,
};

export const quadrupleLayout: VisualizationLayoutConfig = {
    columns: 2,
    compactType: 'vertical',
    layout: [
        { i: 'a', x: 0, y: 0, w: 1, h: 1 },
        { i: 'b', x: 1, y: 0, w: 1, h: 1 },
        { i: 'c', x: 0, y: 1, w: 1, h: 1 },
        { i: 'd', x: 1, y: 1, w: 1, h: 1 },
    ],
    icon: QuadrupleIcon,
    tooltip: QUADRUPLE_TOOLTIP,
};

export const quadrupleRightLayout: VisualizationLayoutConfig = {
    columns: 2,
    compactType: 'horizontal',
    layout: [
        { i: 'a', x: 1, y: 0, w: 1, h: 3 },
        { i: 'b', x: 0, y: 0, w: 1, h: 1 },
        { i: 'c', x: 0, y: 1, w: 1, h: 1 },
        { i: 'd', x: 0, y: 2, w: 1, h: 1 },
    ],
    icon: QuadrupleRightIcon,
    tooltip: QUADRUPLE_TOOLTIP,
};

export const quadrupleBottomLayout: VisualizationLayoutConfig = {
    columns: 3,
    compactType: 'vertical',
    layout: [
        { i: 'a', x: 0, y: 1, w: 3, h: 1 },
        { i: 'b', x: 0, y: 0, w: 1, h: 1 },
        { i: 'c', x: 1, y: 0, w: 1, h: 1 },
        { i: 'd', x: 2, y: 0, w: 1, h: 1 },
    ],
    icon: QuadrupleBottomIcon,
    tooltip: QUADRUPLE_TOOLTIP,
};

export const quadrupleTopLayout: VisualizationLayoutConfig = {
    columns: 3,
    compactType: 'vertical',
    layout: [
        { i: 'a', x: 0, y: 0, w: 3, h: 1 },
        { i: 'b', x: 0, y: 1, w: 1, h: 1 },
        { i: 'c', x: 1, y: 1, w: 1, h: 1 },
        { i: 'd', x: 2, y: 1, w: 1, h: 1 },
    ],
    icon: QuadrupleTopIcon,
    tooltip: QUADRUPLE_TOOLTIP,
};

export const quadrupleLeftLayout: VisualizationLayoutConfig = {
    columns: 2,
    compactType: 'horizontal',
    layout: [
        { i: 'a', x: 0, y: 0, w: 1, h: 3 },
        { i: 'b', x: 1, y: 0, w: 1, h: 1 },
        { i: 'c', x: 1, y: 1, w: 1, h: 1 },
        { i: 'd', x: 1, y: 2, w: 1, h: 1 },
    ],
    icon: QuadrupleLeftIcon,
    tooltip: QUADRUPLE_TOOLTIP,
};

export const layoutMap = new Map([
    [Layout.Single, singleLayout],
    [Layout.DoubleHorizontal, doubleHorizontalLayout],
    [Layout.DoubleVertical, doubleVerticalLayout],
    [Layout.TripleLeft, tripleLeftLayout],
    [Layout.TripleRight, tripleRightLayout],
    [Layout.TripleBottom, tripleBottomLayout],
    [Layout.TripleTop, tripleTopLayout],
    [Layout.Quadruple, quadrupleLayout],
    [Layout.QuadrupleRight, quadrupleRightLayout],
    [Layout.QuadrupleBottom, quadrupleBottomLayout],
    [Layout.QuadrupleTop, quadrupleTopLayout],
    [Layout.QuadrupleLeft, quadrupleLeftLayout],
]);

// HACK: We're simplifying the possible layouts while we debug issues
// that can occur in the visualizations often when there are three of four
// on the page at one time.
export const layoutMapSimple = new Map([
    [Layout.Single, singleLayout],
    [Layout.DoubleHorizontal, doubleHorizontalLayout],
    [Layout.DoubleVertical, doubleVerticalLayout],
]);

export const layoutMapGroupings = new Map([
    [LayoutGroup.Single, [Layout.Single]],
    [LayoutGroup.Double, [Layout.DoubleHorizontal, Layout.DoubleVertical]],
    [LayoutGroup.Triple, [Layout.TripleLeft, Layout.TripleRight, Layout.TripleBottom, Layout.TripleTop]],
    [
        LayoutGroup.Quadruple,
        [Layout.Quadruple, Layout.QuadrupleRight, Layout.QuadrupleBottom, Layout.QuadrupleTop, Layout.QuadrupleLeft],
    ],
]);

export const layoutMapArray = [
    [Layout.Single],
    [Layout.DoubleHorizontal, Layout.DoubleVertical],
    [Layout.TripleLeft, Layout.TripleRight, Layout.TripleBottom, Layout.TripleTop],
    [Layout.Quadruple, Layout.QuadrupleRight, Layout.QuadrupleBottom, Layout.QuadrupleTop, Layout.QuadrupleLeft],
];

// HACK: We're simplifying the possible layouts while we debug issues
// that can occur in the visualizations often when there are three of four
// on the page at one time.
export const layoutMapGroupingsSimple = new Map([
    [LayoutGroup.Single, [Layout.Single]],
    [LayoutGroup.Double, [Layout.DoubleHorizontal, Layout.DoubleVertical]],
]);

export const layoutKeyMap = ['a', 'b', 'c', 'd'];

export const frameCountByLayout = {
    Single: 1,
    Double: 2,
    Triple: 3,
    Quadruple: 4,
};

export const calculateNextLayoutIncrease = (currentLayout: Layout): Layout => {
    if (layoutMapGroupings.get(LayoutGroup.Single).includes(currentLayout)) {
        return Layout.DoubleHorizontal;
    }

    if (layoutMapGroupings.get(LayoutGroup.Double).includes(currentLayout)) {
        return Layout.TripleLeft;
    }

    if (layoutMapGroupings.get(LayoutGroup.Triple).includes(currentLayout)) {
        return Layout.Quadruple;
    }

    if (layoutMapGroupings.get(LayoutGroup.Quadruple).includes(currentLayout)) {
        return Layout.Quadruple;
    }

    // WARNING: This should never occur, but fallback to Single if we don't find the next layout
    return Layout.Single;
};

export const calculateNextLayoutDecrease = (currentLayout: Layout): Layout => {
    if (layoutMapGroupings.get(LayoutGroup.Quadruple).includes(currentLayout)) {
        return Layout.TripleLeft;
    }

    if (layoutMapGroupings.get(LayoutGroup.Triple).includes(currentLayout)) {
        return Layout.DoubleHorizontal;
    }

    if (layoutMapGroupings.get(LayoutGroup.Double).includes(currentLayout)) {
        return Layout.Single;
    }

    if (layoutMapGroupings.get(LayoutGroup.Single).includes(currentLayout)) {
        return Layout.Single;
    }

    // WARNING: This should never occur, but fallback to Single if we don't find the next layout
    return Layout.Single;
};
export const SingleLayoutGroup = [SingleViewIcon];
export const DoubleLayoutGroup = [DoubleViewIcon, DoubleViewIcon];
export const TripleLayoutGroup = [TripViewIcon, TripViewIcon, TripViewIcon, TripViewIcon];
export const QuadrupleLayoutGroup = [
    QuadViewIcon,
    QuadSideViewIcon,
    QuadSideViewIcon,
    QuadSideViewIcon,
    QuadSideViewIcon,
];

export const findLayoutIconGroup = (numberOfViews: number) => {
    switch (numberOfViews) {
        case 1:
            return SingleLayoutGroup;
        case 2:
            return DoubleLayoutGroup;
        case 3:
            return TripleLayoutGroup;
        case 4:
            return QuadrupleLayoutGroup;
        default:
            return SingleLayoutGroup;
    }
};
