import { FormEvent } from 'react';

import Checkbox from '@mui/material/Checkbox';
import ListItemIcon from '@mui/material/ListItemIcon';
import Switch from '@mui/material/Switch';
import { SettingsMenuItemProps, SettingsControls } from './types';
import { StyledMenuItem, StyledListItemText } from './styled';

export const SettingsMenuItem = ({ label, icon, checkboxAdornment, onClick, ...restProps }: SettingsMenuItemProps) => {
    // We spread the rest of the props in the component signature here because the component accepts different props
    // depends on the menu item type.
    // And to prevent isToggled from being applied to StyledMenuItem, we spread that out for use only when it is valid
    type RestPropsWithoutIsToggled = Omit<typeof restProps, 'isToggled'> & { isToggled?: boolean };
    const { isToggled, ...spreadableProps } = restProps as RestPropsWithoutIsToggled;

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement> | FormEvent<HTMLLIElement>) => {
        event.stopPropagation();
        if (!restProps.type) {
            return;
        }
        restProps.onChange?.(event);
    };

    const handleControlsOnClick = (event: React.MouseEvent<HTMLElement>) => {
        // Prevent Checkbox's click event from reaching MenuItem
        event.stopPropagation();
    };

    return (
        <StyledMenuItem
            onClick={(e) => onClick?.(e)}
            {...spreadableProps}
        >
            {(icon || restProps.type) && (
                <ListItemIcon>
                    {icon && icon}
                    {restProps.type === SettingsControls.checkbox && (
                        <Checkbox
                            checked={restProps.isChecked ?? false}
                            onChange={handleOnChange}
                            onClick={handleControlsOnClick}
                            size="small"
                            disableRipple
                        />
                    )}
                    {restProps.type === SettingsControls.checkbox && checkboxAdornment}
                    {restProps.type === SettingsControls.toggle && (
                        <Switch
                            checked={isToggled}
                            onChange={handleOnChange}
                            onClick={handleControlsOnClick}
                            size="small"
                            disableRipple
                        />
                    )}
                </ListItemIcon>
            )}
            <StyledListItemText>{label}</StyledListItemText>
        </StyledMenuItem>
    );
};
