import { FallbackProps } from 'react-error-boundary';
import Typography from '@mui/material/Typography';
import HomeIcon from '@mui/icons-material/Home';
import RefreshIcon from '@mui/icons-material/Refresh';
import UndoIcon from '@mui/icons-material/Undo';
import ScheduleIcon from '@mui/icons-material/Schedule';
import Stack from '@mui/material/Stack';
import { StyledActionText } from '~/src/components/ui/action-text';
import { DarkHeader } from '../../components/complex/dark-header';
import { BkpLink } from '../../components/ui/bkp-link';
import { IS_PRODUCTION } from '../../constants/environment';
import { StyledItem } from './styled';

export const FatalErrorPage = ({ error, resetErrorBoundary }: FallbackProps) => (
    <>
        <DarkHeader title="Something went wrong..." />
        <Typography
            variant="subtitle2"
            fontSize="large"
            marginX={5}
            marginY={2}
        >
            There was an error. Apologies for the inconvenience, please try the following:
        </Typography>
        <Stack
            spacing={1}
            paddingX={5}
        >
            <StyledItem>
                <UndoIcon />
                <StyledActionText
                    onClick={() => {
                        resetErrorBoundary();
                    }}
                >
                    Try again now
                </StyledActionText>
            </StyledItem>
            <StyledItem>
                <RefreshIcon />
                <StyledActionText
                    onClick={() => {
                        window.location.reload();
                    }}
                >
                    Refresh page now
                </StyledActionText>
            </StyledItem>
            <StyledItem>
                <HomeIcon />
                <BkpLink href="/">Head back to the home page</BkpLink>
            </StyledItem>
            <StyledItem>
                <ScheduleIcon />
                Try again later.
            </StyledItem>
            {!IS_PRODUCTION && <Typography marginTop={2}>{error?.message}</Typography>}
        </Stack>
    </>
);
