const matchKeyString = (arr: string[]): string => arr.map((str) => JSON.stringify(str)).join();

const specimenQueryString = 'aio_specimen';
const specimenTableDataKey = 'SpecimensTableData';
const donorTableDataKey = 'DonorsTableData';
const specimenImagesKey = 'SpecimenImages';
export const specimenTableDataCacheKey = (filter: string[], sort: string[]) =>
    `${specimenQueryString}:${specimenTableDataKey}({"filter":[${matchKeyString(filter)}],"sort":[${matchKeyString(
        sort
    )}]})`;
export const specimenImagesCacheKey = (filter: string[], sort: string[]) =>
    `${specimenQueryString}:${specimenImagesKey}({"filter":[${matchKeyString(filter)}],"sort":[${matchKeyString(
        sort
    )}]})`;
export const donorTableDataCacheKey = (filter: string[], sort: string[]) =>
    `${specimenQueryString}:${donorTableDataKey}({"filter":[${matchKeyString(filter)}],"sort":[${matchKeyString(
        sort
    )}]})`;
