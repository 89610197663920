import { useMemo } from 'react';
import { FilterType, Operator } from '../../../types';
import { useAllFieldsQuery } from '../../view-properties';

export const useSpecimensQualitativeMeasurementProperties = (projectReferenceId: string) => {
    const response = useAllFieldsQuery(projectReferenceId);
    const data = useMemo(
        () =>
            response.data?.filter(
                ({ type, filterOperator }) => type === FilterType.MEASUREMENT && filterOperator === Operator.BETWEEN
            ),
        [response.data]
    );
    return { ...response, data };
};

export const useSpecimensMeasurementRefIds = (projectReferenceId: string) => {
    const response = useSpecimensQualitativeMeasurementProperties(projectReferenceId);
    const data = useMemo(() => response.data?.map(({ featureType }) => featureType.referenceId), [response.data]);
    return { ...response, data };
};
