/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

// call a function after a given delay as an effect
// if this function is invoked again before delayMs milliseconds have gone by
// then the timer is reset, and the onTimerAbort() function is invoked, if it exists
export function useDebounceEffect<T extends unknown[]>(
    delayMs: number,
    effect: () => unknown,
    dependencies: T,
    onTimerAbort?: () => void
) {
    useEffect(() => {
        const timeout = window.setTimeout(effect, delayMs);
        return () => {
            window.clearTimeout(timeout);
            onTimerAbort?.();
        };
        // effect almost certainly has a closure over things in dependencies:
    }, [delayMs, effect, onTimerAbort, ...dependencies]);
}
