import { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
// TODO: remove me when we migrate to react18+ (it should magically batch updates for us)
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom';

const MS_PER_FRAME = 16;
function mix(a: number, b: number, p: number) {
    return a * (1 - p) + b * p;
}

/**
 * a (pure js) animation utility for managing transitions of data in an array
 * @param valueOf a function that returns the animated value of an item of type T
 * @param update a function that returns a new T, with its animated value given by v
 * @param durationMs the duration of the animation, in miliseconds. note that values below 16 may be visually unstable
 * @param data the data to animate. any time that this hook runs with new (not deeply equal) data, it will trigger an animation.
 * in the event the array changes size, missing values will have 0 substituted
 */
export function useAnimatedValues<T>(
    valueOf: (t: T) => number,
    update: (t: T, v: number) => T,
    durationMs: number,
    data: readonly T[]
) {
    const [prev, setPrev] = useState<readonly T[]>(data.map((x) => update(x, 0)));
    const [running, setRunning] = useState<boolean>(false);
    const [result, setResult] = useState<readonly T[]>(data.map((x) => update(x, 0)));

    useEffect(() => {
        if (!running && !isEqual(prev, data)) {
            const start = performance.now();
            setRunning(true);
            const interval = window.setInterval(() => {
                unstable_batchedUpdates(() => {
                    const now = performance.now();
                    const p = Math.min(1, (now - start) / durationMs);
                    setResult(data.map((goal, i) => update(goal, mix(valueOf(prev[i] || goal), valueOf(goal), p))));
                });
            }, MS_PER_FRAME);
            window.setTimeout(() => {
                unstable_batchedUpdates(() => {
                    setPrev(data);
                    setRunning(false);
                    window.clearInterval(interval);
                });
            }, durationMs);
        }
    }, [data, running, prev, durationMs, valueOf, update]);
    return result;
}
