/* eslint-disable import/no-import-module-exports */
import { configureStore } from '@reduxjs/toolkit';
import { middleware } from './redux-middleware';
import { rootReducer } from './root-reducer';
import { browserScheduledSubscriberStore } from './renderOnSchedule';

export const reduxStore = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
    enhancers: [browserScheduledSubscriberStore],
});

// Hot Module Replacement only runs on Parcel's development server. It's broken at the moment
// because HMR doesn't work with class components, and our ErrorBoundary is the only class component
// remaining in the codebase. This will be removed at a future date and we'll have the niceness of HMR again.
if (module.hot) {
    module.hot.accept('./root-reducer', () => reduxStore.replaceReducer(rootReducer));
}

export type AppDispatch = typeof reduxStore.dispatch;
export type AppStore = typeof reduxStore;
