/**
 * shouldForwardProp in styled() is a feature to carry transient props
 * which can be used to prevent styled props to be forwarded to the DOM:
 * shouldForwardProp: (prop) => prop !== 'color' && prop !== 'isVisible'
 *
 * The default method could be painful to write if there are too many of props to check.
 * This is a util to simplify down the usage
 *
 * example usage:
 *
 * export const MyComponent = styled(Box, {
 *  shouldForwardProp: shouldForwardProp<MyProps>(['color', 'isVisible'])
 * })<MyProps>(({ color, isVisible }) => ({
 *    //...styles
 * }));
 */
export const shouldForwardProp =
    <T extends Record<string, unknown>>(props: ReadonlyArray<keyof T> | Array<keyof T>) =>
    (prop: string) =>
        !props.includes(prop);
