import { useDispatch } from 'react-redux';
import { useErrorBoundary } from 'react-error-boundary';
import { ApolloError, isApolloError } from '@apollo/client';
import { NonFatalError } from '~/src/types/error';
import { errorSlice } from '~/src/services/error';
import { createErrorText, createReadableApolloErrors, splitObjectToKeyValString } from '~/src/services/error/utils';
import { GraphQLError } from 'graphql';

export type BKPErrorHandler = (error?: Error | ApolloError, extraInfo?: string) => void;

const checkIsErrorNonFatal = (error: Error): error is NonFatalError =>
    error instanceof NonFatalError || error instanceof ApolloError || error instanceof GraphQLError;

export const useErrorHandler = (): BKPErrorHandler => {
    const { showBoundary } = useErrorBoundary();
    const dispatch = useDispatch();

    const handleError: BKPErrorHandler = (error?: Error, extraInfo?: string) => {
        if (!error) {
            return;
        }

        if (checkIsErrorNonFatal(error)) {
            const apolloErrs = isApolloError(error) ? createErrorText([createReadableApolloErrors(error)]) : null;
            const allErrs = Object.entries(error).reduce(
                (acc, item) =>
                    `${acc}\n ${item[0]}: ${
                        typeof item[1] === 'object' && item[1] ? splitObjectToKeyValString(item[1]) : item[1]
                    }`,
                ''
            );

            // non fatal error handling, this triggers in-page ErrorNotification
            dispatch(
                errorSlice.actions.logNonFatalError({
                    friendlyMessage: error.friendlyMessage,
                    errorMessage: error.originalError?.message ?? '',
                    errorName: error.originalError?.name ?? '',
                    stack: error.originalError?.stack ?? '',
                    apolloErrorsText: apolloErrs,
                    extraInfo,
                    fullErrorInfo: allErrs,
                })
            );
            return;
        }

        // fatal error handling, this should invoke react-error-boundary
        // and renders a fatal error page UI
        showBoundary(error);
    };

    return handleError;
};
