// the explore page query has multiple entries, each corresponding to a frame on the page

import queryString from 'query-string';
import castArray from 'lodash/castArray';

import {
    FILTER_FIELD_QUERY_DELIMITER,
    FILTER_ARGUMENT_QUERY_DELIMITER,
    QUERYSTRING_COMMA_REPLACER,
    GENE_EXPRESSION_CATEGORY,
    EXPLORE_PAGE_URL_STATE_PARAMETER,
} from '~/src/constants/strings';
import { Layout } from '~/src/constants/layouts';
import { BkpDatasetFragment, CellFilterType } from '~/src/types/generated-schema-types';
import { DataCollectionId, DatasetId } from '~/src/types/reference-ids';
import { CameraProjection, ScatterbrainCamera } from '~/src/components/complex/scatterbrain/types';
import type { ExplorePage } from './explore-page-slice';
import { DEFAULT_VIS_CONTROL_STATE, DEFAULT_ANNOTATION_STATE } from './constants';
import { GeneFilter, GenesSymbolsUxState, VisSettings, VisFrameLayout } from './types/states';
import { ColorByType, QuantitativeFilterType } from './types/namespace';

// All query param names from the old URL style
const LEGACY_QUERY_PARAMS = [
    // Original Params
    'views',
    'genes',
    'filterOptions',
    'layoutState',
    'visualizations',
    // Second version, where state was encoded and stored in the query param. Abandoned in favor
    // of the URL hash since that doesn't have size limitations
    EXPLORE_PAGE_URL_STATE_PARAMETER,
];

/**
 * A helper function to determine if a given URL contains any legacy parameters. This prevents any
 * page breakages if/when we add new URL parameters in the future.
 *
 * @param url Legacy version of a url string that will be parsed to set page state
 * @returns True if we find any legacy parameters, false if none are present
 */
export const urlContainsLegacyParams = (url?: string): boolean =>
    LEGACY_QUERY_PARAMS.some((param) => url?.includes(param));

function parseVisualizationString(visualization: string) {
    const [reactGridKey, dataset, colorBy] = visualization.split('~');
    const [dataCollectionId, visualizationId] = dataset.split(':');
    const [colorType, colorProperty, index, rangeMin, rangeMax, isTransparent, transparency] = colorBy.split(':');
    return {
        reactGridKey,
        dataCollectionId,
        visualizationId,
        colorType,
        colorProperty,
        index,
        rangeMax,
        rangeMin,
        isTransparent,
        transparency,
    };
}

function deserializeVisualizations(visualizations: string | string[] | undefined) {
    if (!visualizations) {
        return [];
    }

    return castArray(visualizations).map(parseVisualizationString);
}

function parseFilterEntry(filterValue: string) {
    const [, fieldKey] = filterValue.split(FILTER_FIELD_QUERY_DELIMITER);
    const [field, restOfString] = fieldKey.split(':');
    const [filterOperator, preValue] = restOfString.split(FILTER_ARGUMENT_QUERY_DELIMITER);
    const value = preValue.replace(QUERYSTRING_COMMA_REPLACER, ',');
    let index;
    let range;
    if (field === GENE_EXPRESSION_CATEGORY) {
        const [, geneSelectedRange, geneIndex] = filterValue.split('~');
        range = geneSelectedRange.split('|');
        index = geneIndex;
    }
    const operator = filterOperator;
    return { field, operator, value, index, min: range?.[0], max: range?.[1] };
}
function parseFilterOptions(config: queryString.ParsedQuery<string>, key: string) {
    const filterEntries = config[`filterOptions[${key}]`];
    if (!filterEntries) {
        return [];
    }
    // parse each thing:
    return castArray(filterEntries).map(parseFilterEntry);
}
function fromFilterEntries(entries: ReturnType<typeof parseFilterOptions>, selectedGene: string | undefined) {
    let gene: GeneFilter = {
        type: CellFilterType.Gene,
        symbol: selectedGene,
        index: 0,
        range: undefined,
        selectedRange: undefined,
    };
    const metadata: Record<string, Record<string, boolean>> = {};
    entries.forEach((entry) => {
        if (entry.field === GENE_EXPRESSION_CATEGORY) {
            const min = Number.parseFloat(entry.min);
            const max = Number.parseFloat(entry.max);
            gene = {
                ...gene,
                index: Number(entry.index.split('.')[1]),
                symbol: entry.index.split('.')[0],
                selectedRange: Number.isFinite(min) && Number.isFinite(max) ? { min, max } : undefined,
                range: undefined,
            };
        } else if (metadata[entry.field]) {
            // the category already exists:
            metadata[entry.field][entry.value] = true;
        } else {
            // we're setting the first value in the given category
            metadata[entry.field] = { [entry.value]: true };
        }
    });
    return { metadataFilters: metadata, geneExpressionFilter: gene };
}
const validFrameKeys: string[] = ['a', 'b', 'c', 'd'];
function deserializeGenesUx(queryEntries: string | string[] | undefined): Record<string, GenesSymbolsUxState> {
    if (!queryEntries) {
        return {};
    }

    return castArray(queryEntries).reduce((acc, entry) => {
        const [pageFrameKey, genesEntry] = entry.split(':');
        const rawKey = pageFrameKey.split('-');
        // old keys were pagekey-frameKey, new keys are just framekey
        const frameKey = rawKey.length > 1 ? rawKey[1] : pageFrameKey;
        if (validFrameKeys.includes(frameKey) && genesEntry.length) {
            const genes = genesEntry.split('|');
            const genesUx = genes.reduce<GenesSymbolsUxState>(
                (genesAcc, gene) => ({
                    ...genesAcc,
                    [gene]: {
                        isExpanded: false,
                        symbol: gene,
                    },
                }),
                {}
            );
            return {
                ...acc,
                [frameKey]: genesUx,
            };
        }
        return acc;
    }, {});
}

function parseGeneColorInfo(v: ReturnType<typeof parseVisualizationString>) {
    if (v.colorType === ColorByType.quantitative) {
        const min = Number.parseFloat(v.rangeMin);
        const max = Number.parseFloat(v.rangeMax);
        // if either of those are non-finite, then bail
        if (Number.isFinite(min) && Number.isFinite(max)) {
            return {
                type: ColorByType.quantitative,
                symbol: v.colorProperty,
                index: Number(v.index),
                range: { min, max },
            } as const;
        }
    }
    return undefined;
}

/**
 * Helper function to serialize the camera state for the URL.
 *
 * We use ~ to separate the frame from the data, _ to separate items, and - to separate vec2 values
 * Serialization format: frameKey~centerX-centerY_widthX-widthY_rotationRadians_projectionType_slicePlane_sliceDepth_sliceDepthRange
 *
 * @param cameras A map of Scatterbrain cameras, keyed by frame
 * @returns A list of stringified cameras for the URL
 */
export const serializeCameras = (cameras: Record<string, ScatterbrainCamera>): string[] =>
    Object.entries(cameras).map(([key, camera]) => {
        if (!camera) {
            return '';
        }

        // If we don't join the vec2s on a non-comma value, the URL parsing library will group things incorrectly
        const serializedCenter = camera.center.join('-');
        const serializedSize = camera.size.join('-');
        const rotationRadiansNotSupportedAnyLonger = 0;
        const cameraPieces = [
            serializedCenter,
            serializedSize,
            rotationRadiansNotSupportedAnyLonger,
            camera.projection,
        ];

        const serializedCamera = cameraPieces.join('_');
        return `${key}~${serializedCamera}`;
    });

/**
 * Helper function to parse through the camera string URL so it can be added to Redux.
 * See serializeCameras for serialization format.
 *
 * @param cameras List of stringified cameras coming from the URL
 * @returns A map of Scatterbrain cameras, keyed by frame
 */
const deserializeCameras = (cameras: string | string[] | undefined): Record<string, ScatterbrainCamera> => {
    if (!cameras) {
        return {};
    }

    return castArray(cameras).reduce((acc, camera) => {
        const [frameKey, cameraString] = camera.split('~');
        const [center, size, _rotationRadians, projection, slicePlane, sliceDepth, sliceDepthRange] =
            cameraString.split('_');
        const [x, y] = center.split('-');
        const [width, height] = size.split('-');

        // Guard for checking camera projection value
        let typedProjection: CameraProjection;
        if (projection !== 'web-image' && projection !== 'cartesian') {
            typedProjection = 'web-image';
            // eslint-disable-next-line no-console
            console.warn('Invalid projection type was provided in the URL. Falling back to `web-image`');
        } else {
            typedProjection = projection;
        }

        const scatterbrainCamera = {
            center: [Number(x), Number(y)],
            size: [Number(width), Number(height)],
            projection: typedProjection,
        };

        if (slicePlane) {
            if (projection !== 'web-image') {
                // eslint-disable-next-line no-console
                console.warn(
                    'Incorrect VolumeSliceCamera projection type was provided in the URL. Reverting to "web-image"'
                );
            }

            return {
                ...acc,
                [frameKey]: {
                    ...scatterbrainCamera,
                    // VolumeSliceCamera is always 'web-image'
                    projection: 'web-image',
                    slice: {
                        plane: slicePlane,
                        depthInMillimeters: sliceDepth,
                        depthRangeInMillimeters: sliceDepthRange,
                    },
                },
            };
        }

        return {
            ...acc,
            [frameKey]: scatterbrainCamera,
        };
    }, {});
};

export const legacyInflateExplorePage = (
    config: queryString.ParsedQuery<string>,
    datasetsByVisualizationIds: Record<string, BkpDatasetFragment>,
    defaultColorBy: string
): ExplorePage | undefined => {
    const visualizations = deserializeVisualizations(config.visualizations);
    // so the gene symbol comes from config.geneExpr,
    // however, the range of the gene is only found in the colorBy section...
    // which is per-frame
    const cameras = deserializeCameras(config.views);
    const genesUx = deserializeGenesUx(config.genes);
    const visFrames = visualizations.reduce((layout, v) => {
        if (validFrameKeys.includes(v.reactGridKey)) {
            const geneInfoFromColorBy = parseGeneColorInfo(v);
            const filterEntries = parseFilterOptions(config, v.reactGridKey);
            const { metadataFilters, geneExpressionFilter } = fromFilterEntries(
                filterEntries,
                genesUx[v.reactGridKey]?.[0]?.symbol
            );
            const gene = {
                type: QuantitativeFilterType.gene,
                index: geneInfoFromColorBy?.index ?? geneExpressionFilter?.index,
                range: geneInfoFromColorBy?.range ?? geneExpressionFilter?.range,
                symbol: geneInfoFromColorBy?.symbol ?? geneExpressionFilter?.symbol,
                selectedRange: geneExpressionFilter?.selectedRange, // only ever from filter
            } as GeneFilter;
            const quantitativeFilters = gene.symbol
                ? {
                      [gene.symbol]: gene,
                  }
                : {};
            const { dataCollectionId, visualizationId } = v;
            const projectId = datasetsByVisualizationIds[visualizationId]?.projectReferenceId;
            const datasetId = datasetsByVisualizationIds[visualizationId]?.referenceId;
            const version = datasetsByVisualizationIds[visualizationId]?.version;

            const geneSymbols = genesUx[v.reactGridKey];
            const settings: VisSettings = {
                projectId,
                dataCollectionId: dataCollectionId as DataCollectionId,
                camera: cameras[v.reactGridKey],
                datasetId: datasetId as DatasetId,
                datasetVersion: version,
                visualizationId,
                ux: {
                    selectedCell: undefined,
                    metadataFilters: {},
                    focusedPanel: null,
                    genes: {
                        canShowDefaultGene: Object.keys(geneSymbols ?? {}).length === 0,
                        symbols: geneSymbols ?? {},
                    },
                    disableClose: false,
                },
                vizControls: DEFAULT_VIS_CONTROL_STATE,
                aesthetic: {
                    performanceVsQuality: undefined,
                    pointSizeScale: 1,
                },
                annotations: DEFAULT_ANNOTATION_STATE,
                metadataFilters,
                quantitativeFilters,
                colorProperties: {
                    category: v.colorProperty,
                    transparency: Number(v.transparency),
                    isTransparent: v.isTransparent === 'true',
                    type: geneInfoFromColorBy ? ColorByType.quantitative : ColorByType.metadata,
                    value: gene.symbol,
                    index: gene.index,
                    range: gene.range,
                },
                colorByDefault: defaultColorBy,
            };
            return { ...layout, [v.reactGridKey]: settings };
        }
        return layout;
    }, {} as VisFrameLayout);
    return {
        currentFrame: 'a',
        layout: (config.layoutState as Layout) ?? Layout.Single,
        type: 'explore',
        visFrames,
        synced: {},
        allowSidebarResize: true,
    };
};
