import { NonFatalErrorInfo } from '~/src/types/error';
import { ErrorReportItem } from './error-report-item';
import { StyledErrorReport } from './styled';

const DEFAULT_STACK_MAX = 5;

export interface ErrorReportProps {
    errorOptionGroups: NonFatalErrorInfo[];
    dismissError?: (errorId: string) => void;
    selfDismissTimeout?: number;
    stackMax?: number;
}

export const ErrorReport = ({
    errorOptionGroups,
    dismissError,
    selfDismissTimeout,
    stackMax = DEFAULT_STACK_MAX,
}: ErrorReportProps) => (
    <StyledErrorReport>
        {errorOptionGroups.slice(0, stackMax).map(({ friendlyMessage, errorId }) => (
            <ErrorReportItem
                key={`${errorId} ${friendlyMessage}`}
                friendlyMessage={friendlyMessage}
                selfDismissTimeout={selfDismissTimeout}
                handleDismissError={() => dismissError(errorId)}
            />
        ))}
    </StyledErrorReport>
);
