import countBy from 'lodash/countBy';
import entries from 'lodash/entries';
import flow from 'lodash/flow';
import head from 'lodash/head';
import last from 'lodash/last';
import maxBy from 'lodash/maxBy';
import partialRight from 'lodash/partialRight';

/**
 * Returns a function that returns a string representation of the
 * value that most often occurs by given key.
 *
 * lodash flex adapted from
 * https://stackoverflow.com/questions/49731282/the-most-frequent-item-of-an-array-using-lodash
 */
export const getModeBy = (key: string) => flow(partialRight(countBy, key), entries, partialRight(maxBy, last), head);
