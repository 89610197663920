import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import has from 'lodash/has';

import { BaseSpecimenPayload, PrepareReturn } from '../../types';

export type SpecimensSummaryReferenceIds = {
    [specimenKey: string]: string[];
};

const INITIAL_STATE: SpecimensSummaryReferenceIds = {};

export type SetSpecimensSummaryReferenceIdsPayload = BaseSpecimenPayload & {
    properties: string[];
};

export const setSpecimensSummaryReferenceIdsReducer: CaseReducer<
    SpecimensSummaryReferenceIds,
    PayloadAction<SetSpecimensSummaryReferenceIdsPayload>
> = (state, action) => {
    const { properties, specimenKey } = action.payload;

    return {
        ...state,
        [specimenKey]: properties,
    };
};

export const setSpecimensSummaryReferenceIdsPrepare = (
    specimenKey: string,
    properties: string[]
): PrepareReturn<SetSpecimensSummaryReferenceIdsPayload> => ({
    payload: {
        specimenKey,
        properties,
    },
});

type InitSpecimensSummaryReferenceIdsReducer = CaseReducer<
    SpecimensSummaryReferenceIds,
    PayloadAction<SetSpecimensSummaryReferenceIdsPayload>
>;

export const initSpecimensSummaryReferenceIdsReducer: InitSpecimensSummaryReferenceIdsReducer = (state, action) => {
    const { properties, specimenKey } = action.payload;

    if (has(state, specimenKey)) {
        return state;
    }

    return {
        ...state,
        [specimenKey]: properties,
    };
};

export const initSpecimensSummaryReferenceIdsPrepare = (
    specimenKey: string,
    properties: string[]
): PrepareReturn<SetSpecimensSummaryReferenceIdsPayload> => ({
    payload: {
        specimenKey,
        properties,
    },
});

export const specimensSummaryReferenceIdsSlice = createSlice({
    name: 'specimens-summary-reference-ids',
    initialState: INITIAL_STATE,
    reducers: {
        setSpecimensSummaryReferenceIds: {
            reducer: setSpecimensSummaryReferenceIdsReducer,
            prepare: setSpecimensSummaryReferenceIdsPrepare,
        },
        initSpecimensSummaryReferenceIds: {
            reducer: initSpecimensSummaryReferenceIdsReducer,
            prepare: initSpecimensSummaryReferenceIdsPrepare,
        },
    },
});
