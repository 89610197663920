import { useParams } from 'react-router-dom';
import compact from 'lodash/compact';
import { cmsClient } from '~/src/services/apollo/cms-client';
import type { ProjectInfoQueryResult } from '~/src/types/generated-schema-types';
import { useProgramPageQuery } from '~/src/types/cms-generated-schema-types';
import type { ProgramPageContentFragment } from '~/src/types/cms-generated-schema-types';
import { useProjectInfoQuery } from '~/src/services/data-catalog/use-project-info-query';
import { useItemCountQuery } from '~/src/services/inventory-filter/use-item-count-query';
import { ItemCount } from '~/src/types/filter-state';
import { CATEGORY_ARGUMENTS } from '~/src/constants/configuration';
import { Operator } from '~/src/types/filter-argument';
import { getAvailableResources } from '~/src/utils/data-catalog/get-available-resources';
import { getPrimaryAuthors } from '~/src/utils/data-catalog/get-primary-authors';
import { getProjectsOverviewData } from '~/src/utils/data-catalog/get-projects-overview-data';
import { getCmsMediaUrl } from '~/src/utils/cms/get-cms-media-url';

import { ProgramPageLayout } from '~/src/components/complex/common-layouts/program-page-layout';
import type { ProgramPageLayoutProps } from '~/src/components/complex/common-layouts/program-page-layout';
import { ProgramPageSkeleton } from '~/src/components/ui/skeleton-closet/page-skeletons/program-page-skeleton';
import { withSkeleton } from '~/src/components/ui/skeleton-closet/with-skeleton';

const includedAliases = ['species', 'modality', 'technique', 'program'];

export const PROJECTS_OVERVIEW_ARGUMENTS = CATEGORY_ARGUMENTS.filter((argument) =>
    includedAliases.includes(argument.alias)
);

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const extractValueByKey = <T extends unknown>(key: string, data?: T[] | null) =>
    compact(data?.map((item) => item[key as keyof T] ?? null) || []);

const getTotalProjectsCount = (data: ItemCount[], programName: string) =>
    data.find((item) => item.name === programName)?.count || 0;

const createProjectTableData = (data?: ProjectInfoQueryResult['data'] | null): ProgramPageLayoutProps['projects'] => {
    if (!data || !data?.dataCollectionProjectInventory?.length) {
        return [];
    }

    return compact(
        data.dataCollectionProjectInventory.map((project) => {
            if (!project) {
                return null;
            }
            return {
                projectName: project.title,
                projectDescription: project.description,
                species: extractValueByKey('name', project.species),
                modality: extractValueByKey('name', project.modality),
                technique: extractValueByKey('name', project.technique),
                subProgram: extractValueByKey('title', project.subProgram),
                primaryAuthor: getPrimaryAuthors(project.dataCreator),
                link: `/data/${project.referenceId}/summary`,
                resources: getAvailableResources(project),
            };
        })
    );
};

const createRelevantResources = (
    resources?: ProgramPageContentFragment['relevantResources'] | null
): ProgramPageLayoutProps['relevantResources'] => {
    if (!resources?.length) {
        return [];
    }
    return compact(
        resources.map((resource) => {
            if (!resource || !resource.name || !resource.url || !resource.type) {
                return null;
            }
            return {
                name: resource.name,
                type: resource.type,
                link: resource.url,
            };
        })
    );
};

export const ProgramPage = withSkeleton(ProgramPageLayout, {
    customSkeleton: <ProgramPageSkeleton />,
});

export const Program = () => {
    const params = useParams();
    const { loading: cmsDataLoading, data } = useProgramPageQuery({
        variables: { slug: params.programSlug || '' },
        client: cmsClient,
        skip: !params.programSlug?.length,
    });
    const program = data?.programs?.data[0]?.attributes;
    const featuredProjects = compact(program?.featuredProjects?.map((item) => (item ? item.referenceId : null))) || [];

    const { loading: itemCountDataLoading, data: itemCountData } = useItemCountQuery(
        PROJECTS_OVERVIEW_ARGUMENTS,
        [
            {
                field: 'program.title',
                value: program?.title || '',
                operator: Operator.CONTAINS,
            },
        ],
        {
            skip: !program?.title.length,
        }
    );

    const { loading: projectsInfoLoading, data: projectsInfoData } = useProjectInfoQuery(featuredProjects);

    const totalProjectsCount = getTotalProjectsCount(itemCountData?.program || [], program?.title || '');
    const projectsOverviewData = getProjectsOverviewData(program?.title, itemCountData);
    const projectsTableData = createProjectTableData(projectsInfoData);
    const relevantResources = createRelevantResources(program?.relevantResources || []);

    return (
        <ProgramPage
            isLoading={cmsDataLoading || itemCountDataLoading || projectsInfoLoading}
            isNotFound={!program}
            header={{
                imageUrl: getCmsMediaUrl(program?.heroImage?.data?.attributes?.url),
                description: program?.description || '',
                link: program?.programSiteLink || '',
            }}
            programName={program?.title || ''}
            projects={projectsTableData}
            projectsOverviewData={projectsOverviewData}
            projectsType="Featured"
            totalProjectsCount={totalProjectsCount}
            relevantResources={relevantResources}
        />
    );
};
