import { styled } from '@mui/material/styles';
import { Box } from '~/src/components/bkp-ui/box';
import { zIndexMap } from '~/src/styles/constants/z-index-map';

export const StyledLayout = styled(Box)(() => ({
    margin: 0,
    display: 'flex',
    flexFlow: 'column',
    height: '100vh',
    width: '100%',
    overflow: 'hidden',
}));

export const StyledHeaderWrapper = styled(Box)(() => ({
    // the header height is sized to content
    margin: 0,
    flex: '0 1 auto',
    position: 'sticky',
    top: 0,
    width: '100%',
    zIndex: zIndexMap.appHeader,
}));

export const StyledMainContentWrapper = styled(Box)(() => ({
    // the content height fills the remaining space
    margin: 0,
    flex: '1 1 auto',
    width: '100%',
    minHeight: '50%',
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'relative',
}));

export const StyledFooterWrapper = styled(Box)(() => ({
    // footer height is sized to content
    margin: 0,
    flex: '0 1 auto',
    width: '100%',
}));
