import { useState, forwardRef, SyntheticEvent } from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import { StyledSnackbar } from './styled';

const DEFAULT_DISMISS_TIMEOUT = 10000;
export interface ErrorReportItemProps {
    friendlyMessage: string;
    selfDismissTimeout?: number;
    handleDismissError?: () => void;
}

const Alert = forwardRef<HTMLDivElement, AlertProps>((props, ref) => (
    <MuiAlert
        elevation={3}
        ref={ref}
        {...props}
    />
));

Alert.displayName = 'Alert';

type HandleOnClose = (event?: SyntheticEvent | Event, reason?: string) => void;

export const ErrorReportItem = ({ friendlyMessage, selfDismissTimeout, handleDismissError }: ErrorReportItemProps) => {
    const [open, setOpen] = useState(true);

    const handleOnClose: HandleOnClose = (_, reason) => {
        // this prevents other click actions from dismissing the error reports
        // e.g.: onetrust cookie settings actions would dismiss them before
        if (reason === 'clickaway') {
            return;
        }
        handleDismissError?.();
        setOpen(false);
    };

    return (
        <StyledSnackbar
            open={open}
            autoHideDuration={selfDismissTimeout ?? DEFAULT_DISMISS_TIMEOUT}
            onClose={handleOnClose}
        >
            <Alert
                severity="error"
                onClose={handleOnClose}
                sx={{ width: '100%' }}
            >
                {friendlyMessage}
            </Alert>
        </StyledSnackbar>
    );
};
