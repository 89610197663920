import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import remove from 'lodash/remove';
import uniqueId from 'lodash/uniqueId';
import { FatalErrorInfo, NonFatalErrorInfo } from '~/src/types';

export interface ErrorState {
    fatalError: null | FatalErrorInfo;
    nonFatalErrors: NonFatalErrorInfo[];
}

const initialState: ErrorState = {
    fatalError: null,
    nonFatalErrors: [],
};

type LogFatalErrorReducer = CaseReducer<ErrorState, PayloadAction<FatalErrorInfo>>;
const logFatalError: LogFatalErrorReducer = (state, { payload }) => {
    state.fatalError = payload;
};

// Used in use-error-handler for typing an error that omits the id
export type LogNonFatalErrorPayload = Omit<NonFatalErrorInfo, 'errorId'>;
type LogNonFatalErrorReducer = CaseReducer<ErrorState, PayloadAction<LogNonFatalErrorPayload>>;
const logNonFatalError: LogNonFatalErrorReducer = (state, { payload }) => {
    state.nonFatalErrors.push({
        ...payload,
        errorId: uniqueId('error_'),
    });
};

type DismissNonFatalErrorPayload = { errorId: string };
type DismissNonFatalErrorReducer = CaseReducer<ErrorState, PayloadAction<DismissNonFatalErrorPayload>>;
const dismissNonFatalError: DismissNonFatalErrorReducer = (state, { payload }) => {
    remove<NonFatalErrorInfo>(state.nonFatalErrors, ({ errorId: id }) => id === payload.errorId);
};

export const errorSlice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        logFatalError,
        logNonFatalError,
        dismissNonFatalError,
    },
});
