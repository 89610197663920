import { createAction, PayloadAction } from '@reduxjs/toolkit';
import TagManager from 'react-gtm-module';

import { GOOGLE_TAG_DATA_LAYER_NAME } from '../../../constants/analytics';
import { FiltersPayload } from '../../filter/filter-slice';
import { type RootState } from '../../redux';
import { createAnalyticsAction } from '../create-analytics-action';

const metadataDownloadAction = createAction(
    'gtm/specimens-metadata-download',
    (filters: FiltersPayload): { payload: FiltersPayload } => ({
        payload: filters,
    })
);

const sendAnalyticsSpecimensMetadataDownload = (_state: RootState, action: PayloadAction<FiltersPayload>) => {
    const {
        payload: { key: specimenKey, filterArguments },
    } = action;

    TagManager.dataLayer({
        dataLayerName: GOOGLE_TAG_DATA_LAYER_NAME,
        dataLayer: {
            event: 'specimenMetadataDownloaded',
            specimenKey,
            filterArguments,
        },
    });
};

export const specimensMetadataDownloadAction = createAnalyticsAction(
    metadataDownloadAction,
    sendAnalyticsSpecimensMetadataDownload
);
