import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

import { NotFound } from '../not-found';

const LoginPage = lazy(() => import('./login'));

export const Login = () => (
    <Routes>
        <Route
            path="/"
            element={<LoginPage />}
        />
        <Route
            path="*"
            element={<NotFound />}
        />
    </Routes>
);
