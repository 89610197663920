import { useLocation } from 'react-router-dom';
import { isFeatureEnabled } from '~/src/utils/feature-flag/feature-flag';
import { featureFlagIds } from '~/src/constants/features';

// this array contains the routes that can adopt the SDS theme
// as we are integrating parts of the BKP with SDS
const SDS_ROUTES = ['/login', '/program'];

const isNewCatalogEnabled = isFeatureEnabled(featureFlagIds.newDataCatalog);
if (isNewCatalogEnabled) {
    SDS_ROUTES.push('/data');
}

/**
 * @TODO: when SDS is fully integrated across all pages, remove this hook and relevant code using MUI library components
 */
export const useIsSdsEnabled = () => {
    const location = useLocation();
    const isSdsEnabled = SDS_ROUTES.some((route) => location.pathname.includes(route));

    return isSdsEnabled;
};
