import { ReactNode } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { useIsSdsEnabled } from '~/src/hooks/use-is-sds-enabled';
import { bkpTheme } from './bkp-theme';
import { selectTheme } from '../../services/theme';

declare module '@mui/styles/defaultTheme' {
    interface DefaultTheme extends Theme {}
}

interface BkpThemeProviderProps {
    children: ReactNode;
    alwaysUseSds?: boolean;
}

export const BkpThemeProvider = ({ children, alwaysUseSds = false }: BkpThemeProviderProps) => {
    const theme = useSelector(selectTheme);
    const useSDS = useIsSdsEnabled();

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={bkpTheme({ theme, useSDS: alwaysUseSds || useSDS })}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
};
