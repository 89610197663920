// lifted from our own transcriptomics explorer
// https://github.com/AllenInstitute/transcriptomics-viewer/blob/dev/src/utils/get-text-width.js
export const getTextWidth = (text: string, font: string) => {
    const canvas: HTMLCanvasElement = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'));
    const context = canvas.getContext('2d');
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
};

// re-use canvas object for better performance
getTextWidth.canvas = null;

export default getTextWidth;
