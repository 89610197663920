import { ProjectCapabilities } from '~/src/types/generated-schema-types';

export const VIZ_CAPABILITY = ProjectCapabilities.BkpDataset;

export const SINGLE_VERTICAL_ROWS = ['Single', 'Double Horizontal'];
export const TRIPLE_VERTICAL_ROWS = ['Quadruple (Right)', 'Quadruple (Left)'];

export const MAX_VISUALIZATION_FRAMES = 4;

// TODO: Pull from the list of columns in the 2D visualization endpoint
export const metadataMapping = new Map([
    ['Cell Type Class', 1],
    ['Cell Type Level2', 2],
    ['Cell Type Level1', 3],
    ['Cell Type Level1', 4],
    ['Genotype', 5],
    ['Platform', 6],
    ['Sex', 7],
    ['Brain Region (CCF)', 8],
    ['Donor ID', 9],
]);
// right sidebar for cell info on cell selection
export const CELL_SIDEBAR_WIDTH = 338;
export const MAIN_SIDEBAR_MIN_WIDTH = 420;
export const SIDEBAR_NAV_WIDTH = 56;
export const SIDEBAR_PANEL_HEADER_HEIGHT = 108;
