import { batchedSubscribe } from 'redux-batched-subscribe';

let frameRequested: number | null = null;
/**
 * pass me to redux's configureStore, as a store enhancer - this will prevent dispatch(action) from instantly triggering a react re-render (which is a good thing)
 * @param notify the notify callback (from redux) - if called, subscribers to the store will be notified of changes
 * @returns a store enhancer which will defer re-rendering in react (at least, re-rendering due to changes in redux state) until the next browser-animation-frame cycle.
 */
export const browserScheduledSubscriberStore = batchedSubscribe((notify) => {
    if (frameRequested) return;

    frameRequested = requestAnimationFrame(() => {
        notify();
        frameRequested = null;
    });
});
