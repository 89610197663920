import { AnnotationColorOption as ColorOption } from '~/src/services/explore-visualizations/types/namespace';
import { VizControlsState, AnnotationsState, FrameKey, GenesUxState } from './types/states';

export const MAX_VISUALIZATIONS_PER_PAGE = 4;

export const DEFAULT_VIS_CONTROL_STATE: Readonly<VizControlsState> = {
    controlType: 'pan',
    box: null,
    selection: null,
    selectionOffset: null,
    enableCameraPan: false,
    slideId: null,
};

export const ANNOTATION_ID_NONE = 'none';

export const DEFAULT_ANNOTATION_OPTION = {
    referenceId: ANNOTATION_ID_NONE,
    label: 'None',
    description: 'No annotation',
};

export const DEFAULT_ANNOTATION_STATE: AnnotationsState = {
    isInFront: true,
    selectedId: DEFAULT_ANNOTATION_OPTION.referenceId,
    selectedFeatureTypeId: DEFAULT_ANNOTATION_OPTION.referenceId,
    fill: {
        selectedColor: '#000000',
        colorOption: ColorOption.None,
        opacity: 100,
    },
    stroke: {
        selectedColor: '#000000',
        colorOption: ColorOption.Default,
        opacity: 100,
    },
};

export const DEFAULT_FRAME_KEY: FrameKey = 'a';
export const DEFAULT_GENES_UX_STATE: GenesUxState = {
    canShowDefaultGene: true,
    symbols: {},
};
