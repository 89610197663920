import { partial, sortBy } from 'lodash';
import { useTheme, styled } from '@mui/material/styles';
import { getSemanticTextColors } from '~/src/utils/theme/theme-helpers';
import { Tooltip, TooltipTable } from '@czi-sds/components';
import { pluralizeNoun } from '~/src/utils';
import { useAnimatedValues } from '~/src/hooks/use-animated-values';
import { Typography } from '../bkp-ui/typography';
import { Box } from '../bkp-ui/box';
import { Stack } from '../bkp-ui/stack';
import { Grid } from '../bkp-ui/grid';

export const StyledTooltipTable = styled(TooltipTable)(({ theme }) => {
    const textColors = getSemanticTextColors(theme);
    return {
        '.MuiTableRow-root': {
            '> td:first-of-type': {
                color: textColors.base.secondary,
                width: '80%',
                fontWeight: 'normal',
            },
            '> td:last-of-type': {
                fontWeight: 'bold',
            },
        },
    };
});

type Props = {
    label?: string;
    backgroundColor?: string;
    textColor?: string;
    w: string;
    h: string;
    onClick?: () => void;
};
function Bar(props: Props) {
    const { w, h, label, textColor, backgroundColor, onClick } = props;

    return (
        <Box
            alignItems="center"
            alignContent="center"
            textAlign="left"
            style={{ backgroundColor }}
            width={w}
            height={h}
            paddingInline="xxs"
            onClick={onClick}
        >
            <Typography
                stylePath="body.semibold.xs"
                color={textColor ?? 'text.primary'}
                margin={0}
            >
                {label ?? ''}
            </Typography>
        </Box>
    );
}

type EntryData = { title: string; value: number; onClick?: () => void };
export type ChartData = ReadonlyArray<EntryData>;

type BarChartProps = {
    categoryName: string;
    /**
     * use to replace the default category display tring in the total section
     * by default, this string is generated by pluralizing the categoryName
     */
    categoryTotalString?: string;
    h: string;
    topRow: EntryData;
    data: ChartData;
    dataMax: number;
    color: string | ((value: number) => string);
    remaining: ReadonlyArray<EntryData>;
};

export function ColumnHeader(props: {
    title: string;
    subHeading: string;
    count: number | string;
    isLeft?: boolean;
    onClick?: () => void;
}) {
    const { title, subHeading, count, isLeft, onClick } = props;
    return (
        <Grid
            item
            key={title}
            xs={isLeft ? 5 : 7}
            style={{ cursor: onClick ? 'pointer' : 'default' }}
            onClick={onClick}
        >
            <Typography
                stylePath="caps.semibold.xxxs"
                color="text.secondary"
            >
                {title}
            </Typography>
            <Stack
                direction="row"
                alignItems="center"
                spacing="s"
            >
                <Typography
                    stylePath="header.semibold.xl"
                    color="text.primary"
                >
                    {count}
                </Typography>
                <Typography
                    stylePath="header.semibold.s"
                    color="text.primary"
                >
                    {subHeading}
                </Typography>
            </Stack>
        </Grid>
    );
}

const EntryTitle = (props: { title: string }) => (
    <Typography
        title={props.title}
        stylePath="body.regular.s"
        color="text.secondary"
        style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        }}
    >
        {props.title}
    </Typography>
);

function Bars(props: BarChartProps) {
    const { data, h, color, remaining, topRow, categoryName, categoryTotalString, dataMax } = props;
    const theme = useTheme();
    const textColor = getSemanticTextColors(theme).base.onFill;
    const isDarkMode = theme.palette.mode === 'dark';
    const totalRows = remaining.length + data.length + 1;
    return (
        <Stack
            direction="column"
            spacing="s"
        >
            <Grid container>
                <ColumnHeader
                    title="Total"
                    subHeading={categoryTotalString ?? pluralizeNoun(totalRows, categoryName)}
                    count={totalRows}
                    isLeft
                />
                <ColumnHeader
                    title={`Top ${categoryName}`}
                    subHeading={topRow?.title}
                    count={topRow?.value.toFixed(0)}
                    onClick={topRow?.onClick}
                />
            </Grid>
            {data.map((entry) => (
                <Grid
                    key={`${entry.title}_${entry.value}`}
                    container
                    direction="row"
                    alignItems="center"
                    onClick={entry.onClick}
                    style={{ cursor: entry.onClick ? 'pointer' : 'default' }}
                >
                    <Grid
                        item
                        xs={5}
                        key="title"
                        paddingRight="xs"
                    >
                        <EntryTitle title={entry.title} />
                    </Grid>
                    <Grid
                        xs={7}
                        item
                        key="bar"
                    >
                        <Bar
                            textColor={textColor}
                            label={entry.value.toFixed(0)}
                            h={h}
                            w={`${(entry.value / dataMax) * 100}%`}
                            backgroundColor={typeof color === 'string' ? color : color(entry.value)}
                            key={entry.title}
                            onClick={entry.onClick}
                        />
                    </Grid>
                </Grid>
            ))}
            {!!remaining.length && (
                <Tooltip
                    sdsStyle={isDarkMode ? 'dark' : 'light'}
                    placement="bottom-start"
                    title={
                        <StyledTooltipTable
                            data={[{ dataRows: remaining.map((row) => ({ label: row.title, value: row.value })) }]}
                        />
                    }
                >
                    <span style={{ width: 'fit-content', cursor: 'default' }}>
                        <Typography
                            stylePath="header.semibold.s"
                            color="text.secondary"
                        >{`+${remaining.length.toFixed(0)} other${remaining.length > 1 ? 's' : ''}`}</Typography>
                    </span>
                </Tooltip>
            )}
        </Stack>
    );
}

export type AnimatedBarChartProps = Pick<BarChartProps, 'data' | 'color' | 'categoryName' | 'categoryTotalString'> & {
    limit: number;
    durationMs: number;
};
type Row = { title: string; value: number };

const update = (goal: Row, v: number) => ({ ...goal, value: v });
const valueOf = (goal: Row) => goal.value;
const useRowAnimation = partial(useAnimatedValues, valueOf, update);

export function AnimatedBars(props: AnimatedBarChartProps) {
    const { data, limit, categoryName, color, durationMs, categoryTotalString } = props;

    const sorted = sortBy(data, 'value').reverse();
    // split the sorted items by the limit, note that we grab the top row and put it in the "header"
    const top: Row | undefined = sorted[0];
    const items = sorted.slice(1, limit + 1);
    const remaining = sorted.slice(limit + 1);
    const result = useRowAnimation(durationMs, items);
    // take the max of the data out here, otherwise it makes the animation
    // seem to not happen upon load (everthing will be proportional to its final state)
    const max = Math.max(...items.map((e) => e.value));

    return (
        <Bars
            categoryName={categoryName}
            categoryTotalString={categoryTotalString}
            h="fit-content"
            topRow={top}
            dataMax={max}
            data={result}
            color={color}
            remaining={remaining}
        />
    );
}
