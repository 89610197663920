export const getComputeImageWidth =
    (totalWidth: number) =>
    (imageWidth: number, screenWidth: number, minImageWidth?: number): number => {
        if (!totalWidth || !imageWidth || !screenWidth) return 0;
        const scaledImageWidth = (imageWidth / totalWidth) * screenWidth;
        const computedMinImageWidth = minImageWidth ?? imageWidth / 2;
        const minWidth = Math.max(scaledImageWidth, computedMinImageWidth);
        return Math.min(minWidth, imageWidth);
    };

export const computeImageHeight = (originalWidth: number, computedWidth: number, originalHeight: number) =>
    (computedWidth / originalWidth) * originalHeight;
