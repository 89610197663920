import TagManager, { TagManagerArgs } from 'react-gtm-module';
import {
    GOOGLE_TAG_DATA_LAYER_NAME,
    GOOGLE_TAG_MANAGER_AUTH,
    GOOGLE_TAG_MANAGER_ENVIRONMENT,
    GOOGLE_TAG_MANAGER_ID,
} from '~/src/constants/analytics';

declare global {
    interface Window {
        dataLayer: Record<string, object>[];
    }
}

export const bkpTagManagerArgs: TagManagerArgs = {
    gtmId: GOOGLE_TAG_MANAGER_ID,
    auth: GOOGLE_TAG_MANAGER_AUTH,
    preview: GOOGLE_TAG_MANAGER_ENVIRONMENT,
    dataLayerName: GOOGLE_TAG_DATA_LAYER_NAME,
    dataLayer: window.dataLayer,
};

export function initializeAnalytics() {
    TagManager.initialize(bkpTagManagerArgs);
}
