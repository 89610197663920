// Local Storage approach for a simple feature flag implementation since there is currently no server-side requirement for feature toggles.
// For optimal performance and to minimize potential issues when adding new features dynamically,
// consider using isFeatureEnabled outside of the component. Note that this approach requires a page refresh to reflect the changes.
//
// Add new feature flags in src/constants/features.ts
//
// Example:
// import { isFeatureEnabled } from '~/src/utils/feature-flag/feature-flag';
// import { featureFlagIds } from '~/src/constants/features';
//
// const isDynamicGridEnabled = isFeatureEnabled(featureFlagIds.dynamicGrid);

// export const MyComponent = () => {
//     return (
//       <div>{ isDynamicGridEnabled ? "Has dyanmic grid" : "No dynamic grid"}</div>
//     );
// }

import assign from 'lodash/assign';
import { FeatureFlagId, FeatureFlagSettings, featureFlags } from '~/src/constants/features';

type FeatureFlags = Record<string, boolean>;

export const LOCAL_STORAGE_KEY = 'feature-flags';

/**
 * Little transformation function to turn the feature flags configuration object into a record stating whether
 * the feature flag is turned on or off.
 *
 * @param input Feature flags with pretty name and environment variable value
 * @returns A simple record of the flag name and whether it's enabled
 */
const transformFlags = (input: Record<FeatureFlagId, FeatureFlagSettings>): Record<FeatureFlagId, boolean> => {
    const output: Record<string, boolean> = {};

    Object.keys(input).forEach((key) => {
        output[key] = input[key as FeatureFlagId].envVal === 'true';
    });

    return output;
};

/**
 * Retrieves the feature flags, with the ones from local storage taking precedence over the ones from environment variables
 *
 * @returns A record of feature flag IDs with their on/off value
 */
export const getFeatureFlags = (): FeatureFlags => {
    const storedFlags = localStorage.getItem(LOCAL_STORAGE_KEY);
    const envFeatureFlags = transformFlags(featureFlags);

    // If we don't have any in local storage, we can simply use the ones set by environment variables
    if (!storedFlags) {
        return envFeatureFlags;
    }

    try {
        const parsedFlags = JSON.parse(storedFlags);
        // Merge the flags from the environment variables with the ones from local storage,
        // using local storage to overwrite. This allows the UI toggles to take precedence.
        return Object.assign(envFeatureFlags, parsedFlags);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error('unable to initialize feature flags', e);
        return {};
    }
};

/**
 * Setter function for feature flags, used by dev tools to dynamically turn them on and off
 *
 * @param flagId The ID of the feature flag to set
 * @param on Whether the feature flag should be turned on or off
 */
export const setFeatureFlag = (flagId: string, on: boolean) => {
    const storedFlags = getFeatureFlags();
    const updatedFlags = assign({}, storedFlags, {
        [flagId]: on,
    });
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedFlags));
};

/**
 * Getter function for feature flags, used throughout the application to enable/disable features
 *
 * @param flagId The feature flag ID
 * @returns Whether the feature flag is enabled or not
 */
export const isFeatureEnabled = (flagId: string): boolean => getFeatureFlags()[flagId];
