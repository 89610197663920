import { ReactNode, CSSProperties } from 'react';
import { ButtonProps } from '@mui/material/Button';
import { MenuItemProps } from '@mui/material/MenuItem';

export const SettingsControls = {
    checkbox: 'checkbox',
    toggle: 'toggle',
} as const;

/**
 * default is an onClick trigger action
 */
type SettingsMenuItemOption =
    | {
          type?: never;
      }
    | {
          type: typeof SettingsControls.checkbox;
          isChecked: boolean;
          onChange?: (e: React.ChangeEvent<HTMLInputElement> | React.FormEvent<HTMLLIElement>) => void;
      }
    | {
          type: typeof SettingsControls.toggle;
          isToggled: boolean;
          onChange?: () => void;
      };

export type SettingsMenuItemProps = SettingsMenuItemOption &
    MenuItemProps & {
        label: string;
        icon?: ReactNode;
        checkboxAdornment?: ReactNode;
        onClick?: (e: React.ChangeEvent<HTMLInputElement> | React.FormEvent<HTMLLIElement>) => void;
    };

export interface SettingsMenuProps {
    /**
     * The label of the button to open the menu
     */
    label: string;
    /** Indicates whether the menu is visible and interactable */
    isOpen?: boolean;
    /** Disables all interactions */
    isDisabled?: boolean;
    /** Sets the button style, defaults to "outlined" */
    variant?: ButtonProps['variant'];
    /** Sets the button style, defaults to "small" */
    size?: ButtonProps['size'];
    styles?: CSSProperties;
    ariaLabel?: string;
    /**
     * Callback fired when the menu requests to be closed.
     */
    onClose: () => void;
    /**
     * Callback fired when the menu requests to be opened.
     */
    onOpen: () => void;
}
