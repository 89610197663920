import { forwardRef } from 'react';
import Link, { LinkProps } from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { setTargetForUrl } from '~/src/utils';

// Due to the links being a mix of react-router and regular links, eslint gets a little confused
// about some props not being required and the way it's used. We disable this rule because of that confusion.
// eslint-disable-next-line react/require-default-props
export type BkpLinkProps = LinkProps & { to?: string; onClick?: () => void; underline?: string };

export const BkpLink = forwardRef<HTMLAnchorElement, BkpLinkProps>(
    ({ href, children, className, to, underline = 'hover', onClick, ...props }: BkpLinkProps, ref) => {
        // Use the optional `to` parameter to do ReactRouter internal navigation
        if (to) {
            return (
                <Link
                    to={to}
                    component={RouterLink}
                    ref={ref}
                    className={className}
                    {...props}
                    underline={underline}
                >
                    {children}
                </Link>
            );
        }

        if (href) {
            return (
                <Link
                    href={href}
                    target={setTargetForUrl(window.location.host, href)}
                    ref={ref}
                    className={className}
                    {...props}
                    underline={underline}
                >
                    {children}
                </Link>
            );
        }

        if (onClick) {
            return (
                <Link
                    onClick={onClick}
                    ref={ref}
                    className={className}
                    {...props}
                    style={{ ...props.style, cursor: 'pointer' }}
                    underline={underline}
                >
                    {children}
                </Link>
            );
        }

        // We only get to this point if a to, link, or onClick prop is not provided.
        // That shouldn't generally happen, but we were seeing it when a URL wasn't provided
        // for a program on the project summary page.
        // In this case, simply show the children passed, which will most often just be text.
        // Disabling the rule because the fragment makes TS happy
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <>{children}</>;
    }
);

BkpLink.displayName = 'BkpLink';
