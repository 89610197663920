/**
 * Brand Colors
 * NO NOT USE!!!!!!!
 */
export const brandGreen = '#789C4A';
export const brandOrange = '#F99D20';

/**
 * Accent Colors (non-blink-ux)
 * NO NOT USE!!!!!!!
 */
export const donorProjectPageLink = '#bb6bd9';
export const donorProjectPageLinkHover = '#ba59e4';
export const projectPageLink = '#06B256';
export const projectPageLinkHover = '#05A04D';
export const exploreProjectPageLinkHover = '#d98a21';

export const DATA_CATALOG_BKP = {
    species: '#285F8A',
    modality: '#5DA7E5',
    technique: '#827AA3',
    other: '#AEAEAE',
    miscOne: '#789C4A',
    miscTwo: '#F99D20',
    miscThree: '#FFE700',
};
