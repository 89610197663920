import SettingsIcon from '@mui/icons-material/Settings';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Divider from '@mui/material/Divider';
import { MenuItem } from '@czi-sds/components';
import { MenuItemText } from '~/src/components/bkp-ui/menu-item-text';
import { ToggleMenu } from '~/src/components/bkp-ui/toggle-menu';
import {
    HELP_MENU_ALLEN_INSTITUTE_FOR_BRAIN_SCIENCE,
    HELP_MENU_COOKIE_SETTINGS,
    HELP_MENU_FOOTER_ALL_RIGHTS_RESERVED,
    HELP_MENU_FOOTER_COPYRIGHT,
    HELP_MENU_SUBHEADER_1,
    HELP_MENU_SUBHEADER_2,
} from '~/src/constants/strings';
import { getYear } from '~/src/utils/date/get-time';
import { HelpMenuLinkItem } from './help-menu-link-item';
import { LinkData } from './types';

// This allows TypeScript to know about the global `OneTrust.ToggleInfoDisplay()` function
declare global {
    interface Window {
        OneTrust: {
            ToggleInfoDisplay: () => void;
        };
    }
}

const MenuItemTitle = ({ text }: { text: string }) => (
    <MenuItemText
        text={text}
        variant="title"
    />
);

const CopyrightText = ({ text }: { text: string }) => (
    <MenuItemText
        text={text}
        variant="footnote"
    />
);

const CookiesSettings = () => {
    // Don't show the cookie button if the OneTrust library is missing. This will happen if the
    // user is blocking trackers in their browser.
    if (!window.OneTrust) {
        return null;
    }

    const handleClick = () => {
        window.OneTrust.ToggleInfoDisplay();
    };

    return (
        <MenuItem
            icon={<SettingsIcon />}
            onClick={handleClick}
        >
            {HELP_MENU_COOKIE_SETTINGS}
        </MenuItem>
    );
};

export interface HelpMenuProps {
    helpLinks: LinkData[];
    termsLinks: LinkData[];
}

export const HelpMenu = ({ helpLinks, termsLinks }: HelpMenuProps) => (
    <ToggleMenu
        aria-label="Help menu"
        buttonProps={{ sdsType: 'secondary', sdsStyle: 'icon', icon: <HelpOutlineOutlinedIcon /> }}
    >
        <MenuItemTitle text={HELP_MENU_SUBHEADER_1} />
        {helpLinks.map((link) => (
            <HelpMenuLinkItem
                key={link.url}
                text={link.title}
                url={link.url}
            />
        ))}
        <Divider />
        <MenuItemTitle text={HELP_MENU_SUBHEADER_2} />
        {termsLinks.map((link) => (
            <HelpMenuLinkItem
                key={link.url}
                text={link.title}
                url={link.url}
            />
        ))}
        <CookiesSettings />
        <Divider />
        <CopyrightText
            text={`${HELP_MENU_FOOTER_COPYRIGHT} ${getYear()}. ${HELP_MENU_ALLEN_INSTITUTE_FOR_BRAIN_SCIENCE}. ${HELP_MENU_FOOTER_ALL_RIGHTS_RESERVED}.`}
        />
    </ToggleMenu>
);
