{
  "colors": {
    "grey": {
      "50": {
        "value": "#fafafa",
        "type": "color"
      },
      "100": {
        "value": "#f5f5f5",
        "type": "color"
      },
      "200": {
        "value": "#eeeeee",
        "type": "color"
      },
      "300": {
        "value": "#e0e0e0",
        "type": "color"
      },
      "400": {
        "value": "#bdbdbd",
        "type": "color"
      },
      "500": {
        "value": "#9e9e9e",
        "type": "color"
      },
      "600": {
        "value": "#757575",
        "type": "color"
      },
      "700": {
        "value": "#616161",
        "type": "color"
      },
      "800": {
        "value": "#424242",
        "type": "color"
      },
      "900": {
        "value": "#212121",
        "type": "color"
      },
      "A100": {
        "value": "#d5d5d5",
        "type": "color"
      },
      "A200": {
        "value": "#aaaaaa",
        "type": "color"
      },
      "A400": {
        "value": "#616161",
        "type": "color"
      },
      "A700": {
        "value": "#303030",
        "type": "color"
      }
    },
    "indigo": {
      "50": {
        "value": "#e8eaf6",
        "type": "color"
      },
      "100": {
        "value": "#c5cae9",
        "type": "color"
      },
      "200": {
        "value": "#9fa8da",
        "type": "color"
      },
      "300": {
        "value": "#7986cb",
        "type": "color"
      },
      "400": {
        "value": "#5c6bc0",
        "type": "color"
      },
      "500": {
        "value": "#3f51b5",
        "type": "color"
      },
      "600": {
        "value": "#3949ab",
        "type": "color"
      },
      "700": {
        "value": "#303f9f",
        "type": "color"
      },
      "800": {
        "value": "#283593",
        "type": "color"
      },
      "900": {
        "value": "#1a237e",
        "type": "color"
      },
      "A100": {
        "value": "#8c9eff",
        "type": "color"
      },
      "A200": {
        "value": "#536dfe",
        "type": "color"
      },
      "A400": {
        "value": "#3d5afe",
        "type": "color"
      },
      "A700": {
        "value": "#304ffe",
        "type": "color"
      }
    },
    "deepPurple": {
      "50": {
        "value": "#ede7f6",
        "type": "color"
      },
      "100": {
        "value": "#d1c4e9",
        "type": "color"
      },
      "200": {
        "value": "#b39ddb",
        "type": "color"
      },
      "300": {
        "value": "#9575cd",
        "type": "color"
      },
      "400": {
        "value": "#7e57c2",
        "type": "color"
      },
      "500": {
        "value": "#673ab7",
        "type": "color"
      },
      "600": {
        "value": "#5e35b1",
        "type": "color"
      },
      "700": {
        "value": "#512da8",
        "type": "color"
      },
      "800": {
        "value": "#4527a0",
        "type": "color"
      },
      "900": {
        "value": "#311b92",
        "type": "color"
      },
      "A100": {
        "value": "#b388ff",
        "type": "color"
      },
      "A200": {
        "value": "#7c4dff",
        "type": "color"
      },
      "A400": {
        "value": "#651fff",
        "type": "color"
      },
      "A700": {
        "value": "#6200ea",
        "type": "color"
      }
    },
    "amber": {
      "50": {
        "value": "#fff8e1",
        "type": "color"
      },
      "300": {
        "value": "#ffd54f",
        "type": "color"
      },
      "400": {
        "value": "#ffca28",
        "type": "color"
      },
      "500": {
        "value": "#ffc107",
        "type": "color"
      },
      "600": {
        "value": "#ffb300",
        "type": "color"
      },
      "700": {
        "value": "#ffa000",
        "type": "color"
      },
      "800": {
        "value": "#ff8f00",
        "type": "color"
      },
      "900": {
        "value": "#ff6f00",
        "type": "color"
      },
      "A100": {
        "value": "#ffe57f",
        "type": "color"
      },
      "A200": {
        "value": "#ffd740",
        "type": "color"
      },
      "A400": {
        "value": "#ffc400",
        "type": "color"
      },
      "A700": {
        "value": "#ffab00",
        "type": "color"
      }
    },
    "orange": {
      "50": {
        "value": "#fff3e0",
        "type": "color"
      },
      "100": {
        "value": "#ffe0b2",
        "type": "color"
      },
      "200": {
        "value": "#ffcc80",
        "type": "color"
      },
      "300": {
        "value": "#ffb74d",
        "type": "color"
      },
      "400": {
        "value": "#ffa726",
        "type": "color"
      },
      "500": {
        "value": "#ff9800",
        "type": "color"
      },
      "600": {
        "value": "#fb8c00",
        "type": "color"
      },
      "700": {
        "value": "#f57c00",
        "type": "color"
      },
      "800": {
        "value": "#ef6c00",
        "type": "color"
      },
      "900": {
        "value": "#e65100",
        "type": "color"
      },
      "A100": {
        "value": "#ffd180",
        "type": "color"
      },
      "A200": {
        "value": "#ffab40",
        "type": "color"
      },
      "A400": {
        "value": "#ff9100",
        "type": "color"
      },
      "A700": {
        "value": "#ff6d00",
        "type": "color"
      }
    },
    "pink": {
      "50": {
        "value": "#fce4ec",
        "type": "color"
      },
      "100": {
        "value": "#f8bbd0",
        "type": "color"
      },
      "200": {
        "value": "#f48fb1",
        "type": "color"
      },
      "300": {
        "value": "#f06292",
        "type": "color"
      },
      "400": {
        "value": "#ec407a",
        "type": "color"
      },
      "500": {
        "value": "#e91e63",
        "type": "color"
      },
      "600": {
        "value": "#d81b60",
        "type": "color"
      },
      "700": {
        "value": "#c2185b",
        "type": "color"
      },
      "800": {
        "value": "#ad1457",
        "type": "color"
      },
      "900": {
        "value": "#880e4f",
        "type": "color"
      },
      "A100": {
        "value": "#ff80ab",
        "type": "color"
      },
      "A200": {
        "value": "#ff4081",
        "type": "color"
      },
      "A400": {
        "value": "#f50057",
        "type": "color"
      },
      "A700": {
        "value": "#c51162",
        "type": "color"
      }
    },
    "deepOrange": {
      "50": {
        "value": "#fbe9e7",
        "type": "color"
      },
      "100": {
        "value": "#ffccbc",
        "type": "color"
      },
      "200": {
        "value": "#ffab91",
        "type": "color"
      },
      "300": {
        "value": "#ff8a65",
        "type": "color"
      },
      "400": {
        "value": "#ff7043",
        "type": "color"
      },
      "500": {
        "value": "#ff5722",
        "type": "color"
      },
      "600": {
        "value": "#f4511e",
        "type": "color"
      },
      "700": {
        "value": "#e64a19",
        "type": "color"
      },
      "800": {
        "value": "#d84315",
        "type": "color"
      },
      "900": {
        "value": "#bf360c",
        "type": "color"
      },
      "A100": {
        "value": "#ff9e80",
        "type": "color"
      },
      "A200": {
        "value": "#ff6e40",
        "type": "color"
      },
      "A400": {
        "value": "#ff3d00",
        "type": "color"
      },
      "A700": {
        "value": "#dd2c00",
        "type": "color"
      }
    },
    "green": {
      "50": {
        "value": "#e8f5e9",
        "type": "color"
      },
      "100": {
        "value": "#c8e6c9",
        "type": "color"
      },
      "200": {
        "value": "#a5d6a7",
        "type": "color"
      },
      "300": {
        "value": "#81c784",
        "type": "color"
      },
      "400": {
        "value": "#66bb6a",
        "type": "color"
      },
      "500": {
        "value": "#4caf50",
        "type": "color"
      },
      "600": {
        "value": "#43a047",
        "type": "color"
      },
      "700": {
        "value": "#388e3c",
        "type": "color"
      },
      "800": {
        "value": "#2e7d32",
        "type": "color"
      },
      "900": {
        "value": "#1b5e20",
        "type": "color"
      },
      "A100": {
        "value": "#b9f6ca",
        "type": "color"
      },
      "A200": {
        "value": "#69f0ae",
        "type": "color"
      },
      "A400": {
        "value": "#00e676",
        "type": "color"
      },
      "A700": {
        "value": "#00c853",
        "type": "color"
      }
    },
    "red": {
      "50": {
        "value": "#feebee",
        "type": "color"
      },
      "100": {
        "value": "#fecdd2",
        "type": "color"
      },
      "200": {
        "value": "#ef9a9a",
        "type": "color"
      },
      "300": {
        "value": "#e57373",
        "type": "color"
      },
      "400": {
        "value": "#ef5350",
        "type": "color"
      },
      "500": {
        "value": "#f44336",
        "type": "color"
      },
      "600": {
        "value": "#e53935",
        "type": "color"
      },
      "700": {
        "value": "#d32f2f",
        "type": "color"
      },
      "800": {
        "value": "#c62828",
        "type": "color"
      },
      "900": {
        "value": "#b71c1c",
        "type": "color"
      },
      "A100": {
        "value": "#ff8a80",
        "type": "color"
      },
      "A200": {
        "value": "#ff5252",
        "type": "color"
      },
      "A400": {
        "value": "#ff1744",
        "type": "color"
      },
      "A700": {
        "value": "#d50000",
        "type": "color"
      }
    },
    "lightGreen": {
      "50": {
        "value": "#f1f8e9",
        "type": "color"
      },
      "100": {
        "value": "#dcedc8",
        "type": "color"
      },
      "200": {
        "value": "#c5e1a5",
        "type": "color"
      },
      "300": {
        "value": "#aed581",
        "type": "color"
      },
      "400": {
        "value": "#8bc34a",
        "type": "color"
      },
      "500": {
        "value": "#8bc34a",
        "type": "color"
      },
      "600": {
        "value": "#7cb342",
        "type": "color"
      },
      "700": {
        "value": "#689f38",
        "type": "color"
      },
      "800": {
        "value": "#558b2f",
        "type": "color"
      },
      "900": {
        "value": "#33691e",
        "type": "color"
      },
      "A100": {
        "value": "#ccff90",
        "type": "color"
      },
      "A200": {
        "value": "#b2ff59",
        "type": "color"
      },
      "A400": {
        "value": "#76ff03",
        "type": "color"
      },
      "A700": {
        "value": "#64dd17",
        "type": "color"
      }
    },
    "purple": {
      "50": {
        "value": "#f3e5f5",
        "type": "color"
      },
      "100": {
        "value": "#e1bee7",
        "type": "color"
      },
      "200": {
        "value": "#ce93d8",
        "type": "color"
      },
      "300": {
        "value": "#ba68c8",
        "type": "color"
      },
      "400": {
        "value": "#ab47bc",
        "type": "color"
      },
      "500": {
        "value": "#9c27b0",
        "type": "color"
      },
      "600": {
        "value": "#8e24aa",
        "type": "color"
      },
      "700": {
        "value": "#7b1fa2",
        "type": "color"
      },
      "800": {
        "value": "#6a1b9a",
        "type": "color"
      },
      "900": {
        "value": "#4a148c",
        "type": "color"
      },
      "A100": {
        "value": "#ea80fc",
        "type": "color"
      },
      "A200": {
        "value": "#e040fb",
        "type": "color"
      },
      "A400": {
        "value": "#d500f9",
        "type": "color"
      },
      "A700": {
        "value": "#aa00ff",
        "type": "color"
      }
    },
    "lime": {
      "50": {
        "value": "#f9fbe7",
        "type": "color"
      },
      "100": {
        "value": "#f0f4c3",
        "type": "color"
      },
      "200": {
        "value": "#e6ee9c",
        "type": "color"
      },
      "300": {
        "value": "#dce775",
        "type": "color"
      },
      "400": {
        "value": "#d4e157",
        "type": "color"
      },
      "500": {
        "value": "#cddc39",
        "type": "color"
      },
      "600": {
        "value": "#c0ca33",
        "type": "color"
      },
      "700": {
        "value": "#afb42b",
        "type": "color"
      },
      "800": {
        "value": "#9e9d24",
        "type": "color"
      },
      "900": {
        "value": "#827717",
        "type": "color"
      },
      "A100": {
        "value": "#f4ff81",
        "type": "color"
      },
      "A200": {
        "value": "#eeff41",
        "type": "color"
      },
      "A400": {
        "value": "#c6ff00",
        "type": "color"
      },
      "A700": {
        "value": "#aeea00",
        "type": "color"
      }
    },
    "lightBlue": {
      "50": {
        "value": "#e1f5fe",
        "type": "color"
      },
      "100": {
        "value": "#b3e5fc",
        "type": "color"
      },
      "200": {
        "value": "#81d4fa",
        "type": "color"
      },
      "300": {
        "value": "#4fc3f7",
        "type": "color"
      },
      "400": {
        "value": "#29b6f6",
        "type": "color"
      },
      "500": {
        "value": "#03a9f4",
        "type": "color"
      },
      "600": {
        "value": "#039be5",
        "type": "color"
      },
      "700": {
        "value": "#0288d1",
        "type": "color"
      },
      "800": {
        "value": "#0277bd",
        "type": "color"
      },
      "900": {
        "value": "#01579b",
        "type": "color"
      },
      "A100": {
        "value": "#80d8ff",
        "type": "color"
      },
      "A200": {
        "value": "#40c4ff",
        "type": "color"
      },
      "A400": {
        "value": "#00b0ff",
        "type": "color"
      },
      "A700": {
        "value": "#0091ea",
        "type": "color"
      }
    },
    "yellow": {
      "50": {
        "value": "#fffde7",
        "type": "color"
      },
      "100": {
        "value": "#fff9c4",
        "type": "color"
      },
      "200": {
        "value": "#fff59d",
        "type": "color"
      },
      "300": {
        "value": "#fff176",
        "type": "color"
      },
      "400": {
        "value": "#ffee58",
        "type": "color"
      },
      "500": {
        "value": "#ffeb3b",
        "type": "color"
      },
      "600": {
        "value": "#fdd835",
        "type": "color"
      },
      "700": {
        "value": "#fbc02d",
        "type": "color"
      },
      "800": {
        "value": "#f9a825",
        "type": "color"
      },
      "900": {
        "value": "#f57f17",
        "type": "color"
      },
      "A100": {
        "value": "#ffff8d",
        "type": "color"
      },
      "A200": {
        "value": "#ffff00",
        "type": "color"
      },
      "A400": {
        "value": "#ffea00",
        "type": "color"
      },
      "A700": {
        "value": "#ffd600",
        "type": "color"
      }
    },
    "cyan": {
      "50": {
        "value": "#e0f7fa",
        "type": "color"
      },
      "100": {
        "value": "#b2ebf2",
        "type": "color"
      },
      "200": {
        "value": "#80deea",
        "type": "color"
      },
      "300": {
        "value": "#4dd0e1",
        "type": "color"
      },
      "400": {
        "value": "#26c6da",
        "type": "color"
      },
      "500": {
        "value": "#00bcd4",
        "type": "color"
      },
      "600": {
        "value": "#00acc1",
        "type": "color"
      },
      "700": {
        "value": "#0097a7",
        "type": "color"
      },
      "800": {
        "value": "#00838f",
        "type": "color"
      },
      "900": {
        "value": "#006064",
        "type": "color"
      },
      "A100": {
        "value": "#84ffff",
        "type": "color"
      },
      "A200": {
        "value": "#18ffff",
        "type": "color"
      },
      "A400": {
        "value": "#00e5ff",
        "type": "color"
      },
      "A700": {
        "value": "#00b8d4",
        "type": "color"
      }
    },
    "teal": {
      "50": {
        "value": "#e0f2f1",
        "type": "color"
      },
      "100": {
        "value": "#b2dfdb",
        "type": "color"
      },
      "200": {
        "value": "#80cbc4",
        "type": "color"
      },
      "300": {
        "value": "#4db6ac",
        "type": "color"
      },
      "400": {
        "value": "#26a69a",
        "type": "color"
      },
      "500": {
        "value": "#009688",
        "type": "color"
      },
      "600": {
        "value": "#00897b",
        "type": "color"
      },
      "700": {
        "value": "#00796b",
        "type": "color"
      },
      "800": {
        "value": "#00695c",
        "type": "color"
      },
      "900": {
        "value": "#004d40",
        "type": "color"
      },
      "A100": {
        "value": "#a7ffeb",
        "type": "color"
      },
      "A200": {
        "value": "#64ffda",
        "type": "color"
      },
      "A400": {
        "value": "#1de9b6",
        "type": "color"
      },
      "A700": {
        "value": "#00bfa5",
        "type": "color"
      }
    },
    "blue": {
      "50": {
        "value": "#e3f2fd",
        "type": "color"
      },
      "100": {
        "value": "#bbdefb",
        "type": "color"
      },
      "200": {
        "value": "#90caf9",
        "type": "color"
      },
      "400": {
        "value": "#42a5f5",
        "type": "color"
      },
      "500": {
        "value": "#2196F3",
        "type": "color"
      },
      "600": {
        "value": "#1e88e5",
        "type": "color"
      },
      "700": {
        "value": "#0057b2",
        "type": "color"
      },
      "800": {
        "value": "#1565c0",
        "type": "color"
      },
      "900": {
        "value": "#0d47a1",
        "type": "color"
      },
      "A100": {
        "value": "#82b1ff",
        "type": "color"
      },
      "A200": {
        "value": "#448aff",
        "type": "color"
      },
      "A400": {
        "value": "#2979ff",
        "type": "color"
      },
      "A700": {
        "value": "#2962ff",
        "type": "color"
      }
    },
    "blueGrey": {
      "50": {
        "value": "#eceff1",
        "type": "color"
      },
      "100": {
        "value": "#cfd8dc",
        "type": "color"
      },
      "200": {
        "value": "#b0bec5",
        "type": "color"
      },
      "300": {
        "value": "#90a4ae",
        "type": "color"
      },
      "400": {
        "value": "#78909c",
        "type": "color"
      },
      "500": {
        "value": "#607d8b",
        "type": "color"
      },
      "600": {
        "value": "#546e7a",
        "type": "color"
      },
      "700": {
        "value": "#455a64",
        "type": "color"
      },
      "800": {
        "value": "#37474f",
        "type": "color"
      },
      "900": {
        "value": "#263238",
        "type": "color"
      }
    }
  },
  "light/base": {
    "divider": {
      "value": "rgba(0,0,0,0.12)",
      "type": "color",
      "description": "Reflects the divider variable from the theme object"
    },
    "text": {
      "primary": {
        "value": "rgba(0,0,0, 0.87)",
        "type": "color",
        "description": "Reflects the text.primary variable from the theme object"
      },
      "secondary": {
        "value": "rgba(0,0,0, 0.6)",
        "type": "color",
        "description": "Reflects the text.secondary variable from the theme object"
      },
      "disabled": {
        "value": "rgba({text.primary}, {action.disabledOpacity})",
        "type": "color",
        "description": "Reflects the text.disabled variable from the theme object"
      }
    },
    "primary": {
      "main": {
        "value": "#334C67",
        "type": "color",
        "description": "Reflects the primary.main variable from the theme object"
      },
      "dark": {
        "value": "#233548",
        "type": "color",
        "description": "Used for hover states. Reflects the primary.dark variable from the theme object"
      },
      "light": {
        "value": "#5b6f85",
        "type": "color",
        "description": "Reflects the primary.light variable from the theme object"
      },
      "contrast": {
        "value": "#ffffff",
        "type": "color",
        "description": "Reflects the primary.contrast variable from the theme object. Color that keeps a contrast ratio above AA when XX.main is used as a bg. color"
      },
      "states": {
        "hover": {
          "value": "rgba({primary.main}, {action.hoverOpacity})",
          "type": "color",
          "description": "Used for hover states. The token represents the value of action.hoverOpacity (0.04 by default) of the main token."
        },
        "selected": {
          "value": "rgba({primary.main}, {action.selectedOpacity})",
          "type": "color",
          "description": "Used for selected states. The token represents the value of action.selectedOpacity (0.08 by default) of the main token."
        },
        "focus": {
          "value": "rgba({primary.main}, {action.focusOpacity})",
          "type": "color",
          "description": "Used for focus states. The token represents the value of action.focusOpacity (0.12 by default) of the main token."
        },
        "focusVisible": {
          "value": "rgba({primary.main}, 0.3)",
          "type": "color",
          "description": "Used for focus visible states. The token represents the value of focusVisibleOpacity (0.3 by default) of the main token."
        },
        "outlinedBorder": {
          "value": "rgba({primary.main}, 0.5)",
          "type": "color",
          "description": "Used for enabled states (e.g Button outlined variant). The token represents the value of outlinedBorderOpacity (0.5 by default) of the main token."
        }
      }
    },
    "secondary": {
      "main": {
        "value": "#CB975E",
        "type": "color",
        "description": "Reflects the secondary.main variable from the theme object"
      },
      "dark": {
        "value": "#8e6941",
        "type": "color",
        "description": "Used for hover states. Reflects the secondary.dark variable from the theme object"
      },
      "light": {
        "value": "#d5ab7e",
        "type": "color",
        "description": "Reflects the secondary.light variable from the theme object"
      },
      "contrast": {
        "value": "#ffffff",
        "type": "color",
        "description": "Reflects the secondary.contrast variable from the theme object. Color that keeps a contrast ratio above AA when XX.main is used as a bg. color"
      },
      "states": {
        "hover": {
          "value": "rgba({secondary.main}, {action.hoverOpacity})",
          "type": "color",
          "description": "Used for hover states. The token represents the value of action.hoverOpacity (0.04 by default) of the main token."
        },
        "selected": {
          "value": "rgba({secondary.main}, {action.selectedOpacity})",
          "type": "color",
          "description": "Used for selected states. The token represents the value of action.selectedOpacity (0.08 by default) of the main token."
        },
        "focus": {
          "value": "rgba({secondary.main}, {action.focusOpacity})",
          "type": "color",
          "description": "Used for focus visible states. The token represents 12% of the Secondary/Main token"
        },
        "focusVisible": {
          "value": "rgba({secondary.main}, 0.3)",
          "type": "color",
          "description": "Used for focus visible states. The token represents the value of focusVisibleOpacity (0.3 by default) of the main token."
        },
        "outlinedBorder": {
          "value": "rgba({secondary.main}, 0.5)",
          "type": "color",
          "description": "Used for enabled states (e.g Button outlined variant). The token represents the value of outlinedBorderOpacity (0.5 by default) of the main token."
        }
      }
    },
    "action": {
      "hover": {
        "value": "rgba({action.activeChannel}, {action.hoverOpacity})",
        "type": "color",
        "description": "Reflects the action.hover variable from the theme object"
      },
      "selected": {
        "value": "rgba({action.selectedChannel}, {action.selectedOpacity})",
        "type": "color",
        "description": "Reflects the action.selected variable from the theme object"
      },
      "disabledBackground": {
        "value": "rgba({action.activeChannel}, 0.12)",
        "type": "color",
        "description": "Reflects the action.disabledBackground variable from the theme object"
      },
      "focus": {
        "value": "rgba({action.activeChannel}, {action.focusOpacity})",
        "type": "color",
        "description": "Reflects the action.focus variable from the theme object"
      },
      "disabled": {
        "value": "rgba({action.activeChannel}, {action.disabledOpacity})",
        "type": "color",
        "description": "Reflects the action.disabled variable from the theme object"
      },
      "active": {
        "value": "rgba({action.activeChannel}, 0.56)",
        "type": "color",
        "description": "Reflects the action.active variable from the theme object"
      },
      "hoverOpacity": {
        "value": "0.04",
        "type": "opacity"
      },
      "selectedOpacity": {
        "value": "0.08",
        "type": "opacity"
      },
      "disabledOpacity": {
        "value": "0.38",
        "type": "opacity"
      },
      "focusOpacity": {
        "value": "0.12",
        "type": "opacity"
      },
      "activeChannel": {
        "value": "rgb(0,0,0)",
        "type": "color"
      },
      "selectedChannel": {
        "value": "rgb(0,0,0)",
        "type": "color"
      }
    },
    "error": {
      "main": {
        "value": "{red.700}",
        "type": "color",
        "description": "Reflects the error.main variable from the theme object"
      },
      "dark": {
        "value": "{red.800}",
        "type": "color",
        "description": "Used for hover states. Reflects the error.dark variable from the theme object"
      },
      "light": {
        "value": "{red.400}",
        "type": "color",
        "description": "Reflects the error.light variable from the theme object"
      },
      "contrast": {
        "value": "#ffffff",
        "type": "color",
        "description": "Reflects the error.contrast variable from the theme object. Color that keeps a contrast ratio above AA when XX.main is used as a bg. color"
      },
      "states": {
        "hover": {
          "value": "rgba({error.main}, {action.hoverOpacity})",
          "type": "color",
          "description": "Used for hover states. The token represents the value of action.hoverOpacity (0.04 by default) of the main token."
        },
        "selected": {
          "value": "rgba({error.main}, {action.selectedOpacity})",
          "type": "color",
          "description": "Used for selected states. The token represents the value of action.selectedOpacity (0.08 by default) of the main token."
        },
        "focusVisible": {
          "value": "rgba({error.main}, 0.3)",
          "type": "color",
          "description": "Used for focus visible states. The token represents the value of focusVisibleOpacity (0.3 by default) of the main token."
        },
        "outlinedBorder": {
          "value": "rgba({error.main}, 0.5)",
          "type": "color",
          "description": "Used for enabled states (e.g Button outlined variant). The token represents the value of outlinedBorderOpacity (0.5 by default) of the main token."
        }
      }
    },
    "warning": {
      "main": {
        "value": "#ed6c02",
        "type": "color",
        "description": "Reflects the warning.main variable from the theme object"
      },
      "dark": {
        "value": "{orange.900}",
        "type": "color",
        "description": "Used for hover states. Reflects the warning.dark variable from the theme object"
      },
      "light": {
        "value": "{orange.500}",
        "type": "color",
        "description": "Reflects the warning.light variable from the theme object"
      },
      "contrast": {
        "value": "#ffffff",
        "type": "color",
        "description": "Reflects the warning.contrast variable from the theme object. Color that keeps a contrast ratio above AA when XX.main is used as a bg. color"
      },
      "states": {
        "hover": {
          "value": "rgba({warning.main}, {action.hoverOpacity})",
          "type": "color",
          "description": "Used for hover states. The token represents the value of action.hoverOpacity (0.04 by default) of the main token."
        },
        "selected": {
          "value": "rgba({warning.main}, {action.hoverOpacity})",
          "type": "color",
          "description": "Used for selected states. The token represents the value of action.selectedOpacity (0.08 by default) of the main token."
        },
        "focusVisible": {
          "value": "rgba({warning.main}, 0.3)",
          "type": "color",
          "description": "Used for focus visible states. The token represents the value of focusVisibleOpacity (0.3 by default) of the main token."
        },
        "outlinedBorder": {
          "value": "rgba({warning.main}, 0.5)",
          "type": "color",
          "description": "Used for enabled states (e.g Button outlined variant). The token represents the value of outlinedBorderOpacity (0.5 by default) of the main token."
        }
      }
    },
    "info": {
      "main": {
        "value": "{lightBlue.700}",
        "type": "color",
        "description": "Reflects the info.main variable from the theme object"
      },
      "dark": {
        "value": "{lightBlue.900}",
        "type": "color",
        "description": "Used for hover states. Reflects the info.dark variable from the theme object"
      },
      "light": {
        "value": "{lightBlue.500}",
        "type": "color",
        "description": "Reflects the info.light variable from the theme object"
      },
      "contrast": {
        "value": "#ffffff",
        "type": "color",
        "description": "Reflects the info.contrast variable from the theme object. Color that keeps a contrast ratio above AA when XX.main is used as a bg. color"
      },
      "states": {
        "hover": {
          "value": "rgba({info.main}, {action.hoverOpacity})",
          "type": "color",
          "description": "Used for hover states. The token represents the value of action.hoverOpacity (0.04 by default) of the main token."
        },
        "selected": {
          "value": "rgba({info.main}, {action.selectedOpacity})",
          "type": "color",
          "description": "Used for selected states. The token represents the value of action.selectedOpacity (0.08 by default) of the main token."
        },
        "focusVisible": {
          "value": "rgba({info.main}, 0.3)",
          "type": "color",
          "description": "Used for focus visible states. The token represents the value of focusVisibleOpacity (0.3 by default) of the main token."
        },
        "outlinedBorder": {
          "value": "rgba({info.main}, 0.5)",
          "type": "color",
          "description": "Used for enabled states (e.g Button outlined variant). The token represents the value of outlinedBorderOpacity (0.5 by default) of the main token."
        }
      }
    },
    "success": {
      "main": {
        "value": "{green.800}",
        "type": "color",
        "description": "Reflects the success.main variable from the theme object"
      },
      "dark": {
        "value": "{green.900}",
        "type": "color",
        "description": "Used for hover states. Reflects the success.dark variable from the theme object"
      },
      "light": {
        "value": "{green.500}",
        "type": "color",
        "description": "Reflects the success.light variable from the theme object"
      },
      "contrast": {
        "value": "#ffffff",
        "type": "color",
        "description": "Reflects the success.contrast variable from the theme object. Color that keeps a contrast ratio above AA when XX.main is used as a bg. color"
      },
      "states": {
        "hover": {
          "value": "rgba({success.main}, {action.hoverOpacity})",
          "type": "color",
          "description": "Used for hover states. The token represents the value of action.hoverOpacity (0.04 by default) of the main token."
        },
        "selected": {
          "value": "rgba({success.main}, {action.selectedOpacity})",
          "type": "color",
          "description": "Used for selected states. The token represents the value of action.selectedOpacity (0.08 by default) of the main token."
        },
        "focusVisible": {
          "value": "rgba({success.main}, 0.3)",
          "type": "color",
          "description": "Used for focus visible states. The token represents the value of focusVisibleOpacity (0.3 by default) of the main token."
        },
        "outlinedBorder": {
          "value": "rgba({success.main}, 0.5)",
          "type": "color",
          "description": "Used for enabled states (e.g Button outlined variant). The token represents the value of outlinedBorderOpacity (0.5 by default) of the main token."
        }
      }
    },
    "inherit": {
      "white": {
        "main": {
          "value": "#FFFFFF",
          "type": "color"
        },
        "hover": {
          "value": "rgba({inherit.white.main}, {action.hoverOpacity})",
          "type": "color",
          "description": "Used for hover states. The token represents the value of action.hoverOpacity (0.04 by default) of the main token."
        },
        "selected": {
          "value": "rgba({inherit.white.main}, {action.selectedOpacity})",
          "type": "color",
          "description": "Used for selected states. The token represents the value of action.selectedOpacity (0.08 by default) of the main token."
        },
        "focus": {
          "value": "rgba({inherit.white.main}, {action.focusOpacity})",
          "type": "color",
          "description": "Used for focus states. The token represents the value of action.focusOpacity (0.12 by default) of the main token."
        },
        "focusVisible": {
          "value": "rgba({inherit.white.main}, 0.3)",
          "type": "color",
          "description": "Used for focus visible states. The token represents the value of focusVisibleOpacity (0.3 by default) of the main token."
        }
      },
      "textPrimary": {
        "main": {
          "value": "{text.primary}",
          "type": "color"
        },
        "hover": {
          "value": "rgba({inherit.textPrimary.main}, {action.hoverOpacity})",
          "type": "color",
          "description": "Used for hover states. The token represents the value of action.hoverOpacity (0.04 by default) of the main token."
        },
        "selected": {
          "value": "rgba({inherit.textPrimary.main}, {action.selectedOpacity})",
          "type": "color",
          "description": "Used for selected states. The token represents the value of action.selectedOpacity (0.08 by default) of the main token."
        },
        "focus": {
          "value": "rgba({inherit.textPrimary.main}, {action.focusOpacity})",
          "type": "color",
          "description": "Used for focus states. The token represents the value of action.focusOpacity (0.12 by default) of the main token."
        },
        "focusVisible": {
          "value": "rgba({inherit.textPrimary.main}, 0.3)",
          "type": "color",
          "description": "Used for focus visible states. The token represents the value of focusVisibleOpacity (0.3 by default) of the main token."
        }
      }
    },
    "elevation": {
      "1": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "1",
            "blur": "3",
            "spread": "0"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "1",
            "blur": "1",
            "spread": "0"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "2",
            "blur": "1",
            "spread": "-1"
          }
        ],
        "type": "boxShadow"
      },
      "2": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "1",
            "blur": "5",
            "spread": "0"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "2",
            "blur": "2",
            "spread": "0"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "3",
            "blur": "1",
            "spread": "-2"
          }
        ],
        "type": "boxShadow"
      },
      "3": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "1",
            "blur": "8",
            "spread": "0"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "3",
            "blur": "4",
            "spread": "0"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "3",
            "blur": "3",
            "spread": "-2"
          }
        ],
        "type": "boxShadow"
      },
      "4": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "1",
            "blur": "10",
            "spread": "0"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "4",
            "blur": "5",
            "spread": "0"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "2",
            "blur": "4",
            "spread": "-1"
          }
        ],
        "type": "boxShadow"
      },
      "5": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "1",
            "blur": "14",
            "spread": "0"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "5",
            "blur": "8",
            "spread": "0"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "3",
            "blur": "5",
            "spread": "-1"
          }
        ],
        "type": "boxShadow"
      },
      "6": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "1",
            "blur": "18",
            "spread": "0"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "6",
            "blur": "10",
            "spread": "0"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "3",
            "blur": "5",
            "spread": "-1"
          }
        ],
        "type": "boxShadow"
      },
      "7": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "2",
            "blur": "16",
            "spread": "1"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "7",
            "blur": "10",
            "spread": "1"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "4",
            "blur": "5",
            "spread": "-2"
          }
        ],
        "type": "boxShadow"
      },
      "8": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "3",
            "blur": "14",
            "spread": "2"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "8",
            "blur": "10",
            "spread": "1"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "5",
            "blur": "5",
            "spread": "-3"
          }
        ],
        "type": "boxShadow"
      },
      "9": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "3",
            "blur": "16",
            "spread": "2"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "9",
            "blur": "12",
            "spread": "1"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "5",
            "blur": "6",
            "spread": "-3"
          }
        ],
        "type": "boxShadow"
      },
      "10": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "4",
            "blur": "18",
            "spread": "3"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "10",
            "blur": "14",
            "spread": "1"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "6",
            "blur": "6",
            "spread": "-3"
          }
        ],
        "type": "boxShadow"
      },
      "11": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "4",
            "blur": "20",
            "spread": "3"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "11",
            "blur": "15",
            "spread": "1"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "6",
            "blur": "7",
            "spread": "-4"
          }
        ],
        "type": "boxShadow"
      },
      "12": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "5",
            "blur": "22",
            "spread": "4"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "12",
            "blur": "17",
            "spread": "2"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "7",
            "blur": "8",
            "spread": "-4"
          }
        ],
        "type": "boxShadow"
      },
      "13": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "5",
            "blur": "24",
            "spread": "4"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "13",
            "blur": "19",
            "spread": "2"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "7",
            "blur": "8",
            "spread": "-4"
          }
        ],
        "type": "boxShadow"
      },
      "14": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "5",
            "blur": "26",
            "spread": "4"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "14",
            "blur": "21",
            "spread": "2"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "7",
            "blur": "9",
            "spread": "-4"
          }
        ],
        "type": "boxShadow"
      },
      "15": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "6",
            "blur": "28",
            "spread": "5"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "15",
            "blur": "22",
            "spread": "2"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "8",
            "blur": "9",
            "spread": "-5"
          }
        ],
        "type": "boxShadow"
      },
      "16": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "6",
            "blur": "30",
            "spread": "5"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "16",
            "blur": "24",
            "spread": "2"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "8",
            "blur": "10",
            "spread": "-5"
          }
        ],
        "type": "boxShadow"
      },
      "17": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "6",
            "blur": "32",
            "spread": "5"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "17",
            "blur": "26",
            "spread": "2"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "8",
            "blur": "11",
            "spread": "-5"
          }
        ],
        "type": "boxShadow"
      },
      "18": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "7",
            "blur": "34",
            "spread": "6"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "18",
            "blur": "28",
            "spread": "2"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "9",
            "blur": "11",
            "spread": "-5"
          }
        ],
        "type": "boxShadow"
      },
      "19": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "7",
            "blur": "36",
            "spread": "6"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "19",
            "blur": "29",
            "spread": "2"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "9",
            "blur": "12",
            "spread": "-6"
          }
        ],
        "type": "boxShadow"
      },
      "20": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "8",
            "blur": "38",
            "spread": "7"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "20",
            "blur": "31",
            "spread": "3"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "10",
            "blur": "13",
            "spread": "-6"
          }
        ],
        "type": "boxShadow"
      },
      "21": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "8",
            "blur": "40",
            "spread": "7"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "21",
            "blur": "33",
            "spread": "3"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "10",
            "blur": "13",
            "spread": "-6"
          }
        ],
        "type": "boxShadow"
      },
      "22": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "8",
            "blur": "42",
            "spread": "7"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "22",
            "blur": "35",
            "spread": "3"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "10",
            "blur": "14",
            "spread": "-6"
          }
        ],
        "type": "boxShadow"
      },
      "23": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "9",
            "blur": "44",
            "spread": "8"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "23",
            "blur": "36",
            "spread": "3"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "11",
            "blur": "14",
            "spread": "-7"
          }
        ],
        "type": "boxShadow"
      },
      "24": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "9",
            "blur": "46",
            "spread": "8"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "24",
            "blur": "38",
            "spread": "3"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "11",
            "blur": "15",
            "spread": "-7"
          }
        ],
        "type": "boxShadow"
      },
      "outlined": {
        "value": {
          "color": "#e0e0e0",
          "type": "dropShadow",
          "x": "0",
          "y": "0",
          "blur": "0",
          "spread": "1"
        },
        "type": "boxShadow"
      }
    },
    "fontFamilies": {
      "roboto": {
        "value": "Roboto",
        "type": "fontFamilies"
      }
    },
    "lineHeights": {
      "0": {
        "value": "116.7%",
        "type": "lineHeights"
      },
      "1": {
        "value": "120%",
        "type": "lineHeights"
      },
      "2": {
        "value": "123.5%",
        "type": "lineHeights"
      },
      "3": {
        "value": "133.4%",
        "type": "lineHeights"
      },
      "4": {
        "value": "160%",
        "type": "lineHeights"
      },
      "5": {
        "value": "150%",
        "type": "lineHeights"
      },
      "6": {
        "value": "143%",
        "type": "lineHeights"
      },
      "7": {
        "value": "175%",
        "type": "lineHeights"
      },
      "8": {
        "value": "157%",
        "type": "lineHeights"
      },
      "9": {
        "value": "266%",
        "type": "lineHeights"
      },
      "10": {
        "value": "166%",
        "type": "lineHeights"
      },
      "11": {
        "value": "20",
        "type": "lineHeights"
      },
      "12": {
        "value": "26",
        "type": "lineHeights"
      },
      "13": {
        "value": "24",
        "type": "lineHeights"
      },
      "14": {
        "value": "22",
        "type": "lineHeights"
      },
      "15": {
        "value": "12",
        "type": "lineHeights"
      },
      "16": {
        "value": "18",
        "type": "lineHeights"
      },
      "17": {
        "value": "14",
        "type": "lineHeights"
      },
      "18": {
        "value": "48",
        "type": "lineHeights"
      }
    },
    "fontWeights": {
      "light": {
        "value": "Light",
        "type": "fontWeights"
      },
      "regular": {
        "value": "Regular",
        "type": "fontWeights"
      },
      "medium": {
        "value": "Medium",
        "type": "fontWeights"
      }
    },
    "fontSize": {
      "0": {
        "value": "10",
        "type": "fontSizes"
      },
      "1": {
        "value": "12",
        "type": "fontSizes"
      },
      "2": {
        "value": "13",
        "type": "fontSizes"
      },
      "3": {
        "value": "14",
        "type": "fontSizes"
      },
      "4": {
        "value": "15",
        "type": "fontSizes"
      },
      "5": {
        "value": "16",
        "type": "fontSizes"
      },
      "6": {
        "value": "20",
        "type": "fontSizes"
      },
      "7": {
        "value": "24",
        "type": "fontSizes"
      },
      "8": {
        "value": "34",
        "type": "fontSizes"
      },
      "9": {
        "value": "48",
        "type": "fontSizes"
      },
      "10": {
        "value": "60",
        "type": "fontSizes"
      },
      "11": {
        "value": "96",
        "type": "fontSizes"
      }
    },
    "letterSpacing": {
      "0": {
        "value": "-1.5",
        "type": "letterSpacing"
      },
      "1": {
        "value": "-0.5",
        "type": "letterSpacing"
      },
      "2": {
        "value": "0",
        "type": "letterSpacing"
      },
      "3": {
        "value": "0.25",
        "type": "letterSpacing"
      },
      "4": {
        "value": "0.15",
        "type": "letterSpacing"
      },
      "5": {
        "value": "0.17",
        "type": "letterSpacing"
      },
      "6": {
        "value": "0.1",
        "type": "letterSpacing"
      },
      "7": {
        "value": "1",
        "type": "letterSpacing"
      },
      "8": {
        "value": "0.4",
        "type": "letterSpacing"
      },
      "9": {
        "value": "0.14",
        "type": "letterSpacing"
      },
      "10": {
        "value": "0.46",
        "type": "letterSpacing"
      },
      "11": {
        "value": "0.16",
        "type": "letterSpacing"
      }
    },
    "paragraphSpacing": {
      "none": {
        "value": "0",
        "type": "paragraphSpacing"
      }
    },
    "typography": {
      "h1": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.light}",
          "lineHeight": "{lineHeights.0}",
          "fontSize": "{fontSize.11}",
          "letterSpacing": "{letterSpacing.0}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "h2": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.light}",
          "lineHeight": "{lineHeights.1}",
          "fontSize": "{fontSize.10}",
          "letterSpacing": "{letterSpacing.1}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "h3": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.regular}",
          "lineHeight": "{lineHeights.0}",
          "fontSize": "{fontSize.9}",
          "letterSpacing": "{letterSpacing.2}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "h4": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.regular}",
          "lineHeight": "{lineHeights.2}",
          "fontSize": "{fontSize.8}",
          "letterSpacing": "{letterSpacing.3}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "h5": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.regular}",
          "lineHeight": "{lineHeights.3}",
          "fontSize": "{fontSize.7}",
          "letterSpacing": "{letterSpacing.2}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "h6": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.medium}",
          "lineHeight": "{lineHeights.4}",
          "fontSize": "{fontSize.6}",
          "letterSpacing": "{letterSpacing.4}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "body1": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.regular}",
          "lineHeight": "{lineHeights.5}",
          "fontSize": "{fontSize.5}",
          "letterSpacing": "{letterSpacing.4}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "body2": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.regular}",
          "lineHeight": "{lineHeights.6}",
          "fontSize": "{fontSize.3}",
          "letterSpacing": "{letterSpacing.5}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "subtitle1": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.regular}",
          "lineHeight": "{lineHeights.7}",
          "fontSize": "{fontSize.5}",
          "letterSpacing": "{letterSpacing.4}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "subtitle2": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.medium}",
          "lineHeight": "{lineHeights.8}",
          "fontSize": "{fontSize.3}",
          "letterSpacing": "{letterSpacing.6}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "overline": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.regular}",
          "lineHeight": "{lineHeights.9}",
          "fontSize": "{fontSize.1}",
          "letterSpacing": "{letterSpacing.7}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.uppercase}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "caption": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.regular}",
          "lineHeight": "{lineHeights.10}",
          "fontSize": "{fontSize.1}",
          "letterSpacing": "{letterSpacing.8}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "annotation": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.light}",
          "lineHeight": "{lineHeights.0}",
          "fontSize": "{fontSize.0}",
          "letterSpacing": "{letterSpacing.3}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      }
    },
    "textCase": {
      "none": {
        "value": "none",
        "type": "textCase"
      },
      "uppercase": {
        "value": "uppercase",
        "type": "textCase"
      }
    },
    "textDecoration": {
      "none": {
        "value": "none",
        "type": "textDecoration"
      }
    },
    "shape": {
      "borderRadius": {
        "value": "4",
        "type": "borderRadius"
      }
    },
    "paragraphIndent": {
      "none": {
        "value": "0",
        "type": "paragraphSpacing"
      }
    },
    "spacing": {
      "1": {
        "value": "{spacing.base} * 1",
        "type": "spacing"
      },
      "2": {
        "value": "{spacing.base} * 2",
        "type": "spacing"
      },
      "3": {
        "value": "{spacing.base} * 3",
        "type": "spacing"
      },
      "4": {
        "value": "{spacing.base} * 4",
        "type": "spacing"
      },
      "5": {
        "value": "{spacing.base} * 5",
        "type": "spacing"
      },
      "6": {
        "value": "{spacing.base} * 6",
        "type": "spacing"
      },
      "7": {
        "value": "{spacing.base} * 7",
        "type": "spacing"
      },
      "8": {
        "value": "{spacing.base} * 8",
        "type": "spacing"
      },
      "9": {
        "value": "{spacing.base} * 9",
        "type": "spacing"
      },
      "10": {
        "value": "{spacing.base} * 10",
        "type": "spacing"
      },
      "11": {
        "value": "{spacing.base} * 11",
        "type": "spacing"
      },
      "12": {
        "value": "{spacing.base} * 12",
        "type": "spacing"
      },
      "base": {
        "value": "8",
        "type": "spacing"
      }
    },
    "sizing": {
      "1": {
        "value": "{spacing.base} * 1",
        "type": "sizing"
      },
      "2": {
        "value": "{spacing.base} * 2",
        "type": "sizing"
      },
      "3": {
        "value": "{spacing.base} * 3",
        "type": "sizing"
      },
      "4": {
        "value": "{spacing.base} * 4",
        "type": "sizing"
      },
      "5": {
        "value": "{spacing.base} * 5",
        "type": "sizing"
      },
      "6": {
        "value": "{spacing.base} * 6",
        "type": "sizing"
      },
      "7": {
        "value": "{spacing.base} * 7",
        "type": "sizing"
      },
      "8": {
        "value": "{spacing.base} * 8",
        "type": "sizing"
      },
      "9": {
        "value": "{spacing.base} * 9",
        "type": "sizing"
      },
      "10": {
        "value": "{spacing.base} * 10",
        "type": "sizing"
      },
      "11": {
        "value": "{spacing.base} * 11",
        "type": "sizing"
      },
      "12": {
        "value": "{spacing.base} * 12",
        "type": "sizing"
      },
      "base": {
        "value": "{spacing.base}",
        "type": "sizing"
      }
    },
    "background": {
      "default": {
        "value": "#FFFFFF",
        "type": "color",
        "description": "Reflects the background.default variable from the theme object"
      },
      "paper-elevation-0": {
        "value": "#FFFFFF",
        "type": "color",
        "description": "Reflects the background.paper variable from the theme object"
      },
      "paper-elevation-1": {
        "value": "#FFFFFF",
        "type": "color"
      },
      "paper-elevation-2": {
        "value": "#FFFFFF",
        "type": "color"
      },
      "paper-elevation-3": {
        "value": "#FFFFFF",
        "type": "color"
      },
      "paper-elevation-4": {
        "value": "#FFFFFF",
        "type": "color"
      },
      "paper-elevation-5": {
        "value": "#FFFFFF",
        "type": "color"
      },
      "paper-elevation-6": {
        "value": "#FFFFFF",
        "type": "color"
      },
      "paper-elevation-7": {
        "value": "#FFFFFF",
        "type": "color"
      },
      "paper-elevation-8": {
        "value": "#FFFFFF",
        "type": "color"
      },
      "paper-elevation-9": {
        "value": "#FFFFFF",
        "type": "color"
      },
      "paper-elevation-10": {
        "value": "#FFFFFF",
        "type": "color"
      },
      "paper-elevation-11": {
        "value": "#FFFFFF",
        "type": "color"
      },
      "paper-elevation-12": {
        "value": "#FFFFFF",
        "type": "color"
      },
      "paper-elevation-13": {
        "value": "#FFFFFF",
        "type": "color"
      },
      "paper-elevation-14": {
        "value": "#FFFFFF",
        "type": "color"
      },
      "paper-elevation-15": {
        "value": "#FFFFFF",
        "type": "color"
      },
      "paper-elevation-16": {
        "value": "#FFFFFF",
        "type": "color"
      },
      "paper-elevation-17": {
        "value": "#FFFFFF",
        "type": "color"
      },
      "paper-elevation-18": {
        "value": "#FFFFFF",
        "type": "color"
      },
      "paper-elevation-19": {
        "value": "#FFFFFF",
        "type": "color"
      },
      "paper-elevation-20": {
        "value": "#FFFFFF",
        "type": "color"
      },
      "paper-elevation-21": {
        "value": "#FFFFFF",
        "type": "color"
      },
      "paper-elevation-22": {
        "value": "#FFFFFF",
        "type": "color"
      },
      "paper-elevation-23": {
        "value": "#FFFFFF",
        "type": "color"
      },
      "paper-elevation-24": {
        "value": "#FFFFFF",
        "type": "color"
      }
    }
  },
  "light/components": {
    "fontFamilies": {
      "roboto": {
        "value": "Roboto",
        "type": "fontFamilies"
      }
    },
    "lineHeights": {
      "0": {
        "value": "116.7%",
        "type": "lineHeights"
      },
      "1": {
        "value": "120%",
        "type": "lineHeights"
      },
      "2": {
        "value": "123.5%",
        "type": "lineHeights"
      },
      "3": {
        "value": "133.4%",
        "type": "lineHeights"
      },
      "4": {
        "value": "160%",
        "type": "lineHeights"
      },
      "5": {
        "value": "150%",
        "type": "lineHeights"
      },
      "6": {
        "value": "143%",
        "type": "lineHeights"
      },
      "7": {
        "value": "175%",
        "type": "lineHeights"
      },
      "8": {
        "value": "157%",
        "type": "lineHeights"
      },
      "9": {
        "value": "266%",
        "type": "lineHeights"
      },
      "10": {
        "value": "166%",
        "type": "lineHeights"
      },
      "11": {
        "value": "20",
        "type": "lineHeights"
      },
      "12": {
        "value": "26",
        "type": "lineHeights"
      },
      "13": {
        "value": "24",
        "type": "lineHeights"
      },
      "14": {
        "value": "22",
        "type": "lineHeights"
      },
      "15": {
        "value": "12",
        "type": "lineHeights"
      },
      "16": {
        "value": "18",
        "type": "lineHeights"
      },
      "17": {
        "value": "14",
        "type": "lineHeights"
      },
      "18": {
        "value": "48",
        "type": "lineHeights"
      }
    },
    "fontWeights": {
      "light": {
        "value": "Light",
        "type": "fontWeights"
      },
      "regular": {
        "value": "Regular",
        "type": "fontWeights"
      },
      "medium": {
        "value": "Medium",
        "type": "fontWeights"
      }
    },
    "fontSize": {
      "0": {
        "value": "10",
        "type": "fontSizes"
      },
      "1": {
        "value": "12",
        "type": "fontSizes"
      },
      "2": {
        "value": "13",
        "type": "fontSizes"
      },
      "3": {
        "value": "14",
        "type": "fontSizes"
      },
      "4": {
        "value": "15",
        "type": "fontSizes"
      },
      "5": {
        "value": "16",
        "type": "fontSizes"
      },
      "6": {
        "value": "20",
        "type": "fontSizes"
      },
      "7": {
        "value": "24",
        "type": "fontSizes"
      },
      "8": {
        "value": "34",
        "type": "fontSizes"
      },
      "9": {
        "value": "48",
        "type": "fontSizes"
      },
      "10": {
        "value": "60",
        "type": "fontSizes"
      },
      "11": {
        "value": "96",
        "type": "fontSizes"
      }
    },
    "letterSpacing": {
      "0": {
        "value": "-1.5",
        "type": "letterSpacing"
      },
      "1": {
        "value": "-0.5",
        "type": "letterSpacing"
      },
      "2": {
        "value": "0",
        "type": "letterSpacing"
      },
      "3": {
        "value": "0.25",
        "type": "letterSpacing"
      },
      "4": {
        "value": "0.15",
        "type": "letterSpacing"
      },
      "5": {
        "value": "0.17",
        "type": "letterSpacing"
      },
      "6": {
        "value": "0.1",
        "type": "letterSpacing"
      },
      "7": {
        "value": "1",
        "type": "letterSpacing"
      },
      "8": {
        "value": "0.4",
        "type": "letterSpacing"
      },
      "9": {
        "value": "0.14",
        "type": "letterSpacing"
      },
      "10": {
        "value": "0.46",
        "type": "letterSpacing"
      },
      "11": {
        "value": "0.16",
        "type": "letterSpacing"
      }
    },
    "paragraphSpacing": {
      "none": {
        "value": "0",
        "type": "paragraphSpacing"
      }
    },
    "components": {
      "alert-title": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.medium}",
          "lineHeight": "{lineHeights.5}",
          "fontSize": "{fontSize.5}",
          "letterSpacing": "{letterSpacing.4}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "avatar-initials": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.regular}",
          "lineHeight": "{lineHeights.11}",
          "fontSize": "{fontSize.6}",
          "letterSpacing": "{letterSpacing.9}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "badge-label": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.medium}",
          "lineHeight": "{lineHeights.11}",
          "fontSize": "{fontSize.1}",
          "letterSpacing": "{letterSpacing.9}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "button-large": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.medium}",
          "lineHeight": "{lineHeights.12}",
          "fontSize": "{fontSize.4}",
          "letterSpacing": "{letterSpacing.10}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.uppercase}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "button-medium": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.medium}",
          "lineHeight": "{lineHeights.13}",
          "fontSize": "{fontSize.3}",
          "letterSpacing": "{letterSpacing.8}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.uppercase}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "button-small": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.medium}",
          "lineHeight": "{lineHeights.14}",
          "fontSize": "{fontSize.2}",
          "letterSpacing": "{letterSpacing.10}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.uppercase}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "input-label": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.regular}",
          "lineHeight": "{lineHeights.15}",
          "fontSize": "{fontSize.1}",
          "letterSpacing": "{letterSpacing.4}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "helper-text": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.regular}",
          "lineHeight": "{lineHeights.10}",
          "fontSize": "{fontSize.1}",
          "letterSpacing": "{letterSpacing.8}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "input-text": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.regular}",
          "lineHeight": "{lineHeights.13}",
          "fontSize": "{fontSize.5}",
          "letterSpacing": "{letterSpacing.4}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "chip-label": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.regular}",
          "lineHeight": "{lineHeights.16}",
          "fontSize": "{fontSize.2}",
          "letterSpacing": "{letterSpacing.11}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "tooltip-label": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.medium}",
          "lineHeight": "{lineHeights.17}",
          "fontSize": "{fontSize.0}",
          "letterSpacing": "{letterSpacing.2}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "table-header": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.medium}",
          "lineHeight": "{lineHeights.13}",
          "fontSize": "{fontSize.3}",
          "letterSpacing": "{letterSpacing.5}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "menu-item": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.regular}",
          "lineHeight": "{lineHeights.5}",
          "fontSize": "{fontSize.5}",
          "letterSpacing": "{letterSpacing.4}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "menu-item-dense": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.regular}",
          "lineHeight": "{lineHeights.13}",
          "fontSize": "{fontSize.3}",
          "letterSpacing": "{letterSpacing.5}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "list-subheader": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.medium}",
          "lineHeight": "{lineHeights.18}",
          "fontSize": "{fontSize.3}",
          "letterSpacing": "{letterSpacing.6}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "bottom-navigation-active-label": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.regular}",
          "lineHeight": "{lineHeights.10}",
          "fontSize": "{fontSize.3}",
          "letterSpacing": "{letterSpacing.8}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      }
    },
    "textCase": {
      "none": {
        "value": "none",
        "type": "textCase"
      },
      "uppercase": {
        "value": "uppercase",
        "type": "textCase"
      }
    },
    "textDecoration": {
      "none": {
        "value": "none",
        "type": "textDecoration"
      }
    },
    "paragraphIndent": {
      "none": {
        "value": "0",
        "type": "paragraphSpacing"
      }
    },
    "input": {
      "standard": {
        "enabledBorder": {
          "value": "rgba(0,0,0,0.42)",
          "type": "color"
        },
        "hoverBorder": {
          "value": "rgba(0,0,0,1)",
          "type": "color"
        }
      },
      "filled": {
        "enabledFill": {
          "value": "rgba(0,0,0,0.06)",
          "type": "color"
        },
        "hoverFill": {
          "value": "rgba(0,0,0,0.09)",
          "type": "color"
        }
      },
      "outlined": {
        "enabledBorder": {
          "value": "rgba(0,0,0,0.23)",
          "type": "color"
        },
        "hoverBorder": {
          "value": "rgba(0,0,0,1)",
          "type": "color"
        }
      }
    },
    "switch": {
      "knobFill": {
        "value": "{grey.50}",
        "type": "color"
      },
      "slideFill": {
        "value": "rgba(0,0,0,1)",
        "type": "color"
      }
    },
    "avatar": {
      "fill": {
        "value": "{grey.400}",
        "type": "color"
      }
    },
    "chip": {
      "defaultCloseFill": {
        "value": "rgba(0,0,0, 1)",
        "type": "color"
      },
      "defaultHoverFill": {
        "value": "rgba(0,0,0,0.12)",
        "type": "color"
      },
      "defaultEnabledBorder": {
        "value": "{grey.400}",
        "type": "color"
      }
    },
    "alert": {
      "errorContent": {
        "value": "#5f2120",
        "type": "color",
        "description": "Used for Alert content. The token represents 60% #000 on top of the Error/Main token"
      },
      "errorFill": {
        "value": "#fdeded",
        "type": "color",
        "description": "Used for Alert background. The token represents 90% #FFF on top of the Error/Main token"
      },
      "warningContent": {
        "value": "#663c00",
        "type": "color",
        "description": "Used for Alert content. The token represents 60% #000 on top of the Warning/Main token"
      },
      "warningFill": {
        "value": "#fff4e5",
        "type": "color",
        "description": "Used for Alert background. The token represents 90% #FFF on top of the Warning/Main token"
      },
      "infoContent": {
        "value": "#014361",
        "type": "color",
        "description": "Used for Alert content. The token represents 60% #000 on top of the Info/Main token"
      },
      "infoFill": {
        "value": "#e5f6fd",
        "type": "color",
        "description": "Used for Alert background. The token represents 90% #FFF on top of the Info/Main token"
      },
      "successContent": {
        "value": "#1e4620",
        "type": "color",
        "description": "Used for Alert content. The token represents 60% #000 on top of the Success/Main token"
      },
      "successFill": {
        "value": "#edf7ed",
        "type": "color",
        "description": "Used for Alert background. The token represents 90% #FFF on top of the Success/Main token"
      }
    },
    "backdrop": {
      "fill": {
        "value": "rgba(0,0,0,0.5)",
        "type": "color"
      }
    },
    "breadcrumbs": {
      "collapseFill": {
        "value": "{grey.100}",
        "type": "color"
      }
    },
    "appbar": {
      "defaultFill": {
        "value": "{grey.100}",
        "type": "color"
      }
    },
    "rating": {
      "enabledBorder": {
        "value": "rgba(0,0,0,0.23)",
        "type": "color"
      }
    }
  },
  "dark/base": {
    "divider": {
      "value": "rgba(255,255,255,0.12)",
      "type": "color",
      "description": "Reflects the divider variable from the theme object"
    },
    "text": {
      "primary": {
        "value": "rgba(255,255,255, 1)",
        "type": "color",
        "description": "Reflects the text.primary variable from the theme object"
      },
      "secondary": {
        "value": "rgba(255,255,255, 0.7)",
        "type": "color",
        "description": "Reflects the text.secondary variable from the theme object"
      },
      "disabled": {
        "value": "rgba({text.primary}, {action.disabledOpacity})",
        "type": "color",
        "description": "Reflects the text.disabled variable from the theme object"
      }
    },
    "primary": {
      "main": {
        "value": "#5da7e5",
        "type": "color",
        "description": "Reflects the primary.main variable from the theme object"
      },
      "dark": {
        "value": "#4174A0",
        "type": "color",
        "description": "Used for hover states. Reflects the primary.dark variable from the theme object"
      },
      "light": {
        "value": "#7DB8EA",
        "type": "color",
        "description": "Reflects the primary.light variable from the theme object"
      },
      "contrast": {
        "value": "rgba(255,255,255,0.87)",
        "type": "color",
        "description": "Reflects the primary.contrast variable from the theme object. Color that keeps a contrast ratio above AA when XX.main is used as a bg. color"
      },
      "states": {
        "hover": {
          "value": "rgba({primary.main}, {action.hoverOpacity})",
          "type": "color",
          "description": "Used for hover states. The token represents the value of action.hoverOpacity (0.04 by default) of the main token."
        },
        "selected": {
          "value": "rgba({primary.main}, {action.selectedOpacity})",
          "type": "color",
          "description": "Used for selected states. The token represents the value of action.selectedOpacity (0.08 by default) of the main token."
        },
        "focus": {
          "value": "rgba({primary.main}, {action.focusOpacity})",
          "type": "color",
          "description": "Used for focus states. The token represents the value of action.focusOpacity (0.12 by default) of the main token."
        },
        "focusVisible": {
          "value": "rgba({primary.main}, 0.3)",
          "type": "color",
          "description": "Used for focus visible states. The token represents the value of focusVisibleOpacity (0.3 by default) of the main token."
        },
        "outlinedBorder": {
          "value": "rgba({primary.main}, 0.5)",
          "type": "color",
          "description": "Used for enabled states (e.g Button outlined variant). The token represents the value of outlinedBorderOpacity (0.5 by default) of the main token."
        }
      }
    },
    "secondary": {
      "main": {
        "value": "#F99D20",
        "type": "color",
        "description": "Reflects the secondary.main variable from the theme object"
      },
      "dark": {
        "value": "#AE6D16",
        "type": "color",
        "description": "Used for hover states. Reflects the secondary.dark variable from the theme object"
      },
      "light": {
        "value": "#FAB04C",
        "type": "color",
        "description": "Reflects the secondary.light variable from the theme object"
      },
      "contrast": {
        "value": "rgba(255,255,255,0.87)",
        "type": "color",
        "description": "Reflects the secondary.contrast variable from the theme object. Color that keeps a contrast ratio above AA when XX.main is used as a bg. color"
      },
      "states": {
        "hover": {
          "value": "rgba({secondary.main}, {action.hoverOpacity})",
          "type": "color",
          "description": "Used for hover states. The token represents the value of action.hoverOpacity (0.04 by default) of the main token."
        },
        "selected": {
          "value": "rgba({secondary.main}, {action.selectedOpacity})",
          "type": "color",
          "description": "Used for selected states. The token represents the value of action.selectedOpacity (0.08 by default) of the main token."
        },
        "focus": {
          "value": "rgba({secondary.main}, {action.focusOpacity})",
          "type": "color",
          "description": "Used for focus visible states. The token represents 12% of the Secondary/Main token"
        },
        "focusVisible": {
          "value": "rgba({secondary.main}, 0.3)",
          "type": "color",
          "description": "Used for focus visible states. The token represents the value of focusVisibleOpacity (0.3 by default) of the main token."
        },
        "outlinedBorder": {
          "value": "rgba({secondary.main}, 0.5)",
          "type": "color",
          "description": "Used for enabled states (e.g Button outlined variant). The token represents the value of outlinedBorderOpacity (0.5 by default) of the main token."
        }
      }
    },
    "action": {
      "hover": {
        "value": "rgba({action.activeChannel}, {action.hoverOpacity})",
        "type": "color",
        "description": "Reflects the action.hover variable from the theme object"
      },
      "selected": {
        "value": "rgba({action.selectedChannel}, {action.selectedOpacity})",
        "type": "color",
        "description": "Reflects the action.selected variable from the theme object"
      },
      "disabledBackground": {
        "value": "rgba({action.activeChannel}, 0.12)",
        "type": "color",
        "description": "Reflects the action.disabledBackground variable from the theme object"
      },
      "focus": {
        "value": "rgba({action.activeChannel}, {action.focusOpacity})",
        "type": "color",
        "description": "Reflects the action.focus variable from the theme object"
      },
      "disabled": {
        "value": "rgba({action.activeChannel}, {action.disabledOpacity})",
        "type": "color",
        "description": "Reflects the action.disabled variable from the theme object"
      },
      "active": {
        "value": "rgba({action.activeChannel}, 0.56)",
        "type": "color",
        "description": "Reflects the action.active variable from the theme object"
      },
      "hoverOpacity": {
        "value": "0.08",
        "type": "opacity"
      },
      "selectedOpacity": {
        "value": "0.16",
        "type": "opacity"
      },
      "disabledOpacity": {
        "value": "0.38",
        "type": "opacity"
      },
      "focusOpacity": {
        "value": "0.12",
        "type": "opacity"
      },
      "activeChannel": {
        "value": "rgb(255,255,255)",
        "type": "color"
      },
      "selectedChannel": {
        "value": "rgb(255,255,255)",
        "type": "color"
      }
    },
    "error": {
      "main": {
        "value": "{red.500}",
        "type": "color",
        "description": "Reflects the error.main variable from the theme object"
      },
      "dark": {
        "value": "{red.700}",
        "type": "color",
        "description": "Used for hover states. Reflects the error.dark variable from the theme object"
      },
      "light": {
        "value": "{red.300}",
        "type": "color",
        "description": "Reflects the error.light variable from the theme object"
      },
      "contrast": {
        "value": "#ffffff",
        "type": "color",
        "description": "Reflects the error.contrast variable from the theme object. Color that keeps a contrast ratio above AA when XX.main is used as a bg. color"
      },
      "states": {
        "hover": {
          "value": "rgba({error.main}, {action.hoverOpacity})",
          "type": "color",
          "description": "Used for hover states. The token represents the value of action.hoverOpacity (0.04 by default) of the main token."
        },
        "selected": {
          "value": "rgba({error.main}, {action.selectedOpacity})",
          "type": "color",
          "description": "Used for selected states. The token represents the value of action.selectedOpacity (0.08 by default) of the main token."
        },
        "focusVisible": {
          "value": "rgba({error.main}, 0.3)",
          "type": "color",
          "description": "Used for focus visible states. The token represents the value of focusVisibleOpacity (0.3 by default) of the main token."
        },
        "outlinedBorder": {
          "value": "rgba({error.main}, 0.5)",
          "type": "color",
          "description": "Used for enabled states (e.g Button outlined variant). The token represents the value of outlinedBorderOpacity (0.5 by default) of the main token."
        }
      }
    },
    "warning": {
      "main": {
        "value": "{orange.400}",
        "type": "color",
        "description": "Reflects the warning.main variable from the theme object"
      },
      "dark": {
        "value": "{orange.700}",
        "type": "color",
        "description": "Used for hover states. Reflects the warning.dark variable from the theme object"
      },
      "light": {
        "value": "{orange.300}",
        "type": "color",
        "description": "Reflects the warning.light variable from the theme object"
      },
      "contrast": {
        "value": "rgba(0,0,0,0.87)",
        "type": "color",
        "description": "Reflects the warning.contrast variable from the theme object. Color that keeps a contrast ratio above AA when XX.main is used as a bg. color"
      },
      "states": {
        "hover": {
          "value": "rgba({warning.main}, {action.hoverOpacity})",
          "type": "color",
          "description": "Used for hover states. The token represents the value of action.hoverOpacity (0.04 by default) of the main token."
        },
        "selected": {
          "value": "rgba({warning.main}, {action.hoverOpacity})",
          "type": "color",
          "description": "Used for selected states. The token represents the value of action.selectedOpacity (0.08 by default) of the main token."
        },
        "focusVisible": {
          "value": "rgba({warning.main}, 0.3)",
          "type": "color",
          "description": "Used for focus visible states. The token represents the value of focusVisibleOpacity (0.3 by default) of the main token."
        },
        "outlinedBorder": {
          "value": "rgba({warning.main}, 0.5)",
          "type": "color",
          "description": "Used for enabled states (e.g Button outlined variant). The token represents the value of outlinedBorderOpacity (0.5 by default) of the main token."
        }
      }
    },
    "info": {
      "main": {
        "value": "{lightBlue.400}",
        "type": "color",
        "description": "Reflects the info.main variable from the theme object"
      },
      "dark": {
        "value": "{lightBlue.700}",
        "type": "color",
        "description": "Used for hover states. Reflects the info.dark variable from the theme object"
      },
      "light": {
        "value": "{lightBlue.300}",
        "type": "color",
        "description": "Reflects the info.light variable from the theme object"
      },
      "contrast": {
        "value": "rgba(0,0,0,0.87)",
        "type": "color",
        "description": "Reflects the info.contrast variable from the theme object. Color that keeps a contrast ratio above AA when XX.main is used as a bg. color"
      },
      "states": {
        "hover": {
          "value": "rgba({info.main}, {action.hoverOpacity})",
          "type": "color",
          "description": "Used for hover states. The token represents the value of action.hoverOpacity (0.04 by default) of the main token."
        },
        "selected": {
          "value": "rgba({info.main}, {action.selectedOpacity})",
          "type": "color",
          "description": "Used for selected states. The token represents the value of action.selectedOpacity (0.08 by default) of the main token."
        },
        "focusVisible": {
          "value": "rgba({info.main}, 0.3)",
          "type": "color",
          "description": "Used for focus visible states. The token represents the value of focusVisibleOpacity (0.3 by default) of the main token."
        },
        "outlinedBorder": {
          "value": "rgba({info.main}, 0.5)",
          "type": "color",
          "description": "Used for enabled states (e.g Button outlined variant). The token represents the value of outlinedBorderOpacity (0.5 by default) of the main token."
        }
      }
    },
    "success": {
      "main": {
        "value": "{green.400}",
        "type": "color",
        "description": "Reflects the success.main variable from the theme object"
      },
      "dark": {
        "value": "{green.700}",
        "type": "color",
        "description": "Used for hover states. Reflects the success.dark variable from the theme object"
      },
      "light": {
        "value": "{green.300}",
        "type": "color",
        "description": "Reflects the success.light variable from the theme object"
      },
      "contrast": {
        "value": "rgba(0,0,0,0.87)",
        "type": "color",
        "description": "Reflects the success.contrast variable from the theme object. Color that keeps a contrast ratio above AA when XX.main is used as a bg. color"
      },
      "states": {
        "hover": {
          "value": "rgba({success.main}, {action.hoverOpacity})",
          "type": "color",
          "description": "Used for hover states. The token represents the value of action.hoverOpacity (0.04 by default) of the main token."
        },
        "selected": {
          "value": "rgba({success.main}, {action.selectedOpacity})",
          "type": "color",
          "description": "Used for selected states. The token represents the value of action.selectedOpacity (0.08 by default) of the main token."
        },
        "focusVisible": {
          "value": "rgba({success.main}, 0.3)",
          "type": "color",
          "description": "Used for focus visible states. The token represents the value of focusVisibleOpacity (0.3 by default) of the main token."
        },
        "outlinedBorder": {
          "value": "rgba({success.main}, 0.5)",
          "type": "color",
          "description": "Used for enabled states (e.g Button outlined variant). The token represents the value of outlinedBorderOpacity (0.5 by default) of the main token."
        }
      }
    },
    "inherit": {
      "white": {
        "main": {
          "value": "{text.primary}",
          "type": "color"
        },
        "hover": {
          "value": "rgba({inherit.white.main}, {action.hoverOpacity})",
          "type": "color",
          "description": "Used for hover states. The token represents the value of action.hoverOpacity (0.04 by default) of the main token."
        },
        "selected": {
          "value": "rgba({inherit.white.main}, {action.selectedOpacity})",
          "type": "color",
          "description": "Used for selected states. The token represents the value of action.selectedOpacity (0.08 by default) of the main token."
        },
        "focus": {
          "value": "rgba({inherit.white.main}, {action.focusOpacity})",
          "type": "color",
          "description": "Used for focus states. The token represents the value of action.focusOpacity (0.12 by default) of the main token."
        },
        "focusVisible": {
          "value": "rgba({inherit.white.main}, 0.3)",
          "type": "color",
          "description": "Used for focus visible states. The token represents the value of focusVisibleOpacity (0.3 by default) of the main token."
        }
      },
      "textPrimary": {
        "main": {
          "value": "{text.primary}",
          "type": "color"
        },
        "hover": {
          "value": "rgba({inherit.textPrimary.main}, {action.hoverOpacity})",
          "type": "color",
          "description": "Used for hover states. The token represents the value of action.hoverOpacity (0.04 by default) of the main token."
        },
        "selected": {
          "value": "rgba({inherit.textPrimary.main}, {action.selectedOpacity})",
          "type": "color",
          "description": "Used for selected states. The token represents the value of action.selectedOpacity (0.08 by default) of the main token."
        },
        "focus": {
          "value": "rgba({inherit.textPrimary.main}, {action.focusOpacity})",
          "type": "color",
          "description": "Used for focus states. The token represents the value of action.focusOpacity (0.12 by default) of the main token."
        },
        "focusVisible": {
          "value": "rgba({inherit.textPrimary.main}, 0.3)",
          "type": "color",
          "description": "Used for focus visible states. The token represents the value of focusVisibleOpacity (0.3 by default) of the main token."
        }
      }
    },
    "elevation": {
      "1": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "1",
            "blur": "3",
            "spread": "0"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "1",
            "blur": "1",
            "spread": "0"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "2",
            "blur": "1",
            "spread": "-1"
          }
        ],
        "type": "boxShadow"
      },
      "2": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "1",
            "blur": "5",
            "spread": "0"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "2",
            "blur": "2",
            "spread": "0"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "3",
            "blur": "1",
            "spread": "-2"
          }
        ],
        "type": "boxShadow"
      },
      "3": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "1",
            "blur": "8",
            "spread": "0"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "3",
            "blur": "4",
            "spread": "0"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "3",
            "blur": "3",
            "spread": "-2"
          }
        ],
        "type": "boxShadow"
      },
      "4": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "1",
            "blur": "10",
            "spread": "0"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "4",
            "blur": "5",
            "spread": "0"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "2",
            "blur": "4",
            "spread": "-1"
          }
        ],
        "type": "boxShadow"
      },
      "5": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "1",
            "blur": "14",
            "spread": "0"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "5",
            "blur": "8",
            "spread": "0"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "3",
            "blur": "5",
            "spread": "-1"
          }
        ],
        "type": "boxShadow"
      },
      "6": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "1",
            "blur": "18",
            "spread": "0"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "6",
            "blur": "10",
            "spread": "0"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "3",
            "blur": "5",
            "spread": "-1"
          }
        ],
        "type": "boxShadow"
      },
      "7": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "2",
            "blur": "16",
            "spread": "1"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "7",
            "blur": "10",
            "spread": "1"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "4",
            "blur": "5",
            "spread": "-2"
          }
        ],
        "type": "boxShadow"
      },
      "8": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "3",
            "blur": "14",
            "spread": "2"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "8",
            "blur": "10",
            "spread": "1"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "5",
            "blur": "5",
            "spread": "-3"
          }
        ],
        "type": "boxShadow"
      },
      "9": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "3",
            "blur": "16",
            "spread": "2"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "9",
            "blur": "12",
            "spread": "1"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "5",
            "blur": "6",
            "spread": "-3"
          }
        ],
        "type": "boxShadow"
      },
      "10": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "4",
            "blur": "18",
            "spread": "3"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "10",
            "blur": "14",
            "spread": "1"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "6",
            "blur": "6",
            "spread": "-3"
          }
        ],
        "type": "boxShadow"
      },
      "11": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "4",
            "blur": "20",
            "spread": "3"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "11",
            "blur": "15",
            "spread": "1"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "6",
            "blur": "7",
            "spread": "-4"
          }
        ],
        "type": "boxShadow"
      },
      "12": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "5",
            "blur": "22",
            "spread": "4"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "12",
            "blur": "17",
            "spread": "2"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "7",
            "blur": "8",
            "spread": "-4"
          }
        ],
        "type": "boxShadow"
      },
      "13": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "5",
            "blur": "24",
            "spread": "4"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "13",
            "blur": "19",
            "spread": "2"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "7",
            "blur": "8",
            "spread": "-4"
          }
        ],
        "type": "boxShadow"
      },
      "14": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "5",
            "blur": "26",
            "spread": "4"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "14",
            "blur": "21",
            "spread": "2"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "7",
            "blur": "9",
            "spread": "-4"
          }
        ],
        "type": "boxShadow"
      },
      "15": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "6",
            "blur": "28",
            "spread": "5"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "15",
            "blur": "22",
            "spread": "2"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "8",
            "blur": "9",
            "spread": "-5"
          }
        ],
        "type": "boxShadow"
      },
      "16": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "6",
            "blur": "30",
            "spread": "5"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "16",
            "blur": "24",
            "spread": "2"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "8",
            "blur": "10",
            "spread": "-5"
          }
        ],
        "type": "boxShadow"
      },
      "17": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "6",
            "blur": "32",
            "spread": "5"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "17",
            "blur": "26",
            "spread": "2"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "8",
            "blur": "11",
            "spread": "-5"
          }
        ],
        "type": "boxShadow"
      },
      "18": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "7",
            "blur": "34",
            "spread": "6"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "18",
            "blur": "28",
            "spread": "2"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "9",
            "blur": "11",
            "spread": "-5"
          }
        ],
        "type": "boxShadow"
      },
      "19": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "7",
            "blur": "36",
            "spread": "6"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "19",
            "blur": "29",
            "spread": "2"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "9",
            "blur": "12",
            "spread": "-6"
          }
        ],
        "type": "boxShadow"
      },
      "20": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "8",
            "blur": "38",
            "spread": "7"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "20",
            "blur": "31",
            "spread": "3"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "10",
            "blur": "13",
            "spread": "-6"
          }
        ],
        "type": "boxShadow"
      },
      "21": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "8",
            "blur": "40",
            "spread": "7"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "21",
            "blur": "33",
            "spread": "3"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "10",
            "blur": "13",
            "spread": "-6"
          }
        ],
        "type": "boxShadow"
      },
      "22": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "8",
            "blur": "42",
            "spread": "7"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "22",
            "blur": "35",
            "spread": "3"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "10",
            "blur": "14",
            "spread": "-6"
          }
        ],
        "type": "boxShadow"
      },
      "23": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "9",
            "blur": "44",
            "spread": "8"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "23",
            "blur": "36",
            "spread": "3"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "11",
            "blur": "14",
            "spread": "-7"
          }
        ],
        "type": "boxShadow"
      },
      "24": {
        "value": [
          {
            "color": "#0000001f",
            "type": "dropShadow",
            "x": "0",
            "y": "9",
            "blur": "46",
            "spread": "8"
          },
          {
            "color": "#00000024",
            "type": "dropShadow",
            "x": "0",
            "y": "24",
            "blur": "38",
            "spread": "3"
          },
          {
            "color": "#00000033",
            "type": "dropShadow",
            "x": "0",
            "y": "11",
            "blur": "15",
            "spread": "-7"
          }
        ],
        "type": "boxShadow"
      },
      "outlined": {
        "value": {
          "x": "0",
          "y": "0",
          "blur": "0",
          "spread": "1",
          "color": "$divider",
          "type": "dropShadow"
        },
        "type": "boxShadow"
      }
    },
    "fontFamilies": {
      "roboto": {
        "value": "Roboto",
        "type": "fontFamilies"
      }
    },
    "lineHeights": {
      "0": {
        "value": "116.7%",
        "type": "lineHeights"
      },
      "1": {
        "value": "120%",
        "type": "lineHeights"
      },
      "2": {
        "value": "123.5%",
        "type": "lineHeights"
      },
      "3": {
        "value": "133.4%",
        "type": "lineHeights"
      },
      "4": {
        "value": "160%",
        "type": "lineHeights"
      },
      "5": {
        "value": "150%",
        "type": "lineHeights"
      },
      "6": {
        "value": "143%",
        "type": "lineHeights"
      },
      "7": {
        "value": "175%",
        "type": "lineHeights"
      },
      "8": {
        "value": "157%",
        "type": "lineHeights"
      },
      "9": {
        "value": "266%",
        "type": "lineHeights"
      },
      "10": {
        "value": "166%",
        "type": "lineHeights"
      },
      "11": {
        "value": "20",
        "type": "lineHeights"
      },
      "12": {
        "value": "26",
        "type": "lineHeights"
      },
      "13": {
        "value": "24",
        "type": "lineHeights"
      },
      "14": {
        "value": "22",
        "type": "lineHeights"
      },
      "15": {
        "value": "12",
        "type": "lineHeights"
      },
      "16": {
        "value": "18",
        "type": "lineHeights"
      },
      "17": {
        "value": "14",
        "type": "lineHeights"
      },
      "18": {
        "value": "48",
        "type": "lineHeights"
      }
    },
    "fontWeights": {
      "light": {
        "value": "Light",
        "type": "fontWeights"
      },
      "regular": {
        "value": "Regular",
        "type": "fontWeights"
      },
      "medium": {
        "value": "Medium",
        "type": "fontWeights"
      }
    },
    "fontSize": {
      "0": {
        "value": "10",
        "type": "fontSizes"
      },
      "1": {
        "value": "12",
        "type": "fontSizes"
      },
      "2": {
        "value": "13",
        "type": "fontSizes"
      },
      "3": {
        "value": "14",
        "type": "fontSizes"
      },
      "4": {
        "value": "15",
        "type": "fontSizes"
      },
      "5": {
        "value": "16",
        "type": "fontSizes"
      },
      "6": {
        "value": "20",
        "type": "fontSizes"
      },
      "7": {
        "value": "24",
        "type": "fontSizes"
      },
      "8": {
        "value": "34",
        "type": "fontSizes"
      },
      "9": {
        "value": "48",
        "type": "fontSizes"
      },
      "10": {
        "value": "60",
        "type": "fontSizes"
      },
      "11": {
        "value": "96",
        "type": "fontSizes"
      }
    },
    "letterSpacing": {
      "0": {
        "value": "-1.5",
        "type": "letterSpacing"
      },
      "1": {
        "value": "-0.5",
        "type": "letterSpacing"
      },
      "2": {
        "value": "0",
        "type": "letterSpacing"
      },
      "3": {
        "value": "0.25",
        "type": "letterSpacing"
      },
      "4": {
        "value": "0.15",
        "type": "letterSpacing"
      },
      "5": {
        "value": "0.17",
        "type": "letterSpacing"
      },
      "6": {
        "value": "0.1",
        "type": "letterSpacing"
      },
      "7": {
        "value": "1",
        "type": "letterSpacing"
      },
      "8": {
        "value": "0.4",
        "type": "letterSpacing"
      },
      "9": {
        "value": "0.14",
        "type": "letterSpacing"
      },
      "10": {
        "value": "0.46",
        "type": "letterSpacing"
      },
      "11": {
        "value": "0.16",
        "type": "letterSpacing"
      }
    },
    "paragraphSpacing": {
      "none": {
        "value": "0",
        "type": "paragraphSpacing"
      }
    },
    "typography": {
      "h1": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.light}",
          "lineHeight": "{lineHeights.0}",
          "fontSize": "{fontSize.11}",
          "letterSpacing": "{letterSpacing.0}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "h2": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.light}",
          "lineHeight": "{lineHeights.1}",
          "fontSize": "{fontSize.10}",
          "letterSpacing": "{letterSpacing.1}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "h3": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.regular}",
          "lineHeight": "{lineHeights.0}",
          "fontSize": "{fontSize.9}",
          "letterSpacing": "{letterSpacing.2}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "h4": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.regular}",
          "lineHeight": "{lineHeights.2}",
          "fontSize": "{fontSize.8}",
          "letterSpacing": "{letterSpacing.3}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "h5": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.regular}",
          "lineHeight": "{lineHeights.3}",
          "fontSize": "{fontSize.7}",
          "letterSpacing": "{letterSpacing.2}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "h6": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.medium}",
          "lineHeight": "{lineHeights.4}",
          "fontSize": "{fontSize.6}",
          "letterSpacing": "{letterSpacing.4}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "body1": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.regular}",
          "lineHeight": "{lineHeights.5}",
          "fontSize": "{fontSize.5}",
          "letterSpacing": "{letterSpacing.4}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "body2": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.regular}",
          "lineHeight": "{lineHeights.6}",
          "fontSize": "{fontSize.3}",
          "letterSpacing": "{letterSpacing.5}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "subtitle1": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.regular}",
          "lineHeight": "{lineHeights.7}",
          "fontSize": "{fontSize.5}",
          "letterSpacing": "{letterSpacing.4}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "subtitle2": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.medium}",
          "lineHeight": "{lineHeights.8}",
          "fontSize": "{fontSize.3}",
          "letterSpacing": "{letterSpacing.6}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "overline": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.regular}",
          "lineHeight": "{lineHeights.9}",
          "fontSize": "{fontSize.1}",
          "letterSpacing": "{letterSpacing.7}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.uppercase}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "caption": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.regular}",
          "lineHeight": "{lineHeights.10}",
          "fontSize": "{fontSize.1}",
          "letterSpacing": "{letterSpacing.8}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "annotation": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.light}",
          "lineHeight": "{lineHeights.0}",
          "fontSize": "{fontSize.0}",
          "letterSpacing": "{letterSpacing.3}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      }
    },
    "textCase": {
      "none": {
        "value": "none",
        "type": "textCase"
      },
      "uppercase": {
        "value": "uppercase",
        "type": "textCase"
      }
    },
    "textDecoration": {
      "none": {
        "value": "none",
        "type": "textDecoration"
      }
    },
    "shape": {
      "borderRadius": {
        "value": "4",
        "type": "borderRadius"
      }
    },
    "paragraphIndent": {
      "none": {
        "value": "0",
        "type": "paragraphSpacing"
      }
    },
    "spacing": {
      "1": {
        "value": "{spacing.base} * 1",
        "type": "spacing"
      },
      "2": {
        "value": "{spacing.base} * 2",
        "type": "spacing"
      },
      "3": {
        "value": "{spacing.base} * 3",
        "type": "spacing"
      },
      "4": {
        "value": "{spacing.base} * 4",
        "type": "spacing"
      },
      "5": {
        "value": "{spacing.base} * 5",
        "type": "spacing"
      },
      "6": {
        "value": "{spacing.base} * 6",
        "type": "spacing"
      },
      "7": {
        "value": "{spacing.base} * 7",
        "type": "spacing"
      },
      "8": {
        "value": "{spacing.base} * 8",
        "type": "spacing"
      },
      "9": {
        "value": "{spacing.base} * 9",
        "type": "spacing"
      },
      "10": {
        "value": "{spacing.base} * 10",
        "type": "spacing"
      },
      "11": {
        "value": "{spacing.base} * 11",
        "type": "spacing"
      },
      "12": {
        "value": "{spacing.base} * 12",
        "type": "spacing"
      },
      "base": {
        "value": "8",
        "type": "spacing"
      }
    },
    "sizing": {
      "1": {
        "value": "{spacing.base} * 1",
        "type": "sizing"
      },
      "2": {
        "value": "{spacing.base} * 2",
        "type": "sizing"
      },
      "3": {
        "value": "{spacing.base} * 3",
        "type": "sizing"
      },
      "4": {
        "value": "{spacing.base} * 4",
        "type": "sizing"
      },
      "5": {
        "value": "{spacing.base} * 5",
        "type": "sizing"
      },
      "6": {
        "value": "{spacing.base} * 6",
        "type": "sizing"
      },
      "7": {
        "value": "{spacing.base} * 7",
        "type": "sizing"
      },
      "8": {
        "value": "{spacing.base} * 8",
        "type": "sizing"
      },
      "9": {
        "value": "{spacing.base} * 9",
        "type": "sizing"
      },
      "10": {
        "value": "{spacing.base} * 10",
        "type": "sizing"
      },
      "11": {
        "value": "{spacing.base} * 11",
        "type": "sizing"
      },
      "12": {
        "value": "{spacing.base} * 12",
        "type": "sizing"
      },
      "base": {
        "value": "{spacing.base}",
        "type": "sizing"
      }
    },
    "background": {
      "default": {
        "value": "#121212",
        "type": "color",
        "description": "Reflects the background.default variable from the theme object"
      },
      "paper-elevation-0": {
        "value": "#121212",
        "type": "color",
        "description": "Reflects the background.paper variable from the theme object"
      },
      "paper-elevation-1": {
        "value": "#1E1E1E",
        "type": "color"
      },
      "paper-elevation-2": {
        "value": "#232323",
        "type": "color"
      },
      "paper-elevation-3": {
        "value": "#252525",
        "type": "color"
      },
      "paper-elevation-4": {
        "value": "#272727",
        "type": "color"
      },
      "paper-elevation-5": {
        "value": "#2A2A2A",
        "type": "color"
      },
      "paper-elevation-6": {
        "value": "#2C2C2C",
        "type": "color"
      },
      "paper-elevation-7": {
        "value": "#2C2C2C",
        "type": "color"
      },
      "paper-elevation-8": {
        "value": "#2E2E2E",
        "type": "color"
      },
      "paper-elevation-9": {
        "value": "#2E2E2E",
        "type": "color"
      },
      "paper-elevation-10": {
        "value": "#313131",
        "type": "color"
      },
      "paper-elevation-11": {
        "value": "#313131",
        "type": "color"
      },
      "paper-elevation-12": {
        "value": "#333333",
        "type": "color"
      },
      "paper-elevation-13": {
        "value": "#333333",
        "type": "color"
      },
      "paper-elevation-14": {
        "value": "#333333",
        "type": "color"
      },
      "paper-elevation-15": {
        "value": "#333333",
        "type": "color"
      },
      "paper-elevation-16": {
        "value": "#363636",
        "type": "color"
      },
      "paper-elevation-17": {
        "value": "#363636",
        "type": "color"
      },
      "paper-elevation-18": {
        "value": "#363636",
        "type": "color"
      },
      "paper-elevation-19": {
        "value": "#363636",
        "type": "color"
      },
      "paper-elevation-20": {
        "value": "#383838",
        "type": "color"
      },
      "paper-elevation-21": {
        "value": "#383838",
        "type": "color"
      },
      "paper-elevation-22": {
        "value": "#383838",
        "type": "color"
      },
      "paper-elevation-23": {
        "value": "#383838",
        "type": "color"
      },
      "paper-elevation-24": {
        "value": "#383838",
        "type": "color"
      }
    }
  },
  "dark/components": {
    "fontFamilies": {
      "roboto": {
        "value": "Roboto",
        "type": "fontFamilies"
      }
    },
    "lineHeights": {
      "0": {
        "value": "116.7%",
        "type": "lineHeights"
      },
      "1": {
        "value": "120%",
        "type": "lineHeights"
      },
      "2": {
        "value": "123.5%",
        "type": "lineHeights"
      },
      "3": {
        "value": "133.4%",
        "type": "lineHeights"
      },
      "4": {
        "value": "160%",
        "type": "lineHeights"
      },
      "5": {
        "value": "150%",
        "type": "lineHeights"
      },
      "6": {
        "value": "143%",
        "type": "lineHeights"
      },
      "7": {
        "value": "175%",
        "type": "lineHeights"
      },
      "8": {
        "value": "157%",
        "type": "lineHeights"
      },
      "9": {
        "value": "266%",
        "type": "lineHeights"
      },
      "10": {
        "value": "166%",
        "type": "lineHeights"
      },
      "11": {
        "value": "20",
        "type": "lineHeights"
      },
      "12": {
        "value": "26",
        "type": "lineHeights"
      },
      "13": {
        "value": "24",
        "type": "lineHeights"
      },
      "14": {
        "value": "22",
        "type": "lineHeights"
      },
      "15": {
        "value": "12",
        "type": "lineHeights"
      },
      "16": {
        "value": "18",
        "type": "lineHeights"
      },
      "17": {
        "value": "14",
        "type": "lineHeights"
      },
      "18": {
        "value": "48",
        "type": "lineHeights"
      }
    },
    "fontWeights": {
      "light": {
        "value": "Light",
        "type": "fontWeights"
      },
      "regular": {
        "value": "Regular",
        "type": "fontWeights"
      },
      "medium": {
        "value": "Medium",
        "type": "fontWeights"
      }
    },
    "fontSize": {
      "0": {
        "value": "10",
        "type": "fontSizes"
      },
      "1": {
        "value": "12",
        "type": "fontSizes"
      },
      "2": {
        "value": "13",
        "type": "fontSizes"
      },
      "3": {
        "value": "14",
        "type": "fontSizes"
      },
      "4": {
        "value": "15",
        "type": "fontSizes"
      },
      "5": {
        "value": "16",
        "type": "fontSizes"
      },
      "6": {
        "value": "20",
        "type": "fontSizes"
      },
      "7": {
        "value": "24",
        "type": "fontSizes"
      },
      "8": {
        "value": "34",
        "type": "fontSizes"
      },
      "9": {
        "value": "48",
        "type": "fontSizes"
      },
      "10": {
        "value": "60",
        "type": "fontSizes"
      },
      "11": {
        "value": "96",
        "type": "fontSizes"
      }
    },
    "letterSpacing": {
      "0": {
        "value": "-1.5",
        "type": "letterSpacing"
      },
      "1": {
        "value": "-0.5",
        "type": "letterSpacing"
      },
      "2": {
        "value": "0",
        "type": "letterSpacing"
      },
      "3": {
        "value": "0.25",
        "type": "letterSpacing"
      },
      "4": {
        "value": "0.15",
        "type": "letterSpacing"
      },
      "5": {
        "value": "0.17",
        "type": "letterSpacing"
      },
      "6": {
        "value": "0.1",
        "type": "letterSpacing"
      },
      "7": {
        "value": "1",
        "type": "letterSpacing"
      },
      "8": {
        "value": "0.4",
        "type": "letterSpacing"
      },
      "9": {
        "value": "0.14",
        "type": "letterSpacing"
      },
      "10": {
        "value": "0.46",
        "type": "letterSpacing"
      },
      "11": {
        "value": "0.16",
        "type": "letterSpacing"
      }
    },
    "paragraphSpacing": {
      "none": {
        "value": "0",
        "type": "paragraphSpacing"
      }
    },
    "components": {
      "alert-title": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.medium}",
          "lineHeight": "{lineHeights.5}",
          "fontSize": "{fontSize.5}",
          "letterSpacing": "{letterSpacing.4}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "avatar-initials": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.regular}",
          "lineHeight": "{lineHeights.11}",
          "fontSize": "{fontSize.6}",
          "letterSpacing": "{letterSpacing.9}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "badge-label": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.medium}",
          "lineHeight": "{lineHeights.11}",
          "fontSize": "{fontSize.1}",
          "letterSpacing": "{letterSpacing.9}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "button-large": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.medium}",
          "lineHeight": "{lineHeights.12}",
          "fontSize": "{fontSize.4}",
          "letterSpacing": "{letterSpacing.10}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.uppercase}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "button-medium": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.medium}",
          "lineHeight": "{lineHeights.13}",
          "fontSize": "{fontSize.3}",
          "letterSpacing": "{letterSpacing.8}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.uppercase}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "button-small": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.medium}",
          "lineHeight": "{lineHeights.14}",
          "fontSize": "{fontSize.2}",
          "letterSpacing": "{letterSpacing.10}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.uppercase}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "input-label": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.regular}",
          "lineHeight": "{lineHeights.15}",
          "fontSize": "{fontSize.1}",
          "letterSpacing": "{letterSpacing.4}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "helper-text": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.regular}",
          "lineHeight": "{lineHeights.10}",
          "fontSize": "{fontSize.1}",
          "letterSpacing": "{letterSpacing.8}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "input-text": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.regular}",
          "lineHeight": "{lineHeights.13}",
          "fontSize": "{fontSize.5}",
          "letterSpacing": "{letterSpacing.4}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "chip-label": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.regular}",
          "lineHeight": "{lineHeights.16}",
          "fontSize": "{fontSize.2}",
          "letterSpacing": "{letterSpacing.11}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "tooltip-label": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.medium}",
          "lineHeight": "{lineHeights.17}",
          "fontSize": "{fontSize.0}",
          "letterSpacing": "{letterSpacing.2}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "table-header": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.medium}",
          "lineHeight": "{lineHeights.13}",
          "fontSize": "{fontSize.3}",
          "letterSpacing": "{letterSpacing.5}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "menu-item": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.regular}",
          "lineHeight": "{lineHeights.5}",
          "fontSize": "{fontSize.5}",
          "letterSpacing": "{letterSpacing.4}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "menu-item-dense": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.regular}",
          "lineHeight": "{lineHeights.13}",
          "fontSize": "{fontSize.3}",
          "letterSpacing": "{letterSpacing.5}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "list-subheader": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.medium}",
          "lineHeight": "{lineHeights.18}",
          "fontSize": "{fontSize.3}",
          "letterSpacing": "{letterSpacing.6}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      },
      "bottom-navigation-active-label": {
        "value": {
          "fontFamily": "{fontFamilies.roboto}",
          "fontWeight": "{fontWeights.regular}",
          "lineHeight": "{lineHeights.10}",
          "fontSize": "{fontSize.3}",
          "letterSpacing": "{letterSpacing.8}",
          "paragraphSpacing": "{paragraphSpacing.none}",
          "paragraphIndent": "{paragraphIndent.none}",
          "textCase": "{textCase.none}",
          "textDecoration": "{textDecoration.none}"
        },
        "type": "typography"
      }
    },
    "textCase": {
      "none": {
        "value": "none",
        "type": "textCase"
      },
      "uppercase": {
        "value": "uppercase",
        "type": "textCase"
      }
    },
    "textDecoration": {
      "none": {
        "value": "none",
        "type": "textDecoration"
      }
    },
    "paragraphIndent": {
      "none": {
        "value": "0",
        "type": "paragraphSpacing"
      }
    },
    "input": {
      "standard": {
        "enabledBorder": {
          "value": "rgba(255,255,255,0.42)",
          "type": "color"
        },
        "hoverBorder": {
          "value": "rgba(255,255,255,1)",
          "type": "color"
        }
      },
      "filled": {
        "enabledFill": {
          "value": "rgba(255,255,255, 0.09)",
          "type": "color"
        },
        "hoverFill": {
          "value": "rgba(255,255,255, 0.12)",
          "type": "color"
        }
      },
      "outlined": {
        "enabledBorder": {
          "value": "rgba(255,255,255, 0.23)",
          "type": "color"
        },
        "hoverBorder": {
          "value": "rgba(255,255,255, 1)",
          "type": "color"
        }
      }
    },
    "switch": {
      "knobFill": {
        "value": "{grey.300}",
        "type": "color"
      },
      "slideFill": {
        "value": "rgba(255,255,255,0.38)",
        "type": "color"
      }
    },
    "avatar": {
      "fill": {
        "value": "{grey.600}",
        "type": "color"
      }
    },
    "chip": {
      "defaultCloseFill": {
        "value": "rgba(255,255,255, 1)",
        "type": "color"
      },
      "defaultHoverFill": {
        "value": "rgba(255,255,255,0.12)",
        "type": "color"
      },
      "defaultEnabledBorder": {
        "value": "{grey.700}",
        "type": "color"
      }
    },
    "alert": {
      "errorContent": {
        "value": "#f4c7c7",
        "type": "color"
      },
      "errorFill": {
        "value": "#160b0b",
        "type": "color"
      },
      "warningContent": {
        "value": "#ffe2b7",
        "type": "color"
      },
      "warningFill": {
        "value": "#191207",
        "type": "color"
      },
      "infoContent": {
        "value": "#b8e7fb",
        "type": "color"
      },
      "infoFill": {
        "value": "#071318",
        "type": "color"
      },
      "successContent": {
        "value": "#cce8cd",
        "type": "color"
      },
      "successFill": {
        "value": "#0c130d",
        "type": "color"
      }
    },
    "backdrop": {
      "fill": {
        "value": "rgba(0,0,0,0.5)",
        "type": "color"
      }
    },
    "breadcrumbs": {
      "collapseFill": {
        "value": "{grey.600}",
        "type": "color"
      }
    },
    "appbar": {
      "defaultFill": {
        "value": "{background.paper-elevation-4}",
        "type": "color"
      }
    },
    "rating": {
      "enabledBorder": {
        "value": "rgba(255,255,255,0.23)",
        "type": "color"
      }
    }
  },
  "$themes": [],
  "$metadata": {
    "tokenSetOrder": [
      "colors",
      "light/base",
      "light/components",
      "dark/base",
      "dark/components"
    ]
  }
}