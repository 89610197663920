import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

// TODO Delete these, they should be replaced by quad-view, triple-view, etc.
export const TripleRightIcon = (props: SvgIconProps) => (
    <SvgIcon
        viewBox="0 0 18 18"
        {...props}
    >
        <rect
            x="10"
            y="2"
            width="6"
            height="14"
            rx="1"
        />
        <rect
            x="2"
            y="2"
            width="6"
            height="6"
            rx="1"
        />
        <rect
            x="2"
            y="10"
            width="6"
            height="6"
            rx="1"
        />
    </SvgIcon>
);
