import { createAction, PayloadAction } from '@reduxjs/toolkit';
import TagManager from 'react-gtm-module';
import { WebResourceCBCreatorProps } from '../../../components/complex/web-resource-icon-links';
import { GOOGLE_TAG_DATA_LAYER_NAME } from '../../../constants/analytics';
import { type RootState } from '../../redux';
import { createAnalyticsAction } from '../create-analytics-action';

const analyticsAction = createAction(
    'gtm/web-resource-click-action',
    (data: WebResourceCBCreatorProps): { payload: WebResourceCBCreatorProps } => ({
        payload: data,
    })
);

const sendAnalyticsWebResourceClick = (_state: RootState, action: PayloadAction<WebResourceCBCreatorProps>) => {
    TagManager.dataLayer({
        dataLayerName: GOOGLE_TAG_DATA_LAYER_NAME,
        dataLayer: {
            event: 'webResourceClick',
            actionType: action.type,
            ...action.payload,
        },
    });
};

export const webResourceClickAction = createAnalyticsAction(analyticsAction, sendAnalyticsWebResourceClick);
