import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import has from 'lodash/has';

import { BaseSpecimenPayload, PrepareReturn, SpecimensViewToggleId } from '../../types';

export type SpecimenCurrentViewState = {
    [specimenKey: string]: SpecimensViewToggleId;
};

const INITIAL_STATE: SpecimenCurrentViewState = {};

interface SpecimensCurrentViewPayload extends BaseSpecimenPayload {
    specimensView: SpecimensViewToggleId;
}

type SetSpecimensCurrentViewReducer = CaseReducer<SpecimenCurrentViewState, PayloadAction<SpecimensCurrentViewPayload>>;
export const setSpecimenCurrentViewReducer: SetSpecimensCurrentViewReducer = (state, action) => {
    const { specimensView, specimenKey } = action.payload;
    return {
        ...state,
        [specimenKey]: specimensView,
    };
};

type InitializeSpecimensCurrentViewReducer = CaseReducer<
    SpecimenCurrentViewState,
    PayloadAction<SpecimensCurrentViewPayload>
>;
export const initializeSpecimensCurrentViewReducer: InitializeSpecimensCurrentViewReducer = (state, action) => {
    const { specimenKey, specimensView } = action.payload;

    if (has(state, specimenKey)) {
        return state;
    }

    return {
        ...state,
        [specimenKey]: specimensView,
    };
};

const getSpecimensView = (path: string): SpecimensViewToggleId => {
    switch (path?.toUpperCase()) {
        case SpecimensViewToggleId.IMAGE:
            return SpecimensViewToggleId.IMAGE;
        case SpecimensViewToggleId.DETAILS:
        default:
            return SpecimensViewToggleId.DETAILS;
    }
};

export const specimensCurrentViewPrepare = (
    specimenKey: string,
    path: string
): PrepareReturn<SpecimensCurrentViewPayload> => ({
    payload: {
        specimenKey,
        specimensView: getSpecimensView(path),
    },
});

export const specimensCurrentViewSlice = createSlice({
    name: 'specimens-current-view',
    initialState: INITIAL_STATE,
    reducers: {
        setSpecimensCurrentView: {
            reducer: setSpecimenCurrentViewReducer,
            prepare: specimensCurrentViewPrepare,
        },
        initializeSpecimensCurrent: {
            reducer: initializeSpecimensCurrentViewReducer,
            prepare: specimensCurrentViewPrepare,
        },
    },
});
