import * as ReactDOM from 'react-dom';

import { initializeAnalytics } from './services/analytics';
import { Main } from './pages/main';
import { configureAmplify, configureHub } from './services/cognito';

initializeAnalytics();
configureAmplify();
configureHub();

ReactDOM.render(<Main />, document.getElementById('main'));
