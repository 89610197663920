import { createAction, PayloadAction } from '@reduxjs/toolkit';
import TagManager from 'react-gtm-module';
import { GOOGLE_TAG_DATA_LAYER_NAME } from '../../../constants/analytics';

import { ProjectSummaryProps } from '../../project-summary/query';
import { type RootState } from '../../redux';
import { createAnalyticsAction } from '../create-analytics-action';

interface ProjectSummaryVisitedData {
    project: string;
    program: string;
    modality: string[];
}

const analyticsAction = createAction(
    'gtm/project-summary-visited',
    (data: ProjectSummaryProps): { payload: ProjectSummaryVisitedData } => ({
        payload: {
            project: data.shortTitle.value,
            program: data.programShortTitle.value,
            modality: data.modality.value,
        },
    })
);

const sendAnalyticsProjectSummaryVisited = (_state: RootState, action: PayloadAction<ProjectSummaryVisitedData>) => {
    TagManager.dataLayer({
        dataLayerName: GOOGLE_TAG_DATA_LAYER_NAME,
        dataLayer: {
            event: 'projectSummaryVisited',
            actionType: action.type,
            ...action.payload,
        },
    });
};

export const projectSummaryVisitedAction = createAnalyticsAction(analyticsAction, sendAnalyticsProjectSummaryVisited);
