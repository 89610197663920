import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import Divider from '@mui/material/Divider';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import { LOGIN_URI_BASE } from '~/src/constants/nav';
import { SIGN_IN, SIGN_OUT } from '~/src/constants/strings';
import { MenuItem } from '@czi-sds/components';
import { Box } from '~/src/components/bkp-ui/box';
import { Button } from '~/src/components/bkp-ui/button';
import { MenuItemText } from '~/src/components/bkp-ui/menu-item-text';
import { ToggleMenu } from '~/src/components/bkp-ui/toggle-menu';

interface AccountMenuProps {
    canSignIn?: boolean;
    isAuthenticated: boolean;
    username: string;
    onSignOut: () => void;
    onRedirectToLogin: () => void;
}

export const AccountMenuView = ({
    canSignIn,
    isAuthenticated,
    username,
    onSignOut,
    onRedirectToLogin,
}: AccountMenuProps) => {
    const [currentUser, setCurrentUser] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const onOpenMenu = () => {
        setIsMenuOpen(true);
        setCurrentUser(username);
    };

    const handleSignOut = () => {
        setIsMenuOpen(false);
        onSignOut();
    };

    return (
        <>
            {canSignIn && (
                <Button
                    startIcon={<PersonIcon />}
                    onClick={onRedirectToLogin}
                    sdsType="primary"
                    sx={{ textTransform: 'uppercase' }}
                >
                    {SIGN_IN}
                </Button>
            )}

            {isAuthenticated && (
                <ToggleMenu
                    aria-label="account menu"
                    isOpen={isMenuOpen}
                    onOpen={onOpenMenu}
                    onClose={() => setIsMenuOpen(false)}
                    buttonProps={{ sdsType: 'secondary', sdsStyle: 'icon', icon: <PersonIcon /> }}
                >
                    <Box>
                        <MenuItemText text={currentUser} />
                        <Divider />
                        <MenuItem
                            onClick={handleSignOut}
                            icon={<LogoutIcon />}
                        >
                            {SIGN_OUT}
                        </MenuItem>
                    </Box>
                </ToggleMenu>
            )}
        </>
    );
};

export const AccountMenu = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleRedirectToLogin = () => {
        navigate(LOGIN_URI_BASE, { state: { from: { pathname: location.pathname } } });
    };

    const { authStatus, user, signOut } = useAuthenticator((context) => [context.authStatus, context.user]);

    const handleSignOut = () => {
        signOut();
    };

    const displayableUsername = user?.username ? user.username.split('_')[1] ?? user.username : '';

    return (
        <AccountMenuView
            canSignIn={authStatus !== 'authenticated'}
            isAuthenticated={authStatus === 'authenticated'}
            username={displayableUsername}
            onRedirectToLogin={handleRedirectToLogin}
            onSignOut={handleSignOut}
        />
    );
};
