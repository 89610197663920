import { Link } from '@czi-sds/components';
import { Stack } from '~/src/components/bkp-ui/stack';
import { Typography } from '~/src/components/bkp-ui/typography';
import { truncateText } from '~/src/utils/strings/truncate-text';
import { ResourceTags } from './resource-tags';
import type { ResourceTagsProps } from './resource-tags';

interface EntrySummaryProps {
    title: string;
    description: string;
    link?: string;
    resources?: ResourceTagsProps['resources'];
}

export const EntrySummary = ({ title, description, link, resources = [] }: EntrySummaryProps) => {
    const truncatedDescription = truncateText(description, { charLimit: 180, showEllipsis: true });
    return (
        <Stack
            direction="column"
            spacing="xxs"
        >
            {link ? (
                <Link
                    sdsStyle="default"
                    fontWeight="bold"
                    sdsSize="s"
                    href={link}
                >
                    {title}
                </Link>
            ) : (
                <Typography stylePath="header.semibold.s">{title}</Typography>
            )}
            <Typography
                stylePath="body.regular.xs"
                color="text.secondary"
            >
                {truncatedDescription}
            </Typography>
            <ResourceTags resources={resources} />
        </Stack>
    );
};
