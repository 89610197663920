import { createSelector } from 'reselect';
import { type RootState } from '../redux';
import { DONOR_PAGE_SPECIMEN_ID_COLUMN_REFERENCE_ID } from '../../constants/strings';
import { selectPageKey } from '../page-key';
import { TableFacetProperty, FeatureType } from '../../types';

const selectDisplayPropertyReferenceIdsState = (state: RootState) => state.displayPropertyReferenceIds;

export const selectDisplayPropertyReferenceIds = createSelector(
    [selectPageKey, selectDisplayPropertyReferenceIdsState],
    (pageKey, viewTableColumnsState): string[] =>
        // Used by the Donor page to filter out an extra ID column
        // The specimen's page is unaffected by this reference ID
        viewTableColumnsState[pageKey]?.filter(
            (referenceId) => referenceId !== DONOR_PAGE_SPECIMEN_ID_COLUMN_REFERENCE_ID
        ) ?? []
);

export const selectDisplayPropertyReferenceIdsQueryOption = createSelector(
    [selectDisplayPropertyReferenceIds],
    (displayProperties) => ({ displayProperties })
);

const selectFacetPropertiesState = (state: RootState) => state.facetProperties;

export const selectFacetProperties = createSelector(
    [selectPageKey, selectFacetPropertiesState],
    (pageKey, facetedPropertiesLookup): TableFacetProperty[] => facetedPropertiesLookup?.[pageKey] ?? []
);

/**
 * Create a lookup object for key value mapping in a property FeatureType
 * @returns lookup object
 */
const getFeatureTypeMap = (properties: TableFacetProperty[], value: keyof FeatureType, key: keyof FeatureType) =>
    properties.reduce<{ [title: string]: string }>((acc, facetProperty) => {
        const fromKey = facetProperty.featureType[key];
        const toValue = facetProperty.featureType[value];
        acc[fromKey] = toValue;

        return acc;
    }, {});

/**
 * Select a lookup object from "title" keyed by "referenceId"
 */
export const selectTitleFromReferenceId = createSelector([selectFacetProperties], (properties) => {
    const referenceIdFromTitleMap = getFeatureTypeMap(properties, 'title', 'referenceId');
    return (referenceId: string) => referenceIdFromTitleMap[referenceId];
});

const selectDisplayColumnWidthsState = (state: RootState) => state.displayColumnWidths;

export const selectDisplayColumnWidths = createSelector(
    [selectPageKey, selectDisplayColumnWidthsState],
    (pageKey, displayColumnWidthsState) => displayColumnWidthsState[pageKey]
);
