import { StyledHeaderContainer, StyledHeaderText } from './styled';

export interface DarkHeaderProps {
    title: string;
}

export const DarkHeader = ({ title }: DarkHeaderProps) => (
    <StyledHeaderContainer>
        <StyledHeaderText variant="h2">{title}</StyledHeaderText>
    </StyledHeaderContainer>
);
