import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import has from 'lodash/has';
import { BaseSpecimenPayload, PrepareReturn } from '../../types';
import { TaxonomyData } from '../../types/taxonomy';

export type SpecimenTaxonomyState = {
    [specimenKey: string]: TaxonomyData[];
};

const INITIAL_STATE: SpecimenTaxonomyState = {};

export type SetSpecimenTaxonomyPayload = BaseSpecimenPayload & {
    taxonomies: TaxonomyData[];
};

type InitializeSpecimenTaxonomyReducer = CaseReducer<SpecimenTaxonomyState, PayloadAction<SetSpecimenTaxonomyPayload>>;
export const initializeSpecimenTaxonomyReducer: InitializeSpecimenTaxonomyReducer = (state, action) => {
    const { specimenKey, taxonomies } = action.payload;

    if (has(state, specimenKey)) {
        return state;
    }

    return {
        ...state,
        [specimenKey]: taxonomies || [],
    };
};

export const initializeSpecimenTaxonomyPrepare = (
    specimenKey: string,
    taxonomies: TaxonomyData[]
): PrepareReturn<SetSpecimenTaxonomyPayload> => ({
    payload: {
        specimenKey,
        taxonomies,
    },
});

export const specimenTaxonomySlice = createSlice({
    name: 'specimen-filter',
    initialState: INITIAL_STATE,
    reducers: {
        initializeSpecimenTaxonomy: {
            reducer: initializeSpecimenTaxonomyReducer,
            prepare: initializeSpecimenTaxonomyPrepare,
        },
    },
});
