import { CellFilterInput } from './generated-schema-types';

export interface FilterArgument {
    field: string;
    value: string;
    operator: Operator;
    index?: string;
}

export interface NumericFilterArgument extends FilterArgument {
    selectedRange: {
        min: number;
        max: number;
    };
}

export interface GeneExpressionFilterArgument extends FilterArgument {
    selectedRange: {
        min: number;
        max: number;
    };
    geneIndex: number;
    geneName: string;
}

export enum Operator {
    EQ = 'EQ',
    CONTAINS = 'CONTAINS',
    BETWEEN = 'BETWEEN',
}

export enum FilterType {
    ANNOTATION = 'ANNOTATION',
    MEASUREMENT = 'MEASUREMENT',
    IMAGE = 'IMAGE',
}
// queries now support CNF (Clausal Normal Form) filters - fancy-words for
// groups of "clauses" which are And'd together, like so:
// CNF = (filter1 OR filter2 OR filter 3) AND (filter4  OR filter5)

export type FilterUnion = {
    OR: CellFilterInput[];
};
export type FilterCNF = {
    AND: FilterUnion[];
};
