import { HTMLAttributes, ReactNode } from 'react';
import { Link } from '@czi-sds/components';
import { Button } from '~/src/components/bkp-ui/button';
import { Grid } from '~/src/components/bkp-ui/grid';
import { Stack } from '~/src/components/bkp-ui/stack';
import { Typography } from '~/src/components/bkp-ui/typography';
import type { ChartData } from '~/src/components/ui/bar-chart';
import { ProgramProjectsTable } from '~/src/components/complex/data-catalog-table/program-projects-table';
import type { ProgramProjectsTableProps } from '~/src/components/complex/data-catalog-table/program-projects-table';
import { WebResourceCard } from '~/src/components/complex/data-catalog/web-resource/web-resource-card';
import type { WebResourceCardProps } from '~/src/components/complex/data-catalog/web-resource/web-resource-card';
import { ProjectsOverview } from '~/src/components/complex/data-catalog/projects-overview/projects-overview';
import type { ProjectsOverviewProps } from '~/src/components/complex/data-catalog/projects-overview/projects-overview';
import { DEFAULT_CATEGORIES_CHART_CONFIG } from '~/src/components/complex/data-catalog/projects-overview/constants';

export interface ProgramPageLayoutProps {
    /**
     * additional content to be displayed on the page between Projects Overview and Projects sections
     */
    additionalContent?: Array<{ sectionName: string; id: string; content: ReactNode }>;
    header: {
        imageUrl: string;
        description: string;
        link: string;
    };
    programName: string;
    projects: ProgramProjectsTableProps['projects'];
    projectsOverviewData: Record<'species' | 'modality' | 'technique', ChartData>;
    projectsType?: 'Recent' | 'Featured';
    relevantResources?: Array<WebResourceCardProps>;
    totalProjectsCount: number;
    onSearchEnter?: ProjectsOverviewProps['onSearchEnter'];
}

const Section = ({
    children,
    title,
    id,
}: {
    children?: ReactNode;
    title: string;
    id?: HTMLAttributes<HTMLElement>['id'];
}) => (
    <Stack
        id={id}
        direction="column"
        spacing="l"
        paddingBlock="xxl"
        width="100%"
    >
        <Typography
            stylePath="header.semibold.l"
            color="text.secondary"
        >
            {title}
        </Typography>
        {children}
    </Stack>
);

const AnchorLink = ({ children, href }: { children: ReactNode; href: string }) => {
    const handleAnchorLinkClick = (e: MouseEvent) => {
        e.preventDefault();
        const targetElement = document.getElementById(href);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <Link
            href={href}
            onClick={handleAnchorLinkClick}
        >
            {children}
        </Link>
    );
};

export const ProgramPageLayout = ({
    additionalContent,
    header,
    programName,
    projects,
    projectsOverviewData,
    projectsType = 'Recent',
    relevantResources = [],
    totalProjectsCount,
    onSearchEnter,
}: ProgramPageLayoutProps) => {
    const projectsSectionTitle = `${projectsType} projects`;
    const hasResources = relevantResources.length > 0;

    const projectsOverviewChartsConfig = [
        {
            ...DEFAULT_CATEGORIES_CHART_CONFIG.species,
            data: projectsOverviewData.species,
        },
        {
            ...DEFAULT_CATEGORIES_CHART_CONFIG.modality,
            data: projectsOverviewData.modality,
        },
        {
            ...DEFAULT_CATEGORIES_CHART_CONFIG.technique,
            data: projectsOverviewData.technique,
        },
    ];

    return (
        <Stack direction="column">
            <img
                src={header.imageUrl}
                alt={`${programName} page hero`}
                style={{ width: '100%', minHeight: '280px', height: '32vh', objectFit: 'cover' }}
            />
            <Stack
                direction="column"
                alignItems="center"
                padding="xxl"
                spacing="xxl"
            >
                <Grid
                    id="introduction"
                    spacing="xxl"
                    width={{ sm: '100%', md: '70%' }}
                    container
                >
                    <Grid
                        item
                        sm={12}
                        md={3}
                    >
                        <Stack
                            direction="column"
                            spacing="xs"
                        >
                            <AnchorLink href="introduction">Introduction</AnchorLink>
                            <AnchorLink href="overview">Projects Overview</AnchorLink>
                            {additionalContent?.map(({ id, sectionName }) => (
                                <AnchorLink
                                    key={id}
                                    href={id}
                                >
                                    {sectionName}
                                </AnchorLink>
                            ))}
                            <AnchorLink href="projects">{projectsSectionTitle}</AnchorLink>
                            {hasResources && <AnchorLink href="resources">Relevant Resources</AnchorLink>}
                        </Stack>
                    </Grid>
                    <Grid
                        item
                        sm={12}
                        md={9}
                    >
                        <Stack
                            direction="column"
                            spacing="m"
                        >
                            <Typography stylePath="header.semibold.xl">{programName}</Typography>
                            <Typography
                                stylePath="body.regular.m"
                                color="text.secondary"
                            >
                                {header.description}
                            </Typography>
                            <Button
                                href={header.link}
                                external
                                sdsType="secondary"
                                sdsStyle="square"
                            >
                                Visit Program Site
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
                <Section
                    id="overview"
                    title="Projects Overview"
                >
                    <ProjectsOverview
                        charts={projectsOverviewChartsConfig}
                        totalProjectsCount={totalProjectsCount}
                        onSearchEnter={onSearchEnter}
                        link={`/data?filter.program.title=CONTAINS~${programName}`}
                        ctaText={`See all ${totalProjectsCount} program projects`}
                    />
                </Section>
                {additionalContent?.map(({ sectionName, id, content }) => (
                    <Section
                        key={id}
                        id={id}
                        title={sectionName}
                    >
                        {content}
                    </Section>
                ))}
                <Section
                    id="projects"
                    title={projectsSectionTitle}
                >
                    <ProgramProjectsTable projects={projects} />
                </Section>
                {hasResources && (
                    <Section
                        id="resources"
                        title="Relevant Resources"
                    >
                        <Stack
                            direction="row"
                            spacing="m"
                            flexWrap="wrap"
                        >
                            {relevantResources.map((resource) => (
                                <WebResourceCard
                                    key={resource.name}
                                    {...resource}
                                />
                            ))}
                        </Stack>
                    </Section>
                )}
            </Stack>
        </Stack>
    );
};
