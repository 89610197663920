import { Milliseconds } from '~/src/types/time';
import { partial } from 'lodash';
import { SlideId } from '~/src/types/reference-ids';
import { remap } from '~/src/utils/record/remap';
import {
    Box2D,
    Vec2,
    box2D,
    vec2,
    rectangle2D,
    scaleFromPoint,
    interpolateRectangles,
    getMinimumBoundingBox,
} from '@alleninstitute/vis-geometry';
import type {
    ScatterbrainCamera,
    BasicCamera,
    CameraProjection,
    SlideViewCamera,
    DynamicGridLayout,
} from '../../components/complex/scatterbrain/types';

export const ZOOM_IN_SCROLL = 0.9;
export const ZOOM_OUT_SCROLL = 1.1;
export const DOUBLE_CLICK_ZOOM = 0.5;
const ANIMATION_INTERVAL: Milliseconds = 33;

export function isSlideCamera(cam: object | undefined): cam is SlideViewCamera {
    if (cam === undefined) return false;

    return 'availableSlides' in cam && 'layout' in cam;
}

export function getDataSpaceCoordinateFromCanvas(screenPoint: vec2, view: BasicCamera, screenSize: vec2): vec2 {
    const [screenX, screenY] = screenPoint;
    const [width, height] = screenSize;

    // Convert to % of screen location
    const x = screenX / width;
    const y = view.projection === 'cartesian' ? (height - screenY) / height : screenY / height;

    // Convert to data space location by using the view size
    const viewPosition = Vec2.mul(view.size, [x, y]);

    // Find true data space coordinate by adding the corner of the view to the position
    const distanceToCorner = Vec2.scale(view.size, 0.5);
    const bottomLeftCorner = Vec2.sub(view.center, distanceToCorner);
    return Vec2.add(viewPosition, bottomLeftCorner);
}

/**
 * Convert coordinates in data space into coordinates in canvas similar to approach in getDataSpaceCoordinateFromCanvas.
 */
function getCanvasCoordinateFromDataSpace(coordinates: vec2, canvasSize: vec2, view: BasicCamera): vec2 {
    // Find the relative data space points based on current view;
    const distanceToCorner = Vec2.scale(view.size, 0.5);
    const bottomLeftCorner = Vec2.sub(view.center, distanceToCorner);
    const [dataX, dataY] = Vec2.sub(coordinates, bottomLeftCorner);

    // convert to points to space ratios
    const [viewWidth, viewHeight] = view.size;
    const xRatio = dataX / viewWidth;
    const yRatio = view.projection === 'cartesian' ? (viewHeight - dataY) / viewHeight : dataY / viewHeight;

    return Vec2.mul(canvasSize, [xRatio, yRatio]);
}

export function getMousePositionRelativeToCanvas(clientXy: vec2, canvas: HTMLElement) {
    const cnvsBounds = canvas.getBoundingClientRect();
    const canvasOrigin: vec2 = [cnvsBounds.x, cnvsBounds.y];
    return Vec2.sub(clientXy, canvasOrigin);
}

export function getCorrectedMouseEventPosition(
    event: { clientX: number; clientY: number },
    canvas: HTMLElement | undefined | null
): vec2 | undefined {
    if (canvas) {
        const mouseInContainer = canvas.contains(document.elementFromPoint(event.clientX, event.clientY)) ?? false;
        return mouseInContainer ? getMousePositionRelativeToCanvas([event.clientX, event.clientY], canvas) : undefined;
    }
    return undefined;
}

function boxFromDOMRect(r: DOMRect, height: number): box2D {
    const topLeftInGlSpace: vec2 = [r.x, height - r.y];
    const size: vec2 = [r.width, r.height];
    const bottomLeft = Vec2.sub(topLeftInGlSpace, [0, r.height]);
    return Box2D.create(bottomLeft, Vec2.add(bottomLeft, size));
}
// we use a single, shared canvas to render multiple visualizations
// we do that for a bunch of reasons, but what matters is that we have to compute where
// on the canvas our current "window" sits - the window (which I cant name window for browser reasons)
// is the view, and we need to figure out where the two intersect, and convert that into WebGL windowing coordinates
// which are of course upside down from normal browser/window coordinates
export function getViewportForSharedCanvas(view: HTMLElement, hostCanvas: HTMLCanvasElement) {
    const height = hostCanvas.getBoundingClientRect().bottom;
    const viewBounds = boxFromDOMRect(view.getBoundingClientRect(), height);
    const canvasBounds = boxFromDOMRect(hostCanvas.getBoundingClientRect(), height);
    // we need the cBox to be relative to the canvas...
    const vp = Box2D.intersection(viewBounds, canvasBounds) ?? viewBounds;
    return Box2D.create(Vec2.sub(vp.minCorner, canvasBounds.minCorner), Vec2.sub(vp.maxCorner, canvasBounds.minCorner));
}
export function getBoxAsReglViewport(box: box2D) {
    const size = Box2D.size(box);
    return {
        x: box.minCorner[0],
        y: box.minCorner[1],
        width: size[0],
        height: size[1],
    };
}
export function getContainingSlide(
    canvasLocation: vec2,
    camera: SlideViewCamera,
    screenSizePx: vec2
): SlideId | undefined {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const slides = getSlidesInScreenSpace(camera, screenSizePx);
    // it might technically be faster for us to convert the canvaslocation into "layout space"
    // and then use an index, but a linear search is probably completely fine
    const match = Object.entries(slides).find(([_id, box]) => Box2D.containsPoint(box, canvasLocation));
    return (match?.[0] as SlideId) ?? undefined;
}
/**
 * Takes coordinates in canvas space and returns a box in data space
 * @param coordinates [x, y]: center coordinates of the box
 * @param canvasSize [width, height]
 * @param view camera view
 * @param boxSize [width, height]
 * @returns
 */
export function getBoxInDataSpace(
    coordinates: vec2,
    canvasSize: vec2,
    view: ScatterbrainCamera,
    boxSize: vec2 = [5, 5]
): box2D {
    // eslint-disable-next-line no-param-reassign
    coordinates = Vec2.sub(coordinates, Vec2.scale(boxSize, 0.5));
    const boxStart: vec2 = getDataSpaceCoordinateFromCanvas(Vec2.add(coordinates, boxSize), view, canvasSize);
    const mouseWorldPos = getDataSpaceCoordinateFromCanvas(coordinates, view, canvasSize);
    const low: vec2 = Vec2.min(boxStart, mouseWorldPos);
    const high: vec2 = Vec2.max(boxStart, mouseWorldPos);
    const box = Box2D.create(low, high);
    if (isSlideCamera(view)) {
        // use the center of the box to pick which slide we're in
        // then return a box in data-space relative to that slide
        const id = getContainingSlide(coordinates, view, canvasSize);
        const { slideBounds } = view;
        if (id) {
            const gridIndex = view.layout[id];
            const offset = Vec2.mul(Box2D.size(slideBounds), gridIndex);
            return Box2D.translate(box, Vec2.scale(offset, -1));
        }
    }
    return box;
}

export function getValueForSlide(view: SlideViewCamera, id: SlideId): string | undefined {
    const first = view.availableSlides.find((slide) => slide.id === id);
    return first?.value ?? undefined;
}

/**
 * Takes in box in data space and returns a box in canvas pixels
 */
export function getBoxInCanvas(dataSpaceBox: box2D, canvasSize: vec2, view: BasicCamera): box2D {
    const boxStart: vec2 = getCanvasCoordinateFromDataSpace(dataSpaceBox.minCorner, canvasSize, view);
    const boxEnd: vec2 = getCanvasCoordinateFromDataSpace(dataSpaceBox.maxCorner, canvasSize, view);
    const low: vec2 = Vec2.min(boxStart, boxEnd);
    const high: vec2 = Vec2.max(boxStart, boxEnd);
    return Box2D.create(low, high);
}

export function slideGridLayout<I extends string | number, T extends { id: I }>(
    visibleSlides: readonly T[]
): Record<I, vec2> {
    const zero: vec2 = [0, 0];
    if (visibleSlides.length < 1) return {} as Record<I, vec2>;
    if (visibleSlides.length < 2) return { [visibleSlides[0].id]: zero } as Record<I, vec2>;

    const slidesPerRow = Math.max(1, Math.ceil(Math.sqrt(visibleSlides.length)));
    // return a mapping from slide-index to X,Y slide-offset?
    const offset = (index: number) => [index % slidesPerRow, Math.floor(index / slidesPerRow)] as const;
    return visibleSlides.reduce(
        (layout, slide, index) => ({ ...layout, [slide.id]: offset(index) }),
        {} as Record<I, vec2>
    );
}
// the bounds of a dynamic grid, respecting the current selection
export function dynamicGridBounds(camera: DynamicGridLayout) {
    const { layout, slideBounds } = camera;
    const size = Box2D.size(slideBounds);
    const totalSize = Object.values(layout).reduce<box2D | null>((bounds, gridIndex) => {
        const curBounds = Box2D.translate(slideBounds, Vec2.mul(size, gridIndex));
        return bounds === null ? curBounds : Box2D.union(curBounds, bounds);
    }, null);
    return totalSize ?? slideBounds;
}
// the bounds of a dynamic grid (assuming all slides are selected for display)
export function maximalGridBounds(camera: DynamicGridLayout) {
    const { availableSlides, slideBounds } = camera;
    const size = Box2D.size(slideBounds);
    const slidesPerRow = Math.max(1, Math.ceil(Math.sqrt(availableSlides.length)));
    return Box2D.union(slideBounds, Box2D.translate(slideBounds, Vec2.mul(size, [slidesPerRow, slidesPerRow])));
}
export function getSlideOffset(camera: SlideViewCamera, slideId: SlideId): vec2 | undefined {
    const { layout, slideBounds } = camera;
    const gridIndex: vec2 = layout[slideId];
    if (!gridIndex) return undefined;

    const offset = Vec2.mul(Box2D.size(slideBounds), gridIndex);
    return offset;
}
export function getSlideBoundsInCameraSpace(camera: SlideViewCamera, slideId: SlideId): box2D | undefined {
    const { slideBounds } = camera;
    const offset = getSlideOffset(camera, slideId);
    if (!offset) return undefined;

    return Box2D.translate(slideBounds, offset);
}
/**
 *
 * @param boxInCameraSpace a box in terms of a slide-view camera. (e.g. a selection box)
 * @param id the id of the slide, to which the resulting box will be relative
 * @param camera the current slide view camera
 * @returns a box in terms of the given slide (suitable for use with gql queries)
 */
export function getBoxInSlideSpace(boxInCameraSpace: box2D, id: SlideId, camera: SlideViewCamera): box2D | undefined {
    const { layout, slideBounds } = camera;
    const offset = Vec2.mul(layout[id], Box2D.size(slideBounds));
    const boxRelativeToSlide = Box2D.translate(boxInCameraSpace, Vec2.scale(offset, -1));
    return Box2D.intersection(boxRelativeToSlide, slideBounds);
}
export function isSlideInView(camera: SlideViewCamera, slideId: SlideId) {
    const myBounds = getSlideBoundsInCameraSpace(camera, slideId);
    return !!myBounds && !!Box2D.intersection(myBounds, getMinimumBoundingBox(camera));
}

export function cameraCenterForSlideGridView(
    camera: SlideViewCamera,
    slideId: SlideId,
    slideLayout: Record<SlideId, vec2>
) {
    const { center, slideBounds } = camera;

    const gridIndex: vec2 = slideLayout[slideId] ?? [0, 0];
    const offset = Vec2.mul(Box2D.size(slideBounds), gridIndex);
    return Vec2.sub(center, offset);
}

export function getSlidesInScreenSpace(camera: SlideViewCamera, screenPx: vec2) {
    const { slideBounds, layout } = camera;

    const slideBoxesOnScreen = remap<vec2, box2D, typeof layout>(layout, (gridIndex) => {
        const offset = Vec2.mul(Box2D.size(slideBounds), gridIndex);
        return getBoxInCanvas(Box2D.map(slideBounds, partial(Vec2.add, offset)), screenPx, camera);
    });
    return slideBoxesOnScreen;
}

// Returns the ideal width in data-space to preserve the aspect ratio of the canvas based on the provided height
export function getAspectRatioWidth(canvasSize: vec2, desiredHeight: number): number {
    const [width, height] = canvasSize;
    const aspectRatio = width / height;
    return desiredHeight * aspectRatio;
}

// Returns a viewBox with the size adjusted to fit the aspect ratio
export function matchScreenAspectRatio<C extends rectangle2D>(camera: C, screenSize: vec2): C {
    const [_, cameraHeight] = camera.size;

    return {
        ...camera,
        size: [getAspectRatioWidth(screenSize, cameraHeight), cameraHeight],
    };
}
// Finds the height of the bounding box in data space for use in camera calculations
export function boundingBoxToDataSpaceHeight(boundingBox: box2D): number {
    const [_ux, uy] = boundingBox.maxCorner;
    const [_lx, ly] = boundingBox.minCorner;
    const dataSpaceHeight = uy - ly;

    return dataSpaceHeight;
}

export const clampedZoomScaleByHeight = (
    scale: number,
    currentHeight: number,
    minHeight: number,
    maxHeight: number
) => {
    // do not allow scale*currentHeight to escape its bounds - return a safe scale
    const maxScale = maxHeight / currentHeight;
    const minScale = minHeight / currentHeight;
    return Math.min(Math.max(minScale, scale), maxScale);
};
export function zoomCamera<C extends BasicCamera>(
    camera: C,
    desiredScale: number,
    locus: vec2,
    [minHeight, maxHeight]: vec2
): BasicCamera {
    // pick a scale that cannot escape our limits:
    const safeScale = clampedZoomScaleByHeight(desiredScale, camera.size[1], minHeight, maxHeight);

    return { ...camera, ...scaleFromPoint(camera, safeScale, locus) };
}

// TODO: Apply rotation!
export const calculateDrag = (
    event: { movementX: number; movementY: number },
    camera: rectangle2D,
    canvasSize: vec2,
    projection: CameraProjection
): vec2 => {
    // Turn mouse event location into camera coordinates
    // Convert from browser to WebGL space by leaving X alone and inverting the Y,
    // due to WebGL starting from the lower left corner while browsers start from the upper left
    const movement = Vec2.mul(
        camera.size,
        Vec2.div([event.movementX, (projection === 'web-image' ? 1 : -1) * event.movementY], canvasSize)
    );

    // Subtract movement vector between mouse move events and the camera's center to find the new camera center
    const newXY = Vec2.sub(camera.center, movement);

    return newXY;
};

export const calculateRotate = (event: MouseEvent, camera: rectangle2D, dragStart: vec2, height: number) => {
    const [startX, startY] = dragStart;
    const [midX, midY] = camera.center;

    // Convert from browser to WebGL space by leaving X alone and inverting the Y,
    // due to WebGL starting from the lower left corner while browsers start from the upper left
    const [currentX, currentY] = [event.offsetX, height - event.offsetY];

    // Calculate angles using the tangential lines in order to provide realistic
    // rotational movement when mouse starts closer or farther from the center point
    const startAngle = Math.atan2(startY - midY, startX - midX);
    const currentAngle = Math.atan2(currentY - midY, currentX - midX);
    const angle = currentAngle - startAngle;

    return angle;
};

export const getThresholdInDataSpace = (camera: rectangle2D, widthOfScreen: number, pixelThreshold: number): number => {
    const pxPerUnit = widthOfScreen / camera.size[0];
    const upx = 1 / pxPerUnit;
    const thresholdInDataSpace = pixelThreshold * upx;
    return thresholdInDataSpace;
};

/**
 *
 * @param boundingBox the bounds of the data, in data units
 * @param width of the rendering region on the screen, in pixels
 * @param height of the rendering region on the screen, in pixels
 */
export function initialViewForBoundsAndScreen(
    boundingBox: box2D,
    width: number,
    height: number,
    projection: CameraProjection
): ScatterbrainCamera {
    const center = Box2D.midpoint(boundingBox);
    const dataSpaceHeight = boundingBoxToDataSpaceHeight(boundingBox);
    const dataSpaceWidth = getAspectRatioWidth([width, height], dataSpaceHeight);
    // Back out 5% to give the data a little breathing room on the sides
    const paddedSize = Vec2.scale([dataSpaceWidth, dataSpaceHeight], 1.05);

    return {
        projection,
        center,
        size: paddedSize,
    };
}

// https://easings.net/#easeInOutQuad
const easeInOutQuad = (t: number): number => (t < 0.5 ? 2 * t * t : 1 - (-2 * t + 2) ** 2 / 2);

/**
 * Camera animation function to animate between two camera states in a performant way.
 * It uses the time since the animation was requested to determine the next camera location,
 * ensuring that we update the camera when React is re-rendering, reducing the number of update
 * calls needed.
 *
 * ANIMATION_INTERVAL is controlled by a constant in the cameraUtils.ts file, tweak that as needed.
 *
 * @param start The camera to start the animation position from
 * @param goal The camera for the end state of the animation to go towards
 * @param duration The length of the animation time (in milliseconds)
 * @param update A camera update function called for each interval of the animation
 * @param easingFunction The easing function to use for the animation, defaults to easeInOutQuad
 */
export function animate(
    start: ScatterbrainCamera,
    goal: ScatterbrainCamera,
    duration: Milliseconds,
    update: (c: ScatterbrainCamera) => void,
    easingFunction: (t: number) => number = easeInOutQuad
) {
    const safeGoal = matchScreenAspectRatio(goal, start.size);
    const startTime = performance.now();
    const interval = setInterval(() => {
        const delta = performance.now() - startTime;
        const p = delta / duration;
        const easedP = easingFunction(p);
        if (p >= 1.0) {
            clearInterval(interval);
            update(safeGoal);
        } else {
            update(interpolateRectangles(start, safeGoal, easedP));
        }
    }, ANIMATION_INTERVAL);
}
