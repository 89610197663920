// the explore page query has multiple entries, each corresponding to a frame on the page

import queryString from 'query-string';

import { EXPLORE_PAGE_URL_STATE_PARAMETER } from '~/src/constants/strings';
import { BkpDatasetFragment, VisualizationFragment } from '~/src/types/generated-schema-types';
import { DatasetId, VisualizationId } from '~/src/types/reference-ids';
import type { ExplorePage } from './explore-page-slice';
import { decodeKiwiPayload, inflateExplorePage } from './kiwi-url-utils';
import { legacyInflateExplorePage } from './explore-page-url-parsing-legacy';

// attempt to initialize an explore page from a url query string
export function decodeUrlParameters(
    encoded: string,
    hash: string | undefined,
    visualizationsByDataset: Record<DatasetId, VisualizationFragment[]>,
    datasetsByVisualizationIds: Record<VisualizationId, BkpDatasetFragment>,
    defaultColorBy: string
): ExplorePage | undefined {
    try {
        const config = queryString.parse(encoded, { arrayFormat: 'comma' });

        // We use the legacy query params logic if we don't see the `state` parameter or the hash
        if (!config[EXPLORE_PAGE_URL_STATE_PARAMETER] && !hash) {
            return legacyInflateExplorePage(config, datasetsByVisualizationIds, defaultColorBy);
        }

        // it would be nice to be able to use the SearchParams functionality of the browser, as in:
        // see: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams#preserving_plus_signs
        // however I cant get that to work - lets go with this for now
        const cantUseConfigNotSureWhy = encoded.substring(`?${EXPLORE_PAGE_URL_STATE_PARAMETER}=`.length);

        // Hash is the current way to encode the state, so we use it if it's present
        const payloadToDecode = hash?.substring(1) ?? cantUseConfigNotSureWhy;
        const payload = decodeKiwiPayload(payloadToDecode);

        return inflateExplorePage(payload, visualizationsByDataset, datasetsByVisualizationIds, defaultColorBy);
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        return undefined;
    }
}
