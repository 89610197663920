import { ActionCreatorWithPreparedPayload, AnyAction } from '@reduxjs/toolkit';
import { type RootState } from '../redux';

export interface AnalyticsAction {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gtmAction: ActionCreatorWithPreparedPayload<any, any>;
    dataLayer: (state: RootState, action: AnyAction) => void;
}

export const createAnalyticsAction = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gtmAction: ActionCreatorWithPreparedPayload<any, any>,
    dataLayer: (state: RootState, action: AnyAction) => void
): AnalyticsAction => ({
    gtmAction,
    dataLayer,
});
