// eslint-disable-next-line import/extensions
import data from '~/src/assets/data/nav-links.json';
import { useMatch } from 'react-router-dom';
import { useNavOptions } from '~/src/containers/application-header/use-nav-options';
import { Footer } from '../components/ui/footer';
import { usePickMetadata } from '../hooks';
import {
    ABC_ATLAS_PAGE_URI_BASE,
    DONOR_INDEX_PAGE_URI_BASE,
    EXPLORE_PAGE_URI_BASE,
    SPECIMENS_PAGE_URI_BASE,
} from '../constants/nav';

// This is pretty empty for now, if we integrate with CMS we will spruce it up
export function FooterContainer() {
    const pickMetadata = usePickMetadata();
    const navOptions = useNavOptions();
    const exploreMatch = useMatch(EXPLORE_PAGE_URI_BASE);

    const specPage = useMatch(SPECIMENS_PAGE_URI_BASE);
    const specDetailsMatch = useMatch(`${SPECIMENS_PAGE_URI_BASE}/details`);
    const specImageMatch = useMatch(`${SPECIMENS_PAGE_URI_BASE}/image`);
    const specMatch = specPage || specDetailsMatch || specImageMatch;

    const atlasMatch = useMatch(ABC_ATLAS_PAGE_URI_BASE);
    const donorMatch = useMatch(DONOR_INDEX_PAGE_URI_BASE);

    // don't show it on explore, specimens, donors, or abacatlas
    const showFooter = !exploreMatch && !specMatch && !donorMatch && !atlasMatch;

    return showFooter ? (
        <Footer
            title="Brain Knowledge Platform"
            description={pickMetadata('Content.sidebar').description}
            pageLinks={navOptions}
            helpLinks={data.helpLinks}
            termsLinks={data.termsLinks}
        />
    ) : null;
}
