import reduce from 'lodash/reduce';

// a function that, given a constantly typed object where all values are type V
// return a new object, with the same keys, but all the values are of type R
// as given by fn(v)=>R.
export type RemappedType<R, T> = { [k in keyof T]: R };
export function remap<V, R, T extends { [k: string]: V }>(m: T, fn: (v: V, k: keyof T) => R): RemappedType<R, T> {
    type returnType = { [k in keyof T]: R };
    const initial = {} as returnType;
    return reduce<T, returnType>(m, (f, cur, k: keyof T) => ({ ...f, [k]: fn(cur, k) }), initial);
}
/**
 * replace all the keys in a given record with the result of the keyFn - note that
 * if your given keyFn maps multiple old keys to a single new key, entries will be dropped!
 * @param record a Record of key-value pairs
 * @param keyFn a function to update the keys of the given record with
 * @returns a new record of key-value pairs, like this: {keyFn(oldKey) : oldValue }
 */
export function rekey<V, K extends string | number | symbol, T extends Record<string, V>>(
    record: T,
    keyFn: (oldKey: string, value: V) => K
): Record<K, V> {
    return Object.keys(record).reduce((obj, k) => ({ ...obj, [keyFn(k, record[k])]: record[k] }), {} as Record<K, V>);
}
