import SvgIcon from '@mui/material/SvgIcon';
import { ViewIconProps } from './single-view';

export const QuadSideViewIcon = ({ rotation = 0, colors, iconProps }: ViewIconProps) => (
    <SvgIcon
        viewBox="0 0 18 18"
        {...iconProps}
        sx={{ transform: `rotate(${rotation}deg)` }}
    >
        <rect
            x="7"
            y="2"
            width="9"
            height="14"
            rx="1"
            color={colors?.[0]}
        />
        <rect
            x="2"
            y="2"
            width="4"
            height="4"
            rx="1"
            color={colors?.[1]}
        />
        <rect
            x="2"
            y="7"
            width="4"
            height="4"
            rx="1"
            color={colors?.[2]}
        />
        <rect
            x="2"
            y="12"
            width="4"
            height="4"
            rx="1"
            color={colors?.[3]}
        />
    </SvgIcon>
);
