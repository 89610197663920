import { donorProjectPageLink, donorProjectPageLinkHover, projectPageLink, projectPageLinkHover } from './colors';

export const projectPageButtonStyle = {
    textDecoration: 'none',
    backgroundColor: projectPageLink,
    '&:hover': {
        backgroundColor: projectPageLinkHover,
    },
    minWidth: '200px',
};

export const donorProjectPageButtonStyle = {
    textDecoration: 'none',
    backgroundColor: donorProjectPageLink,
    '&:hover': {
        backgroundColor: donorProjectPageLinkHover,
    },
    minWidth: '175px',
};
