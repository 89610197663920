import { CategoryArguments } from '../types';

// TODO: Check if this can be simplified after inventoryFilter slice consolidation
export const CATEGORY_ARGUMENTS: CategoryArguments[] = [
    {
        alias: 'species',
        categoryProperty: 'species.name',
    },
    {
        alias: 'modality',
        categoryProperty: 'modality.name',
    },
    {
        alias: 'technique',
        categoryProperty: 'technique.name',
    },
    {
        alias: 'specimenType',
        categoryProperty: 'specimenType.name',
    },
    {
        alias: 'program',
        categoryProperty: 'program.title',
    },
    {
        alias: 'subProgram',
        categoryProperty: 'subProgram.shortTitle',
    },
];

export const OSD_CONFIGURATION = {
    animationTime: 0.5,
    blendTime: 0.1,
    constrainDuringPan: true,
    showZoomControl: true,
    showHomeControl: true,
    showFullPageControl: false,
    autoHideControls: true,
    maxZoomPixelRatio: 2,
    minZoomImageRatio: 1,
    visibilityRatio: 1,
    zoomPerScroll: 2,
};

export const ERROR_NOTIFICATION_TIMEOUT = 15000;
export const POPOVER_COL_HEADER_HEIGHT = 80;
export const SLIDE_COL_HEADER_HEIGHT = 40;

export const DEFAULT_SCREEN_WIDTH = 1440;
export const DEFAULT_SCREEN_HEIGHT = 900;

export const DEFAULT_FILTER_HEIGHT = 500;
export const DEFAULT_TABLE_FILTER_HEIGHT = 400;

export const FILTER_DRAG_MIN_HEIGHT = 5;
export const MIN_FILTER_HEIGHT = 100;
export const MAX_FILTER_HEIGHT = 600;

export const DEFAULT_TOOLTIP_DELAY = 1500;
export const FILTER_ITEM_TOOLTIP_DELAY = 500;
export const FEATURE_TOOLTIP_DELAY = 0;
// Both sync and unsync tooltips were showing in a quick procession after clicking on icon.
// By setting tooltip leave time to 10ms it prevents this from happening.
export const SYNC_TOOLTIP_LEAVE = 5;

export const NUMBER_OF_RANGE_STEPS = 50;
export const NUMBER_OF_RANGE_BINS = 10;

export const DEFAULT_TABLE_COLUMN_WIDTH = 100;
export const DEFAULT_TABLE_MAX_COLUMN_WIDTH = 600;
export const DEFAULT_TABLE_MIN_COLUMN_WIDTH = 40;

export const ABC_ATLAS_PAGE_KEY = 'abc-atlas';

// Max was determined from the best fit of the taxonomy wheel filter
export const DEFAULT_FILTER_MAX_COLUMN_WIDTH = 550;
export const DEFAULT_FILTER_MIN_COLUMN_WIDTH = 200;

export const DEFAULT_DONORS_MIN_COLUMN_WIDTH = 105;
export const SUBJECT_ID_COLUMN_WIDTH = 200;

export const DEFAULT_TABBED_ICON_MENU_HEIGHT = 293;

export const SVG_CANVAS_PADDING = 50;

export const INVENTORY_PAGE_KEY = 'inventory';

export const HISTOGRAM_BIN_COUNT = 30;
