import { styled } from '@mui/material/styles';
import { MenuItem } from '@czi-sds/components';

import { getSemanticTextColors } from '~/src/utils/theme/theme-helpers';
import { BkpLink } from '../../ui/bkp-link';
import { ToggleMenu } from '../../bkp-ui/toggle-menu';
import { LinkData } from './types';
import { Typography } from '../../bkp-ui/typography';

const StyledTypography = styled(Typography)(({ theme }) => {
    const colors = getSemanticTextColors(theme);
    return {
        color: colors.base.accent,
    };
});

export interface NavMenuProps {
    title: string;
    links: LinkData[];
}

export const NavMenu = ({ title, links }: NavMenuProps) => {
    const handleKeyDown = (event: React.KeyboardEvent<HTMLLIElement>) => {
        if (event.key === 'Enter') {
            const target = event.currentTarget as HTMLElement;
            const link = target.querySelector('a') as HTMLAnchorElement;
            if (link) {
                link.click();
            }
        }
    };

    return (
        <ToggleMenu
            aria-label={`${title} menu`}
            label={title}
            buttonProps={{ sdsType: 'primary' }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            {links.map((link) => (
                <MenuItem
                    key={link.url}
                    onKeyDown={handleKeyDown}
                    tabIndex={0}
                >
                    <BkpLink
                        href={link.url}
                        style={{ textDecoration: 'none' }}
                    >
                        <StyledTypography>{link.title}</StyledTypography>
                    </BkpLink>
                </MenuItem>
            ))}
        </ToggleMenu>
    );
};
