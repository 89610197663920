import { ApolloError, GraphQLErrors } from '@apollo/client/errors';

type ErrorTextItem = {
    title: string;
    error: string;
};

export const createErrorText = (errors: ErrorTextItem[]) =>
    errors.reduce<string>((acc, err) => `${acc} ------ ${err.title} ------\n${err.error}\n`, '');

const createReadableGraphQLErrors = (errors: GraphQLErrors) =>
    errors.map<ErrorTextItem>((e) => ({
        title: e.name,
        error: `Message: ${e.message}\nOriginalError: ${e.originalError}\nPath: ${e.path}\nErrorCode: ${e.extensions?.code}\nStack: ${e.extensions?.stacktrace}`,
    }));

export const createReadableApolloErrors = (e: ApolloError) => ({
    title: e.name,
    error: `
        \nMessage: ${e.message}
        \nErrors: ${e.clientErrors}
        \n'Network Error:' ${e.networkError?.message}
        \nClient Errors: \n ${e.clientErrors.reduce((acc, item) => `${acc}\n${item?.name}: ${item?.message}`, '')}
        \nProtocol Errors: \n ${e.protocolErrors.reduce((acc, item) => `${acc}\n${item?.message}`, '')}
        \nGQL Errors: \n ${createErrorText(createReadableGraphQLErrors(e.graphQLErrors))}`,
});

export const splitObjectToKeyValString = (obj: object) =>
    Object.entries(obj).reduce((acc, item) => `${acc}\n ${item[0]}: ${item[1]}`, '');
