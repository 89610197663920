import { ItemCountQueryData } from '~/src/types/filter-state';
import type { ProgramPageLayoutProps } from '~/src/components/complex/common-layouts/program-page-layout';

export const getProjectsOverviewData = (
    programName?: string,
    data?: ItemCountQueryData
): ProgramPageLayoutProps['projectsOverviewData'] => {
    if (!data || !programName) {
        return {
            species: [],
            modality: [],
            technique: [],
        };
    }
    return Object.keys(data).reduce(
        (acc, category) => {
            if (category === 'program') {
                return acc;
            }
            return {
                ...acc,
                [category]: data[category].map((item) => ({
                    title: item.name,
                    value: item.count,
                    onClick: () => {
                        window.location.assign(
                            `/data?filter.${category}.name=CONTAINS~${item.name}&filter.program.title=CONTAINS~${programName}`
                        );
                    },
                })),
            };
        },
        {} as ProgramPageLayoutProps['projectsOverviewData']
    );
};
