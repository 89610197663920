import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useNavOptions } from '~/src/containers/application-header/use-nav-options';
import { infoPanelSlice } from '~/src/services/info-panel/info-panel-slice';
import { createGlobalInfoPanelContent } from '~/src/components/complex/info-panel/panel-contents/create-global-info-panel-content';
import { ApplicationHeader } from '~/src/components/complex/app-header/application-header';
/* eslint-disable import/extensions */
import navData from '~/src/assets/data/nav-links.json';
import { BKP_TITLE } from '../../constants/strings';
import { selectTheme, themeSlice } from '../../services/theme';

export const ApplicationHeaderContainer = () => {
    const dispatch = useDispatch();
    const theme = useSelector(selectTheme);
    const location = useLocation();
    const navOptions = useNavOptions();

    const isAbcAtlasPage = location.pathname === '/abcatlas';

    const handleThemeChange = () => {
        dispatch(themeSlice.actions.toggleTheme());
    };

    const handleInfoClick = () => {
        dispatch(
            infoPanelSlice.actions.toggleInfoPanel({
                title: 'ABC Atlas',
                content: createGlobalInfoPanelContent(isAbcAtlasPage),
            })
        );
    };

    return (
        <ApplicationHeader
            title={BKP_TITLE}
            theme={theme}
            showInfoButton={isAbcAtlasPage}
            onThemeChange={handleThemeChange}
            onInfoClick={handleInfoClick}
            navOptions={navOptions}
            helpLinks={navData.helpLinks}
            termsLinks={navData.termsLinks}
        />
    );
};
