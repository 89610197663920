import { Amplify } from '@aws-amplify/core';
import { IS_DEVELOPMENT } from '~/src/constants/environment';
import {
    BKP_COGNITO_REGION,
    BKP_COGNITO_USER_POOL_ID,
    BKP_COGNITO_WEB_CLIENT_ID,
    BKP_COGNITO_OAUTH_DOMAIN,
    BKP_COGNITO_OAUTH_SCOPE,
    BKP_COGNITO_OAUTH_REDIRECT_SIGN_IN,
    BKP_COGNITO_OAUTH_REDIRECT_SIGN_OUT,
    BKP_COGNITO_OAUTH_RESPONSE_TYPE,
    BKP_COGNITO_AUTH_DOMAIN,
} from '~/src/constants/cognito';

export function configureAmplify() {
    if (
        BKP_COGNITO_REGION === '' ||
        BKP_COGNITO_USER_POOL_ID === '' ||
        BKP_COGNITO_WEB_CLIENT_ID === '' ||
        BKP_COGNITO_OAUTH_DOMAIN === '' ||
        BKP_COGNITO_OAUTH_SCOPE === '' ||
        BKP_COGNITO_OAUTH_REDIRECT_SIGN_IN === '' ||
        BKP_COGNITO_OAUTH_REDIRECT_SIGN_OUT === '' ||
        BKP_COGNITO_OAUTH_RESPONSE_TYPE === ''
    ) {
        // eslint-disable-next-line no-console
        console.error('[Amplify configuration variables are not set]');
    } else if (IS_DEVELOPMENT) {
        // setting Amplify auth for local and DEV, auth details will be stored in localStorage
        Amplify.configure({
            Auth: {
                region: BKP_COGNITO_REGION,
                userPoolId: BKP_COGNITO_USER_POOL_ID,
                userPoolWebClientId: BKP_COGNITO_WEB_CLIENT_ID,
                oauth: {
                    domain: BKP_COGNITO_OAUTH_DOMAIN,
                    scope: BKP_COGNITO_OAUTH_SCOPE.split(','),
                    redirectSignIn: BKP_COGNITO_OAUTH_REDIRECT_SIGN_IN,
                    redirectSignOut: BKP_COGNITO_OAUTH_REDIRECT_SIGN_OUT,
                    responseType: BKP_COGNITO_OAUTH_RESPONSE_TYPE,
                },
            },
        });
    } else {
        // adapted from https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/#scoped-configuration
        // setting Amplify auth for higher environments, auth details will be stored in secure cookies
        Amplify.configure({
            Auth: {
                region: BKP_COGNITO_REGION,
                userPoolId: BKP_COGNITO_USER_POOL_ID,
                userPoolWebClientId: BKP_COGNITO_WEB_CLIENT_ID,
                oauth: {
                    domain: BKP_COGNITO_OAUTH_DOMAIN,
                    scope: BKP_COGNITO_OAUTH_SCOPE.split(','),
                    redirectSignIn: BKP_COGNITO_OAUTH_REDIRECT_SIGN_IN,
                    redirectSignOut: BKP_COGNITO_OAUTH_REDIRECT_SIGN_OUT,
                    responseType: BKP_COGNITO_OAUTH_RESPONSE_TYPE,
                },
                // Configuration for cookie storage
                // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
                cookieStorage: {
                    // - Cookie domain (only required if cookieStorage is provided)
                    domain: BKP_COGNITO_AUTH_DOMAIN,
                    // (optional) - Cookie path
                    path: '/',
                    // (optional) - Cookie expiration in days
                    expires: 365,
                    // (optional) - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
                    // sameSite: 'strict' | 'lax',
                    sameSite: 'strict',
                    // (optional) - Cookie secure flag
                    // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
                    secure: true,
                },
            },
        });
    }
}
