import { Hub } from '@aws-amplify/core';

export function configureHub() {
    Hub.listen('auth', ({ payload: { event, data } }) => {
        if (event === 'customOAuthState') {
            // data will always come in with a / on the front
            const currentDomain = `${window.location.protocol}//${window.location.host}`;
            window.location.replace(`${currentDomain}${data}`);
        }
    });
}
