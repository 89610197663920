import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import has from 'lodash/has';

import { BaseKeyPayload, PrepareReturn } from '../../types';
import { parseLimitFromSearch } from '../../utils';

export type LimitState = {
    [key: string]: number;
};

export const INITIAL_LIMIT = 50;
const INITIAL_STATE: LimitState = {};

type LimitPayload = BaseKeyPayload & {
    limit: number;
};

type SetLimitReducer = CaseReducer<LimitState, PayloadAction<LimitPayload>>;
export const setLimitReducer: SetLimitReducer = (state, action) => {
    const { limit, key } = action.payload;
    return {
        ...state,
        [key]: limit,
    };
};

type InitializeLimitReducer = CaseReducer<LimitState, PayloadAction<LimitPayload>>;
const initializeLimitReducer: InitializeLimitReducer = (state, action) => {
    const { key, limit } = action.payload;

    if (has(state, key)) {
        return state;
    }

    return {
        ...state,
        [key]: limit,
    };
};

const initializeLimitPrepare = (
    key: string,
    search: string,
    initialLimit = INITIAL_LIMIT
): PrepareReturn<LimitPayload> => {
    const limit = parseLimitFromSearch(search, initialLimit);

    return {
        payload: {
            key,
            limit,
        },
    };
};

export const limitSlice = createSlice({
    name: 'limit',
    initialState: INITIAL_STATE,
    reducers: {
        setLimit: setLimitReducer,
        initializeLimit: {
            reducer: initializeLimitReducer,
            prepare: initializeLimitPrepare,
        },
    },
});
