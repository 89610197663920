import { Button } from '~/src/components/bkp-ui/button';
import { Grid } from '~/src/components/bkp-ui/grid';
import { Stack } from '~/src/components/bkp-ui/stack';
import { Typography } from '~/src/components/bkp-ui/typography';
import { InputSearch } from '@czi-sds/components';
import { AnimatedBars } from '~/src/components/ui/bar-chart';
import type { AnimatedBarChartProps } from '~/src/components/ui/bar-chart';
import { pluralizeNoun } from '~/src/utils/strings';
import { BASE_PROPS } from './constants';

export interface ProjectsOverviewProps {
    charts: Array<Omit<AnimatedBarChartProps, 'durationMs' | 'limit'>>;
    ctaText?: string;
    /** additional documentation, displays below the title */
    description?: string;
    /**
     * If passed in, will render a button, the value passes into the button href, this should navigate to the data catalog page
     */
    link?: string;
    totalProjectsCount: number;
    /**
     *
     * If pass in, will render the search input, and will call the function when the user presses enter
     */
    onSearchEnter?: (value: string) => void;
}

const ProjectOverviewItem = (props: AnimatedBarChartProps) => (
    <Grid
        item
        xs={12}
        sm={6}
        md={4}
    >
        <AnimatedBars {...props} />
    </Grid>
);

/**
 * Display a visualizaiton of the breakdown of the projects statistics
 */
export const ProjectsOverview = ({
    charts,
    ctaText = 'See all projects',
    description,
    link,
    totalProjectsCount,
    onSearchEnter,
}: ProjectsOverviewProps) => {
    const searchPlaceHolderText = `Search ${totalProjectsCount} ${pluralizeNoun(totalProjectsCount, 'project')}`;

    const handleSearchEnter = (value: string) => {
        onSearchEnter?.(value);
    };

    return (
        <Stack direction="column">
            {description && (
                <Typography
                    stylePath="body.regular.s"
                    color="text.secondary"
                >
                    {description}
                </Typography>
            )}
            <Stack
                direction="row"
                spacing="m"
                marginBlock="l"
            >
                {onSearchEnter && (
                    <InputSearch
                        id="projects-overview-search"
                        label="Search projects"
                        placeholder={searchPlaceHolderText}
                        sdsStyle="square"
                        style={{ margin: 0 }}
                        inputProps={{
                            onKeyDown: (event) => {
                                if (event.key === 'Enter') {
                                    handleSearchEnter(event.currentTarget.value);
                                }
                            },
                        }}
                    />
                )}
                {link && (
                    <Button
                        href={link}
                        sdsType="primary"
                        sdsStyle="square"
                    >
                        {ctaText}
                    </Button>
                )}
            </Stack>
            <Grid
                container
                spacing="xxl"
            >
                {charts.map((chart) => (
                    <ProjectOverviewItem
                        key={chart.categoryName}
                        {...chart}
                        {...BASE_PROPS}
                    />
                ))}
            </Grid>
        </Stack>
    );
};
