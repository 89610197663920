import { useDispatch, useSelector } from 'react-redux';

import { ERROR_NOTIFICATION_TIMEOUT } from '~/src/constants/configuration';
import { selectNonFatalErrors } from '../../services/error/selectors';
import { errorSlice } from '../../services/error';
import { ErrorReport } from '../../components/complex/error-report';

export const ErrorNotification = () => {
    const dispatch = useDispatch();
    const nonFatalErrors = useSelector(selectNonFatalErrors);

    const hasErrors = nonFatalErrors.length > 0;

    const dismissError = (errorId: string) => {
        dispatch(errorSlice.actions.dismissNonFatalError({ errorId }));
    };

    if (hasErrors) {
        return (
            <ErrorReport
                errorOptionGroups={nonFatalErrors}
                selfDismissTimeout={ERROR_NOTIFICATION_TIMEOUT}
                dismissError={dismissError}
            />
        );
    }

    return null;
};
