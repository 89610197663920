// eslint-disable-next-line max-classes-per-file
import type { ApolloError } from '@apollo/client';

const NON_FATAL_FALLBACK_MESSAGE = 'An unknown error has occurred. If data seems off, a page refresh can help.';
export interface NonFatalErrorInfo {
    friendlyMessage: string;
    errorName: string;
    errorMessage: string;
    errorId: string;
    stack?: string;
    apolloErrorsText?: string;
    extraInfo?: string;
    fullErrorInfo?: string;
}
export interface FatalErrorInfo {
    errorName: string;
    errorMessage: string;
    componentStack: string;
    stack?: string;
    apolloErrorsText?: string;
}

/**
 * Represents custom errors we can recover from. Eg use-case,
 * Fetch call fails, but navigation to other parts of site
 * are still possible.
 */
export class NonFatalError extends Error {
    friendlyMessage: string;
    originalError: Error;

    constructor(originalError: Error, friendlyMessage?: string) {
        super();
        this.originalError = originalError;
        this.friendlyMessage = friendlyMessage ?? NON_FATAL_FALLBACK_MESSAGE;
    }
}

/**
 * Represents custom errors we can associate with useQuery apart from
 * the ones Apollo sends. This means, if something is unexpected in the payload,
 * we can return this class of error.
 */
export class UseQueryNonFatalError extends NonFatalError {}

/**
 * Specific non fatal error where properties are not found.
 */
export class PropertiesNotFoundError extends UseQueryNonFatalError {
    static readonly message: string = 'No options found in `specimenProperties` query payload. Cannot render page.';

    readonly message: string = PropertiesNotFoundError.message;

    readonly name: string;

    constructor() {
        super(PropertiesNotFoundError, PropertiesNotFoundError.message);
    }
}

export type BkpServiceError = UseQueryNonFatalError | ApolloError;
