import { PayloadAction } from '@reduxjs/toolkit';
import TagManager from 'react-gtm-module';
import { GOOGLE_TAG_DATA_LAYER_NAME } from '../../../constants/analytics';
import { filterSlice } from '../../filter/filter-slice';
import { type RootState } from '../../redux';
import { createAnalyticsAction } from '../create-analytics-action';

const sendAnalyticsInventoryFilterClearField = (_state: RootState, action: PayloadAction<{ field: string }>) => {
    const {
        type,
        payload: { field },
    } = action;

    TagManager.dataLayer({
        dataLayerName: GOOGLE_TAG_DATA_LAYER_NAME,
        dataLayer: {
            event: 'inventoryFilterClearField',
            actionType: type,
            field,
        },
    });
};

export const inventoryFilterClearFieldAction = createAnalyticsAction(
    filterSlice.actions.clearFieldFilter,
    sendAnalyticsInventoryFilterClearField
);
