import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Stack } from '~/src/components/bkp-ui/stack';
import { SettingsMenuItem } from '~/src/components/ui/settings-menu/settings-menu-item';
import { featureFlagIds, featureFlags, FeatureFlagId } from '~/src/constants/features';
import { isFeatureEnabled, setFeatureFlag } from '~/src/utils/feature-flag/feature-flag';

const StyledFeatureFlagsControl = styled(Stack)(({ theme }) => ({
    '& .MuiMenuItem-root': {
        padding: theme.spacing(0.5),
        minHeight: 'unset',
    },
}));

/**
 * A section of available feature flags that can be toggled on/off.
 * Currently there are not a lot of feature flags, if we do have a lot more in the future
 * and the horizontal stack is not working out, we can consider using <SettingsMenu /> as a dropdown
 */
export const FeatureFlagsControl = () => {
    const [flagsState, setFlagsState] = useState({} as Record<FeatureFlagId, boolean>);

    useEffect(() => {
        // initialize toggled flags from local storage and reflect the states in local UI
        const initFlagsState = Object.values(featureFlagIds).reduce(
            (acc, flagId) => ({
                ...acc,
                [flagId]: isFeatureEnabled(flagId),
            }),
            {} as Record<FeatureFlagId, boolean>
        );
        setFlagsState(initFlagsState);
    }, []);

    const handleFlagToggle = (flagId: FeatureFlagId) => () => {
        const newFlagState = !flagsState[flagId];
        setFlagsState({
            ...flagsState,
            [flagId]: newFlagState,
        });
        setFeatureFlag(flagId, newFlagState);
    };

    return (
        <StyledFeatureFlagsControl
            direction="column"
            spacing="xxxs"
        >
            {Object.keys(flagsState).map((id) => {
                const flagId = id as FeatureFlagId;
                return (
                    <SettingsMenuItem
                        key={flagId}
                        label={featureFlags[flagId].name}
                        onClick={handleFlagToggle(flagId)}
                        onChange={handleFlagToggle(flagId)}
                        type="toggle"
                        isToggled={flagsState[flagId]}
                    />
                );
            })}
        </StyledFeatureFlagsControl>
    );
};
