import { styled } from '@mui/material/styles';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { Button as SdsButton, Link } from '@czi-sds/components';
import type { ButtonProps as SdsButtonProps } from '@czi-sds/components';
import { getSemanticTextColors } from '~/src/utils/theme/theme-helpers';

export type ButtonProps = SdsButtonProps & {
    external?: boolean;
};

const StyledButton = styled(SdsButton)(({ theme }) => {
    const colors = getSemanticTextColors(theme);
    // For SDS: Add rules for start/end button icon for various states to use the same colors as the text, so they work with dark theme.
    return {
        '&:hover': {
            '& .MuiButton-icon span svg': {
                color: colors.base.onFill,
            },
        },
        '&:active': {
            '& .MuiButton-icon span svg': {
                color: colors.base.onFill,
            },
        },
        '&:disabled': {
            '& .MuiButton-icon span svg': {
                color: colors.base.onFillDisabled,
            },
        },
    };
});

/**
 * This is the Button component with styles applied following the SDS.
 */
export const Button = ({ children, external, ...restProps }: ButtonProps) => {
    if (external) {
        // manually add attributes for external links for consistent pattern
        const { href, ...splitRestProps } = restProps;
        return (
            <Link
                href={href}
                target="_blank"
                rel="noopener noreferrer"
            >
                <StyledButton
                    startIcon={<OpenInNewOutlinedIcon />}
                    {...splitRestProps}
                >
                    {children}
                </StyledButton>
            </Link>
        );
    }
    return <StyledButton {...restProps}>{children}</StyledButton>;
};
