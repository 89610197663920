import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import { getSpaces } from '~/src/utils/theme/theme-helpers';
import { Typography, TypographyStylePath } from './typography';

const StyledMenuItemTextWrapper = styled(Box)(({ theme }) => {
    const spaces = getSpaces(theme);
    return {
        paddingInline: spaces.s,
        paddingBlock: spaces.xs,
    };
});

interface MenuItemTextProps extends BoxProps {
    text?: string;
    variant?: 'title' | 'body' | 'footnote';
}

const getStylePath = (variant: MenuItemTextProps['variant']): TypographyStylePath => {
    switch (variant) {
        case 'title':
            return 'caps.semibold.xxxs';
        case 'footnote':
            return 'body.regular.xxs';
        default:
            return 'body.regular.xs';
    }
};
/**
 * Non-actionable component that displays text or other content in a menu item.
 *
 * Note: This is not included in the SDS, but it's intended to use with SDS Menus.
 */
export const MenuItemText = ({ text, children, variant = 'body', ...restProps }: MenuItemTextProps) => (
    <StyledMenuItemTextWrapper {...restProps}>
        <Typography
            color="text.secondary"
            stylePath={getStylePath(variant)}
        >
            {text}
        </Typography>
        {children}
    </StyledMenuItemTextWrapper>
);
