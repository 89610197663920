import { lazy, useEffect } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { NotFound } from '../not-found';
import { dataRedirect } from './redirect';

const Inventory = lazy(() => import('./inventory'));
const Summary = lazy(() => import('./summary'));
const Specimens = lazy(() => import('./specimens'));
const DonorIndex = lazy(() => import('./donor'));
const DonorProfile = lazy(() => import('./donor/donor-profile'));
const ComparativeViewer = lazy(() => import('./comparative-viewer'));
const Explore = lazy(() => import('../explore'));

export const DataCatalogSEO = () => (
    <Helmet>
        <title>Data Catalog for Allen Institute&apos;s Brain Knowledge Platform</title>
        <meta
            name="description"
            content="Catalog of datasets and research tools from Allen Institute for Brain Science and partners, including BRAIN Initiative Cell Census Network, providing neuroscience teams with access to a wealth of resources to accelerate research."
        />
        <meta
            name="keywords"
            content="Allen Institute, Brain Science, Allen Brain Map, BICCN, neuroscience, data, datasets"
        />
    </Helmet>
);

export const DataCollectionProjects = () => {
    // to redirect from /:referenceId and /data/:referenceId
    // to data/:referenceId/summary if needed (react router v6 update)
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        // to check if redirect is necessary and redirect as needed
        const returnUrl = dataRedirect(params);
        if (returnUrl) {
            navigate(returnUrl, { replace: true });
        }
    }, [navigate, params]);

    return (
        <>
            <DataCatalogSEO />
            <Routes>
                <Route
                    path="/"
                    element={<Inventory />}
                />
                <Route
                    path=":referenceId/summary"
                    element={<Summary />}
                />
                <Route
                    path=":referenceId/collections"
                    element={<Summary />}
                />
                <Route
                    path=":referenceId/explore"
                    element={<Explore />}
                />
                <Route
                    path=":referenceId/specimens/:specimensView"
                    element={<Specimens />}
                />
                <Route
                    path=":referenceId/specimens"
                    element={<Specimens />}
                />
                <Route
                    path=":referenceId/donors/:donorReferenceId"
                    element={<DonorProfile />}
                />
                <Route
                    path=":referenceId/donors"
                    element={<DonorIndex />}
                />
                <Route
                    path=":programId/:subProgramId/compare"
                    element={<ComparativeViewer />}
                />
                <Route
                    path="*"
                    element={<NotFound />}
                />
            </Routes>
        </>
    );
};
