import { FC } from 'react';
import Box from '@mui/material/Box';
import { NotFound } from '~/src/pages/not-found/not-found';
import { getSkeleton, SkeletonMode, SkeletonVariant, SkeletonModeType, SkeletonVariantType } from './get-skeletons';

export const SKELETON_WRAPPER_ID = 'skeleton-wrapper';

interface WithSkeletonProps {
    isLoading?: boolean;
    isNotFound?: boolean;
}

/**
 * This is an HOC that wraps a component with a skeleton to visually display loading state.
 * See skeletons-closet.md for more details.
 * @param WrappedComponent The component to wrap with a skeleton.
 */
export const withSkeleton = <P extends object>(
    WrappedComponent?: FC<P>,
    settings?: {
        variant?: SkeletonVariantType;
        /**
         * defaults to 'component', if 'page' is passed in, the skeleton will apply page specific UI and styles
         */
        mode?: SkeletonModeType;
        customSkeleton?: React.ReactNode;
    }
) => {
    const { variant = SkeletonVariant.text, mode = SkeletonMode.component } = settings || {};

    const WithSkeleton: React.FC<P & WithSkeletonProps> = (props) => {
        // destructuring the HOC props and make sure restProps are only the props of component
        const { isLoading, isNotFound, ...restProps } = props;

        if (isLoading || WrappedComponent === undefined) {
            return (
                <Box
                    data-testid={SKELETON_WRAPPER_ID}
                    height="100%"
                    overflow="hidden"
                    width="100%"
                >
                    {settings?.customSkeleton ?? getSkeleton(variant)}
                </Box>
            );
        }

        if (isNotFound) {
            if (mode === SkeletonMode.page) {
                return <NotFound />;
            }
            if (mode === SkeletonMode.component) {
                return <Box color="text.secondary">Item does not exist</Box>;
            }
        }

        return <WrappedComponent {...(restProps as P)} />;
    };

    WithSkeleton.displayName = `WithSkeleton(${WrappedComponent?.displayName || 'Component'})`;

    return WithSkeleton;
};
