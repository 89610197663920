import { AD_PROGRAM_ID } from '../../constants/strings';

export const PAGE_OPTIONS: string[] = [
    'celltypes',
    'data',
    'donors',
    'compare',
    'summary',
    'specimens',
    'collections',
    'explore',
];

interface DataURLParams {
    referenceId?: string | number;
    '*'?: string;
}

// TODO: Simplify with React Router `useMatch` or similar
export const dataRedirect = (params: DataURLParams): string | null => {
    const projectPath = params?.['*'] || '';
    const nestedProjectPath = projectPath.split('/');
    const projectPage = nestedProjectPath.find((path: string) => PAGE_OPTIONS.some((opt: string) => path === opt));

    if (params['*'] === '') {
        return null;
    }

    // if referenceID is present as params initial url was .../:referenceID, tab implementation means
    // we also need to account for toggling between collections and summary without a redirect
    // if no projectPage is present but nested length is == 2 initial url was data/:referenceId
    // HACK ALERT
    // if projectPage is 'compare' then programId must match AD programId otherwise navigate to page not found
    if (params && params.referenceId && projectPage === 'summary') {
        return `/data/${params.referenceId}/summary`;
    }
    if (params && params.referenceId && projectPage === 'collections') {
        return `/data/${params.referenceId}/collections`;
    }
    if (params && params.referenceId && projectPage === 'explore') {
        return `/data/${params.referenceId}/explore`;
    }
    if (params && !projectPage && nestedProjectPath.length <= 2) {
        const includeSlash = params['*'].slice(-1) === '/' ? '' : '/';
        return `/data/${params['*']}${includeSlash}summary`;
    }
    if (projectPage === 'compare' && nestedProjectPath[0] !== AD_PROGRAM_ID) {
        return '*';
    }
    return null;
};
