import type { ScatterbrainCamera } from '~/src/components/complex/scatterbrain/types';
import {
    AnnotationId,
    CategoryId,
    DataCollectionId,
    DatasetId,
    FeatureTypeId,
    FilterValueId,
    ProjectId,
    SlideId,
    VisualizationId,
} from '~/src/types/reference-ids';
import { CellFilterType } from '~/src/types/generated-schema-types';
import { vec2, box2D } from '@alleninstitute/vis-geometry';
import { ColorByType, AnnotationColorAttribute, AnnotationColorOption } from './namespace';

export type ColorByTypes = (typeof ColorByType)[keyof typeof ColorByType];

export type SelectedCellType = {
    value: string;
    property: string;
};

type TransparancySettings = {
    isTransparent: boolean;
    transparency: number;
};

export type MetadataColorProperties = {
    type: typeof ColorByType.metadata;
    category: string;
} & TransparancySettings;

export type NullColoring = 'ZEROS' | 'COLOR';

export type NumericColorBase = {
    nullColoring: NullColoring;
    nullColor: string;
    // Inverses all colors, not just for maps
    invertMap: boolean;
    excludeZeros: boolean;
};

export type NumericColorGradient = NumericColorBase & {
    clampRange: Interval;
    gradient: string[];
    type: 'GRADIENT';
};

export type NumericColorMap = NumericColorBase & {
    clampRange: Interval;
    name: string;
    type: 'MAP';
};

export type NumericColorGene = NumericColorBase & {
    type: 'GENE';
};

export type NumericColor = NumericColorGradient | NumericColorMap | NumericColorGene;

export type QuantitativeColorProperties = {
    type: typeof ColorByType.quantitative;
    index?: number;
    value: string;
    range: {
        min: number;
        max: number;
    };
    color?: NumericColor;
} & TransparancySettings;

export type MetadataColorBy = {
    type: typeof ColorByType.metadata;
    category: string;
} & TransparancySettings;

export type GeneSymbolUx = {
    symbol: string;
    isExpanded: boolean;
};
export type GenesSymbolsUxState = Record<string, GeneSymbolUx>;

export type GenesUxState = {
    canShowDefaultGene: boolean;
    symbols: GenesSymbolsUxState;
    notFoundGenes?: string[];
};

type Interval = {
    min: number;
    max: number;
};

export type GeneFilter = {
    type: typeof CellFilterType.Gene;
    symbol: string;
    index: number;
    range: Interval;
    selectedRange?: Interval;
};

export type NumericFilter = {
    type: typeof CellFilterType.Metadata;
    value: string;
    range: Interval;
    selectedRange?: Interval;
};

export type QuantitativeFilter = GeneFilter | NumericFilter;

export type QuantitativeFilters = Record<string, QuantitativeFilter>;

export type VisualizationsState = {
    [referenceId: string]: VisualizationState[];
};

export type VisualizationState = {
    reactGridKey: FrameKey;
    dataCollectionId: DataCollectionId;
    referenceId: string;
    datasetId: DatasetId;
    visualizationId: VisualizationId;
    colorProperties: MetadataColorProperties | QuantitativeColorProperties;
};

export const VizControl = {
    pan: 'pan',
    select: 'select',
} as const;

export type CellSelectorSelection = {
    start: vec2 | null;
    end: vec2 | null;
};

export type VizControlType = (typeof VizControl)[keyof typeof VizControl];
export interface VizControlsState {
    controlType: VizControlType;
    box: box2D | null;
    selection: CellSelectorSelection | null;
    selectionOffset: vec2 | null;
    slideId: SlideId | null;
    enableCameraPan: boolean;
}

export type AnnotationColorAttributeType = (typeof AnnotationColorAttribute)[keyof typeof AnnotationColorAttribute];
export type AnnotationColorOptionType = (typeof AnnotationColorOption)[keyof typeof AnnotationColorOption];
export type AnnotationColorPreference = {
    selectedColor: string;
    colorOption: AnnotationColorOptionType;
    opacity: number;
};
export type AnnotationOption = {
    label: string;
    description?: string;
    referenceId: string;
};
export interface AnnotationsState {
    isInFront: boolean;
    selectedId: AnnotationId;
    selectedFeatureTypeId: FeatureTypeId;
    fill: AnnotationColorPreference;
    stroke: AnnotationColorPreference;
}

export type FilterSelectionState = Record<FilterValueId, boolean>;
export type FilterCategoryUxState = {
    isExpanded: boolean;
    showSearch: boolean;
    searchText: string;
    showCategory: boolean;
};

export type VisSettings = {
    ux: {
        selectedCell:
            | undefined
            | {
                  value: string; // filter value
                  property: string; // filter category
              };
        focusedPanel: string | null;
        metadataFilters: Record<CategoryId, FilterCategoryUxState>;
        genes: GenesUxState;
        disableClose: boolean;
    };
    aesthetic: {
        performanceVsQuality: 1 | 2 | 3 | 4 | undefined;
        pointSizeScale: number;
    };
    annotations: AnnotationsState;
    camera: ScatterbrainCamera | undefined;
    vizControls: VizControlsState;
    projectId: ProjectId;
    dataCollectionId: DataCollectionId;
    datasetId: DatasetId;
    datasetVersion: string;
    visualizationId: VisualizationId;
    metadataFilters: Record<CategoryId, FilterSelectionState>;
    quantitativeFilters: QuantitativeFilters;
    colorByDefault: FeatureTypeId;
    colorProperties: MetadataColorProperties | QuantitativeColorProperties;
};

export type VisFrameLayout = Partial<{
    a: VisSettings;
    b: VisSettings;
    c: VisSettings;
    d: VisSettings;
}>;
export type FrameKey = keyof VisFrameLayout;
