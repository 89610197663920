// SENTENCE_REGEX is not used here because it's too aggressive and clashes with the URLs

export const getLastSentence = (text: string): string => {
    const textSentences = text.trim().split('. ');
    const [lastSentence] = textSentences.slice(-1);

    return lastSentence;
};

export const getAllButLastSentence = (text: string): string => {
    const textSentences = text.trim().split('. ');
    const allButLastSentence = textSentences.slice(0, -1).reduce((acc, next) => `${acc} ${next}.`, '');

    return allButLastSentence;
};

/**
 * Transforms a block of text into two pieces, allowing the function user to easily get
 * the last sentence of a paragraph. This is useful when formatting needs the last sentence
 * of some data to be displayed differently and we don't have the time or ability to get the
 * data split into different parts on the backend.
 *
 * @param text Text to analyze for splitting out the last sentence
 * @returns a tuple with two string values: everything but the last sentence and the last sentence
 */
export const splitLastSentence = (text: string): [string, string] => {
    if (!text) {
        return ['', ''];
    }

    const allButLastSentence = getAllButLastSentence(text);
    const lastSentence = getLastSentence(text);

    return [allButLastSentence, lastSentence];
};
