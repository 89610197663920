export const InfoPanelResource = {
    download: 'download',
    link: 'link',
};
export const InfoPanelContentItem = {
    citation: 'citation',
    custom: 'custom',
    header: 'header',
    paragraph: 'paragraph',
    resource: 'resource',
    shortcut: 'shortcut',
} as const;
