export const DATA_URI_BASE = '/data';

export const SUMMARY_PAGE_URI_BASE = '/data/:referenceId/summary';
export const DATA_COLLECTION_URI_BASE = '/data/:referenceId/collections';
export const EXPLORE_PAGE_URI_BASE = '/data/:referenceId/explore';
export const SPECIMENS_PAGE_URI_BASE = '/data/:referenceId/specimens';
export const DONOR_INDEX_PAGE_URI_BASE = '/data/:referenceId/donors';
export const DONOR_PROFILE_PAGE_URI_BASE = '/data/:referenceId/donors/:donorReferenceId';
export const ABC_ATLAS_PAGE_URI_BASE = '/abcatlas';

export const COMPARATIVE_VIEWER_URI_BASE = 'data/:programId/:subProgramId/compare';

export const CELL_TYPES_URI_BASE = '/celltypes';
export const CELL_TYPES_EXPLORER_URI_BASE = '/celltypes/:taxId';
export const CELL_TYPES_CARD_URI_BASE = '/celltypes/:taxId/:cellTypeId';

export const MAPPING_PROCESS_URI_BASE = '/mapmycells/process';
export const MAPPING_RESULTS_URI_BASE = '/mapmycells/results';

export const ABC_ATLAS_TITLE = 'ABC Atlas';
export const EXPLORE_GENERAL_URI_BASE = '/abcatlas';
export const ABC_ATLAS_DEFAULT_PROJECT_ID_QUERY_PARAM = 'defaultProjectId';

export const LOGIN_URI_BASE = '/login';
export const REDIRECT_URI_BASE = '/signin/redirect';
