import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

// TODO Delete these, they should be replaced by quad-view, triple-view, etc.
export const QuadrupleRightIcon = (props: SvgIconProps) => (
    <SvgIcon
        viewBox="0 0 18 18"
        {...props}
    >
        <rect
            x="7"
            y="2"
            width="9"
            height="14"
            rx="1"
        />
        <rect
            x="2"
            y="2"
            width="4"
            height="4"
            rx="1"
        />
        <rect
            x="2"
            y="7"
            width="4"
            height="4"
            rx="1"
        />
        <rect
            x="2"
            y="12"
            width="4"
            height="4"
            rx="1"
        />
    </SvgIcon>
);
