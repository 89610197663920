import { createSelector } from 'reselect';

import { SORT_QUERY_DELIMITER } from '../../constants/strings';
import { SortArgument } from '../../types';
import { selectPageKey } from '../page-key';
import { type RootState } from '../redux';

const selectSortState = (state: RootState) => state.sort;

export const selectSort = createSelector(
    [selectPageKey, selectSortState],
    (pageKey, sortState): SortArgument[] => sortState[pageKey] ?? []
);

export const selectSortQueryOption = createSelector([selectSort], (sortArguments) => {
    const sort = sortArguments?.map(({ order, field }: SortArgument) => `${field}${SORT_QUERY_DELIMITER}${order}`);

    return { sort };
});
