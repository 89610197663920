import { createAction, PayloadAction } from '@reduxjs/toolkit';
import TagManager from 'react-gtm-module';
import { GOOGLE_TAG_DATA_LAYER_NAME } from '../../../constants/analytics';
import { type RootState } from '../../redux';
import { createAnalyticsAction } from '../create-analytics-action';

const abcAtlasImageDownloadAction = createAction(
    'gtm/abc-atlas-image-download',
    (projectKey: string): { payload: string } => ({
        payload: projectKey,
    })
);

const sendAnalyticsABCAtlasImageDownload = (_state: RootState, action: PayloadAction<{ projectKey: string }>) => {
    const {
        payload: { projectKey },
    } = action;

    TagManager.dataLayer({
        dataLayerName: GOOGLE_TAG_DATA_LAYER_NAME,
        dataLayer: {
            event: 'abcAtlasImageDownload',
            projectKey,
        },
    });
};

export const abcAtlasImageDownloadAnalyticsAction = createAnalyticsAction(
    abcAtlasImageDownloadAction,
    sendAnalyticsABCAtlasImageDownload
);
