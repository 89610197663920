/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign */

// these are helper functions for doing various things with the explore page
// they're intended for use inside the explore-page slice
import { omit, has, pickBy, sortBy } from 'lodash';
import { PayloadAction } from '@reduxjs/toolkit';

import type { GridSlide, ScatterbrainCamera, SlideViewCamera } from '~/src/components/complex/scatterbrain/types';
import { exhaustiveSwitchGuard } from '~/src/utils/exhaustive-switch';
import { Layout, frameCountByLayout, layoutKeyMap, layoutMapArray } from '~/src/constants/layouts';
import { FILTER_FIELD_QUERY_DELIMITER, GENE_EXPRESSION_CATEGORY } from '~/src/constants/strings';
import {
    FilterArgument,
    GeneExpressionFilterArgument,
    NumericFilterArgument,
    Operator,
} from '~/src/types/filter-argument';
import { CellFilterType } from '~/src/types/generated-schema-types';
import {
    CategoryId,
    DataCollectionId,
    FilterValueId,
    Nominal,
    DatasetId,
    VisualizationId,
    ProjectId,
    SlideId,
    AnnotationId,
    FeatureTypeId,
} from '~/src/types/reference-ids';
import { Box2D, Interval, Vec2, box2D, vec2 } from '@alleninstitute/vis-geometry';
import type { AbcAtlasReducer, ExplorePage } from './explore-page-slice';
import {
    GeneFilter,
    GeneSymbolUx,
    MetadataColorProperties,
    QuantitativeColorProperties,
    QuantitativeFilters,
    CellSelectorSelection,
    VizControlType,
    VizControlsState,
    VizControl,
    AnnotationColorAttributeType,
    AnnotationColorPreference,
    AnnotationsState,
    FilterCategoryUxState,
    FilterSelectionState,
    FrameKey,
    VisFrameLayout,
    VisSettings,
    GenesUxState,
    NumericFilter,
    QuantitativeFilter,
    NumericColor,
    NullColoring,
} from './types/states';
import { ColorByType } from './types/namespace';
import {
    calculateDrag,
    getDataSpaceCoordinateFromCanvas,
    isSlideCamera,
    matchScreenAspectRatio,
    slideGridLayout,
    zoomCamera,
} from './cameraUtils';
import { MAX_VISUALIZATIONS_PER_PAGE, DEFAULT_VIS_CONTROL_STATE, DEFAULT_ANNOTATION_STATE } from './constants';

/**
 * use this function sparingly, when you are certain that the id you have is
 * actually for the type you're casting it to
 * @param id a string to cast as the given Nominal type
 * @returns
 */
export function unsafeId<T extends Nominal<string>>(id: string): T {
    return id as T;
}
/**
 * Use this function to see if a specific filter category on a given frame has the same key/value pairs
 * as the same filter category for a different frame
 * @param filters1 : Filter Selection State
 * @param filters2 : Filter Selection State
 * @returns  true if the filter selections would seem identical to a user, false otherwise
 */
export const areFilterSelectionsEqual = (
    filters1: FilterSelectionState | undefined,
    filters2: FilterSelectionState | undefined
) => {
    const filter1Keys = Object.keys(filters1 ?? {}).filter((key) => filters1?.[key]);
    const filter2Keys = Object.keys(filters2 ?? {}).filter((key) => filters2?.[key]);
    if (filter1Keys.length !== filter2Keys.length) return false;
    return filter1Keys.every((key) => filter2Keys.includes(key) && filters1?.[key] === filters2?.[key]);
};

// we often apply a reducer to a mapped-type aka Record<K,T>
// this is just a nice helper to make that a bit nicer
export function reduceRecord<K extends string, T, R>(
    dictionary: Record<K, T>,
    reducer: (previous: R, value: T, key: K) => R,
    initialValue: R
) {
    let result: R = initialValue;
    Object.keys(dictionary).forEach((key) => {
        result = reducer(result, dictionary[key as K], key as K);
    });
    return result;
}

/**
 *
 * @param page an explore page
 * @returns the currently selected visualization, or undefined if it does not exist
 */
export function selectCurrentVis(page: ExplorePage | undefined) {
    if (!page) return undefined;
    return page.visFrames[page.currentFrame];
}

/**
 * This is intended for reducers that need access to the whole Abc Atalas (ExplorePage)
 */
export function makeAbcAtlasReducer<Payload extends object>(
    reducer: (page: ExplorePage, payload: Payload) => ExplorePage
): AbcAtlasReducer<Payload> {
    return (state: ExplorePage, action: PayloadAction<Payload>) => {
        const { payload } = action;
        return reducer(state, payload);
    };
}
// many many many of these reducers apply to only the current vis, and I think all of them apply only to the current page
// lets make a helper for that so I dont go nuts

export function makeCurrentVisReducer<Payload extends object>(
    reducer: (vis: VisSettings, payload: Payload) => VisSettings
) {
    return makeAbcAtlasReducer((page: ExplorePage, payload: Payload) => {
        const vis = selectCurrentVis(page);
        const { visFrames, currentFrame } = page;
        if (!vis) {
            return page;
        }
        return { ...page, visFrames: { ...visFrames, [currentFrame]: reducer(vis, payload) } };
    });
}
/**
 * This function intakes a page and a specific frameKey to check if the filters of the specified
 * visualization match the filters of other visualizations it may be synced with
 * If it is not synced, or the filters match, then we just return the page
 * If it is synced and the filters do not match then we perform the necessary remove frame sync logic
 * @param page : Explore Page
 * @param frameKey : FrameKey
 * @returns Explore Page with frames removed from sync if necessary
 */
const enforceSyncRulesOnFilter = (page: ExplorePage, frameKey: FrameKey): ExplorePage => {
    const { visFrames } = page;
    const vis = page.visFrames[frameKey];
    if (!vis) {
        return page;
    }
    const { datasetId, camera } = vis;
    const isPageSynced = page.synced[datasetId]?.includes(frameKey);
    if (isSlideCamera(camera) && camera.gridFeature && isPageSynced) {
        // all synced frames will have the same grid filters otherwise won't be synced
        // therefore we just need to grab any key that is synced that isn't the current frame
        const otherFrameKey = page.synced[datasetId].filter((key) => key !== frameKey)?.[0];
        const metadataGridFeaturesOfSyncedFrame = visFrames[otherFrameKey]?.metadataFilters?.[camera.gridFeature];
        // we want to compare the new version of the visualization where the filters have been changed
        const currentFrameMetadataGridFeatures = vis.metadataFilters?.[camera.gridFeature];
        // if the grid features are different between the two frames we remove frame sync
        if (!areFilterSelectionsEqual(metadataGridFeaturesOfSyncedFrame, currentFrameMetadataGridFeatures)) {
            return { ...removeFrameSync(page, { frameKey }) };
        }
        // otherwise we just return the new vis frames with newly applied filters
        return page;
    }
    return page;
};

/**
 * This reducer wraps any filter related vis level reducers
 * We do this so that we can remove frame syncing if necessary
 * Frame syncing only gets removed if the filters changed are grid features
 * at time of writing this
 * @param reducer : vis level reducer for setting filters
 * @returns explore page
 */
export function makeCurrentVisFilterReducer<Payload extends object>(
    reducer: (vis: VisSettings, payload: Payload) => VisSettings
) {
    return makeAbcAtlasReducer((page: ExplorePage, payload: Payload & { frameKey?: FrameKey }) => {
        const { visFrames, currentFrame } = page;
        // certain toggleFilter functions pass in a key whereas others use the currentFrame
        // i.e. toggleFilterForVis specifically requires a frameKey
        const frameKeyToUse = payload.frameKey ?? currentFrame;
        const vis = visFrames[frameKeyToUse];
        if (!vis) {
            return page;
        }
        const newVis = reducer(vis, { ...payload, frameKey: frameKeyToUse });
        const newVisFrames = { ...visFrames, [frameKeyToUse]: newVis };
        return enforceSyncRulesOnFilter({ ...page, visFrames: newVisFrames }, frameKeyToUse);
    });
}
export function makeGivenVisReducer<Payload extends object>(
    reducer: (vis: VisSettings, payload: Payload) => VisSettings
) {
    return makeAbcAtlasReducer((page: ExplorePage, payload: Payload & { frameKey: FrameKey }) => {
        const { frameKey } = payload;
        const vis = page.visFrames[frameKey];
        const { visFrames } = page;
        if (!vis) {
            return page;
        }
        return { ...page, visFrames: { ...visFrames, [frameKey]: reducer(vis, payload) } };
    });
}

/**
 *
 * @param page an explore page
 * @param dataCollectionId the id of a data collection that we want to display
 * @returns a visualization object for the first available plot of the given datacollection, or undefined in any failure case
 */
export function visualizeDataCollection(
    projectId: ProjectId,
    dataCollectionId: DataCollectionId,
    datasetId: DatasetId,
    visualizationId: VisualizationId,
    version: string,
    defaultColorBy: string,
    annotationId?: AnnotationId,
    annotationFeatureTypeId?: FeatureTypeId
) {
    if (!dataCollectionId || !datasetId || !visualizationId || !defaultColorBy) return undefined;

    // If an annotation and its feature type are provided, use it, otherwise use the default annotation state
    const annotations =
        annotationId && annotationFeatureTypeId
            ? {
                  ...DEFAULT_ANNOTATION_STATE,
                  selectedId: annotationId,
                  selectedFeatureTypeId: annotationFeatureTypeId,
              }
            : DEFAULT_ANNOTATION_STATE;

    const vis: VisSettings = {
        projectId,
        dataCollectionId,
        datasetId,
        datasetVersion: version,
        visualizationId,
        metadataFilters: {},
        quantitativeFilters: {},
        camera: undefined, // we have to read some extra data from some other place - so no default camera for us!
        vizControls: DEFAULT_VIS_CONTROL_STATE,
        aesthetic: {
            performanceVsQuality: undefined,
            pointSizeScale: 1,
        },
        annotations,
        colorByDefault: defaultColorBy,
        colorProperties: {
            type: ColorByType.metadata,
            category: defaultColorBy,
            transparency: 0.5,
            isTransparent: false,
        },
        ux: {
            selectedCell: undefined,
            focusedPanel: null,
            metadataFilters: {},
            genes: {
                canShowDefaultGene: true,
                symbols: {},
            },
            disableClose: false,
        },
    };
    return vis;
}

/**
 *
 * @param page an explore page
 * @param frameKey the frame to select, defaults to currentFrame
 * @param projectId the project id to display in the given frame
 * @param dataCollectionId the dataCollection id to display in the given frame
 * @param datasetId the specific plot id within data collection to display, defaults to first available in data collection
 * @param visualizationId the visualization id to display in the given frame
 * @returns a new page, in which the currently selected frame now displays a visualization of the dataCollection given by @param dataCollectionId, or @param page if the operation cant succeed
 */
export function pickDatasetToView(
    page: ExplorePage,
    payload: {
        frameKey?: FrameKey;
        projectId: string;
        dataCollectionId: string;
        datasetId: string;
        visualizationId: string;
        datasetVersion: string;
        defaultColorBy: string;
        annotationId?: string;
        annotationFeatureTypeId?: string;
    }
): ExplorePage {
    const {
        frameKey,
        projectId,
        dataCollectionId,
        datasetId,
        visualizationId,
        annotationId,
        annotationFeatureTypeId,
        datasetVersion,
        defaultColorBy,
    } = payload;
    const frameKeyToUse = frameKey ?? page.currentFrame;
    const { visFrames } = page;
    const newVis = visualizeDataCollection(
        projectId,
        unsafeId<DataCollectionId>(dataCollectionId),
        unsafeId<DatasetId>(datasetId),
        visualizationId,
        datasetVersion,
        defaultColorBy,
        annotationId,
        annotationFeatureTypeId
    );
    if (!newVis) {
        return page;
    }
    if (!visFrames[frameKeyToUse]) {
        const numberOfVisualizations = Object.keys(visFrames).length;
        const newLayout = layoutMapArray[numberOfVisualizations]?.[0];
        return {
            ...page,
            layout: newLayout,
            visFrames: { ...visFrames, [frameKeyToUse]: newVis },
        };
    }
    // if a frame is synced we need to unsync it when switching datasets
    const oldDatasetId = visFrames[frameKeyToUse]?.datasetId;
    if (oldDatasetId && page.synced[oldDatasetId]?.includes(frameKeyToUse)) {
        return removeFrameSync(
            {
                ...page,
                visFrames: { ...visFrames, [frameKeyToUse]: newVis },
            },
            { frameKey: frameKeyToUse }
        );
    }
    return {
        ...page,
        visFrames: { ...visFrames, [frameKeyToUse]: newVis },
    };
}

export function setVisualization(vis: VisSettings, payload: { visualizationId: VisualizationId }): VisSettings {
    const { visualizationId } = payload;
    return {
        ...vis,
        visualizationId,
    };
}

export function removeNumericFilter(vis: VisSettings, payload: { numericRefId: string }): VisSettings {
    const { numericRefId } = payload;
    const { quantitativeFilters } = vis;
    const updated = omit(quantitativeFilters, numericRefId);
    return {
        ...vis,
        quantitativeFilters: updated,
    };
}

export function removeFilterCategory(vis: VisSettings, payload: { category: string | string[] }): VisSettings {
    const { category } = payload;
    const { camera, metadataFilters } = vis;
    const updated = { ...vis, metadataFilters: omit(metadataFilters, category) };
    if (isSlideCamera(camera) && category === camera.gridFeature) {
        // if we are removing all grid slide filters than all slides should be shown
        return {
            ...updated,
            camera: { ...recomputeSlideGridLayout(camera, updated.metadataFilters), hideUnselected: false },
        };
    }
    return updated;
}
/**
 * Toggle the given filter on or off for @param vis - note that this function is too low level to check to see if the
 * requested filter is valid for this visualization in any way.
 *
 * @param vis a visualization on an explore page
 * @param filter info about a filter
 * @returns a copy of vis in which the given filter has been toggled and sync has been broken if needed
 */
export function toggleFilter(vis: VisSettings, filter: { category: CategoryId; value: FilterValueId }) {
    // return frames with toggled filter
    const { category, value } = filter;
    const { metadataFilters, camera, vizControls } = vis;
    let updated = metadataFilters;
    if (metadataFilters[category] !== undefined) {
        const cat = metadataFilters[category];
        if (cat[value]) {
            // is truthy -> turn it off!
            // return { ...vis, metadataFilters: { ...metadataFilters, [category]: omit(cat, value) } };
            updated = { ...metadataFilters, [category]: omit(cat, value) };
        } else {
            // missing or falsy? turn it on!
            updated = { ...metadataFilters, [category]: { ...cat, [value]: true } };
        }
    } else {
        // else this is the first filter in this category...
        updated = { ...metadataFilters, [category]: { [value]: true } };
    }
    // if we uncheck the last slide on a slide camera we should show all slides
    const isLastSlideUnchecked =
        isSlideCamera(camera) && !Object.values(updated?.[camera?.gridFeature] ?? {}).some((v) => v);
    const newCamera = isSlideCamera(camera)
        ? {
              ...recomputeSlideGridLayout(camera, updated),
              hideUnselected: isLastSlideUnchecked ? false : camera.hideUnselected,
          }
        : camera;

    const newSelectionControls =
        isSlideCamera(camera) && isSlideCamera(newCamera)
            ? moveSlidesUnderSelectedRegion(vizControls, camera, newCamera)
            : vizControls;
    return {
        ...vis,
        metadataFilters: updated,
        camera: newCamera,
        vizControls: newSelectionControls,
    };
}

export function setFilterByCategory(
    vis: VisSettings,
    filter: { category: CategoryId; values: { value: string }[] }
): VisSettings {
    // erase any existing values in the given category:
    const { category, values } = filter;
    const filterColumn: Record<FilterValueId, boolean> = {};
    values.forEach((filterRow) => {
        filterColumn[filterRow.value] = true;
    });

    const { metadataFilters } = vis;
    return {
        ...vis,
        metadataFilters: {
            ...metadataFilters,
            [category]: filterColumn,
        },
    };
}
/**
 * update our page layout to prevent bubbles or gaps - for example a layout like {a: X, c: Y} would have
 * later visualizations moved down resulting in {a:X, b:Y}
 * @param layout the current layout
 * @returns a new layout, containing all previous visualizations, but re-organized to prevent gaps
 */
export function compactLayout(layout: VisFrameLayout) {
    const order = ['a', 'b', 'c', 'd'] as const;
    const existing = order.reduce((acc, id) => {
        const vis = layout[id];
        return vis !== undefined ? [...acc, vis] : acc;
    }, [] as ReadonlyArray<VisSettings>);
    if (existing.length === MAX_VISUALIZATIONS_PER_PAGE) {
        return layout;
    }
    return existing.reduce((acc, vis, index) => ({ ...acc, [order[index]]: vis }), {} as VisFrameLayout);
}

/**
 * Show hide left sidebar
 * @param page the page to change
 * @param showSidebar boolean to determine showing of sidebar
 * @returnsa copy of @param page in which the sidebar state is changed to hide or show
 */
export function allowSidebarResize(page: ExplorePage, payload: { allowSidebarResizing: boolean }) {
    const { allowSidebarResizing } = payload;
    return { ...page, allowSidebarResize: allowSidebarResizing ?? true };
}

/**
 *
 * @param page the page to change
 * @param frameKey the frame to select
 * @returns a copy of @param page in which the given frameKey is selected, if that key would be valid - unmodified @param page otherwise
 */
export function pickFrame(page: ExplorePage, payload: { frameKey: string }) {
    const { frameKey } = payload;
    if (has(page.visFrames, frameKey)) {
        return { ...page, currentFrame: frameKey as FrameKey };
    }
    return page;
}

/**
 *
 * @param page
 * @returns a copy of page, with a valid 'current frame'. note that if page has no frames at all, we default to frame 'a'
 */
export function enforceValidSelection(page: ExplorePage) {
    if (selectCurrentVis(page) === undefined) {
        return { ...page, currentFrame: (Object.keys(page.visFrames)[0] as FrameKey) ?? 'a' };
    }
    return page;
}

export function removeFramesFromSync(page: ExplorePage, frames: string[]): Record<DatasetId, FrameKey[]> {
    const datasetIdsByVisFrameKey = Object.keys(page.visFrames)?.reduce(
        (acc, visFrameKey: FrameKey) => ({ ...acc, [visFrameKey]: page.visFrames[visFrameKey].datasetId }),
        {} as Record<FrameKey, DatasetId>
    );
    return Object.entries(datasetIdsByVisFrameKey).reduce((acc, [visFrameKey, datasetId]: [FrameKey, DatasetId]) => {
        // if the frame is synced and it is a frame we are removing then we need to remove it from the synced state
        if (page.synced[datasetId]?.includes(visFrameKey) && frames.includes(visFrameKey)) {
            return { ...acc, [datasetId]: page.synced[datasetId]?.filter((key) => key !== visFrameKey) };
        }
        return acc;
    }, page.synced);
}

export function removeFrames(page: ExplorePage, frames: string[]) {
    return enforceValidSelection({
        ...page,
        synced: removeFramesFromSync(page, frames),
        visFrames: compactLayout(omit(page.visFrames, ...frames)),
    });
}

export function reorderFrames(page: ExplorePage, payload: { newFrameOrder: string[] }) {
    const { newFrameOrder } = payload;
    const { visFrames, currentFrame } = page;
    const newCurrentFrameIndex = newFrameOrder.indexOf(currentFrame);
    const newCurrentFrame = layoutKeyMap[newCurrentFrameIndex] as FrameKey;
    const newVisFrames = {} as VisFrameLayout;
    newFrameOrder.forEach((newFrameKey, index) => {
        newVisFrames[layoutKeyMap[index] as FrameKey] = visFrames[newFrameKey as FrameKey];
    });
    return { ...page, visFrames: newVisFrames, currentFrame: newCurrentFrame };
}
/**
 * private helper function for producing Filter arguments for the explore page, suitable for
 * passing to a query
 * @param category the filter category (referenceId of a feature type?) eg. ("genotype" but really "ALDKREHALKD03")
 * @param value the fitler value - eg. "some_genotype"
 * @returns a FilterArgument, representing the idea that the given category/row pair has been selected by the user for filtering
 */
export function metadataFilterArg(category: CategoryId, value: FilterValueId): FilterArgument {
    return {
        field: category,
        operator: Operator.EQ, // TODO this might not be right in all cases on the explore page - TODO
        value,
    };
}

export function getGeneFilters(quantFilters: QuantitativeFilters) {
    return pickBy(quantFilters, (filter) => filter.type === CellFilterType.Gene) as Record<string, GeneFilter>;
}

// This helper is used as a temporal middleman between the existing app and new genes UX
// the existing app only supports single gene expression as filter
// todo: remove this when the app supports multiple genes
export function getFirstGeneFilter(quantFilters: QuantitativeFilters): GeneFilter {
    const genes = getGeneFilters(quantFilters);
    return Object.values(genes)?.[0];
}

export function getFirstQuantFilter(quantFilters: QuantitativeFilters): QuantitativeFilter {
    return Object.values(quantFilters)?.[0];
}

export function getNumericFilters(quantFilters: QuantitativeFilters) {
    return pickBy(quantFilters, (filter) => filter.type === CellFilterType.Metadata) as Record<string, NumericFilter>;
}

/**
 * private helper function for producing Filter arguments for the explore page, suitable for
 * passing to a query
 * @param gene a geneExpressionFilter object, to be converted into a FilterArgument
 * @returns a FilterArgument, suitable for use in a query, that will filter based on the given gene
 */
export function geneFilterArg(gene: GeneFilter): GeneExpressionFilterArgument | undefined {
    const { selectedRange } = gene;
    if (!selectedRange) {
        return undefined;
    }
    return {
        field: GENE_EXPRESSION_CATEGORY,
        value: [selectedRange.min, selectedRange.max].toString(),
        operator: Operator.BETWEEN,
        index: `${gene.symbol}${FILTER_FIELD_QUERY_DELIMITER}${gene.index}`,
        geneIndex: gene.index,
        geneName: gene.symbol,
        selectedRange,
    };
}

export function numericFilterArg(filter: NumericFilter): NumericFilterArgument | undefined {
    const { selectedRange } = filter;
    if (!selectedRange) {
        return undefined;
    }
    return {
        field: filter.value,
        value: [selectedRange.min, selectedRange.max].toString(),
        operator: Operator.BETWEEN,
        selectedRange,
    };
}

export function computeFilterArgs(
    metadataFilters: Record<string, FilterSelectionState>,
    numericFilters: Record<string, NumericFilter>,
    geneExpression?: GeneFilter
) {
    // TODO: add support for computing multiple gene filters when the app supports it
    //       (API already support multiple gene filter ranges)
    const args: FilterArgument[] = [];
    Object.keys(metadataFilters).forEach((category: CategoryId) => {
        Object.keys(metadataFilters[category]).forEach((value: FilterValueId) => {
            const filterWithMe = metadataFilters[category][value] ?? false;
            if (filterWithMe) {
                args.push(metadataFilterArg(category, value));
            }
        });
    });

    Object.keys(numericFilters).forEach((filterKey) => {
        const filterArg = numericFilterArg(numericFilters[filterKey]);
        if (filterArg) {
            args.push(filterArg);
        }
    });

    if (geneExpression && geneExpression.selectedRange) {
        const filterArg = geneFilterArg(geneExpression);
        if (filterArg) {
            args.push(filterArg);
        }
    }
    return args;
}
export function filterArgsForVisualization(vis: VisSettings) {
    const { metadataFilters, quantitativeFilters } = vis;
    const geneFilter = getGeneFilters(quantitativeFilters);
    const numericFilters = getNumericFilters(quantitativeFilters);
    // TODO: exisitng app only support one gene filter for Scatterbrain
    const singleGeneFilter = Object.values(geneFilter)?.[0];
    return computeFilterArgs(metadataFilters, numericFilters, singleGeneFilter);
}

/**
 * remove the given frame from the given page. compact subsequent frames, shifting them down (delete b from a,b,c,d --> a,b,c)
 * in the even that the current selected frame is no longer valid, select frame a
 * @param page the page from which to remove the frame
 * @param frame the key of the frame to remove
 * @returns the updated page
 */
export function removeFrame(page: ExplorePage, frame: FrameKey) {
    return removeFrames(removeFrameSync(page, { frameKey: frame }), [frame]);
}
/**
 *
 * @param page the page to alter
 * @param layout the desired layout for the page
 * @returns a copy of page, either cloning the current frame if more frames need to be added, or removing frames (from the back) if the new layout requires fewer
 */
export function updateLayout(page: ExplorePage, payload: { layout: Layout }) {
    const { layout } = payload;
    const layoutGroups = Object.keys(frameCountByLayout);
    const newLayoutGroup = layoutGroups.find((group) => layout.includes(group)); // Note - its a bit risky to build logic off of the display string, cant localize, cosmetic changes would break functionality, etc...
    const allPossibleFramesInOrder = ['a', 'b', 'c', 'd'] as const;

    const updatedNumberOfFrames = frameCountByLayout[newLayoutGroup as keyof typeof frameCountByLayout];
    const curFrameKeys = Object.keys(page.visFrames);
    const curFrames = curFrameKeys.length;
    if (updatedNumberOfFrames > curFrames) {
        const availableSpots = allPossibleFramesInOrder.filter((k) => !curFrameKeys.includes(k));
        // clone the selected frame
        const copies = updatedNumberOfFrames - curFrames;
        const cloneMe = selectCurrentVis(page);
        if (cloneMe) {
            for (let i = 0; i < copies; i += 1) {
                page = { ...page, visFrames: { ...page.visFrames, [availableSpots[i]]: cloneMe } };
            }
        }
    } else if (updatedNumberOfFrames < curFrames) {
        // remove frames... in the order of d c b a ?
        const howManyToRemove = curFrames - updatedNumberOfFrames;
        const removable = allPossibleFramesInOrder.filter((k) => curFrameKeys.includes(k));
        const toRemove = removable.slice(removable.length - howManyToRemove);
        page = removeFrames(page, toRemove);
    }
    return { ...page, layout };
}

export function duplicateFrame(page: ExplorePage, payload: { frameKey: FrameKey }) {
    const { frameKey } = payload;
    const { visFrames } = page;
    const numberOfVisualizations = Object.keys(visFrames).length;
    const orderArray = ['a', 'b', 'c', 'd'];
    const newFrameId = orderArray[numberOfVisualizations];
    const newLayout = layoutMapArray[numberOfVisualizations]?.[0];
    return { ...page, layout: newLayout, visFrames: { ...visFrames, [newFrameId]: visFrames[frameKey] } };
}

export function setTransparency(vis: VisSettings, payload: { transparency: number }): VisSettings {
    const { transparency } = payload;
    return {
        ...vis,
        colorProperties: { ...vis.colorProperties, transparency },
    };
}

export function setUseTransparentFilteredPoints(vis: VisSettings, payload: { isTransparent: boolean }): VisSettings {
    const { isTransparent } = payload;
    return {
        ...vis,
        colorProperties: { ...vis.colorProperties, isTransparent },
    };
}

export function setRenderQuality(vis: VisSettings, payload: { quality: 1 | 2 | 3 | 4 }): VisSettings {
    const { quality } = payload;
    return {
        ...vis,
        aesthetic: {
            ...vis.aesthetic,
            performanceVsQuality: quality,
        },
    };
}
export function setPointSizeScale(vis: VisSettings, payload: { factor: number }): VisSettings {
    const { factor } = payload;

    return {
        ...vis,
        aesthetic: {
            ...vis.aesthetic,
            pointSizeScale: Math.max(0.2, Math.min(100, Math.abs(factor))),
        },
    };
}

const ONE_MILLION = 1_000_000;
// not the most elegant function, but it will do for now
export function computeDefaultQuality(numCellsInDataset: number | undefined): 1 | 2 | 3 | 4 {
    if (numCellsInDataset === undefined) return 2; // no idea, and it does not matter right now

    if (numCellsInDataset < ONE_MILLION) return 4;
    if (numCellsInDataset < ONE_MILLION * 2) return 3;
    if (numCellsInDataset < ONE_MILLION * 3) return 2;

    return 1;
}
export function pixelLODThreshold(quality: 1 | 2 | 3 | 4) {
    // ultra quality: draw a node if its bigger than 4x4 pixels
    // ...
    // lowest quality: draw a node if its bigger than 22x22 pixels
    return 4 + (4 - quality) * 6;
}

// TODO when multiple quant filters are allowed uncomment below code
export function setQuantitativeFilter(vis: VisSettings, payload: { id: string; filter: GeneFilter | NumericFilter }) {
    // const { quantitativeFilters } = vis;
    const { id, filter } = payload;

    return {
        ...vis,
        quantitativeFilters: {
            // ...quantitativeFilters,
            [id]: filter,
        },
    };
}

export function toggleExcludeZeros(vis: VisSettings) {
    if (vis.colorProperties.type !== ColorByType.quantitative) {
        return vis;
    }

    return {
        ...vis,
        colorProperties: {
            ...vis.colorProperties,
            color: {
                ...vis.colorProperties.color,
                excludeZeros: !vis.colorProperties.color?.excludeZeros ?? false,
            },
        },
    };
}

export function toggleInverseColor(vis: VisSettings) {
    if (vis.colorProperties.type !== ColorByType.quantitative) {
        return vis;
    }

    return {
        ...vis,
        colorProperties: {
            ...vis.colorProperties,
            color: {
                ...vis.colorProperties.color,
                invertMap: !vis.colorProperties.color?.invertMap ?? false,
            },
        },
    };
}

export function setNullColoring(vis: VisSettings, payload: { nullColoring: NullColoring }) {
    if (vis.colorProperties.type !== ColorByType.quantitative) {
        return vis;
    }

    return {
        ...vis,
        colorProperties: {
            ...vis.colorProperties,
            color: {
                ...vis.colorProperties.color,
                nullColoring: payload.nullColoring,
            },
        },
    };
}

export function removeQuantitativeFilter(vis: VisSettings, payload: { id: string }) {
    const { quantitativeFilters } = vis;
    const { id } = payload;

    return {
        ...vis,
        quantitativeFilters: {
            ...omit(quantitativeFilters, id),
        },
    };
}

export function clearGeneFilters(vis: VisSettings) {
    const { quantitativeFilters } = vis;
    const geneFilterIds = Object.keys(quantitativeFilters).filter(
        (id) => quantitativeFilters[id].type === CellFilterType.Gene
    );

    return {
        ...vis,
        quantitativeFilters: {
            ...omit(quantitativeFilters, geneFilterIds),
        },
    };
}

export function clearNumericFilters(vis: VisSettings) {
    const { quantitativeFilters } = vis;
    const numericFilterIds = Object.keys(quantitativeFilters).filter(
        (id) => quantitativeFilters[id].type === CellFilterType.Metadata
    );

    return {
        ...vis,
        quantitativeFilters: {
            ...omit(quantitativeFilters, numericFilterIds),
        },
    };
}

export function clearQuantitativeFilters(vis: VisSettings) {
    return {
        ...vis,
        quantitativeFilters: {},
    };
}

export function colorVisBy(
    vis: VisSettings,
    settings:
        | {
              type: typeof ColorByType.quantitative;
              index?: number;
              value: string;
              range: Interval;
              color?: NumericColor;
          }
        | { type: typeof ColorByType.metadata; category: string }
) {
    const { colorProperties } = vis;
    return { ...vis, colorProperties: { ...colorProperties, ...settings } };
}

export function colorVisByDefault(vis: VisSettings) {
    const { colorProperties, colorByDefault } = vis;
    return {
        ...vis,
        colorProperties: {
            ...colorProperties,
            type: ColorByType.metadata,
            category: colorByDefault,
        },
    };
}

/**
 * private helper - get either the geneFilter, or the metadataFilter[category]
 * @param ux the ux from which to get the requested category
 * @param category the metadata category to request, or 'Gene Expression'
 * @returns the requested category, or undefined
 */
function getUxCategory(ux: VisSettings['ux'], category: string) {
    return ux.metadataFilters[category];
}
/**
 * private helper to set the given ux[category] to equal @param state
 * @param ux the ux state to return a shallow modified copy of
 * @param category the category to set
 * @param state the new desired value for the category
 * @returns a new ux object, with the state set at the given category (supports gene expression). note that this will create the category if it did not exist
 */
function setUxCategory(ux: VisSettings['ux'], category: string, state: FilterCategoryUxState) {
    return { ...ux, metadataFilters: { ...ux.metadataFilters, [category]: state } };
}
/**
 * typesafe helper for setting simple keys on FilterCategoryUxstate
 * @param uxState a filter category to modify
 * @param key its subkey to set
 * @param value the value to set at the given key
 * @returns a shallow copy of the given state, with [key]:value set. note that passing undefined as @param uxState will return a Partial<FilterCategoryUxState>
 */
function setFilterUx<K extends keyof FilterCategoryUxState>(
    uxState: FilterCategoryUxState,
    key: K,
    value: FilterCategoryUxState[K]
) {
    return {
        ...uxState,
        [key]: value,
    };
}
function setShowSearch(ux: VisSettings['ux'], category: string, show: boolean) {
    return setUxCategory(ux, category, setFilterUx(getUxCategory(ux, category), 'showSearch', show));
}
function showCategory(ux: VisSettings['ux'], category: string, visible: boolean) {
    return setUxCategory(ux, category, setFilterUx(getUxCategory(ux, category), 'showCategory', visible));
}
function toggleExpanded(ux: VisSettings['ux'], category: string) {
    const filterState = getUxCategory(ux, category);
    return setUxCategory(ux, category, setFilterUx(filterState, 'isExpanded', !(filterState?.isExpanded ?? false)));
}
function setExpanded(ux: VisSettings['ux'], category: string, expanded: boolean) {
    const filterState = getUxCategory(ux, category);
    return setUxCategory(ux, category, setFilterUx(filterState, 'isExpanded', expanded));
}

export function setSearchString(ux: VisSettings['ux'], category: string, search: string) {
    return setUxCategory(ux, category, setFilterUx(getUxCategory(ux, category), 'searchText', search));
}

function updateUx(vis: VisSettings, ux: Partial<VisSettings['ux']>) {
    return { ...vis, ux: { ...vis.ux, ...ux } };
}
/**
 * @returns the property value in the colorProperties object that is generally used as an reference identifier
 *          the property key name is different depending on the type of colorProperties
 */
export function getColorPropertyRefValue(
    colorProperties: MetadataColorProperties | QuantitativeColorProperties
): string {
    const { type } = colorProperties; // needed to create a reference so TS won't complain
    switch (type) {
        case ColorByType.quantitative:
            return colorProperties.value;
        case ColorByType.metadata:
            return colorProperties.category;
        default:
            return exhaustiveSwitchGuard(type);
    }
}
export function toggleFilterCategoryExpanded(vis: VisSettings, payload: { panelId: string }) {
    const category = payload.panelId;
    return updateUx(vis, toggleExpanded(vis.ux, category));
}
export function setSearchText(vis: VisSettings, settings: { category: string; search: string }) {
    return updateUx(vis, setSearchString(vis.ux, settings.category, settings.search));
}

export function setFocusedPanel(vis: VisSettings, payload: { panelId: string | null }) {
    return updateUx(vis, { ...vis.ux, focusedPanel: payload.panelId });
}
export function setShowSearchText(vis: VisSettings, settings: { category: string; show: boolean }) {
    return updateUx(vis, setShowSearch(vis.ux, settings.category, settings.show));
}
export function setDisableClose(vis: VisSettings, payload: { disable: boolean }) {
    return updateUx(vis, { ...vis.ux, disableClose: payload.disable });
}
// Reset other accordion properties when showing/hiding filter categories
export function setShowCategory(vis: VisSettings, settings: { category: string; show: boolean }) {
    const { category, show } = settings;
    const collapsed = updateUx(vis, setExpanded(vis.ux, settings.category, false));
    const clearedSearchAndCollapsed = setSearchText(collapsed, { category, search: '' });
    const clearedCollapsedSearchHidden = setShowSearchText(clearedSearchAndCollapsed, { category, show: false });
    return updateUx(clearedCollapsedSearchHidden, showCategory(clearedCollapsedSearchHidden.ux, category, show));
}

export function setSelectedCellProperties(
    vis: VisSettings,
    cellInfo: undefined | { value: string; property: string }
): VisSettings {
    if (cellInfo) {
        return updateUx(vis, { ...vis.ux, selectedCell: cellInfo });
    }
    return { ...vis, ux: { ...omit(vis.ux, 'selectedCell'), selectedCell: undefined } };
}

// Genes UX

function setGeneUx(vis: VisSettings, uxState: Partial<GenesUxState>) {
    const { ux } = vis;
    return updateUx(vis, {
        genes: {
            ...ux.genes,
            ...uxState,
        },
    });
}

function setGeneSymbolUx(vis: VisSettings, symbol: string, uxState: Partial<Omit<GeneSymbolUx, 'symbol'>>) {
    const { ux } = vis;
    const existingGeneUx = ux.genes?.symbols[symbol];

    if (!existingGeneUx) {
        return vis;
    }

    return updateUx(vis, {
        genes: {
            ...ux.genes,
            symbols: {
                ...ux.genes?.symbols,
                [symbol]: {
                    ...existingGeneUx,
                    ...uxState,
                },
            },
        },
    });
}

export function addGeneUx(vis: VisSettings, payload: { symbols: string[] }) {
    const { ux } = vis;
    const { symbols } = payload;

    // filter the payload symbols to only new symbols that are not in the existing genes ux
    const newSymbols = symbols.filter((gene) => !Object.keys(ux.genes.symbols ?? {}).includes(gene));
    const newGenes = newSymbols.reduce(
        (acc, symbol) => ({
            ...acc,
            [symbol]: {
                symbol,
                isExpanded: false,
            },
        }),
        {}
    );
    return setGeneUx(vis, {
        canShowDefaultGene: false, // no longer need to show default gene after a gene has been added
        symbols: {
            ...ux.genes.symbols,
            ...newGenes,
        },
    });
}

export function removeGeneUx(vis: VisSettings, payload: { symbol: string }) {
    const { ux, colorProperties } = vis;
    const { symbol } = payload;
    // fallback to default color by if the gene being removed is current color by
    const isColorByCurrentGene = colorProperties.type === ColorByType.quantitative && colorProperties.value === symbol;
    return setGeneUx(isColorByCurrentGene ? colorVisByDefault(vis) : vis, {
        // if user had cleared the panel with existing gene(s), we should set the default gene flag to false to prevent
        // another default gene to automatically populate the panel
        canShowDefaultGene: false,
        symbols: omit(ux.genes?.symbols, symbol),
    });
}

export function clearGeneUx(vis: VisSettings) {
    const { colorProperties } = vis;
    const isColorByQuantitative = colorProperties.type === ColorByType.quantitative;
    return setGeneUx(isColorByQuantitative ? colorVisByDefault(vis) : vis, {
        canShowDefaultGene: false, // see comment in removeGeneUx
        symbols: {},
    });
}

export function setNotFoundGenes(vis: VisSettings, payload: { notFoundGenes: string[] }) {
    const { notFoundGenes } = payload;
    return setGeneUx(vis, { notFoundGenes });
}

export function toggleGeneAccordion(vis: VisSettings, payload: { symbol: string }) {
    const { ux } = vis;
    const { symbol } = payload;
    const existingGeneUx = ux.genes?.symbols[symbol];

    return setGeneSymbolUx(vis, symbol, {
        isExpanded: !(existingGeneUx?.isExpanded ?? false),
    });
}

/// camera stuff ///
export function advanceVisibleSlides(vis: VisSettings, payload: { indexOffset: number }) {
    const { camera, metadataFilters, vizControls } = vis;
    const { indexOffset } = payload;
    if (!isSlideCamera(camera) || !camera) {
        return vis;
    }

    const { availableSlides, hideUnselected, gridFeature } = camera;

    if (!camera || availableSlides.length < 1 || indexOffset === 0) return vis;

    const isSelected = (value: string) => !!metadataFilters[gridFeature][value];

    const totalSlides = hideUnselected
        ? availableSlides.filter((slide) => isSelected(slide.value)).length
        : availableSlides.length;

    const newOffset = (camera.slideOffsetIndex + indexOffset) % totalSlides;
    const newCam = recomputeSlideGridLayout({ ...camera, slideOffsetIndex: newOffset }, metadataFilters);
    return {
        ...vis,
        vizControls: moveSlidesUnderSelectedRegion(vizControls, camera, newCam),
        camera: newCam,
    };
}
function sortSlides(camera: SlideViewCamera) {
    return { ...camera, availableSlides: sortBy(camera.availableSlides, 'priorityOrder') };
}
// update the selection box in camera space, assuming the slide to which it refers has moved!
function moveSelectedRegionToFollowSlide(
    viz: VizControlsState,
    oldCamera: SlideViewCamera,
    newCamera: SlideViewCamera
): VizControlsState {
    // first, get the box, relative to the slide
    const { slideId, selection } = viz;
    if (slideId && selection.start && selection.end) {
        const { layout: oldLayout, slideBounds } = oldCamera;
        const newLayout = newCamera.layout;
        const oldIndex = oldLayout[slideId];
        const newIndex = newLayout[slideId];
        if (!newIndex || !oldIndex) {
            // if the slide wasn't visible or wont be,
            // just delete it!
            return { ...viz, slideId: null, box: null, selection: null, selectionOffset: null };
        }
        const oldOffset = Vec2.mul(oldIndex, Box2D.size(slideBounds));
        const newOffset = Vec2.mul(newIndex, Box2D.size(slideBounds));
        const camSpaceBox = createBoxFromSelection(selection, slideId, oldCamera);
        const newBox = Box2D.translate(camSpaceBox, Vec2.sub(newOffset, oldOffset));
        return {
            ...viz,
            selection: {
                start: newBox.minCorner,
                end: newBox.maxCorner,
            },
            box: newBox,
            selectionOffset: null,
        };
    }
    return viz;
}
// when a slide-view camera's layout changes, we may need to update the cellSelection slide-id - the goal
// is to keep the cell-selection box in the same place (from the perspective of the user), but have the slides
// in the dynamic grid appear to move underneath that selection-region
function moveSlidesUnderSelectedRegion(
    viz: VizControlsState,
    oldCamera: SlideViewCamera,
    newCamera: SlideViewCamera
): VizControlsState {
    const { slideId } = viz;
    let slideWithSelectionIndex = -1;
    if (slideId) {
        // advance this one too!
        // find the index of the slide we're selecting on
        slideWithSelectionIndex = oldCamera.availableSlides.findIndex((slide) => slide.id === slideId);
    }
    return {
        ...viz,
        slideId: slideWithSelectionIndex > -1 ? newCamera.availableSlides[slideWithSelectionIndex].id : slideId,
    };
}
export function setShowUnselectedSlides(vis: VisSettings, payload: { show: boolean }) {
    const { camera, vizControls } = vis;
    const { show } = payload;
    if (!isSlideCamera(camera)) {
        return vis;
    }
    if (show === true) {
        // the user may have re-ordered a sub-set of slides... re-sort them by priority order!
        // we might want to move the selection box to be where the selected slide ends up...
        const newCamera = recomputeSlideGridLayout(
            sortSlides({ ...camera, hideUnselected: !show, slideOffsetIndex: 0 }),
            vis.metadataFilters
        );
        return {
            ...vis,
            vizControls: moveSelectedRegionToFollowSlide(vizControls, camera, newCamera),
            camera: newCamera,
        };
    }
    const newCamera = recomputeSlideGridLayout(
        { ...camera, hideUnselected: !show, slideOffsetIndex: 0 },
        vis.metadataFilters
    );
    return { ...vis, camera: newCamera, vizControls: moveSelectedRegionToFollowSlide(vizControls, camera, newCamera) };
}
function reorderArrayBasedOnOffset(slides: GridSlide[], offsetIndex: number) {
    const part1 = slides.slice(offsetIndex);
    const part2 = slides.slice(0, offsetIndex);
    return [...part1, ...part2];
}

function computeSlideOrderOffset(availableSlides: ReadonlyArray<GridSlide>, offsetIndex: number) {
    // if there is no offset then just return slides in default order
    if (offsetIndex === 0) {
        return availableSlides;
    }
    const slideClone = [...availableSlides];
    return reorderArrayBasedOnOffset(slideClone, offsetIndex);
}

function computeVisibleSlides(
    camera: SlideViewCamera,
    metadataFilters: Record<CategoryId, FilterSelectionState>
): ReadonlyArray<GridSlide> {
    // nothing has been selected, thus everything is "filtered in"
    if (!camera.hideUnselected || Object.keys(metadataFilters[camera.gridFeature] ?? {}).length < 1) {
        return camera.availableSlides;
    }
    return camera.availableSlides.filter((slide) => metadataFilters[camera.gridFeature][slide.value]);
}
export function recomputeSlideGridLayout<T extends ScatterbrainCamera>(
    camera: T,
    metadataFilters: Record<CategoryId, FilterSelectionState>
): T {
    if (!isSlideCamera(camera)) {
        return camera;
    }
    return {
        ...camera,
        layout: slideGridLayout(
            computeSlideOrderOffset(computeVisibleSlides(camera, metadataFilters), camera.slideOffsetIndex)
        ),
    };
}

/**
 * Helper function to sync all the cameras on a page that belong to the same syncing group.
 * The update function is called on all cameras and the result is set as that camera's new state in Redux.
 *
 * @param page The explore page slice
 * @param frameKey The frame the user is currently interacting with
 * @param update The camera update function. Gives you a camera and you manipulate it as needed, returning the new camera
 * @returns The new page state with all the cameras in the same sync group updated
 */
const syncAllCameras = (
    page: ExplorePage,
    frameKey: FrameKey,
    update: (syncingCamera: ScatterbrainCamera) => ScatterbrainCamera
): ExplorePage => {
    const { visFrames } = page;
    const vis = visFrames[frameKey];
    const { camera } = vis;
    if (!camera) return page;
    const shouldHandleSyncedFrames =
        page.synced[vis.datasetId]?.length > 0 && page.synced[vis.datasetId]?.includes(frameKey);
    // Get the synced state for all the frames that are synced and have the same datasetId, but only if this camera is synced
    const syncedFrames = shouldHandleSyncedFrames
        ? Object.entries(visFrames).filter(
              ([key]) => page.synced[vis.datasetId].find((givenKey) => givenKey === key) && key !== frameKey
          )
        : [];

    return {
        ...page,
        visFrames: {
            ...visFrames,
            // Update the camera for all the synced frames
            ...syncedFrames.reduce((visAcc, [key, v]) => {
                const { camera: syncingCamera } = v;
                if (!syncingCamera) return visAcc;
                return {
                    ...visAcc,
                    [key]: {
                        ...v,
                        camera: update(syncingCamera),
                    },
                };
            }, {}),
            [frameKey]: {
                ...vis,
                camera: update(camera),
            },
        },
    };
};

export function pan(
    page: ExplorePage,
    payload: { frameKey: FrameKey; mouseMovement: { movementX: number; movementY: number }; screenSize: vec2 }
): ExplorePage {
    const { visFrames } = page;
    const vis = visFrames[payload.frameKey];
    const { camera } = vis;
    const { mouseMovement, screenSize } = payload;

    const makeNewCamera = (syncingCamera: ScatterbrainCamera) => {
        // New center is calculated off of the camera being moved
        const newCenter = calculateDrag(mouseMovement, camera, screenSize, camera.projection);
        // But we preserve all other settings from the camera being manipulated by the update function
        return { ...syncingCamera, center: newCenter };
    };

    return syncAllCameras(page, payload.frameKey, makeNewCamera);
}

export function zoom(
    page: ExplorePage,
    payload: { frameKey: FrameKey; scale: number; mousePos: vec2; screenSize: vec2; limitHeight: Interval }
): ExplorePage {
    const { visFrames } = page;
    const vis = visFrames[payload.frameKey];
    const { camera } = vis;
    const { scale, mousePos, screenSize, limitHeight } = payload;

    const makeNewCamera = (syncingCamera: ScatterbrainCamera) => {
        // figure out the mouse position in data space for the camera being zoomed,
        // which will be passed to all cameras to determine their zoom level
        const locus = getDataSpaceCoordinateFromCanvas(mousePos, camera, screenSize);

        // Run the zoomCamera on the syncing camera with the lotus from the camera being zoomed
        return zoomCamera(syncingCamera, scale, locus, [limitHeight.min, limitHeight.max]);
    };

    return syncAllCameras(page, payload.frameKey, makeNewCamera);
}

export function resizeCamera(vis: VisSettings, payload: { viewSize: vec2 }): VisSettings {
    const { camera } = vis;
    const { viewSize } = payload;
    if (!camera) return vis;

    return { ...vis, camera: matchScreenAspectRatio(camera, viewSize) };
}

/**
 * Sets the camera, overwriting any existing camera
 */
export function setCamera(vis: VisSettings, payload: { camera: ScatterbrainCamera }): VisSettings {
    return {
        ...vis,
        camera: payload.camera,
    };
}
/**
 * Initializes the camera - just another name for setCamera
 */
export function initializeCamera(vis: VisSettings, payload: { camera: ScatterbrainCamera }): VisSettings {
    const { camera } = payload;
    if (isSlideCamera(camera)) {
        const { metadataFilters } = vis;
        return setCamera(vis, { camera: recomputeSlideGridLayout<SlideViewCamera>(camera, metadataFilters) });
    }
    return setCamera(vis, payload);
}

function removeFrameSync(page: ExplorePage, payload: { frameKey: FrameKey }) {
    const { frameKey } = payload;
    const { synced } = page;
    const vis = page.visFrames[frameKey];
    const { datasetId } = vis;
    if (!synced[datasetId]) return page;
    const newSynced = synced[datasetId].length === 2 ? [] : synced[datasetId].filter((key) => key !== frameKey);
    return { ...page, synced: { ...synced, [datasetId]: newSynced } };
}

export function toggleFrameSync(
    page: ExplorePage,
    payload: { currentFrame: FrameKey; checkedFrame: FrameKey; checkValue: boolean }
): ExplorePage {
    const { currentFrame, checkedFrame, checkValue } = payload;
    const { visFrames, synced } = page;
    const currentVisDatasetId = visFrames[currentFrame].datasetId;
    if (checkValue === false) {
        return removeFrameSync(page, { frameKey: currentFrame });
    }
    const newSyncedArrayForDataset = Array.from(
        new Set([...(synced[currentVisDatasetId] ?? []), checkedFrame, currentFrame])
    );
    return { ...page, synced: { ...synced, [currentVisDatasetId]: newSyncedArrayForDataset } };
}

// Viz Controls
export function setControlType(vis: VisSettings, payload: { controlType: VizControlType }) {
    const { vizControls } = vis;
    const { controlType } = payload;
    if (controlType === VizControl.pan) {
        return {
            ...vis,
            vizControls: DEFAULT_VIS_CONTROL_STATE,
        };
    }
    return { ...vis, vizControls: { ...vizControls, controlType } };
}
export function createBoxFromSelection(
    selection: CellSelectorSelection | null,
    slideId: SlideId | null,
    camera: ScatterbrainCamera
): box2D | null {
    if (!selection || !selection.start || !selection.end) {
        return null;
    }

    const box = Box2D.create(Vec2.min(selection.start, selection.end), Vec2.max(selection.start, selection.end));
    if (isSlideCamera(camera)) {
        if (slideId === null) {
            return box;
        }
        // keep everything inside the current slide
        const gridIndex = camera.layout[slideId];
        if (!gridIndex) return box;
        const slideBox = Box2D.translate(camera.slideBounds, Vec2.mul(gridIndex, Box2D.size(camera.slideBounds)));
        return Box2D.intersection(box, slideBox) ?? null;
    }
    return box;
}

export function setSelection(vis: VisSettings, payload: { partialSelection: Partial<CellSelectorSelection> }) {
    const { partialSelection } = payload;
    const { vizControls, camera } = vis;
    const newSelection: CellSelectorSelection = {
        ...vizControls.selection,
        ...partialSelection,
    };
    let { slideId } = vizControls;
    if (isSlideCamera(camera)) {
        if (partialSelection.start) {
            // what slide are we on?
            let worst: SlideId;
            let closestMidpointDistance = Number.POSITIVE_INFINITY;
            const best = camera.availableSlides.find((slide) => {
                const { id } = slide;
                const gridIndex = camera.layout[id];
                if (!gridIndex) return false;
                const slideBox = Box2D.translate(
                    camera.slideBounds,
                    Vec2.mul(gridIndex, Box2D.size(camera.slideBounds))
                );
                const midpoint = Box2D.midpoint(slideBox);
                const dst = Vec2.length(Vec2.sub(midpoint, partialSelection.start));
                if (dst < closestMidpointDistance) {
                    closestMidpointDistance = dst;
                    worst = id;
                }
                return Box2D.containsPoint(slideBox, partialSelection.start);
            });
            slideId = best?.id ?? worst ?? null;
        }
    }
    return { ...vis, vizControls: { ...vis.vizControls, selection: newSelection, slideId } };
}

export function setSelectionOffset(vis: VisSettings, payload: { startPosition: vec2 }) {
    const { startPosition } = payload;
    // NOTE: Selection box movement is based on the cursor location during the mousemove event
    //       the offset is based on the location of initial drag to the top left corner of the box
    //       this offset is needed when calculating the movement because user can initialize drag from anywhere within the box
    const { vizControls } = vis;
    const selectionOffset = Vec2.sub(startPosition, vizControls.box.minCorner);
    return { ...vis, vizControls: { ...vizControls, selectionOffset } };
}

export function moveSelection(vis: VisSettings, payload: { position: vec2 }) {
    const { vizControls } = vis;
    const { selectionOffset, box } = vizControls;
    if (!selectionOffset || !box) {
        return vis;
    }
    const { position } = payload;
    const start = Vec2.sub(position, selectionOffset);
    const end = Vec2.add(start, Box2D.size(box));
    return {
        ...vis,
        vizControls: {
            ...vizControls,
            selection: {
                start,
                end,
            },
        },
    };
}

export function resizeSelection(vis: VisSettings, payload: { position: vec2; cornerIndex: number }) {
    const { vizControls } = vis;
    const { box } = vizControls;

    if (!box) {
        return vis;
    }

    const { position, cornerIndex } = payload;
    const newBox = Box2D.setCorner(box, cornerIndex, position);

    return {
        ...vis,
        vizControls: {
            ...vizControls,
            selection: {
                start: newBox.minCorner,
                end: newBox.maxCorner,
            },
        },
    };
}

export function clearSelection(vis: VisSettings) {
    return { ...vis, vizControls: DEFAULT_VIS_CONTROL_STATE };
}

export function createBox(vis: VisSettings) {
    //    NOTE: This is intended for the selectFilterBox selector so that it could be created on a trigger
    //    selectSelectorBox will return updated box as the user draws the box, used in CellSelector and Scatterbrain
    //    selectFilterBox will return box when user finished drawing the box, used in ExploreContainer
    const { vizControls, camera } = vis;
    const { selection, slideId } = vizControls;
    const box = createBoxFromSelection(selection, slideId, camera);
    return { ...vis, vizControls: { ...vizControls, box } };
}

export function setPanEnabledWhileInSelectionMode(vis: VisSettings, payload: { isEnabled: boolean }) {
    const { vizControls } = vis;
    const { isEnabled } = payload;
    return { ...vis, vizControls: { ...vizControls, enableCameraPan: isEnabled } };
}

// Annotations

export function updateAnnotationState(
    vis: VisSettings,
    payload: {
        state: Partial<AnnotationsState>;
    }
): VisSettings {
    const { state } = payload;
    return {
        ...vis,
        annotations: {
            ...vis.annotations,
            ...state,
        },
    };
}

export function updateAnnotationColorPrefs(
    vis: VisSettings,
    payload: {
        attribute: AnnotationColorAttributeType;
        pref: Partial<Omit<AnnotationColorPreference, 'type'>>;
    }
): VisSettings {
    const { attribute, pref } = payload;
    return {
        ...vis,
        annotations: {
            ...vis.annotations,
            [attribute]: {
                ...vis.annotations[attribute],
                ...pref,
            },
        },
    };
}
