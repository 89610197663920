import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

import { RequireAuth } from '~/src/components/complex/mapping/auth/require-auth';
import { NotFound } from '../not-found';

const MappingProcess = lazy(() => import('./process'));
const MappingResults = lazy(() => import('./results'));

export const Mapping = () => (
    <Routes>
        <Route
            path="process/"
            element={<MappingProcess />}
        />
        <Route
            path="results/"
            element={
                <RequireAuth>
                    <MappingResults />
                </RequireAuth>
            }
        />
        <Route
            path="*"
            element={<NotFound />}
        />
    </Routes>
);
