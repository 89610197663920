import type { InfoPanelContent, InfoPanelContentSection } from '~/src/services/info-panel/types';
import { InfoPanelContentItem, InfoPanelResource } from '~/src/services/info-panel/namespace';
import {
    ABC_ATLAS_CITATION_URL,
    ABC_ATLAS_TOOL_CITATION,
    HELP_MENU_ALLEN_INSTITUTE_FOR_BRAIN_SCIENCE,
    HELP_MENU_ALLEN_INSTITUTE_FOR_BRAIN_SCIENCE_LINK,
    HELP_MENU_CITATION_POLICY,
    HELP_MENU_CITATION_POLICY_LINK,
    HELP_MENU_COMMUNITY_FORUM,
    HELP_MENU_COMMUNITY_FORUM_LINK,
    HELP_MENU_FEEDBACK,
    HELP_MENU_FEEDBACK_LINK,
    HELP_MENU_PRIVACY_POLICY,
    HELP_MENU_PRIVACY_POLICY_LINK,
    HELP_MENU_TERMS_OF_USE,
    HELP_MENU_TERMS_OF_USE_LINK,
} from '~/src/constants/strings';

const GLOBAL_HELP_CONTENT: InfoPanelContentSection = [
    {
        type: InfoPanelContentItem.header,
        text: 'Help',
    },
    // remove this ABC Atlas specific item when the help panel is integrated through out BKP
    {
        type: InfoPanelContentItem.resource,
        text: 'User Guide',
        url: 'https://community.brain-map.org/t/abc-atlas-user-guide/3179',
        resourceType: InfoPanelResource.link,
    },
    {
        type: InfoPanelContentItem.resource,
        text: HELP_MENU_ALLEN_INSTITUTE_FOR_BRAIN_SCIENCE,
        url: HELP_MENU_ALLEN_INSTITUTE_FOR_BRAIN_SCIENCE_LINK,
        resourceType: InfoPanelResource.link,
    },
    {
        type: InfoPanelContentItem.resource,
        text: HELP_MENU_FEEDBACK,
        url: HELP_MENU_FEEDBACK_LINK,
        resourceType: InfoPanelResource.link,
    },
    {
        type: InfoPanelContentItem.resource,
        text: HELP_MENU_COMMUNITY_FORUM,
        url: HELP_MENU_COMMUNITY_FORUM_LINK,
        resourceType: InfoPanelResource.link,
    },
];

const GLOBAL_TERMS_CONTENT: InfoPanelContentSection = [
    {
        type: InfoPanelContentItem.header,
        text: 'Terms',
    },
    {
        type: InfoPanelContentItem.resource,
        text: HELP_MENU_CITATION_POLICY,
        url: HELP_MENU_CITATION_POLICY_LINK,
        resourceType: InfoPanelResource.link,
    },
    {
        type: InfoPanelContentItem.resource,
        text: HELP_MENU_PRIVACY_POLICY,
        url: HELP_MENU_PRIVACY_POLICY_LINK,
        resourceType: InfoPanelResource.link,
    },
    {
        type: InfoPanelContentItem.resource,
        text: HELP_MENU_TERMS_OF_USE,
        url: HELP_MENU_TERMS_OF_USE_LINK,
        resourceType: InfoPanelResource.link,
    },
    {
        type: InfoPanelContentItem.custom,
        contentName: 'cookies-settings',
    },
];

const GLOBAL_COPYRIGHT_CONTENT: InfoPanelContentSection = [
    {
        type: InfoPanelContentItem.custom,
        contentName: 'copyright',
    },
];

const ABC_ATLAS_DESCRIPTION_CONTENT: InfoPanelContentSection = [
    {
        type: InfoPanelContentItem.paragraph,
        text: 'The Allen Brain Cell (ABC) Atlas provides a platform for visualizing  multimodal single cell data across the mammalian brain and aims to  empower researchers to explore and analyze multiple whole-brain datasets  simultaneously.',
    },
];

const ABC_ATLAS_CITATION_CONTENT: InfoPanelContentSection = [
    {
        type: InfoPanelContentItem.header,
        text: 'Citation',
    },
    {
        type: InfoPanelContentItem.citation,
        text: `${ABC_ATLAS_TOOL_CITATION}
        ${ABC_ATLAS_CITATION_URL}`,
    },
];

const ABC_ATLAS_SHORTCUTS_CONTENT: InfoPanelContentSection = [
    {
        type: InfoPanelContentItem.header,
        text: 'Keyboard Shortcuts',
    },
    {
        type: InfoPanelContentItem.custom,
        contentName: 'abc-atlas-shortcuts',
    },
];

// @todo remove ABC Atlas content from global info panel content when the help panel is integrated through out BKP
//       ABC Atlas specific content: User Guide, ABC_ATLAS_DESCRIPTION_CONTENT, ABC_ATLAS_CITATION_CONTENT, ABC_ATLAS_SHORTCUTS_CONTENT

export const createGlobalInfoPanelContent = (isAbcAtlasPage: boolean): InfoPanelContent => {
    if (isAbcAtlasPage) {
        return [
            ABC_ATLAS_DESCRIPTION_CONTENT,
            GLOBAL_HELP_CONTENT,
            ABC_ATLAS_CITATION_CONTENT,
            ABC_ATLAS_SHORTCUTS_CONTENT,
            GLOBAL_TERMS_CONTENT,
            GLOBAL_COPYRIGHT_CONTENT,
        ];
    }
    return [GLOBAL_HELP_CONTENT, GLOBAL_TERMS_CONTENT, GLOBAL_COPYRIGHT_CONTENT];
};
