import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { getURLString, URLConfig } from '../utils';

// NOTE: Consider using `useUpdateUrlQueryString` instead
// This hook feels like overkill since we only need to manipulate the search aspect of the query string
// for all our state-storing URL operations
export const useUpdateUrl = (config: URLConfig) => {
    const navigate = useNavigate();
    const nextUrl = getURLString(config);

    useEffect(() => {
        navigate(nextUrl, { replace: true });
    }, [navigate, nextUrl]);
};
