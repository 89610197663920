import { useCallback } from 'react';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { ErrorBoundary } from 'react-error-boundary';

import { createErrorText, createReadableApolloErrors } from '~/src/services/error/utils';
import { isApolloError } from '@apollo/client';
import { FatalErrorPage } from '../../pages/error';
import { errorSlice } from '../../services/error';

export const BkpErrorBoundary: React.FunctionComponent = ({ children }) => {
    const dispatch = useDispatch();
    const onError = useCallback(
        (error: Error, info: { componentStack: string }) => {
            dispatch(
                errorSlice.actions.logFatalError({
                    errorMessage: error.message,
                    errorName: error.name,
                    stack: error.stack,
                    componentStack: info.componentStack,
                    apolloErrorsText: isApolloError(error) ? createErrorText([createReadableApolloErrors(error)]) : '',
                })
            );
        },
        [dispatch]
    );

    return (
        <ErrorBoundary
            FallbackComponent={FatalErrorPage}
            onError={onError}
        >
            {children}
        </ErrorBoundary>
    );
};
