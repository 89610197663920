import { DATA_CATALOG_BKP } from '~/src/styles/constants/colors';
import type { AnimatedBarChartProps } from '~/src/components/ui/bar-chart';

export const BASE_PROPS = {
    limit: 5,
    durationMs: 200,
};
export const DEFAULT_CATEGORIES_CHART_CONFIG: Record<
    string,
    Pick<AnimatedBarChartProps, 'categoryName' | 'categoryTotalString' | 'color'>
> = {
    species: {
        categoryName: 'Species',
        categoryTotalString: 'Species',
        color: DATA_CATALOG_BKP.species,
    },
    modality: {
        categoryName: 'Modality',
        categoryTotalString: 'Modalities',
        color: DATA_CATALOG_BKP.modality,
    },
    technique: {
        categoryName: 'Technique',
        color: DATA_CATALOG_BKP.technique,
    },
};
