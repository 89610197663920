import * as React from 'react';
import Popover, { PopoverOrigin } from '@mui/material/Popover';

const anchorOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'left' };
const transformOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'center' };

export const BlockingNotification: React.FunctionComponent = ({ children }) => (
    <Popover
        open
        anchorEl={document.body}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
    >
        {children}
    </Popover>
);
