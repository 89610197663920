import has from 'lodash/has';
import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface FilterColumnSyncState {
    [key: string]: boolean;
}

export interface FilterColumnSyncPayload {
    key: string;
    synced: boolean;
}

const INITIAL_STATE: FilterColumnSyncState = {};

type InitFilterColumnSyncReducer = CaseReducer<FilterColumnSyncState, PayloadAction<FilterColumnSyncPayload>>;

const initFilterColumnSyncReducer: InitFilterColumnSyncReducer = (state, action) => {
    const { key, synced } = action.payload;

    if (has(state, key)) {
        return state;
    }

    return {
        ...state,
        [key]: synced,
    };
};

type SetFilterColumnSyncReducer = CaseReducer<FilterColumnSyncState, PayloadAction<FilterColumnSyncPayload>>;
const setFilterColumnSyncReducer: SetFilterColumnSyncReducer = (state, action) => {
    const { key, synced } = action.payload;

    return {
        ...state,
        [key]: synced,
    };
};

export const filterColumnSyncSlice = createSlice({
    name: 'filter-column-sync',
    initialState: INITIAL_STATE,
    reducers: {
        initFilterColumnSync: initFilterColumnSyncReducer,
        setFilterColumnSync: setFilterColumnSyncReducer,
    },
});

export type FilterColumnSyncSlice = typeof filterColumnSyncSlice;
