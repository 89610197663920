import { createAction, PayloadAction } from '@reduxjs/toolkit';
import TagManager from 'react-gtm-module';
import { GOOGLE_TAG_DATA_LAYER_NAME } from '../../../constants/analytics';
import { type RootState } from '../../redux';

import { createAnalyticsAction } from '../create-analytics-action';

export const PROPERTY_ADD = 'PROPERTY_ADD';
export const PROPERTY_REMOVE = 'PROPERTY_REMOVE';
export type ADD_REMOVE_TYPE = typeof PROPERTY_ADD | typeof PROPERTY_REMOVE;

interface SpecimensPropertyAddRemoveData {
    field: string;
    type: ADD_REMOVE_TYPE;
}

const analyticsAddAction = createAction(
    'gtm/specimens-property-add',
    (field: string): { payload: SpecimensPropertyAddRemoveData } => ({
        payload: {
            field,
            type: PROPERTY_ADD,
        },
    })
);

const analyticsRemoveAction = createAction(
    'gtm/specimens-property-remove',
    (field: string): { payload: SpecimensPropertyAddRemoveData } => ({
        payload: {
            field,
            type: PROPERTY_REMOVE,
        },
    })
);

const sendAnalyticsSpecimensPropertyAddRemove = (
    _state: RootState,
    action: PayloadAction<SpecimensPropertyAddRemoveData>
) => {
    const {
        type,
        payload: { field, type: value },
    } = action;

    const eventType = value === PROPERTY_ADD ? 'add' : 'remove';

    TagManager.dataLayer({
        dataLayerName: GOOGLE_TAG_DATA_LAYER_NAME,
        dataLayer: {
            event: `property-${eventType}`,
            actionType: type,
            field,
            value,
        },
    });
};

export const specimensPropertyAddAction = createAnalyticsAction(
    analyticsAddAction,
    sendAnalyticsSpecimensPropertyAddRemove
);

export const specimensPropertyRemoveAction = createAnalyticsAction(
    analyticsRemoveAction,
    sendAnalyticsSpecimensPropertyAddRemove
);
