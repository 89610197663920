import gql from 'graphql-tag';
import { FilterOperator, useProjectInfoQuery as query } from '~/src/types/generated-schema-types';
import { ProjectId } from '~/src/types/reference-ids';

export const projectInfoQuery = gql`
    fragment ProjectInfo on DataCollectionProject {
        referenceId
        title
        description
        capabilities
        dataCollection {
            webResources {
                type
            }
        }
        species {
            name
        }
        modality {
            name
        }
        technique {
            name
        }
        dataCreator {
            agentType
            organization {
                name
            }
            person {
                name
            }
        }
        highlightedWebResources {
            title
            type
            url
        }
        hasSpecimen
        protocol {
            title
            url
        }
        publication {
            title
        }
        subProgram {
            title
        }
    }

    query projectInfo($filter: [Filter!]) {
        dataCollectionProjectInventory(filter: $filter) {
            ...ProjectInfo
        }
    }
`;

export const useProjectInfoQuery = (ids: Array<ProjectId>) => {
    const filter = ids.map((id) => ({
        field: 'referenceId',
        operator: FilterOperator.Eq,
        value: id,
    }));

    return query({
        variables: {
            filter,
        },
    });
};
