import { partial } from 'lodash';
import { REMOVE_NON_INITIAL_GRAPHQL_UNSAFE_NAME_CHARS } from '~/src/constants/regex';
import { REF_ID_ALIAS_PREFIX } from '../../constants/strings';

/**
 * Provides a query-safe alias for a given string
 */
export const getQuerySafeAlias = (prefix: string, value: string) =>
    `${prefix}${value.replace(REMOVE_NON_INITIAL_GRAPHQL_UNSAFE_NAME_CHARS, '')}`;

/**
 * Query safe alias util, specifically for referenceId
 */
export const getRefIdAlias = partial(getQuerySafeAlias, REF_ID_ALIAS_PREFIX);

export function unRefIdAlias(refidAlias: string) {
    if (refidAlias.startsWith(REF_ID_ALIAS_PREFIX)) {
        return refidAlias.substring(REF_ID_ALIAS_PREFIX.length);
    }
    return refidAlias;
}
