export enum MappingWorkflowStatusEnum {
    INITIAL_STEP = 1,
    MAPPING_FILE_UPLOAD_IN_PROGRESS = 2,
    UPLOAD_FAILED_STEP = 3,
    UPLOAD_SUCCESS_STEP = 4,
    MAPPING_IN_PROGRESS_STEP = 5,
    MAPPING_FAILED_STEP = 6,
    MAPPING_SUCCESS_STEP = 7,
    MAPPING_CANCELLED_STEP = 8,
}
