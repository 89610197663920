import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';

import { ApplicationHeaderContainer } from '~/src/containers/application-header';
import { ErrorNotification } from '~/src/containers/error/error-notification';
import { FooterContainer } from '~/src/containers/footer-container';
import { BkpThemeProvider } from '../../styles/theme';
import { TermsOfUse } from '../../containers/terms-of-use';

import { StyledLayout, StyledHeaderWrapper, StyledMainContentWrapper, StyledFooterWrapper } from './styled';

export const Layout = () => (
    <StyledLayout>
        {/* separate out theme providers for header vs. main section for different theme libraries */}
        {/* @TODO: pass in isHeader prop to the header provider */}
        <BkpThemeProvider alwaysUseSds>
            <StyledHeaderWrapper>
                <ApplicationHeaderContainer />
            </StyledHeaderWrapper>
        </BkpThemeProvider>
        <BkpThemeProvider>
            <TermsOfUse />
            <ErrorNotification />
            <StyledMainContentWrapper id="main-content">
                {/* Top-level suspense for the pages of the application. If we want finer grain control over
                suspense loaders that can be added as needed. */}
                <Suspense fallback={<Skeleton />}>
                    <Outlet />
                </Suspense>
                <BkpThemeProvider alwaysUseSds>
                    <StyledFooterWrapper>
                        <FooterContainer />
                    </StyledFooterWrapper>
                </BkpThemeProvider>
            </StyledMainContentWrapper>
        </BkpThemeProvider>
    </StyledLayout>
);
