import { PayloadAction } from '@reduxjs/toolkit';
import TagManager from 'react-gtm-module';
import { FatalErrorInfo } from '~/src/types/error';
import { GOOGLE_TAG_DATA_LAYER_NAME } from '../../../constants/analytics';
import { errorSlice, selectFatalErrorMessage } from '../../error';
import { type RootState } from '../../redux';
import { createAnalyticsAction } from '../create-analytics-action';

const sendAnalyticsFatalError = (state: RootState, action: PayloadAction<FatalErrorInfo>) => {
    TagManager.dataLayer({
        dataLayerName: GOOGLE_TAG_DATA_LAYER_NAME,
        dataLayer: {
            event: 'Fatal Error',
            actionType: action.type,
            errorMessage: selectFatalErrorMessage(state),
        },
    });
};

export const fatalErrorAction = createAnalyticsAction(errorSlice.actions.logFatalError, sendAnalyticsFatalError);
