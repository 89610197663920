import { styled } from '@mui/material/styles';
import { Button } from '../../bkp-ui/button';

const StyledButton = styled(Button)(() => ({
    left: '30%',
    position: 'absolute',
    transform: 'translateY(-999%)',
    transition: 'transform 0.35s',
    '&:focus': {
        transform: 'translateY(-50%)',
    },
}));

export default function SkipContent() {
    return (
        <StyledButton
            sdsType="primary"
            sdsStyle="square"
            href="#main-content"
        >
            Skip to main content
        </StyledButton>
    );
}
