import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { getSpaces } from '~/src/utils/theme/theme-helpers';

export const StyledItem = styled(Box)(({ theme }) => {
    const spaces = getSpaces(theme);
    return {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        fontSize: theme.typography.body1.fontSize,
        '& p': {
            fontSize: 'inherit',
        },
        '& .MuiSvgIcon-root': {
            marginRight: spaces.xxs,
        },
    };
});
