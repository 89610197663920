import { createTheme } from '@mui/material/styles';
/* eslint-disable import/extensions */
import tokens from '~/tokens.json';
import { Themes } from '../../services/theme/theme-slice';
import { createNewBKPTheme } from './sds-bkp-theme';

// Anything that should be shared between the light and dark themes (basically everything but colors) should go here
const sharedThemeConfig = {
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
    },
};
// As we are adding custom theme variables, make sure the variables are being typed in theme.d.ts as well
const lightTheme = {
    palette: {
        primary: {
            main: tokens['light/base'].primary.main.value,
        },
        secondary: {
            main: tokens['light/base'].secondary.main.value,
        },
    },
    viz: {
        filteredOut: tokens.colors.grey['200'].value,
    },
    ...sharedThemeConfig,
};

const darkTheme = {
    palette: {
        mode: 'dark',
        primary: {
            main: tokens['dark/base'].primary.main.value,
        },
        secondary: {
            main: tokens['dark/base'].secondary.main.value,
        },
    },
    viz: {
        filteredOut: tokens.colors.grey['900'].value,
    },
    ...sharedThemeConfig,
};

const chooseTheme = (theme: Themes) => {
    if (theme === 'dark') {
        return darkTheme;
    }
    return lightTheme;
};

export const bkpTheme = ({ theme, useSDS = false }: { theme: Themes; useSDS: boolean }) => {
    if (useSDS) {
        return createNewBKPTheme(theme);
    }
    return createTheme(chooseTheme(theme));
};
