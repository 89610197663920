import keyBy from 'lodash/keyBy';

import { DISPLAY_PROPERTY_TYPE_PROJECT } from '~/src/constants/graphql';
import { Annotation, Operator, TableRow, TransformedTableRow, FilterType, TableFacetProperty } from '../../types';
import { castCridToAnnotation } from '../crid';
import {
    SPECIMEN_ID_TITLE,
    PROJECT_REFERENCE_IDS,
    TABLE_DEFAULT_MODALITY,
    SPECIMENS_SUMMARY_CELL,
    SPECIMENS_SUMMARY_CELL_TITLE,
    SPECIMENS_SUMMARY_CELL_DESCRIPTION,
} from '../../constants/strings';

export const getAnnotationValue = (annotation: Annotation): string =>
    annotation?.taxons?.map(({ symbol }) => symbol).join();

export const transformRow = (row: TableRow): TransformedTableRow => {
    if (row === null) {
        return { annotationsDictionary: {}, measurementsDictionary: {} };
    }
    const annotations = [castCridToAnnotation(row.cRID, SPECIMEN_ID_TITLE), ...row.annotations];
    return {
        annotationsDictionary: keyBy(annotations, 'featureType.referenceId'),
        measurementsDictionary: keyBy(row.measurements, 'featureType.referenceId'),
    };
};

export const createProjectFilter = (projectReferenceId: string) => ({
    field: PROJECT_REFERENCE_IDS,
    operator: Operator.CONTAINS,
    value: projectReferenceId,
});

export const createDisplayPropertyFilter = (projectReferenceId: string) => ({
    typeReferenceId: projectReferenceId,
    type: DISPLAY_PROPERTY_TYPE_PROJECT,
});

export const createSummaryCellFacetedProperty = (): TableFacetProperty => ({
    referenceId: SPECIMENS_SUMMARY_CELL,
    featureType: {
        title: SPECIMENS_SUMMARY_CELL_TITLE,
        description: SPECIMENS_SUMMARY_CELL_DESCRIPTION,
        referenceId: SPECIMENS_SUMMARY_CELL,
    },
    type: FilterType.IMAGE,
    filterOperator: null,
    modality: [{ name: TABLE_DEFAULT_MODALITY.toLowerCase() }],
});
