import { Interval } from '@alleninstitute/vis-geometry';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type HoverAnnotation = {
    type: 'annotation';
    hoverText: string;
    color: string;
};
export type HoverCellCategorical = {
    type: 'metadata';
    name: string;
    color: string;
    category: string;
};
export type HoverCellQuantitative = {
    type: 'quantitative';
    feature: string; // symbol of a gene, or refId of a numerical feature property
    bin: null | Interval; // the min and max value of the bin that the hovere'd item fall in
    color: string;
};
const INITIAL_STATE: FilterHoverState = { cell: undefined, annotation: undefined };
export type FilterHoverState = {
    cell: HoverCellCategorical | HoverCellQuantitative | undefined;
    annotation: HoverAnnotation | undefined;
};

// this slice is a bit unusual - there's really no reason to separate out control over the different parts of state
// here - essentially, this is because what is hovered is a function of the mouse location.
// for now, it seems ok to just set the hover state directly
export const HoverSlice = createSlice({
    name: 'filterHover',
    initialState: INITIAL_STATE,
    reducers: {
        unHover: () => INITIAL_STATE,
        setHover: (_state: FilterHoverState, action: PayloadAction<FilterHoverState>) => {
            const { payload } = action;
            return payload;
        },
    },
});
