import { createSlice } from '@reduxjs/toolkit';

export const cellTypeCardSearchInputSlice = createSlice({
    name: 'cellTypeCardSearchInput',
    initialState: '',
    reducers: {
        set: (_, action) => action.payload,
        reset: () => '',
    },
});
