import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { BkpLink } from '../../ui/bkp-link';
import { Typography } from '../../bkp-ui/typography';

const LEGAL_TEXT_A = 'To access this website, you must agree to the ';
const LEGAL_LINK_TEXT = 'Allen Institute Terms of Use';
const LEGAL_TEXT_B =
    '. This website contains links to third-party websites, with potential dataset access and/or download options. The Allen Institute does not endorse and is not responsible for any content on any third party website, including any data license(s). If you visit a third-party website, you will be subject to the terms of use that apply to such third-party website and/or a data license(s).';

interface TermsOfUseContentProps {
    onAcknowledge: () => void;
}
export const TermsOfUseContent = ({ onAcknowledge }: TermsOfUseContentProps) => (
    <Box p={8}>
        <Typography>
            {LEGAL_TEXT_A}
            <BkpLink href="https://www.alleninstitute.org/legal/terms-use/">{LEGAL_LINK_TEXT}</BkpLink>
            {LEGAL_TEXT_B}
        </Typography>
        <Box pt={3}>
            <Button
                id="terms-acknowledge-button"
                variant="contained"
                color="primary"
                onClick={onAcknowledge}
            >
                Acknowledge
            </Button>
        </Box>
    </Box>
);
