import { Interval, size } from '@alleninstitute/vis-geometry';
import useTheme from '@mui/material/styles/useTheme';
import { interpolateBlues, interpolateMagma, interpolateViridis } from 'd3-scale-chromatic';
import { interpolateRgbBasis } from 'd3-interpolate';

import {
    MetadataColorProperties,
    QuantitativeColorProperties,
} from '~/src/services/explore-visualizations/types/states';

// import and create more color interpolators as needed
type ColorInterPolatorNames = 'red' | 'blue';
type ColorInterpolators = {
    [key in ColorInterPolatorNames]?: Iterable<string> | ((t: number) => string);
};

const INTERPOLATOR_MAP = {
    Viridis: interpolateViridis,
    Magma: interpolateMagma,
    Blues: interpolateBlues,
} as { [x: string]: (t: number) => string };

/**
 * Function that creates a reverse interpolation of the function passed to it
 *
 * @param interpolator Interpolator function to reverse
 * @returns Interpolator function with the domain reverseed
 */
const inverseInterpolator = (interpolator: (t: number) => string) => (domain: number) => interpolator(1 - domain);

const inverseMagma = inverseInterpolator(interpolateMagma);

/**
 * Creates an interpolator function with optional domain mapping to a subrange.
 *
 * @param linearGradient - The interpolator function from d3.
 * @param clampValues - Range [min, max] to map the domain values to the subrange.
 * @returns A mapped interpolator function if clampValues are provided, otherwise the original interpolator.
 */
const createClampedInterpolator = (
    linearGradient: (t: number) => string,
    clampValues?: Interval,
    inverseColors?: boolean
) => {
    const gradient = inverseColors ? inverseInterpolator(linearGradient) : linearGradient;
    if (!clampValues) {
        return gradient;
    }
    const { min } = clampValues;
    return (x: number) => gradient((x - min) / size(clampValues));
};

export const defaultInterpolators = (clampValues?: Interval, inverseColors?: boolean) => ({
    red: createClampedInterpolator(inverseMagma, clampValues, inverseColors),
    blue: createClampedInterpolator(interpolateBlues, clampValues, inverseColors),
});

/**
 * This hook is intended to provide light/dark mode based color interpolators for visualizations
 * The visualization color schemes are from a collection provided by d3-scale-chromatic
 */
export const useVizColorInterpolators = (
    colorProperties?: QuantitativeColorProperties | MetadataColorProperties | undefined
): ColorInterpolators => {
    const color = colorProperties?.type === 'QUANTITATIVE' ? colorProperties.color : undefined;
    const inverseColors = color?.invertMap;

    const theme = useTheme();
    const currentMode = theme.palette.mode;
    const clampValues = color && color.type !== 'GENE' ? color.clampRange : undefined;
    if (color?.type === 'MAP') {
        return {
            red: createClampedInterpolator(INTERPOLATOR_MAP[color?.name ?? 'Viridis'], clampValues, inverseColors),
        };
    }
    if (color?.type === 'GRADIENT') {
        const customInterpolator = interpolateRgbBasis(color?.gradient ?? ['#440154', '#FDE725']);
        return {
            red: createClampedInterpolator(customInterpolator, clampValues, inverseColors),
        };
    }
    if (currentMode === 'dark') {
        return {
            red: createClampedInterpolator(interpolateMagma, clampValues, inverseColors),
            blue: createClampedInterpolator(interpolateViridis, clampValues, inverseColors),
        };
    }

    return defaultInterpolators(clampValues, inverseColors);
};
