import { Divider, useTheme } from '@mui/material';
import { getSemanticTextColors } from '~/src/utils/theme/theme-helpers';
import { HELP_MENU_FOOTER_ALL_RIGHTS_RESERVED, HELP_MENU_FOOTER_COPYRIGHT } from '~/src/constants/strings';
import { Typography } from '../bkp-ui/typography';
import { Grid } from '../bkp-ui/grid';
import { Stack } from '../bkp-ui/stack';
import { BkpLink } from './bkp-link';
import { LinkData } from '../complex/app-header/types';

type LinkGroup = {
    title: string;
    links: LinkData[];
};
export interface FooterProps {
    title: string;
    description: string;
    pageLinks: LinkGroup[];
    helpLinks: LinkData[];
    termsLinks: LinkData[];
}

export function Footer({ title, description, pageLinks, helpLinks, termsLinks }: FooterProps) {
    const theme = useTheme();
    const textColors = getSemanticTextColors(theme);

    return (
        <Grid
            container
            width="100%"
            borderTop={`1px solid ${theme.palette.divider}`}
            paddingBlock="l"
            paddingInline="xxl"
            spacing="l"
        >
            <Grid
                xs={12}
                container
                item
                width="100%"
                spacing="l"
                paddingBottom="l"
            >
                <Grid
                    container
                    item
                    sm={12}
                    md={5}
                    spacing="xs"
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <Typography
                            color={textColors.base.accent}
                            stylePath="header.semibold.s"
                        >
                            {title}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <Typography
                            stylePath="body.regular.xxs"
                            color={theme.palette.text.secondary}
                        >
                            {description}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    item
                    sm={12}
                    md={7}
                    spacing="xl"
                    direction="row"
                    justifyContent={{ sm: 'flex-start', md: 'flex-end' }}
                    alignItems="flex-start"
                >
                    {pageLinks.map((item) => (
                        <Grid
                            item
                            spacing="xs"
                            key={item.title}
                        >
                            <Typography
                                fontWeight={600}
                                stylePath="header.semibold.xs"
                            >
                                {item.title}
                            </Typography>
                            {item.links.map((v) => (
                                <BkpLink
                                    key={v.title}
                                    href={v.url}
                                    color={theme.palette.text.secondary}
                                >
                                    <Typography stylePath="body.regular.xs">{v.title}</Typography>
                                </BkpLink>
                            ))}
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Divider
                variant="middle"
                style={{ width: '100%' }}
            />
            <Grid
                xs={12}
                item
                container
                spacing="l"
            >
                <Grid
                    item
                    sm={12}
                    md={5}
                >
                    <Typography stylePath="body.regular.xs">
                        {HELP_MENU_FOOTER_COPYRIGHT}
                        {new Date().getFullYear()},{' '}
                        <BkpLink
                            color={textColors.action.default}
                            href="https://alleninstitute.org/what-we-do/brain-science/"
                        >
                            Allen Institute For Brain Science.
                        </BkpLink>{' '}
                        {HELP_MENU_FOOTER_ALL_RIGHTS_RESERVED}.
                    </Typography>
                </Grid>
                <Grid
                    item
                    container
                    sm={12}
                    md={7}
                    paddingBottom="l"
                    direction="row"
                    justifyContent={{ sm: 'flex-start', md: 'flex-end' }}
                    alignItems="center"
                >
                    <Stack
                        direction={{ sm: 'column', md: 'row' }}
                        spacing="m"
                    >
                        {helpLinks.map((item) => (
                            <BkpLink
                                href={item.url}
                                key={item.title}
                                color={theme.palette.text.secondary}
                            >
                                <Typography stylePath="body.regular.xs">{item.title}</Typography>
                            </BkpLink>
                        ))}
                        {termsLinks.map((item) => (
                            <BkpLink
                                href={item.url}
                                key={item.title}
                                color={theme.palette.text.secondary}
                            >
                                <Typography stylePath="body.regular.xs">{item.title}</Typography>
                            </BkpLink>
                        ))}
                        {window.OneTrust && (
                            <BkpLink
                                color={theme.palette.text.secondary}
                                onClick={window.OneTrust.ToggleInfoDisplay}
                            >
                                <Typography stylePath="body.regular.xs">Cookies Settings</Typography>
                            </BkpLink>
                        )}
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
    );
}
