import { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { shouldForwardProp } from '~/src/utils/styled/shouldForwardProp';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { getSemanticComponentColors, getSemanticTextColors } from '~/src/utils/theme/theme-helpers';
import { Button } from '~/src/components/bkp-ui/button';
import { Stack } from '~/src/components/bkp-ui/stack';
import { Typography } from '~/src/components/bkp-ui/typography';
import { WebResource } from './constants';

export type WebResourceType = keyof typeof WebResource;

const WEB_RESOURCE_CONFIG: Record<
    WebResourceType,
    {
        ctaText: string;
        icon: ReactNode;
        colorIntent: 'info' | 'beta' | 'positive' | 'notice';
    }
> = {
    [WebResource.download]: {
        ctaText: 'Download data',
        icon: <FileDownloadOutlinedIcon fontSize="large" />,
        colorIntent: 'info',
    },
    [WebResource.explore]: {
        ctaText: 'Explore tool',
        icon: <ExploreOutlinedIcon fontSize="large" />,
        colorIntent: 'beta',
    },
    [WebResource.information]: {
        ctaText: 'Learn more',
        icon: <InfoOutlinedIcon fontSize="large" />,
        colorIntent: 'positive',
    },
    [WebResource.view]: {
        ctaText: 'View resource',
        icon: <SearchOutlinedIcon fontSize="large" />,
        colorIntent: 'notice',
    },
};

type StyledWebResourceCardProps = { resourceType: WebResourceType };
export const StyledWebResourceCard = styled(Stack, {
    shouldForwardProp: shouldForwardProp<StyledWebResourceCardProps>(['resourceType']),
})<StyledWebResourceCardProps>(({ theme, resourceType }) => {
    const colors = getSemanticComponentColors(theme);
    const textColors = getSemanticTextColors(theme);
    const intent = WEB_RESOURCE_CONFIG[resourceType].colorIntent;
    const color = colors[intent];
    const textColor = textColors[intent];

    return {
        width: '100%',
        maxWidth: 280,
        backgroundColor: color.surface,
        color: textColor,
        // colored customizations for resource card
        '& .MuiButtonBase-root': {
            backgroundColor: colors.base.surface,
            color: textColor,
            boxShadow: `inset 0 0 0 1px ${color.border}`,
            '& .MuiSvgIcon-root': {
                color: color.icon,
            },
            '&:hover': {
                backgroundColor: color.fillHover,
                color: textColors.base.onFill,
                boxShadow: `inset 0 0 0 2px ${color.fillHover}`,
                '& .MuiSvgIcon-root': {
                    color: color.fillOnFill,
                },
            },
        },
    };
});

export interface WebResourceCardProps {
    name: string;
    type: WebResourceType;
    link: string;
    /**
     * Custom text to display on the CTA button.
     */
    actionText?: string;
}

export const WebResourceCard = ({ name, type, link, actionText }: WebResourceCardProps) => {
    const wr = WEB_RESOURCE_CONFIG[type];
    return (
        <StyledWebResourceCard
            direction="column"
            justifyContent="space-between"
            paddingBlock="l"
            paddingInline="m"
            spacing="m"
            resourceType={type}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing="xl"
            >
                <Typography
                    stylePath="header.semibold.s"
                    margin={0}
                >
                    {name}
                </Typography>
                {wr.icon}
            </Stack>
            <Button
                href={link}
                external
                sdsType="secondary"
                sdsStyle="square"
                style={{ width: 'fit-content' }}
            >
                {actionText || wr.ctaText}
            </Button>
        </StyledWebResourceCard>
    );
};
