/* eslint-disable import/extensions */
import compact from 'lodash/compact';
import navData from '~/src/assets/data/nav-links.json';
import { isFeatureEnabled } from '~/src/utils/feature-flag/feature-flag';
import { featureFlagIds } from '~/src/constants/features';
import { useProgramsAtHeaderQuery } from '~/src/types/cms-generated-schema-types';
import { cmsClient } from '~/src/services/apollo/cms-client';

const isProgramPageEnabled = isFeatureEnabled(featureFlagIds.programPage);

/**
 * intended to be used in Header and Footer
 * @returns list of ordered page links as nav options, with available program page links (CMS driven)
 */
export const useNavOptions = () => {
    const { data: programsQueryData } = useProgramsAtHeaderQuery({
        client: cmsClient,
        skip: !isProgramPageEnabled,
    });

    if (isProgramPageEnabled) {
        return [
            ...navData.pageLinks.slice(0, 1),
            {
                title: 'Programs',
                links: compact(
                    programsQueryData?.programs?.data.map((program) => {
                        if (!program.attributes) {
                            return null;
                        }
                        return {
                            title: program.attributes.title,
                            url: `/program/${program.attributes.slug}`,
                        };
                    })
                ),
            },
            ...navData.pageLinks.slice(1),
        ];
    }

    return navData.pageLinks;
};
