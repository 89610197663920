import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

export const TextSkeletonVariant = {
    header: 'header',
    body: 'body',
} as const;
export type TextSkeletonVariantTypes = (typeof TextSkeletonVariant)[keyof typeof TextSkeletonVariant];

interface TextSkeletonProps {
    /**
     * determines amount of lines of text skeletons to render, defaults to 1
     */
    lines?: number;
    /**
     * determines width of the text skeleton(s), defaults to "100%"
     */
    width?: string | number;
    /**
     * variant determines the style of the text skeleton(s), defaults to "body"
     */
    variant?: TextSkeletonVariantTypes;
}

const BaseTextSkeleton = ({ width, height }: { width: TextSkeletonProps['width']; height: number }) => (
    <Skeleton
        variant="text"
        width={width}
        height={height}
        sx={{ marginBottom: 0.2 }}
    />
);

export const TextSkeleton = ({ lines = 1, width = '100%', variant = TextSkeletonVariant.body }: TextSkeletonProps) => {
    const elementsArray = Array.from({ length: lines }, (_, index) => index);
    const height = variant === TextSkeletonVariant.header ? 32 : 16;

    if (lines === 1) {
        return (
            <BaseTextSkeleton
                width={width}
                height={height}
            />
        );
    }

    return (
        <Stack
            direction="column"
            width="100%"
        >
            {elementsArray.map((index) => (
                <BaseTextSkeleton
                    key={`text-skeleton-${index}`}
                    width={width}
                    height={height}
                />
            ))}
        </Stack>
    );
};
