import { AutocompleteRenderOptionState } from '@mui/material';
import { DataSectionInterface } from '../components/complex/data-section/types';

export interface CellTypeCardSearchOptionBase extends AutocompleteRenderOptionState {
    id: string;
    // TODO: use cell_set_preferred_alias once populated by EBI API
    prefLabel?: string[];
    taxonomy_id?: string[];
    accession_id?: string[];
    rank?: string[];
    species?: string[];
    topHighlightOption?: string[];
}

export interface CellTypeCardSearchOptionHighlights {
    aliases?: string[];
    cell_set_preferred_alias?: string[];
    definition?: string[];
    has_exact_synonym?: string[];
    label?: string[];
    marker_labels?: string[];
    nsforest_marker_labels?: string[];
    prefLabel?: string[];
    tags?: string[];
}

export type HighlightKey = keyof CellTypeCardSearchOptionHighlights;
export type HighlightScoreTuple = [HighlightKey, number];
export interface CellTypeTaxonomyMetadata {
    species: string;
    taxonomyId: string;
}

export interface CellTypeTaxonomyHierarchyNode {
    accessionId: string;
    aliases?: string[];
    classLabel?: string;
    color: string;
    dataSections?: DataSectionInterface[];
    fullOntologyName?: string;
    id: string;
    label: string;
    parentId: string;
    nfForestMarkers?: { url: string; text: string }[];
    ontologyIri?: { url: string; text: string };
    rank: string;
    references?: string[];
    summary?: string;
    symbol?: string;
    tags?: string[];
}
export interface CellTypeAnatomyImage {
    alt: string;
    src: string;
}

export interface CellTypesDatasetInfo {
    cellsNuclei?: string;
    dataset?: string;
    downloadLink?: string;
    exploreLink?: string;
    text?: string;
}

export interface CellTypeTaxonomyInfo {
    accessionId?: string;
    age?: string;
    anatomy?: string;
    anatomyImages?: [CellTypeAnatomyImage];
    crossSpeciesImages?: string[];
    cellClassesCount?: number;
    cellSubclassesCount?: number;
    cellTypesCount?: number;
    datasets?: [CellTypesDatasetInfo];
    header?: string;
    mainDescription?: string;
    attribution?: string;
    sex?: string;
    species?: string;
    subDescription?: string;
    nodes: CellTypeTaxonomyHierarchyNode[];
    azimuthLink?: string;
    azimuthHeader?: string;
    azimuthText?: string;
}
