import ArticleIcon from '@mui/icons-material/Article';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import ScienceIcon from '@mui/icons-material/Science';
import SourceIcon from '@mui/icons-material/Source';
import { Tooltip } from '@czi-sds/components';
import type { TagProps } from '@czi-sds/components';
import { Stack } from '~/src/components/bkp-ui/stack';
import { Tag } from '~/src/components/bkp-ui/tag';
import { ResourceType } from './constants';

export type AvailableResource = keyof typeof ResourceType;

type ResourceTagItems = { label: string; icon?: TagProps['icon']; description: string };

const RESOURCE_TAGS: Record<AvailableResource, ResourceTagItems> = {
    [ResourceType.abcAtlas]: {
        label: 'ABC Atlas',
        icon: <ScatterPlotIcon />,
        description: 'This project contains visualization data on ABC Atlas.',
    },
    [ResourceType.webResources]: {
        label: 'Web Resource',
        icon: <SourceIcon />,
        description: 'External web resources are available for the project’s data collection data.',
    },
    [ResourceType.protocols]: {
        label: 'Protocols',
        icon: <FactCheckIcon />,
        description: 'Protocols are available on this project.',
    },
    [ResourceType.publications]: {
        label: 'Publications',
        icon: <ArticleIcon />,
        description: 'Relevant publications were associated with this project.',
    },
    [ResourceType.donorsData]: {
        label: 'Donors Data',
        icon: <ScienceIcon />,
        description: 'This project contains donors data.',
    },
    [ResourceType.specimensData]: {
        label: 'Specimens Data',
        icon: <ScienceIcon />,
        description: 'This project contains specimens data.',
    },
};

const ResourceTag = ({ label, icon, description }: ResourceTagItems) => (
    <Tooltip
        title={description}
        sdsStyle="light"
        placement="bottom"
    >
        <div>
            {/* // need a div as a parent element so it can hold a ref for tooltip */}
            <Tag
                label={label}
                sdsType="secondary"
                sdsSize="s"
                tagColor="info"
                hover={false}
                icon={icon}
            />
        </div>
    </Tooltip>
);

export interface ResourceTagsProps {
    resources: AvailableResource[];
}
/**
 * display a group of resource tags based on available resources.
 */
export const ResourceTags = ({ resources }: ResourceTagsProps) => (
    <Stack
        direction="row"
        spacing="xxs"
        flexWrap="wrap"
    >
        {resources.map((resource) => (
            <ResourceTag
                key={resource}
                {...RESOURCE_TAGS[resource]}
            />
        ))}
    </Stack>
);
