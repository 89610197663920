// To add a new feature flag:
// STEP 1: add a new feature flag id to `featureFlagIds`
// STEP 2: add the corresponding new feature flag name to `featureFlagNames`
// STEP 3: add the environment variable (if there is one) that controls the feature flag
// STEP 4: use `isFeatureEnabled` from `src/utils/feature-flag/feature-flag.ts` for feature control
// NOTE: featureFlagIds is for strict value comparison and other function logic, featureFlags is for
// display in features selection in dev tools and to find the environmental variable setting

import { BKP_FF_DEV_TOOLS, BKP_FF_NEW_DATA_CATALOG, BKP_FF_PROGRAM_PAGE } from './environment';

export const featureFlagIds = {
    // devTools isn't actually used in the codebase, it's our example flag and useful for our test code
    devTools: 'devTools',
    newDataCatalog: 'newDataCatalog',
    programPage: 'programPage',
} as const;

export type FeatureFlagId = (typeof featureFlagIds)[keyof typeof featureFlagIds];
export type FeatureFlagSettings = { name: string; envVal?: string };

export const featureFlags: Record<FeatureFlagId, FeatureFlagSettings> = {
    [featureFlagIds.devTools]: { name: 'Dev Tools', envVal: BKP_FF_DEV_TOOLS },
    [featureFlagIds.newDataCatalog]: { name: 'New Data Catalog', envVal: BKP_FF_NEW_DATA_CATALOG },
    // TODO: remove once we get a thumbs up to release Program pages
    [featureFlagIds.programPage]: { name: 'Program Page', envVal: BKP_FF_PROGRAM_PAGE },
};
