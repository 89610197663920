import { createAction, PayloadAction } from '@reduxjs/toolkit';
import TagManager from 'react-gtm-module';
import { GOOGLE_TAG_DATA_LAYER_NAME } from '~/src/constants/analytics';
import { type RootState } from '../../redux';
import { createAnalyticsAction, AnalyticsAction } from '../create-analytics-action';

interface ShareVisData {
    link: string;
}

const shareVisAction = createAction(
    'gtm/abc-atlas-share-visualizations',
    (payload: ShareVisData): { payload: ShareVisData } => ({
        payload,
    })
);

const sendAnalyticsShareVisAction = (_state: RootState, action: PayloadAction<ShareVisData>) => {
    const {
        payload: { link },
    } = action;

    TagManager.dataLayer({
        dataLayerName: GOOGLE_TAG_DATA_LAYER_NAME,
        dataLayer: {
            event: 'abcAtlasShareVisualizations',
            link,
        },
    });
};

export const shareVisActionAnalyticsAction = createAnalyticsAction(shareVisAction, sendAnalyticsShareVisAction);

interface CiteProjectData {
    from: 'project-summary' | 'abc-atlas';
}

const citeProjectAction = createAction(
    'gtm/cite-project',
    (payload: CiteProjectData): { payload: CiteProjectData } => ({
        payload,
    })
);

const sendAnalyticsCiteProjectAction = (_state: RootState, action: PayloadAction<CiteProjectData>) => {
    const {
        payload: { from },
    } = action;

    TagManager.dataLayer({
        dataLayerName: GOOGLE_TAG_DATA_LAYER_NAME,
        dataLayer: {
            event: 'citeProject',
            from,
        },
    });
};

export const citeProjectAnalyticsAction = createAnalyticsAction(citeProjectAction, sendAnalyticsCiteProjectAction);

const citeAbcAtlasAction = createAction('gtm/cite-abc-atlas');

const sendAnalyticCiteAbcAtlasAction = (_state: RootState) => {
    TagManager.dataLayer({
        dataLayerName: GOOGLE_TAG_DATA_LAYER_NAME,
        dataLayer: {
            event: 'citeAbcAtlas',
        },
    });
};

export const citeAbcAtlasAnalyticsAction = createAnalyticsAction(citeAbcAtlasAction, sendAnalyticCiteAbcAtlasAction);

export const citationActions: Record<string, AnalyticsAction> = {
    citeProjectAnalyticsAction,
    citeAbcAtlasAnalyticsAction,
};
