import { lazy } from 'react';
import { Routes, BrowserRouter, Route, Navigate } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';

import { isFeatureEnabled } from '~/src/utils/feature-flag/feature-flag';
import { featureFlagIds } from '~/src/constants/features';
import { BkpServerProvider } from '../../services/apollo';
import { ReduxProvider } from '../../services/redux';
import { BkpErrorBoundary } from '../../containers/error';
import { NotFound } from '../not-found';
import { DataCollectionProjects } from '../data';
import { CellTypes } from '../cell-types';
import { Mapping } from '../mapping';
import { Login } from '../login';
import { Layout } from './layout';
import { Program } from '../program/program';

const Explore = lazy(() => import('../explore'));
const SignInRedirect = lazy(() => import('../sign-in-redirect'));

const isProgramPageEnabled = isFeatureEnabled(featureFlagIds.programPage);

/// The entry point to the BKP React application.
/// The order of nested providers is mostly arbitrary, but BkpThemeProvider must be inside ReduxProvider
/// so it can use Redux to manage the light/dark mode for the application
export const Main = (): React.ReactElement => (
    <Authenticator.Provider>
        <BrowserRouter>
            <ReduxProvider>
                <BkpErrorBoundary>
                    <BkpServerProvider>
                        <Routes>
                            <Route element={<Layout />}>
                                <Route
                                    path="/"
                                    element={
                                        <Navigate
                                            replace
                                            to="data"
                                        />
                                    }
                                />
                                <Route
                                    path=":referenceId/*"
                                    element={<DataCollectionProjects />}
                                />
                                <Route
                                    path="data/*"
                                    element={<DataCollectionProjects />}
                                />
                                {isProgramPageEnabled && (
                                    <Route
                                        path="program/:programSlug"
                                        element={<Program />}
                                    />
                                )}
                                <Route
                                    path="celltypes/*"
                                    element={<CellTypes />}
                                />
                                <Route
                                    path="mapmycells/*"
                                    element={<Mapping />}
                                />
                                <Route
                                    path="abcatlas"
                                    element={<Explore />}
                                />
                                <Route
                                    path="login"
                                    element={<Login />}
                                />
                                <Route
                                    path="signin/redirect"
                                    element={<SignInRedirect />}
                                />
                                <Route
                                    path="*"
                                    element={<NotFound />}
                                />
                            </Route>
                        </Routes>
                    </BkpServerProvider>
                </BkpErrorBoundary>
            </ReduxProvider>
        </BrowserRouter>
    </Authenticator.Provider>
);
