import { useState, MouseEvent } from 'react';
import { styled } from '@mui/material/styles';
import { ButtonDropdown, Menu, MenuProps } from '@czi-sds/components';

import { getSemanticTextColors } from '~/src/utils/theme/theme-helpers';
import { ButtonProps } from './button';

// This is showing an error of sdsType on the DOM element, but I can't get it to go away...
const StyledButtonDropdown = styled(ButtonDropdown)(({ theme }) => {
    const colors = getSemanticTextColors(theme);
    return {
        color: colors.base.accent,
    };
});

interface ToggleMenuProps extends Omit<MenuProps, 'open'> {
    label?: string;
    /**
     * Props being passed into the default SDS button component.
     */
    buttonProps?: ButtonProps;
    /**
     * variable to pass in for a controlled toggle menu, if passed in,
     * use onOpen and onClose to control the state
     */
    isOpen?: boolean;
    onOpen?: (event: MouseEvent<HTMLButtonElement>) => void;
    onClose?: (event: MouseEvent<HTMLButtonElement>) => void;
}

/**
 * Generic Menu recipe component with a Button to toggle the menu.
 */
export const ToggleMenu = ({
    label,
    buttonProps,
    children,
    isOpen,
    onOpen,
    onClose,
    ...restProps
}: ToggleMenuProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    // if not using controlled toggle menu, use anchorEl to control the state
    const isMenuOpen = isOpen ?? Boolean(anchorEl);

    const handleMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
        onOpen?.(event);
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (event: MouseEvent<HTMLButtonElement>) => {
        onClose?.(event);
        setAnchorEl(null);
    };

    const handleToggleMenu = (event: MouseEvent<HTMLButtonElement>) => {
        if (isMenuOpen) {
            handleMenuClose(event);
            return;
        }
        handleMenuOpen(event);
    };

    return (
        <>
            {buttonProps && (
                <StyledButtonDropdown
                    onClick={handleToggleMenu}
                    aria-label={restProps['aria-label']}
                    {...buttonProps}
                >
                    {label}
                </StyledButtonDropdown>
            )}
            <Menu
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                {...restProps}
            >
                {children}
            </Menu>
        </>
    );
};
