import { useEffect, useState, useCallback } from 'react';
import { useTheme } from '@mui/material';
import { Breakpoint } from '@mui/material/styles';
import { useWindowSize } from '../use-window-size';

const DEFAULT_BREAKPOINTS = {
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
};

type UseBreakpointsFunction = (breakpoint: Breakpoint) => boolean;

interface UseBreakpointsResult {
    isLargerThan: UseBreakpointsFunction;
    isSmallerThan: UseBreakpointsFunction;
    /**
     * e.g. given default MUI breakpoints: https://mui.com/material-ui/customization/breakpoints/#default-breakpoints
     *  if current viewport width is 1000, isInBreakpoint('md') returns true and isInBreakpoint('lg') returns false.
     */
    isInBreakpoint: UseBreakpointsFunction;
}

/**
 * Hook to determine the current viewport in relation to the breakpoints set in the theme.
 * @returns an object containing boolean values on whether the current viewport matches the corresponding breakpoint
 */
export const useBreakpoints = (): UseBreakpointsResult => {
    const theme = useTheme();
    const themeBreakpoints = theme.breakpoints.values;
    const [breakpoints, setBreakpoints] = useState(DEFAULT_BREAKPOINTS);
    const { width } = useWindowSize();

    useEffect(() => {
        setBreakpoints({
            xs: width < themeBreakpoints.sm,
            sm: width < themeBreakpoints.md && width >= themeBreakpoints.sm,
            md: width < themeBreakpoints.lg && width >= themeBreakpoints.md,
            lg: width < themeBreakpoints.xl && width >= themeBreakpoints.lg,
            xl: width >= themeBreakpoints.xl,
        });
    }, [width, themeBreakpoints]);

    const isLargerThan = useCallback(
        (breakpoint: Breakpoint) => width > themeBreakpoints[breakpoint],
        [width, themeBreakpoints]
    );

    const isSmallerThan = useCallback(
        (breakpoint: Breakpoint) => width < themeBreakpoints[breakpoint],
        [width, themeBreakpoints]
    );

    const isInBreakpoint = useCallback((breakpoint: Breakpoint) => breakpoints[breakpoint], [breakpoints]);

    return {
        isLargerThan,
        isSmallerThan,
        isInBreakpoint,
    };
};
