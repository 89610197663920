import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { getSpaces } from '~/src/utils/theme/theme-helpers';

export const StyledMenuItem = styled(MenuItem)(() => ({
    display: 'flex',
    alignItems: 'center',
    '& .MuiCheckbox-root': {
        padding: 0,
    },
    '& .MuiListItemIcon-root': {
        justifyContent: 'center',
    },
}));

export const StyledListItemText = styled(ListItemText)(({ theme }) => ({
    marginLeft: getSpaces(theme).xs,
    fontSize: theme.typography.body2.fontSize,
}));
