import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export type ViewIconProps = {
    colors?: string[];
    rotation?: number;
    iconProps?: SvgIconProps;
};

export const SingleViewIcon = ({ rotation = 0, colors, iconProps }: ViewIconProps) => (
    <SvgIcon
        viewBox="0 0 18 18"
        {...iconProps}
        sx={{ transform: `rotate(${rotation}deg)` }}
    >
        <rect
            x="2"
            y="2"
            width="14"
            height="14"
            rx="1"
            color={colors?.[0]}
        />
    </SvgIcon>
);
