import compact from 'lodash/compact';
import type { ProjectInfoFragment } from '~/src/types/generated-schema-types';

/**
 * @returns an array of primary author names based on DataCollectionProject.dataCreator
 */
export const getPrimaryAuthors = (dataCreator: ProjectInfoFragment['dataCreator']): string[] =>
    compact(
        dataCreator?.map((entry) => {
            if (!entry) {
                return null;
            }
            const agentType = entry.agentType as 'person' | 'organization';
            return entry[agentType]?.name;
        })
    ) || [];
