import { useMemo } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import type { QueryFunctionOptions } from '@apollo/client';
import { FilterArgument, CategoryArguments, ItemCountQueryData } from '../../types';

const getItemCountQuery = (categoryArguments: CategoryArguments[]) => {
    const categorySubquery = categoryArguments.map(
        ({ alias, categoryProperty }) => `
${alias}: getItemCount (
    filter: $${alias}
    groupBy: "${categoryProperty}"
    sort: {
        field: "name"
        order: ASC
    }
) {
    name
    count
}  
    `
    );

    return gql`
query itemCounts (
    ${categoryArguments.map(({ alias }) => `$${alias}: [BffFilter]`).join('\n')}
) {
    ${categorySubquery.join('\n')}
}
`;
};

export const useItemCountQuery = (
    categoryArguments: CategoryArguments[],
    filter: FilterArgument[],
    options?: QueryFunctionOptions
) => {
    const itemCountQuery = getItemCountQuery(categoryArguments);

    const variables = useMemo(
        () =>
            categoryArguments.reduce<{ [alias: string]: FilterArgument[] }>((acc, { alias, categoryProperty }) => {
                const applicableFilter = filter.filter(({ field }) => field !== categoryProperty);
                acc[alias] = applicableFilter;
                return acc;
            }, {}),
        [filter, categoryArguments]
    );

    return useQuery<ItemCountQueryData>(itemCountQuery, { variables, ...options });
};
