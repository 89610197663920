import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterCategoryArguments, PrepareReturn } from '../../types';

export interface FilterAllOptionsState {
    [key: string]: FilterCategoryArguments[];
}

export interface FilterAllOptionsPayload {
    filterAllOptions: FilterCategoryArguments[];
    key: string;
}

export interface VerticalFilterAllOptionsPayload {
    filterMap: Record<string, FilterCategoryArguments[]>;
}

const INITIAL_STATE: FilterAllOptionsState = {};

const setFilterAllOptionsPrepare = (
    key: string,
    filterAllOptions: FilterCategoryArguments[]
): PrepareReturn<FilterAllOptionsPayload> => ({
    payload: {
        filterAllOptions,
        key,
    },
});

type SetFilterAllOptionsReducer = CaseReducer<FilterAllOptionsState, PayloadAction<FilterAllOptionsPayload>>;
const setFilterAllOptionsReducer: SetFilterAllOptionsReducer = (state, action) => {
    const { filterAllOptions, key } = action.payload;

    return {
        ...state,
        [key]: filterAllOptions,
    };
};

export const filterAllOptionsSlice = createSlice({
    name: 'filter-all-options',
    initialState: INITIAL_STATE,
    reducers: {
        setFilterAllOptions: {
            reducer: setFilterAllOptionsReducer,
            prepare: setFilterAllOptionsPrepare,
        },
    },
});
