export const BKP_TITLE = 'Brain Knowledge Platform';

export const AD_REFERENCE_ID = 'JGN327NUXRZSHEV88TN';
export const AD_PROGRAM_ID = '5IU4U8BP711TR6KZ843';
export const AD_SUBPROGRAM_ID = '2CD0HDC5PS6A58T0P6E';

export const FETCH_ERROR_MESSAGE =
    'There was a problem fetching data for this page. Try again later and refresh the page.';

export const EXPLORE_PAGE_NAME = 'explore';
export const EXPLORE_LINK_TEXT = 'Explore Data';
export const EXPLORE_PAGE_URL_STATE_PARAMETER = 'state';
export const SUMMARY_PAGE_NAME = 'summary';
export const SPECIMEN_TITLE = 'Specimen';
export const SPECIMENS_PAGE_NAME = 'specimens';
export const SPECIMENS_LINK_TEXT = 'Browse Specimens';
export const SPECIMEN_ID_TITLE = 'Specimen ID';
export const SPECIMEN_TAXONOMY_FILTER_REFERENCE_ID = '7MZTO66W0B9663JCJ20';
// This ID is only present in the Donor dataset, but is labeled Specimen ID, so we suppress it in the UI
export const DONOR_PAGE_SPECIMEN_ID_COLUMN_REFERENCE_ID = 'QL1JDPEMY417CMVCH4I';
export const SPECIMEN_ID_REFERENCE_ID_V2 = '75S21YZHJ1CZ585S202';
export const SPECIMEN_T_TYPE_REFERENCE_ID = 'DW0F0S320VR4NX0DBPT';
export const SPECIMEN_T_TYPE_CLASS_REFERENCE_ID = 'Z8BZR1LDP9G4I97O394';
export const SPECIMEN_T_TYPE_SUBCLASS_REFERENCE_ID = 'O267WEUPC2MMK3MI7ZY';
export const SPECIMENS_SUMMARY_CELL = 'SPECIMENS_SUMMARY_CELL';
export const SPECIMENS_SUMMARY_CELL_TITLE = 'Summary';
export const SPECIMENS_SUMMARY_CELL_DESCRIPTION = 'Summary properties of specimen';
export const SPECIMENS_COLUMN_REF_IDS = [
    SPECIMEN_ID_REFERENCE_ID_V2,
    SPECIMEN_T_TYPE_CLASS_REFERENCE_ID,
    SPECIMEN_T_TYPE_SUBCLASS_REFERENCE_ID,
];
export const SPECIMEN_T_TYPE_REF_IDS = [
    SPECIMEN_T_TYPE_REFERENCE_ID,
    SPECIMEN_T_TYPE_CLASS_REFERENCE_ID,
    SPECIMEN_T_TYPE_SUBCLASS_REFERENCE_ID,
];

export const TABLE_DEFAULT_MODALITY = 'general';

export const CELL_TYPE_PAGE_NAME = 'celltype';
export const CELL_TYPE_PAGE_DISPLAY_NAME = 'Cell Type Knowledge Explorer';

export const DONOR_INDEX_PAGE_NAME = 'donors';
export const DONOR_TITLE = 'Donor';
export const SUBJECT_ID_TITLE = 'Subject ID';
// HACK ALERT: This Donor ID column appears to be going away in favor of the more generic subject ID.
// Check if Subject ID will also support Specimen IDs in the future so we can simplify the logic
// between the two pages.
export const DONOR_ID_TITLE = 'Donor ID';
export const DONOR_INDEX_PAGE_DISPLAY_NAME = "Seattle Alzheimer's Disease Brain Cell Atlas";
export const DONOR_ID_REFERENCE_ID = 'KRP9GYF002I5OPM7JSR';
export const DONORS_LINK_TEXT = 'Browse Donors';
export const DEFAULT_DONOR_PROFILE_IMAGE = 'Abeta(6E10) and IBA1';

export const COMPARATIVE_VIEWER_PAGE_NAME = 'compare';
export const COMPARATIVE_VIEWER_PAGE_DISPLAY_NAME = 'Comparative Viewer';

export const WEB_RESOURCES_DOWNLOAD_TYPE = 'download';

export const SORT_QUERY_DELIMITER = '~';

export const FILTER_FIELD_QUERY_DELIMITER = '.';
export const FILTER_ARGUMENT_QUERY_DELIMITER = '~';

export const QUERYSTRING_COMMA_REPLACER = '|';
// Global regex so we can replace _all_ commas with the placeholder value
export const QUERYSTRING_COMMA_REPLACER_REGEX = /\|/g;
export const ALIAS_PREFIX_DELIMITER = '__';
export const REF_ID_ALIAS_PREFIX = `ref_id${ALIAS_PREFIX_DELIMITER}`;

export const DISPLAY_NULL = 'NULL';
export const SORT_ARROW_INFO = 'Click arrow to change sort order. Hold Ctrl/Cmd key to sort by multiple fields.';

export const PROJECT_REFERENCE_IDS = 'projectReferenceIds';

export const VISUALIZATION_MENU_DEFAULT = 'Select data collection...';
export const GENE_EXPRESSION_CATEGORY = 'Gene Expression';

// Mapping UI labels and links
export const SIGN_IN = 'Sign in';
export const SIGN_IN_EXTERNAL_LOGIN = 'Sign In Using';
export const SIGN_IN_EXTERNAL_LOGIN_DISCLAIMER =
    "The signed-in experience currently requires an existing Allen Institute or Allen Institute collaborator account. We're planning to add additional sign-in options in the future. We appreciate your interest and patience.";
export const SIGN_OUT = 'Sign out';
export const MAPPING_TITLE = 'MapMyCells';
export const STEP_1_TITLE = 'Step 1';
export const STEP_1_SUBTITLE = 'Upload your gene expression data';
export const STEP_1_SAVE_DATA = 'Want to save your data?';
export const FILE_UPLOAD_TEXT_1 = 'Click to upload';
export const FILE_UPLOAD_TEXT_2 = ' or drag and drop';
export const FILE_UPLOAD_TEXT_FILE_SIZE_LIMIT = 'anndata (max. 2GB)';
export const STEP_2_TITLE = 'Step 2';
export const STEP_2_SUBTITLE = 'Choose a reference taxonomy and mapping algorithm';
export const STEP_2_REFERENCE_TAXONOMY_TITLE = 'Reference Taxonomy';
export const STEP_2_MAPPING_ALGORITHM_TITLE = 'Mapping Algorithm';
export const STEP_2_START_MAPPING_BUTTON_TITLE = 'Start';
export const STEP_2_RETRY_MAPPING_BUTTON_TITLE = 'Retry';
export const STEP_2_FAILED_MAPPING_DOWNLOAD_LOGS_BUTTON_TITLE = 'Download Logs';
export const STEP_2_RESTART_MAPPING_BUTTON_TITLE = 'Restart';
export const STEP_2_CANCEL_MAPPING_BUTTON_TITLE = 'Cancel';
export const MAPPING_IN_PROGRESS_LABEL = 'In Progress';
export const MAPPING_ERROR_LABEL = 'Error';
export const MAPPING_CANCEL_LABEL = 'Canceled';
export const MAPPING_COMPLETE_LABEL = 'Mapping Completed';
export const STEP_2_ETA = 'ETA:';
export const STEP_2_SIGN_IN = 'Sign in to save results';
export const STEP_2_PROGRESS_LABEL = 'Progress will be lost if you close the browser window';
export const REFERENCE_TAXONOMY_CARD_MEDIA_TITLE = 'Chosen taxonomy reference UMAP';
export const FILE_UPLOAD_DATA_FAILED = 'Upload data failed';
export const DIVIDER = '•';
export const FILE_UPLOAD_COMPLETED_STATUS_LABEL = 'Complete';
export const FILE_UPLOAD_IN_PROGRESS_STATUS_LABEL = 'File upload in progress...';
export const MAPPING_RESULTS_LABEL = 'Mapping Results';
export const MAP_RESULTS_SIDEBAR_LABEL = 'Map Results';
export const TITLE_SIDEBAR_LABEL = 'MapMyCells';
export const MAPPING_ACTIONS_RERUN_LABEL = 'Rerun Mapping';
export const MAPPING_ACTIONS_CANCEL_LABEL = 'Cancel Mapping';
export const MAPPING_CANCELLATION_SUCCESSFUL_LABEL = 'Mapping canceled successfully.';
export const MAPPING_CANCELLATION_ERROR_LABEL = 'Cancel Mapping';
export const MAPPING_ACTIONS_DOWNLOAD_LABEL = 'Download Results';
export const MAPPING_ACTIONS_SIGN_IN_INITIAL_TOOLTIP_LABEL = 'Sign in to save your data.';
export const MAPPING_ACTIONS_MAPPING_TOOLTIP_LABEL = 'Estimated completion time.';
export const MAPPING_ACTIONS_PRE_STARTING_MAPPING_TOOLTIP_LABEL =
    "Estimated completion time. Don't close your browser window.";
export const MAPPING_ACTIONS_POST_STARTING_MAPPING_TOOLTIP_LABEL =
    'Estimated completion time. Sign in to save your data.';
export const MAPPING_ACTIONS_COMPLETED_TOOLTIP_LABEL = 'Mapping Completed. Please download mapping results';
export const MAPPING_ACTIONS_FAILED_TOOLTIP_LABEL = 'Mapping Failed. Please try again.';
export const MAPPING_ACTIONS_DELETE_LABEL = 'Delete both file and results';
export const MAPPING_HISTORY_TABLE_FILE_COLUMN_HEADER_NAME = 'File';
export const MAPPING_HISTORY_TABLE_STATUS_COLUMN_HEADER_NAME = 'Status';
export const MAPPING_HISTORY_TABLE_PROCESS_RUN_TIME_COLUMN_HEADER_NAME = 'Process Run Time';
export const MAPPING_HISTORY_TABLE_EXECUTION_ID_COLUMN_HEADER_NAME = 'Execution ID';
export const MAPPING_HISTORY_TABLE_REFERENCE_COLUMN_HEADER_NAME = 'Reference';
export const MAPPING_HISTORY_TABLE_ALGORITHM_COLUMN_HEADER_NAME = 'Algorithm';
export const MAPPING_HISTORY_TABLE_MAPPED_CELLS_GENES_COLUMN_HEADER_NAME = 'Mapped Cells & Genes';
export const MAPPING_HISTORY_TABLE_ACTIONS_COLUMN_HEADER_NAME = 'Actions';
export const DELETE_MAPPING_DIALOG_TITLE = 'Delete this mapping?';
export const DELETE_MAPPING_DIALOG_CONTENT_TEXT = 'Please confirm if you want to delete this mapping.';
export const CANCEL_MAPPING_DIALOG_TITLE = 'Cancel this mapping?';
export const CANCEL_MAPPING_DIALOG_CONTENT_TEXT = 'Please confirm if you want to cancel this mapping.';
export const MAPPING_DIALOG_CLOSE_LABEL = 'Close';
export const MAPPING_DIALOG_CONFIRM_LABEL = 'Confirm';
export const SIGN_IN_REDIRECT_LABEL_PART_1 = 'Signing in, you will be redirected shortly... Please';
export const SIGN_IN_REDIRECT_LABEL_PART_2 = 'click here';
export const SIGN_IN_REDIRECT_LABEL_PART_3 = 'if your browser does not redirect you.';
export const MAPPING_FAILED_DATA_CHECK_LABEL =
    'Please confirm that your input data is in cell (rows) by gene (columns) format.';
export const MAPPING_RESULTS_DOWNLOAD_DISCLAIMER = 'Note: Please enable pop-ups for seamless results downloads.';
export const MAPPING_FILE_UPLOAD_SPACE_LIMIT_REACHED_SERVER_RESPONSE_ERROR =
    'You do not have enough space. Please delete one of the mapping result from the history';
export const MAPPING_FILE_UPLOAD_SPACE_LIMIT_REACHED_ERROR_LABEL =
    'You do not have enough space. Please delete an existing mapping result to make room for new results.';
export const MAPPING_FILE_UPLOAD_ERROR = 'File Upload Error';

// Mapping Tooltip labels and links
export const DATA_FILE_LEARN_MORE_ABOUT = 'Learn more about';
export const DATA_FILE_REQUIREMENTS_AND_LIMITS_LABEL = 'Input file requirements, limits, and creation.';
export const DATA_FILE_MAPPING_OUTPUT_FILES_LABEL = 'Learn about the mapping output files.';
export const CITE_THIS_TOOL_LABEL = 'Cite this tool';
export const CITE_THIS_TOOL_LINK =
    'https://scicrunch.org/resources/data/record/nlx_144509-1/SCR_024672/resolver?q=MapMyCells&l=MapMyCells&i=rrid:scr_024672';
export const DATA_FILE_REQUIREMENTS_AND_LIMITS_LINK =
    'https://portal.brain-map.org/explore/file-requirements-and-limits';
export const DATA_FILE_MAPPING_OUTPUT_FILES_LINK =
    'https://github.com/AllenInstitute/cell_type_mapper/blob/main/docs/output.md';
export const CELL_TYPE_REFERENCES_AND_ALGORITHMS_LABEL =
    'Learn about available cell type references, algorithms, and output files.';
export const CELL_TYPE_REFERENCES_AND_ALGORITHMS_LINK =
    'https://portal.brain-map.org/explore/cell-type-references-and-algorithms';

// Help Menu labels and links
// TODO: Delete these from use in the info panel and read from the JSON document/CMS in the future!
export const HELP_MENU_SUBHEADER_1 = 'Help';
export const HELP_MENU_ALLEN_INSTITUTE_FOR_BRAIN_SCIENCE = 'Allen Institute for Brain Science';
export const HELP_MENU_ALLEN_INSTITUTE_FOR_BRAIN_SCIENCE_LINK = 'https://alleninstitute.org/what-we-do/brain-science/';
export const HELP_MENU_FEEDBACK = 'Feedback';
export const HELP_MENU_FEEDBACK_LINK =
    'https://docs.google.com/forms/d/e/1FAIpQLSeIaEalEJM-sz4PYMMXNtWvLXdGceqTdfP_Qvp3DIv7uEHSFg/viewform';
export const HELP_MENU_COMMUNITY_FORUM = 'Community Forum';
export const HELP_MENU_COMMUNITY_FORUM_LINK = 'https://community.brain-map.org/c/how-to/mapmycells/20';
export const HELP_MENU_SUBHEADER_2 = 'Terms';
export const HELP_MENU_CITATION_POLICY = 'Citation Policy';
export const HELP_MENU_CITATION_POLICY_LINK = 'https://alleninstitute.org/legal/citation-policy/';
export const HELP_MENU_PRIVACY_POLICY = 'Privacy Policy';
export const HELP_MENU_PRIVACY_POLICY_LINK = 'https://www.alleninstitute.org/legal/privacy-policy/';
export const HELP_MENU_TERMS_OF_USE = 'Terms of Use';
export const HELP_MENU_TERMS_OF_USE_LINK = 'https://www.alleninstitute.org/legal/terms-use/';
export const HELP_MENU_COOKIE_SETTINGS = 'Cookie Settings';
export const HELP_MENU_FOOTER_COPYRIGHT = 'Copyright © ';
export const HELP_MENU_FOOTER_ALL_RIGHTS_RESERVED = 'All Rights Reserved';
export const STEP_2_ETA_VALUE = '5 min';
export const STEP_2_MAPPING_FAILED = 'Mapping Failed';
export const STEP_1_NOTIFY_ME = 'Notify me when my mapping concludes';
export const STEP_1_NOTIFY_ME_TITLE = 'Notification Settings';
export const STEP_1_NOTIFY_DISCLAIMER =
    'If you want to be notified when your mapping concludes, please enter your email address here. We will never share your email with third-parties.';
export const STEP_1_NOTIFY_PRIVACY_POLICY_1 = 'Please see our';
export const STEP_1_NOTIFY_PRIVACY_POLICY_LINK = 'https://alleninstitute.org/privacy-policy/';
export const STEP_1_NOTIFY_PRIVACY_POLICY_LINK_TEXT = 'Privacy Policy';
export const STEP_1_NOTIFY_PRIVACY_POLICY_2 = 'for more details.';
export const STEP_1_NOTIFY_CANCEL = 'Cancel';
export const STEP_1_NOTIFY_CONFIRM = 'Confirm';
export const STEP_1_NOTIFY_EMAIL_LABEL = 'Email Address';

export const ABC_ATLAS_CITATION_URL = 'https://portal.brain-map.org/atlases-and-data/bkp/abc-atlas';
export const ABC_ATLAS_TOOL_CITATION = `Allen Brain Cell Atlas (RRID:SCR_024440)`;
