import { BKP_CMS_URL } from '~/src/constants/environment';

/**
 * conditional returns CMS media url because localhost CMS server doesn't return the full URL with endpoint at the front
 */
export const getCmsMediaUrl = (url?: string | null) => {
    if (!url || !BKP_CMS_URL) {
        return '';
    }

    if (BKP_CMS_URL.includes('localhost')) {
        return `${BKP_CMS_URL}${url}`;
    }

    return url;
};
