import { styled, TypographyStyle } from '@mui/material/styles';
import MuiTypography from '@mui/material/Typography';
import type { TypographyProps as MuiTypographyProps } from '@mui/material/Typography';
import type { Typography as SDSTypography } from '@czi-sds/components';
import { getTypography, getSpaces } from '~/src/utils/theme/theme-helpers';
import { shouldForwardProp } from '~/src/utils/styled/shouldForwardProp';

// Utility type to generate all possible paths as union types base on the SDS supported Typography styles
// e.g. 'body.regular.xs' | 'header.semibold.l' | ... | 'tabular.semibold.xs'
// We need to manually stop at the third level because the recursive approach will go through the whole TypographyStyle object
type ThirdLevelPaths<T> = {
    [K1 in keyof T]: {
        [K2 in keyof T[K1]]: {
            [K3 in keyof T[K1][K2]]: `${K1 & string}.${K2 & string}.${K3 & string}`;
        }[keyof T[K1][K2]];
    }[keyof T[K1]];
}[keyof T];

export type TypographyStylePath = ThirdLevelPaths<SDSTypography['styles']>;

const getTypographyStyleByPath = (
    typographyStyles: SDSTypography['styles'],
    path: TypographyStylePath
): TypographyStyle => {
    const [variant, weight, size] = path.split('.');
    // @ts-expect-error the possible typography paths are guarded by the TypographyStylePath union type
    return typographyStyles[variant][weight][size];
};

type StyledTypographyProps = { isCode?: boolean; isHeader?: boolean; stylePath?: TypographyStylePath };
export const StyledTypography = styled(MuiTypography, {
    shouldForwardProp: shouldForwardProp<StyledTypographyProps>(['isCode', 'isHeader', 'stylePath'] as const),
})<StyledTypographyProps>(({ theme, isCode, isHeader, stylePath }) => {
    const typography = getTypography(theme);
    const spaces = getSpaces(theme);
    const typographyStyle = stylePath ? getTypographyStyleByPath(typography.styles, stylePath) : {};
    const fontFamily = isCode ? typography.fontFamily.code : theme.typography.fontFamily;

    return {
        ...typographyStyle,
        fontFamily,
        marginTop: isHeader ? spaces.s : 0,
        marginBottom: isHeader ? spaces.xxs : 0,
    };
});

interface TypographyProps extends MuiTypographyProps {
    /**
     * only applicable for pages with SDS enabled
     */
    stylePath?: TypographyStylePath;
}

/**
 * Extending the Typography component from MUI to support SDS typography styles.
 * For SDS semantic typography, use the `stylePath` prop
 * For MUI semantic typography, use the `variant` prop
 */
export const Typography = ({ stylePath, children, ...restProps }: TypographyProps) => (
    <StyledTypography
        stylePath={stylePath}
        isCode={stylePath?.startsWith('code')}
        isHeader={stylePath?.startsWith('header')}
        {...restProps}
    >
        {children}
    </StyledTypography>
);
