import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import has from 'lodash/has';

import { BaseKeyPayload, ColumnWidths } from '../../types';

type ColumnWidthsState = {
    [key: string]: ColumnWidths;
};

const INITIAL_STATE: ColumnWidthsState = {};

type ColumnWidthsPayload = BaseKeyPayload & {
    columnWidths: ColumnWidths;
};

type AddColumnWidthsReducer = CaseReducer<ColumnWidthsState, PayloadAction<ColumnWidthsPayload>>;
const addColumnWidthsReducer: AddColumnWidthsReducer = (state, action) => {
    const { columnWidths, key } = action.payload;

    return {
        ...state,
        [key]: {
            ...state[key],
            ...columnWidths,
        },
    };
};

type SetColumnWidthsReducer = CaseReducer<ColumnWidthsState, PayloadAction<ColumnWidthsPayload>>;
const setColumnWidthsReducer: SetColumnWidthsReducer = (state, action) => {
    const { columnWidths, key } = action.payload;

    return {
        ...state,
        [key]: columnWidths,
    };
};

type InitializeColumnWidthsReducer = CaseReducer<ColumnWidthsState, PayloadAction<ColumnWidthsPayload>>;
const initializeColumnWidthsReducer: InitializeColumnWidthsReducer = (state, action) => {
    const { columnWidths, key } = action.payload;

    if (has(state, key)) {
        return state;
    }

    return {
        ...state,
        [key]: columnWidths,
    };
};

export const createColumnWidthSlice = (name: string) =>
    createSlice({
        name,
        initialState: INITIAL_STATE,
        reducers: {
            addColumnWidths: addColumnWidthsReducer,
            setColumnWidths: setColumnWidthsReducer,
            initializeColumnWidths: initializeColumnWidthsReducer,
        },
    });

export type ColumnWidthSlice = ReturnType<typeof createColumnWidthSlice>;
