import { CaseReducer, AnyAction, PayloadAction, createSlice } from '@reduxjs/toolkit';
import undoable, { excludeAction, groupByActionTypes, StateWithHistory } from 'redux-undo';

import { Layout, calculateNextLayoutDecrease } from '~/src/constants/layouts';
import { BkpDatasetFragment, VisualizationFragment } from '~/src/types/generated-schema-types';
import { DataCollectionId, DatasetId, VisualizationId } from '~/src/types/reference-ids';
import { BkpDatasetWithVis } from '~/src/hooks/use-visualization-info';
import { FiltersMap } from '~/src/utils/visualization/build-visualizations';
import {
    pickDatasetToView,
    updateLayout,
    pickFrame,
    removeFrame,
    toggleFilterCategoryExpanded,
    setShowSearchText,
    setFocusedPanel,
    colorVisBy,
    makeAbcAtlasReducer,
    makeCurrentVisReducer,
    removeFilterCategory,
    removeNumericFilter,
    setSearchText,
    setFilterByCategory,
    enforceValidSelection,
    setSelectedCellProperties,
    initializeCamera,
    pan,
    resizeCamera,
    zoom,
    makeGivenVisReducer,
    setQuantitativeFilter,
    removeQuantitativeFilter,
    addGeneUx,
    removeGeneUx,
    clearGeneUx,
    toggleGeneAccordion,
    setControlType,
    setSelection,
    setSelectionOffset,
    moveSelection,
    resizeSelection,
    clearSelection,
    createBox,
    setPanEnabledWhileInSelectionMode,
    duplicateFrame,
    setTransparency,
    setUseTransparentFilteredPoints,
    reorderFrames,
    allowSidebarResize,
    setRenderQuality,
    setPointSizeScale,
    setShowCategory,
    toggleFrameSync,
    setCamera,
    setVisualization,
    clearGeneFilters,
    advanceVisibleSlides,
    setShowUnselectedSlides,
    setDisableClose,
    updateAnnotationState,
    updateAnnotationColorPrefs,
    setNotFoundGenes,
    toggleFilter,
    clearQuantitativeFilters,
    clearNumericFilters,
    makeCurrentVisFilterReducer,
    toggleExcludeZeros,
    toggleInverseColor,
    setNullColoring,
} from './explore-page-utils';
import { decodeUrlParameters } from './explore-page-url-parsing';
import { urlContainsLegacyParams } from './explore-page-url-parsing-legacy';
import { FrameKey, VisFrameLayout, VisSettings } from './types/states';
import { DEFAULT_FRAME_KEY } from './constants';
import { HoverSlice } from '../filter/filter-hover-slice';

// all the features of the explore page - you'll note that some features are missing (TODO)
// some things to note:
// visframes maps a|b|c|d to another ID - that means you could easily clone a visualization by storing its id in frame 'a' and frame 'b'
// cameras follow the same pattern - a single camera could be shared by multiple visualizations, if we wanted
// biggest benefit, IMHO: its understandable as a single entity - and the reducers here have everything they need to
// keep the UI simple, by mantining coherent state in one easy to debug spot
export type ExplorePage = {
    type: 'explore';
    layout: Layout;
    visFrames: VisFrameLayout;
    currentFrame: keyof VisFrameLayout;
    synced: Record<DatasetId, FrameKey[]>;
    allowSidebarResize: boolean;
};

const initialState: ExplorePage | null = null;

export type AbcAtlasReducer<Payload extends object> = (
    state: ExplorePage,
    action: PayloadAction<Payload>
) => ExplorePage;

// a common pattern is to apply a visSettings reducer to the current visualization of the current page:
function applyReducerToCurrentFrame<Payload extends object>(
    reducer: (vis: VisSettings, payload: Payload) => VisSettings
) {
    return makeCurrentVisReducer(reducer);
}
function applyReducerToGivenFrame<Payload extends object>(
    reducer: (vis: VisSettings, payload: Payload) => VisSettings
) {
    return makeGivenVisReducer(reducer);
}

type SlidesPayload = {
    frameKey: FrameKey;
    indexOffset: number;
};
const advanceSyncedSlides: CaseReducer<ExplorePage, PayloadAction<SlidesPayload>> = (state, action) => {
    const { frameKey, indexOffset } = action.payload;
    const currentFrame = state.visFrames[frameKey];
    if (currentFrame) {
        const currentFrameDatasetId = currentFrame.datasetId;
        if (state.synced?.[currentFrameDatasetId]?.find((key) => key === frameKey)) {
            const frameSettings = Object.entries(state.visFrames).reduce((acc, [key, settings]) => {
                // if both datasets are the same and synced we can advance their slides
                if (state.synced?.[currentFrameDatasetId]?.find((givenKey) => givenKey === key)) {
                    return { ...acc, [key]: advanceVisibleSlides(settings, { indexOffset }) };
                }
                return acc;
            }, state.visFrames);
            return { ...state, visFrames: { ...frameSettings } };
        }
        const currFrameSettings = advanceVisibleSlides(currentFrame, { indexOffset });
        return { ...state, visFrames: { ...state.visFrames, [frameKey]: currFrameSettings } };
    }
    return state;
};

export type ExplorePageInitializationPayload = {
    datasets: Record<DataCollectionId, BkpDatasetWithVis[]>;
    visualizationsByDataset: Record<DatasetId, VisualizationFragment[]>;
    datasetsByVisualizationIds: Record<VisualizationId, BkpDatasetFragment>;
    filterOptions: FiltersMap;
    layout: Layout;
    urlQuery?: string;
    hash?: string;
    defaultDataset: BkpDatasetWithVis;
};

export const explorePageSlice = createSlice({
    name: 'explorePage',
    initialState: initialState as unknown as ExplorePage,
    reducers: {
        initialize: (_state, action: PayloadAction<ExplorePageInitializationPayload>): ExplorePage => {
            const {
                datasets,
                visualizationsByDataset,
                datasetsByVisualizationIds,
                filterOptions,
                layout,
                urlQuery,
                hash,
                defaultDataset,
            } = action.payload;

            const page: ExplorePage = {
                currentFrame: DEFAULT_FRAME_KEY,
                layout,
                type: 'explore',
                visFrames: {},
                synced: {},
                allowSidebarResize: true,
            };

            const dataCollectionIds = Object.keys(datasets) as DataCollectionId[];
            if (dataCollectionIds.length < 1) {
                return page;
            }

            const projectId = defaultDataset.projectReferenceId;
            const dataCollectionId = defaultDataset.dataCollectionReferenceId as DataCollectionId;
            const datasetId = defaultDataset.referenceId as DatasetId;
            const visualizationId = defaultDataset.visualizations[0].referenceId;
            const datasetVersion = defaultDataset.version;
            const defaultColorBy = filterOptions[datasetId as DatasetId]?.[0]?.referenceId;
            const explorePage = pickDatasetToView(page, {
                projectId,
                dataCollectionId,
                datasetId,
                visualizationId,
                datasetVersion,
                defaultColorBy,
            });

            const shouldInitializeFromQuery = urlContainsLegacyParams(urlQuery) || hash;
            if (shouldInitializeFromQuery) {
                const initializedFromQuery = decodeUrlParameters(
                    urlQuery,
                    hash || undefined, // in some cases, we get "" for the hash - coerce this to undefined so we dont actually try to use it further in
                    visualizationsByDataset,
                    datasetsByVisualizationIds,
                    defaultColorBy
                );
                if (initializedFromQuery) {
                    return enforceValidSelection(initializedFromQuery);
                }
            }
            return explorePage;
        },
        // camera (note that these can be applied to the NON-current frame)
        panCamera: makeAbcAtlasReducer(pan),
        zoomCamera: makeAbcAtlasReducer(zoom),
        resizeCamera: applyReducerToGivenFrame(resizeCamera),
        advanceSlide: advanceSyncedSlides,
        showUnselectedSlides: applyReducerToGivenFrame(setShowUnselectedSlides),
        initializeCamera: applyReducerToGivenFrame(initializeCamera),
        setCamera: applyReducerToGivenFrame(setCamera),
        // vis controls
        setControlType: applyReducerToGivenFrame(setControlType),
        setSelection: applyReducerToGivenFrame(setSelection),
        setSelectionOffset: applyReducerToGivenFrame(setSelectionOffset),
        moveSelection: applyReducerToGivenFrame(moveSelection),
        clearSelection: applyReducerToGivenFrame(clearSelection),
        resizeSelection: applyReducerToGivenFrame(resizeSelection),
        createBox: applyReducerToGivenFrame(createBox),
        setPanEnabledWhileInSelectionMode: applyReducerToGivenFrame(setPanEnabledWhileInSelectionMode),
        // coloring
        colorBy: applyReducerToCurrentFrame(colorVisBy),
        setTransparency: applyReducerToGivenFrame(setTransparency),
        setUseTransparentFilteredPoints: applyReducerToGivenFrame(setUseTransparentFilteredPoints),
        // rendering aesthetics
        setRenderQuality: applyReducerToGivenFrame(setRenderQuality),
        setPointSizeScale: applyReducerToGivenFrame(setPointSizeScale),
        // ux
        toggleCurrentVisPanel: applyReducerToCurrentFrame(toggleFilterCategoryExpanded),
        setShowSearch: applyReducerToCurrentFrame(setShowSearchText),
        setSearchText: applyReducerToCurrentFrame(setSearchText),
        setShowCategory: applyReducerToCurrentFrame(setShowCategory),
        focusPanel: applyReducerToCurrentFrame(setFocusedPanel),
        setSelectedCellInfo: applyReducerToCurrentFrame(setSelectedCellProperties),
        toggleFrameSync: makeAbcAtlasReducer(toggleFrameSync),
        setDisableClose: applyReducerToCurrentFrame(setDisableClose),
        // ux: genes panel
        addGeneUx: applyReducerToCurrentFrame(addGeneUx),
        removeGeneUx: applyReducerToCurrentFrame(removeGeneUx),
        clearGeneUx: applyReducerToCurrentFrame(clearGeneUx),
        setNotFoundGenes: applyReducerToCurrentFrame(setNotFoundGenes),
        toggleGeneAccordion: applyReducerToCurrentFrame(toggleGeneAccordion),
        // filtering
        removeFilterByCategory: makeCurrentVisFilterReducer(removeFilterCategory),
        removeNumericFilter: applyReducerToCurrentFrame(removeNumericFilter),
        toggleFilterForCurrentVis: makeCurrentVisFilterReducer(toggleFilter),
        toggleFilterForVis: makeCurrentVisFilterReducer(toggleFilter),
        setFilterByCategory: makeCurrentVisFilterReducer(setFilterByCategory),
        setQuantitativeFilter: applyReducerToCurrentFrame(setQuantitativeFilter),
        toggleExcludeZeros: applyReducerToCurrentFrame(toggleExcludeZeros),
        toggleInverseColor: applyReducerToCurrentFrame(toggleInverseColor),
        setNullColoring: applyReducerToCurrentFrame(setNullColoring),
        removeQuantitativeFilter: applyReducerToCurrentFrame(removeQuantitativeFilter),
        clearGeneFilters: applyReducerToCurrentFrame(clearGeneFilters),
        clearNumericFilters: applyReducerToCurrentFrame(clearNumericFilters),
        clearQuantitativeFilters: applyReducerToCurrentFrame(clearQuantitativeFilters),
        // layouts, dataset selection, sidebar state
        reorderFrames: makeAbcAtlasReducer(reorderFrames),
        pickDatasetToView: makeAbcAtlasReducer(pickDatasetToView),
        setVisualization: applyReducerToGivenFrame(setVisualization),
        duplicateFrame: makeAbcAtlasReducer(duplicateFrame),
        setLayout: makeAbcAtlasReducer(updateLayout),
        allowSidebarResize: makeAbcAtlasReducer(allowSidebarResize),
        pickFrame: makeAbcAtlasReducer(pickFrame),
        removeFrame: makeAbcAtlasReducer((page: ExplorePage, payload: { frameKey: string }) => {
            const { frameKey } = payload;
            return {
                ...removeFrame(page, frameKey as FrameKey),
                layout: calculateNextLayoutDecrease(page.layout),
            };
        }),
        // annotations
        updateAnnotationState: applyReducerToGivenFrame(updateAnnotationState),
        updateAnnotationColorPrefs: applyReducerToGivenFrame(updateAnnotationColorPrefs),
    },
});
/**
 * This is a reducer that allows undo/redo actions through the redux-undo library, it is exported
 * to the Root Reducer in root-reducer.ts
 * It changes the state to have a past, present and future state by wrapping the explore page in a
 * state with history type like so StateWithHistory<ExplorePage>
 * GroupBy allows us to customize which redux actions should be paired together for undo/redo purposes
 * Our current setup utilizes a time based grouping of 200ms
 * filter config property allows us to ignore certain actions from being affected by redo/undo, however,
 * the state will still be updated accordingly
 * Our resize camera action fires rapidly and repeatedly after certain actions which results in a lot of potential
 * redo/undo actions and the ultimate end result is that a user would have to undo/redo many times to get the
 * desired state
 * In addition since we are limiting the number of actions in past/present/future to a total of 10 for performance reasons
 * The resize camera can essentially serve to fill up the undo/redo stack and prevent the user from
 * undoing/redoing other actions
 *
 */

// Function to determine if undo/redo actions should be grouped together
function createGroupingStrategy() {
    let lastActionTime = 0;
    return (action: AnyAction, currentState: null, previousHistory: StateWithHistory<null>): number | null => {
        let currentGroupIdentifier = previousHistory?.group;
        const groupingTimeFrame = 200;
        const now = Date.now();
        const groupId = groupByActionTypes([
            // Below actions fire rapidly
            explorePageSlice.actions.panCamera.type,
            explorePageSlice.actions.zoomCamera.type,
            // numeric property histogram changes
            explorePageSlice.actions.setQuantitativeFilter.type,
            // tree filter category set as multiple
            // filters in the tree are selected at once do to parent selection
            explorePageSlice.actions.setFilterByCategory.type,
            // cell selector related actions
            explorePageSlice.actions.setSelection.type,
            explorePageSlice.actions.moveSelection.type,
            explorePageSlice.actions.createBox.type,
            explorePageSlice.actions.resizeSelection.type,
            // Actions fire on click on cell selection box simultaneously
            // if you click to filter creating double undo
            explorePageSlice.actions.setSelectionOffset.type,
        ])(action, currentState, previousHistory);
        if (now - lastActionTime > groupingTimeFrame || groupId === null) {
            currentGroupIdentifier = now;
        }
        lastActionTime = now;
        return currentGroupIdentifier;
    };
}

export const undoableExplorePageReducer = undoable(explorePageSlice.reducer, {
    groupBy: createGroupingStrategy(),
    filter: excludeAction([
        explorePageSlice.actions.resizeCamera.type,
        explorePageSlice.actions.allowSidebarResize.type,
        explorePageSlice.actions.setSearchText.type,
        explorePageSlice.actions.setShowSearch.type,
        explorePageSlice.actions.focusPanel.type,
        HoverSlice.actions.setHover.type,
        HoverSlice.actions.unHover.type,
    ]),
});
