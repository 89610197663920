import * as React from 'react';
import { ApolloProvider } from '@apollo/client';
import { useErrorHandler } from '~/src/hooks/use-error-handler/use-error-handler';

import { bkpServerClient } from './bkp-server-client';

export const BkpServerProvider: React.FunctionComponent = ({ children }) => {
    const handleError = useErrorHandler();

    return <ApolloProvider client={bkpServerClient(handleError)}>{children}</ApolloProvider>;
};
