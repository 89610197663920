import flatMap from 'lodash/flatMap';

import type { ProjectSummaryProps } from '../../services/project-summary/query';

export const getProjectBaseKeywords = ({ program, shortTitle, modality, technique }: ProjectSummaryProps) => {
    const programNames = flatMap(program?.value, ({ programLink, subProgramLink }) => [
        programLink?.text,
        subProgramLink?.text,
    ]);

    return ['Allen Institute', 'Brain Science', ...programNames, shortTitle?.value, modality?.value, technique?.value]
        .filter(Boolean)
        .join(', ');
};
