// This file is used to create a new theme based on the BKP color schema
// Currently SDS doesn't have dark mode support, in this file the palettes are manually created.

import merge from 'lodash/merge';
import { SDSAppTheme, defaultTheme, makeThemeOptions } from '@czi-sds/components';
import type { Colors } from '@czi-sds/components';
import { createTheme } from '@mui/material/styles';
import { Themes } from '~/src/services/theme/theme-slice';

interface ColorScaleItem {
    700: string;
    600: string;
    500: string;
    400: string;
    300: string;
    200: string;
    100: string;
}

export type BaseColorNames = 'blue' | 'gray' | 'green' | 'purple' | 'red' | 'yellow' | 'granite';

type BKPColorsModel = Record<BaseColorNames, ColorScaleItem> & {
    common: {
        darkest: string;
        lightest: string;
    };
};

// sourcing the color variables from BKP Colors Figma file
export const BKPColors: BKPColorsModel = {
    common: {
        darkest: '#121212',
        lightest: '#FFFFFF',
    },
    blue: {
        '700': '#08273C',
        '600': '#15466A',
        '500': '#155888',
        '400': '#297ABA',
        '300': '#9DCDF2',
        '200': '#E8F4FD',
        '100': '#F2F9FF',
    },
    gray: {
        '700': '#2F2F2F',
        '600': '#545454',
        '500': '#767676',
        '400': '#8B8B8B',
        '300': '#BCBCBC',
        '200': '#EAEAEA',
        '100': '#F8F8F8',
    },
    green: {
        '700': '#0F2F2B',
        '600': '#275855',
        '500': '#277066',
        '400': '#299675',
        '300': '#88E2C1',
        '200': '#EAFBF4',
        '100': '#F2FDF8',
    },
    purple: {
        '700': '#21224B',
        '600': '#43447E',
        '500': '#504A98',
        '400': '#7B68C9',
        '300': '#C6BAFB',
        '200': '#F1F0FC',
        '100': '#F8F8FF',
    },
    red: {
        '700': '#341317',
        '600': '#682930',
        '500': '#9C3636',
        '400': '#BF3D3D',
        '300': '#FCA8A9',
        '200': '#FFF0F0',
        '100': '#FFF8F8',
    },
    yellow: {
        '700': '#39280F',
        '600': '#834E1D',
        '500': '#A16E21',
        '400': '#DBA63F',
        '300': '#FAE2A3',
        '200': '#FEF7E5',
        '100': '#FFFCF4',
    },
    granite: {
        '700': '#2B2C33',
        '600': '#494C57',
        '500': '#636775',
        '400': '#7B7F8E',
        '300': '#BABCCB',
        '200': '#EDEFF7',
        '100': '#F2F4FB',
    },
};

// create a color scale that's adaptable to the SDS colors model
const getSdsColorScale = (color: BaseColorNames) => ({
    '600': BKPColors[color]['600'],
    '500': BKPColors[color]['500'],
    '400': BKPColors[color]['400'],
    '300': BKPColors[color]['300'],
    '200': BKPColors[color]['200'],
    '100': BKPColors[color]['100'],
});

export const BKP_SDS_APP_COLORS: Colors = {
    common: {
        black: BKPColors.common.darkest,
        white: BKPColors.common.lightest,
    },
    blue: getSdsColorScale('blue'),
    gray: getSdsColorScale('gray'),
    green: getSdsColorScale('green'),
    purple: getSdsColorScale('purple'),
    red: getSdsColorScale('red'),
    yellow: getSdsColorScale('yellow'),
};
// SDS schema options
const BKP_APP_THEME_OPTIONS = merge({}, { ...SDSAppTheme }, { colors: BKP_SDS_APP_COLORS });
// Theme options created from the SDS schema that can be used to create a MUI theme
const BKP_THEME_OPTIONS = makeThemeOptions(BKP_APP_THEME_OPTIONS);

const BKP_PALETTE_DEFAULT = merge(
    {},
    { ...BKP_THEME_OPTIONS.palette },
    {
        mode: 'light',
        background: {
            default: BKPColors.common.lightest,
            paper: BKPColors.common.lightest,
        },
        secondary: {
            '100': BKPColors.granite[100],
            '200': BKPColors.granite[200],
            '300': BKPColors.granite[300],
            '400': BKPColors.green[400],
            '500': BKPColors.granite[500],
            '600': BKPColors.granite[600],
            contrastText: {
                light: BKPColors.common.lightest,
                dark: BKPColors.common.darkest,
            },
            dark: BKPColors.granite[600],
            light: BKPColors.granite[200],
            main: BKPColors.granite[400],
        },
    }
);

const BKP_PALETTE_DARK = merge(
    {},
    { ...BKP_PALETTE_DEFAULT },
    {
        mode: 'dark',
        divider: BKPColors.gray[700],
        background: {
            default: BKPColors.common.darkest,
            paper: BKPColors.common.darkest,
        },
        text: {
            disabled: BKPColors.gray[500],
            primary: BKPColors.common.lightest,
            secondary: BKPColors.gray[400],
        },
        sds: {
            component: {
                accent: {
                    borderDisabled: BKPColors.blue[600],
                    borderHover: BKPColors.blue[300],
                    fillHover: BKPColors.blue[400],
                    fillPressed: BKPColors.blue[300],
                    surface: BKPColors.blue[700],
                },
                base: {
                    border: BKPColors.gray[500],
                    borderDisabled: BKPColors.gray[600],
                    borderHover: BKPColors.gray[300],
                    divider: BKPColors.gray[500],
                    fill: BKPColors.common.darkest,
                    fillDisabled: BKPColors.gray[600],
                    fillHover: BKPColors.gray[700],
                    fillOpen: BKPColors.gray[600],
                    fillPressed: BKPColors.gray[600],
                    fillSelected: BKPColors.gray[600],
                    icon: BKPColors.gray[400],
                    iconDisabled: BKPColors.gray[600],
                    iconHover: BKPColors.gray[400],
                    iconPressed: BKPColors.gray[300],
                    onFillDisabled: BKPColors.gray[500],
                    surface: BKPColors.common.darkest,
                    surfacePrimary: BKPColors.common.darkest,
                    surfaceSecondary: BKPColors.gray[700],
                    surfaceTertiary: BKPColors.gray[600],
                },
                beta: {
                    borderDisabled: BKPColors.purple[600],
                    borderHover: BKPColors.purple[300],
                    fillHover: BKPColors.purple[600],
                    fillPressed: BKPColors.purple[700],
                    surface: BKPColors.purple[700],
                },
                info: {
                    borderDisabled: BKPColors.blue[600],
                    borderHover: BKPColors.blue[300],
                    fillHover: BKPColors.blue[600],
                    fillPressed: BKPColors.blue[700],
                    surface: BKPColors.blue[700],
                },
                negative: {
                    borderDisabled: BKPColors.red[600],
                    borderHover: BKPColors.red[300],
                    fillHover: BKPColors.red[600],
                    fillPressed: BKPColors.red[700],
                    surface: BKPColors.red[700],
                },
                neutral: {
                    borderDisabled: BKPColors.gray[600],
                    borderHover: BKPColors.gray[300],
                    fillHover: BKPColors.gray[600],
                    fillPressed: BKPColors.gray[700],
                    surface: BKPColors.gray[700],
                },
                notice: {
                    borderDisabled: BKPColors.yellow[600],
                    borderHover: BKPColors.yellow[300],
                    fillHover: BKPColors.yellow[600],
                    fillPressed: BKPColors.yellow[700],
                    surface: BKPColors.yellow[700],
                },
                positive: {
                    borderDisabled: BKPColors.green[600],
                    borderHover: BKPColors.green[300],
                    fillHover: BKPColors.green[600],
                    fillPressed: BKPColors.green[700],
                    surface: BKPColors.green[700],
                },
            },
            text: {
                action: {
                    default: BKPColors.blue[300],
                    hover: BKPColors.blue[200],
                    pressed: BKPColors.blue[200],
                },
                base: {
                    accent: BKPColors.blue[300],
                    disabled: BKPColors.gray[500],
                    onFill: BKPColors.common.lightest,
                    onFillDisabled: BKPColors.gray[400],
                    primary: BKPColors.common.lightest,
                    secondary: BKPColors.gray[400],
                },
                beta: BKPColors.purple[300],
                info: BKPColors.blue[300],
                negative: BKPColors.red[300],
                neutral: BKPColors.gray[300],
                notice: BKPColors.yellow[300],
                positive: BKPColors.green[300],
            },
        },
    }
);

export const createNewBKPTheme = (mode: Themes) => {
    const palette = mode === 'dark' ? BKP_PALETTE_DARK : BKP_PALETTE_DEFAULT;
    const { corners, spacing } = BKP_APP_THEME_OPTIONS;
    const textPalette = palette.sds!.text!;
    const themeOptions = merge(
        {},
        { ...BKP_THEME_OPTIONS },
        {
            components: {
                ...defaultTheme.components,
                MuiMenuItem: {
                    styleOverrides: {
                        root: {
                            '.MuiSvgIcon-root': {
                                width: '100%',
                                height: '100%',
                            },
                        },
                    },
                },
                MuiPaper: {
                    styleOverrides: {
                        root: {
                            padding: spacing.s,
                            backgroundColor: palette.background.paper,
                            borderRadius: corners.s,
                        },
                    },
                },
                MuiMenu: {
                    styleOverrides: {
                        paper: {
                            padding: spacing.s,
                            borderRadius: corners.s,
                        },
                    },
                },
                MuiLink: {
                    styleOverrides: {
                        // need to manually write this because the "all: unset" CSS somehow overwrites the other styles.
                        // https://github.com/chanzuckerberg/sci-components/blob/96a2af14747a5413c1bbd45ad45c35a10d52f76d/packages/components/src/core/Link/style.ts#L83
                        root: {
                            color: textPalette.action.default,
                            '&:hover': {
                                color: textPalette.action.hover,
                            },
                            '&:active': {
                                color: textPalette.action.pressed,
                            },
                        },
                    },
                },
                MuiPopper: {
                    styleOverrides: {
                        root: {
                            '.MuiTooltip-tooltip .MuiTableRow-root': {
                                verticalAlign: 'top',
                            },
                        },
                    },
                },
            },
            palette,
            spacing: 1,
        }
    );

    return createTheme(themeOptions);
};
