import { createSelector } from 'reselect';

import { type RootState } from '../redux';
import { selectPageKey } from '../page-key';

const selectLimitState = (state: RootState) => state.limit;

export const selectLimit = createSelector(
    [selectPageKey, selectLimitState],
    (pageKey, limitState) => limitState[pageKey]
);

export const selectLimitQueryOption = createSelector([selectLimit], (limit) => ({
    limit,
}));
