import { ReactNode } from 'react';
import { EntrySummary } from '~/src/components/complex/data-catalog-table/entry-summary';
import { createColumnHelper } from '@tanstack/react-table';
import { SdsTable } from '../../bkp-ui/table/sds-table';
import type { ResourceTagsProps } from './resource-tags';

// TODO: Create service on BKP Server so that we could fetch the DisplayMetadataSource data
const PROGRAM_PROJECTS_FIELDS_DESCRIPTIONS = {
    summary: 'Brief project summary including title, description and available resources under the project',
    species: 'Organism types from which specimens used in the data collection was derived',
    modality: 'Data types contained in the data collection',
    technique: 'Methods use to in the creation of the data collection',
    subProgram: 'Research program associated with the data collection',
    primaryAuthor: 'Person(s) who are one of main researchers involved in the generation of the data collection',
};

type ProgramProjectTableEntry = {
    summary: ReactNode;
    species: string;
    modality: string;
    technique: string;
    subProgram: string;
    primaryAuthor: ReactNode;
};

const columnHelper = createColumnHelper<ProgramProjectTableEntry>();

const PROGRAM_PROJECTS_COLUMNS = [
    columnHelper.accessor('summary', {
        header: () => 'Summary',
        cell: (info) => info.getValue(),
        minSize: 440,
    }),
    columnHelper.accessor('species', {
        header: () => 'Species',
        minSize: 180,
    }),
    columnHelper.accessor('modality', {
        header: () => 'Modality',
        minSize: 180,
    }),
    columnHelper.accessor('technique', {
        header: () => 'Technique',
        minSize: 180,
    }),
    columnHelper.accessor('subProgram', {
        header: () => 'Sub Program',
        minSize: 180,
    }),
    columnHelper.accessor('primaryAuthor', {
        header: () => 'Primary Author',
        minSize: 180,
    }),
];
export interface ProgramProjectsTableProps {
    projects: Array<{
        projectName: string;
        projectDescription: string;
        species: string[];
        modality: string[];
        technique: string[];
        subProgram: string[];
        primaryAuthor: string[];
        link: string;
        resources?: ResourceTagsProps['resources'];
    }>;
}

export const ProgramProjectsTable = ({ projects }: ProgramProjectsTableProps) => {
    const tableData = projects.map((project) => ({
        summary: (
            <EntrySummary
                title={project.projectName}
                description={project.projectDescription}
                link={project.link}
                resources={project.resources}
            />
        ),
        species: project.species.join(', '),
        modality: project.modality.join(', '),
        technique: project.technique.join(', '),
        subProgram: project.subProgram.join(', '),
        primaryAuthor: project.primaryAuthor.join(', '),
    }));
    return (
        <SdsTable
            columns={PROGRAM_PROJECTS_COLUMNS}
            data={tableData}
            columnTooltips={PROGRAM_PROJECTS_FIELDS_DESCRIPTIONS}
        />
    );
};
