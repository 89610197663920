import { ApolloClient, HttpLink, ApolloLink, NormalizedCacheObject, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import xor from 'lodash/xor';
import Auth from '@aws-amplify/auth';
import { BKPErrorHandler } from '~/src/hooks/use-error-handler/use-error-handler';
import { NonFatalError } from '~/src/types';
import { BKP_URL } from '~/src/constants/environment';
import { FETCH_ERROR_MESSAGE } from '../../constants/strings';
import { donorTableDataCacheKey, specimenImagesCacheKey, specimenTableDataCacheKey } from '../../utils/strings';

/**
 * Adapted from https://stackoverflow.com/questions/47211778/cleaning-unwanted-fields-from-graphql-responses/51380645#51380645
 */
export const cleanTypeName = new ApolloLink((operation, forward) => {
    if (operation.variables) {
        const omitTypename = (key: string, value: unknown) => (key === '__typename' ? undefined : value);
        operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
    }
    return forward(operation);
});

const getFeatureTypesFromKeys = (key: string) => {
    // Substring between square brackets and Remove all double quotes around featureTypes
    const match = key.match(/\[(.*?)\]/);

    // If no matches are found, there are no feature types
    if (match === null) {
        return [];
    }

    const featureTypeSubString = match[1].replace(/"/g, '');
    return featureTypeSubString.split(',') || [];
};

export const bkpServerClient = (handleError: BKPErrorHandler) =>
    new ApolloClient<NormalizedCacheObject>({
        link: ApolloLink.from([
            cleanTypeName,
            onError(({ graphQLErrors, networkError, operation, forward }) => {
                if (graphQLErrors) {
                    graphQLErrors.forEach((gqlError) => {
                        const { message, locations, path } = gqlError;
                        // adapted from https://www.apollographql.com/docs/react/data/error-handling/#on-graphql-errors
                        if (
                            message.includes('401: Unauthorized') ||
                            operation.operationName === 'getMappingResults' ||
                            gqlError.extensions.code === 'UNAUTHENTICATED'
                        ) {
                            Auth.currentSession().then((session) => {
                                const oldHeaders = operation.getContext().headers;
                                operation.setContext({
                                    headers: {
                                        ...oldHeaders,
                                        authorization: session.getIdToken().getJwtToken(),
                                    },
                                });
                                return forward(operation);
                            });
                        } else {
                            handleError(new NonFatalError(gqlError, FETCH_ERROR_MESSAGE));
                            // eslint-disable-next-line no-console
                            console.error(
                                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                            );
                        }
                    });
                }
                if (networkError) {
                    handleError(new NonFatalError(networkError, FETCH_ERROR_MESSAGE));
                    // eslint-disable-next-line no-console
                    console.error(`[Network error]: ${networkError}`);
                }
            }),
            new HttpLink({
                uri: BKP_URL || 'http://localhost:4000/graphql',
                credentials: 'same-origin',
            }),
        ]),
        cache: new InMemoryCache({
            typePolicies: {
                Query: {
                    fields: {
                        aio_specimen: {
                            merge(
                                existing = [],
                                incoming,
                                {
                                    storeFieldName,
                                    args: { limit = 0, offset = 0, filter = [], sort = [] },
                                    variables: { featureTypes: incomingFeatureTypes, imageRefIds: incomingImageRefIds },
                                }
                            ) {
                                const specimenTableDataMatchName = specimenTableDataCacheKey(filter, sort);
                                const specimenImagesMatchName = specimenImagesCacheKey(filter, sort);
                                const donorTableDataMatchName = donorTableDataCacheKey(filter, sort);
                                const isSupported =
                                    storeFieldName === specimenTableDataMatchName ||
                                    storeFieldName === specimenImagesMatchName ||
                                    storeFieldName === donorTableDataMatchName;
                                const existingLen = existing.length;

                                // If featureTypes properties have changed, invalidate the cache.
                                if (existingLen) {
                                    const existingFeatureTypesKey: string =
                                        Object.keys(existing[0]).find((key: string) => key.includes('featureTypes')) ||
                                        '';
                                    const existingFeatureTypes: string[] =
                                        getFeatureTypesFromKeys(existingFeatureTypesKey);
                                    const haveFeaturesTypesChanged = Boolean(
                                        xor(existingFeatureTypes, incomingFeatureTypes).length
                                    );

                                    const existingImageFeatureTypesKey: string =
                                        Object.keys(existing[0]).find(
                                            (key: string) => key.includes('images') && key.includes('featureTypes')
                                        ) || '';
                                    const existingImageFeatureTypes: string[] =
                                        getFeatureTypesFromKeys(existingImageFeatureTypesKey);
                                    const haveImageFeaturesTypesChanged = Boolean(
                                        xor(existingImageFeatureTypes, incomingImageRefIds).length
                                    );

                                    if (haveFeaturesTypesChanged || haveImageFeaturesTypesChanged) {
                                        return incoming;
                                    }
                                }

                                if (!isSupported) {
                                    return incoming;
                                }

                                const isConsecutive = offset < existingLen;
                                if (isConsecutive) {
                                    return [
                                        ...existing.slice(0, offset),
                                        ...incoming,
                                        ...existing.slice(offset + limit, existingLen),
                                    ];
                                }

                                return [...existing, ...Array(offset - existingLen).fill(null), ...incoming];
                            },
                        },
                        cellInfo: {
                            merge(existing = [], incoming) {
                                return [...existing, ...incoming];
                            },
                        },
                    },
                },
            },
            // When generating fragments that contain interfaces, we have to map out their relation, otherwise
            // it will completely fail. See the following link for more details:
            // https://www.apollographql.com/docs/react/data/fragments#using-fragments-with-unions-and-interfaces
            possibleTypes: {
                IVisualization: ['Umap', 'CoronalGrid', 'DynamicGrid'],
                IAnnotation: ['SvgAnnotation'],
            },
        }),
    });
