import { useNavigate, useLocation } from 'react-router-dom';
import { useDebounceEffect } from '../use-debounce/use-debounce-effect';

/**
 * Utility function to update the URL string with a new value. We use it to update the URL from our Redux or local state
 * in order to allow for sharable URLs.
 *
 * @param searchUpdate - The URI encoded string to update the URL search with (e.g. key1=encodedValue1&key2=encodedValue2)
 */
export const useUpdateUrlQueryString = (searchUpdate: string): void => {
    // useSearchParams is another option instead of useNavigate, but that gives us a Record of the parameters
    // and we're working with strings here. If we kept everything as a URLSearchParams coming out of Redux or local state,
    // then useSearchParams would be a good option. Potential opportunity for refactoring in the future.
    const { pathname, search } = useLocation();
    const navigate = useNavigate();

    // We debounce any changes to the URL so rapid updates don't cause many re-renders, plus we can sometimes hit
    // React setState errors if we have other setStates happening alongside the navigation change.
    useDebounceEffect(
        250,
        () => {
            // If the search string is different from the current one, update the URL. If we don't skip it when we're
            // already on the correct URL, we get the whole page re-rendering constantly as react-router is treating
            // it as a new page to render (as it rightly should, since we're literally telling it to navigate somewhere)
            // NOTE: We need to append the ? because the search comes prefixed wth it from useLocation
            if (`?${searchUpdate}` !== search) {
                navigate(
                    {
                        pathname,
                        search: searchUpdate,
                    },
                    { replace: true }
                );
            }
        },
        [navigate, pathname, searchUpdate]
    );
};
