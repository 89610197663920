import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import { zIndexMap } from '~/src/styles/constants';

export const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
    position: 'relative',
    marginTop: theme.spacing(2),
    width: '25vw',
}));

export const StyledErrorReport = styled(Box)(() => ({
    position: 'fixed',
    zIndex: zIndexMap.errorReport,
    left: '50%',
    bottom: 0,
    transform: 'translate(-50%, 0)',
}));
