import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import has from 'lodash/has';
import { DEFAULT_TABLE_FILTER_HEIGHT } from '../../constants/configuration';

import { BaseKeyPayload } from '../../types';

export type FilterViewState = {
    [key: string]: FilterViewOptions;
};

export type FilterViewOptions = {
    isOpen: boolean;
    height: number;
};

const INITIAL_STATE: FilterViewState = {};

interface FilterIsOpenPayload extends BaseKeyPayload {
    isOpen: boolean;
}

interface FilterHeightPayload extends BaseKeyPayload {
    height: number;
}

type SetFilterIsOpenReducer = CaseReducer<FilterViewState, PayloadAction<FilterIsOpenPayload>>;
export const setFilterIsOpenReducer: SetFilterIsOpenReducer = (state, action) => {
    const { isOpen, key } = action.payload;
    const previousState = state[key];

    return {
        ...state,
        [key]: {
            ...previousState,
            isOpen,
        },
    };
};

type InitializeFilterViewReducer = CaseReducer<FilterViewState, PayloadAction<FilterIsOpenPayload>>;
export const initializeFilterViewReducer: InitializeFilterViewReducer = (state, action) => {
    const { key, isOpen } = action.payload;

    if (has(state, key)) {
        return state;
    }

    return {
        ...state,
        [key]: {
            isOpen,
            height: DEFAULT_TABLE_FILTER_HEIGHT,
        },
    };
};

type SetFilterHeightReducer = CaseReducer<FilterViewState, PayloadAction<FilterHeightPayload>>;
export const setFilterHeightReducer: SetFilterHeightReducer = (state, action) => {
    const { height, key } = action.payload;
    const previousState = state[key];

    return {
        ...state,
        [key]: {
            ...previousState,
            height,
        },
    };
};

export const filterViewSlice = createSlice({
    name: 'filterView',
    initialState: INITIAL_STATE,
    reducers: {
        setFilterIsOpen: setFilterIsOpenReducer,
        setFilterHeight: setFilterHeightReducer,
        initializeFilterView: initializeFilterViewReducer,
    },
});

export type FilterViewSlice = typeof filterViewSlice;
