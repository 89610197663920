import { createSelector } from 'reselect';

import { type RootState } from '../redux';
import { selectPageKey } from '../page-key';

const selectOffsetState = (state: RootState) => state.offset;

export const selectOffset = createSelector(
    [selectPageKey, selectOffsetState],
    (pageKey, offsetState) => offsetState[pageKey]
);

export const selectOffsetQueryOption = createSelector([selectOffset], (offset) => ({
    offset,
}));
