import { MenuItem } from '@czi-sds/components';
import { setTargetForUrl } from '~/src/utils';
import { BkpLink } from '~/src/components/ui/bkp-link';

export interface HelpMenuLinkItemProps {
    url: string;
    text: string;
}

export const HelpMenuLinkItem = ({ url, text }: HelpMenuLinkItemProps) => (
    <MenuItem>
        <BkpLink
            href={url}
            color="inherit"
            target={setTargetForUrl(window.location.host, url)}
            rel="noopener"
            underline="none"
        >
            {text}
        </BkpLink>
    </MenuItem>
);
