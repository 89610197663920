import { Middleware } from '@reduxjs/toolkit';

import { THEME_KEY } from '~/src/constants/local-storage';
import { themeSlice } from './theme-slice';

// Puts the new theme in local storage whenever it changes
export const themeMiddleware: Middleware = (store) => (next) => (action) => {
    next(action);

    switch (action.type) {
        case themeSlice.actions.toggleTheme().type:
            // NOTICE: We don't use the theme selector here because it causes a dependency cycle
            // so if that selector ever changes, this will need to be updated as well
            localStorage.setItem(THEME_KEY, store.getState().theme);
            break;
        default:
            break;
    }
};
