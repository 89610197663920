import SvgIcon from '@mui/material/SvgIcon';
import { ViewIconProps } from './single-view';

export const TripViewIcon = ({ rotation = 0, colors, iconProps }: ViewIconProps) => (
    <SvgIcon
        viewBox="0 0 18 18"
        {...iconProps}
        sx={{ transform: `rotate(${rotation}deg)` }}
    >
        <rect
            x="2"
            y="2"
            width="14"
            height="6"
            rx="1"
            color={colors?.[0]}
        />
        <rect
            x="2"
            y="10"
            width="6"
            height="6"
            rx="1"
            color={colors?.[1]}
        />
        <rect
            x="10"
            y="10"
            width="6"
            height="6"
            rx="1"
            color={colors?.[2]}
        />
    </SvgIcon>
);
