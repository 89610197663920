import { ScatterbrainCamera, BasicCamera, SlideViewCamera } from '~/src/components/complex/scatterbrain/types';
import type {
    box2d,
    Camera,
    ColorOption,
    ColorSettings,
    ExplorePageInitPayload,
    FilterCategory,
    NullColoring,
    QuantitativeFilter,
    Visualization,
} from '~/src/services/abc-atlas/url-schema';
import { BkpDatasetFragment, CellFilterType, VisualizationFragment } from '~/src/types/generated-schema-types';
import { CategoryId, DataCollectionId, DatasetId } from '~/src/types/reference-ids';
import { Schema } from '~/src/services/abc-atlas/url-schema';
import { Layout as LayoutEnum } from '~/src/constants/layouts';
import { Box2D, vec2, box2D } from '@alleninstitute/vis-geometry';
import type { ExplorePage } from './explore-page-slice';
import { recomputeSlideGridLayout, unsafeId } from './explore-page-utils';
import type {
    QuantitativeColorProperties,
    MetadataColorProperties,
    GenesSymbolsUxState,
    QuantitativeFilters,
    FilterSelectionState,
    VisFrameLayout,
    VisSettings,
    AnnotationColorOptionType,
    NumericFilter,
    GeneFilter,
} from './types/states';
import { getUrlCodec } from './url-codec';
import { isSlideCamera } from './cameraUtils';
import { DEFAULT_VIS_CONTROL_STATE, DEFAULT_ANNOTATION_STATE } from './constants';

function inflateMetadataFilters(filters: FilterCategory[]): Record<string, Record<string, boolean>> {
    return filters.reduce(
        (allCategories, category) => {
            if (!category) return allCategories;

            return {
                ...allCategories,
                [category.categoryId]: category.selectedValues.reduce(
                    (allFilters, cur) => ({ ...allFilters, [cur]: true }),
                    {} as Record<string, boolean>
                ),
            };
        },
        {} as Record<string, Record<string, boolean>>
    );
}
function inflateGeneFilters(filters: QuantitativeFilter[]): QuantitativeFilters {
    return filters.reduce((allGenes, gene) => {
        if (!gene) return allGenes;
        return {
            ...allGenes,
            [gene.symbol]: {
                symbol: gene.symbol,
                index: gene.index,
                range: gene.range,
                type: CellFilterType.Gene,
            },
        };
    }, {} as QuantitativeFilters);
}

function inflateNumericFilters(filters: QuantitativeFilter[]): QuantitativeFilters {
    return filters.reduce((allFilters, filter) => {
        if (!filter) return allFilters;
        return {
            ...allFilters,
            [filter.symbol as string]: {
                value: filter.symbol,
                range: filter.range,
                type: CellFilterType.Metadata,
            } as NumericFilter,
        };
    }, {} as QuantitativeFilters);
}
// we've disabled the ability to set null points as hidden, but the schema is public, so convert HIDE to ZEROS
function inflateNullColorSettings(howToColor: NullColoring | undefined) {
    return howToColor === 'HIDE' || howToColor === undefined ? 'ZEROS' : howToColor;
}
function inflateColorBy(
    colorBy: ColorSettings | undefined
): QuantitativeColorProperties | MetadataColorProperties | undefined {
    if (!colorBy) return undefined;
    const { mode, isTransparent, transparency, value, range, index, color } = colorBy;
    if (!mode || !value) return undefined;
    if (mode === 'METADATA') {
        return {
            category: value,
            isTransparent: isTransparent ?? false,
            transparency: transparency ?? 0.5,
            type: 'METADATA',
        };
    }
    if (!range) return undefined;
    // If not genes
    if (index === undefined) {
        const commonColorProps = {
            type: 'QUANTITATIVE',
            range,
            value,
            isTransparent: isTransparent ?? false,
            transparency: transparency ?? 0.5,
            color: {
                ...color,
                clampRange: color?.clampRange ?? { min: 0, max: 1 },
                excludeZeros: color?.excludeZeros ?? false,
                invertMap: color?.invertMap ?? false,
                nullColoring: inflateNullColorSettings(color?.nullColoring),
                nullColor: color?.nullColor ?? '#FFFFFF',
            },
        };

        if (color?.type === 'GRADIENT') {
            return {
                ...commonColorProps,
                type: 'QUANTITATIVE',
                color: {
                    ...commonColorProps.color,
                    type: 'GRADIENT',
                    gradient: color.gradient,
                },
            };
        }

        if (color?.type === 'MAP') {
            return {
                ...commonColorProps,
                type: 'QUANTITATIVE',
                color: {
                    ...commonColorProps.color,
                    type: 'MAP',
                    name: color.name,
                },
            };
        }
    }
    // We're a gene!
    return {
        type: 'QUANTITATIVE',
        index,
        range,
        value,
        isTransparent: isTransparent ?? false,
        transparency: transparency ?? 0.5,
        color: {
            type: 'GENE',
            excludeZeros: color?.excludeZeros ?? false,
            nullColoring: inflateNullColorSettings(color?.nullColoring),
            nullColor: color?.nullColor ?? '#FFFFFF',
            invertMap: color?.invertMap ?? false,
        },
    };
}
function inflateCamera(
    cam: Camera | undefined,
    metadataFilters: Record<CategoryId, FilterSelectionState>
): ScatterbrainCamera | undefined {
    if (!cam) return undefined;
    const { projection, center, size, slideBounds, gridFeatureId, hideUnselected, offsetIndex } = cam;
    if (!projection || !center || !size) return undefined;

    // figure out if the encoded Camera looks like a slide-view camera
    if (slideBounds && gridFeatureId) {
        // yup!

        const slideCam: SlideViewCamera = {
            projection: projection === 'CARTESIAN' ? 'cartesian' : 'web-image',
            center: [center.x, center.y],
            size: [size.x, size.y],
            slideBounds: Box2D.create(
                [slideBounds.minCorner.x, slideBounds.minCorner.y],
                [slideBounds.maxCorner.x, slideBounds.maxCorner.y]
            ),
            availableSlides: [],
            gridFeature: gridFeatureId,
            hideUnselected: hideUnselected ?? false,
            layout: {},
            slideOffsetIndex: offsetIndex ?? 0,
        };
        return recomputeSlideGridLayout(slideCam, metadataFilters ?? {});
    }
    return {
        projection: projection === 'CARTESIAN' ? 'cartesian' : 'web-image',
        center: [center.x, center.y],
        size: [size.x, size.y],
    };
}
function inflateVis(
    payload: Visualization,
    visualizationsByDataset: Record<DatasetId, VisualizationFragment[]>,
    datasetsByVisualizationIds: Record<string, BkpDatasetFragment>,
    defaultColorBy: string
): VisSettings | undefined {
    const dataCollectionId: DataCollectionId = unsafeId<DataCollectionId>(payload.dataCollectionId);
    if (!dataCollectionId) {
        // cant go on without this being valid!
        return undefined;
    }
    const firstDataset: DatasetId = Object.keys(visualizationsByDataset)?.[0] as DatasetId;
    if (!firstDataset) {
        // no available plots for this entire collection...
        return undefined;
    }
    const datasetId: DatasetId = unsafeId<DatasetId>(payload.datasetId ?? payload.plotId) ?? firstDataset;

    const firstVisualizationId = visualizationsByDataset[datasetId]?.[0];
    const visualizationId = payload.visualizationId ?? firstVisualizationId?.referenceId;
    const version = datasetsByVisualizationIds[visualizationId]?.version;
    const geneux: GenesSymbolsUxState = payload.genes
        ? payload.genes?.reduce((acc: GenesSymbolsUxState, cur) => {
              if (!cur) return acc;

              return {
                  ...acc,
                  [cur.symbol]: {
                      symbol: cur.symbol ?? '',
                      isExpanded: false,
                  },
              };
          }, {})
        : {};
    const metadataFilters: Record<string, Record<string, boolean>> = inflateMetadataFilters(
        payload.metadataFilters ?? []
    );
    const geneFilters: QuantitativeFilters = inflateGeneFilters(
        payload.quantitativeFilters?.filter((filter) => filter.index !== undefined) ?? []
    );
    const numericFilters: QuantitativeFilters = inflateNumericFilters(
        payload.quantitativeFilters?.filter((filter) => filter.index === undefined) ?? []
    );
    const colorProps = inflateColorBy(payload.colorBy);
    const defaultCamera: BasicCamera = {
        center: [0, 0],
        projection: 'cartesian',
        size: [3, 3],
    };
    const annotations = {
        isInFront: payload.annotation?.isInFront ?? DEFAULT_ANNOTATION_STATE.isInFront,
        selectedId: payload.annotation?.referenceId ?? DEFAULT_ANNOTATION_STATE.selectedId,
        selectedFeatureTypeId:
            payload.annotation?.featureTypeReferenceId ?? DEFAULT_ANNOTATION_STATE.selectedFeatureTypeId,
        fill: {
            colorOption:
                (payload.annotation?.fill?.option?.toLocaleLowerCase() as AnnotationColorOptionType) ??
                DEFAULT_ANNOTATION_STATE.fill.colorOption,
            selectedColor: payload.annotation?.fill?.color ?? DEFAULT_ANNOTATION_STATE.fill.selectedColor,
            opacity: payload.annotation?.fill?.opacity ?? DEFAULT_ANNOTATION_STATE.fill.opacity,
        },
        stroke: {
            colorOption:
                (payload.annotation?.stroke?.option?.toLocaleLowerCase() as AnnotationColorOptionType) ??
                DEFAULT_ANNOTATION_STATE.stroke.colorOption,
            selectedColor: payload.annotation?.stroke?.color ?? DEFAULT_ANNOTATION_STATE.stroke.selectedColor,
            opacity: payload.annotation?.stroke?.opacity ?? DEFAULT_ANNOTATION_STATE.stroke.opacity,
        },
    };
    return {
        projectId: payload.projectId,
        dataCollectionId,
        camera: inflateCamera(payload.camera, metadataFilters) ?? defaultCamera,
        datasetId,
        visualizationId,
        ux: {
            selectedCell: undefined,
            metadataFilters: {},
            focusedPanel: null,
            genes: {
                canShowDefaultGene: payload.genes?.length === 0,
                symbols: geneux ?? {},
            },
            disableClose: false,
        },
        vizControls: DEFAULT_VIS_CONTROL_STATE,
        aesthetic: {
            performanceVsQuality: undefined,
            pointSizeScale: 1,
        },
        annotations,
        metadataFilters,
        // In future add other quantitative filters
        quantitativeFilters: { ...geneFilters, ...numericFilters },
        colorProperties: colorProps,
        colorByDefault: defaultColorBy,
        datasetVersion: version,
    };
}
// these base64 encoders are stolen directly from MDN!
function base64ToBytes(base64: string) {
    // atob and btoa are deprecated... in nodejs! which for some reason
    // is what TS thinks this function is from - it is well supported in the browser
    // and fine to use for the purpose of binary->base64 encoding!
    const binString = atob(decodeURIComponent(base64));
    return Uint8Array.from(binString, (m) => m.codePointAt(0));
}

function bytesToBase64(bytes: Uint8Array) {
    const binString = String.fromCodePoint(...bytes);
    return encodeURIComponent(btoa(binString));
}
const LayoutEnumToKiwi = {
    [LayoutEnum.Single]: 'Single',
    [LayoutEnum.DoubleVertical]: 'DoubleVertical',
    [LayoutEnum.DoubleHorizontal]: 'DoubleHorizontal',
    [LayoutEnum.TripleLeft]: 'TripleLeft',
    [LayoutEnum.TripleRight]: 'TripleRight',
    [LayoutEnum.TripleBottom]: 'TripleBottom',
    [LayoutEnum.TripleTop]: 'TripleTop',
    [LayoutEnum.Quadruple]: 'Quadruple',
    [LayoutEnum.QuadrupleRight]: 'QuadrupleRight',
    [LayoutEnum.QuadrupleBottom]: 'QuadrupleBottom',
    [LayoutEnum.QuadrupleTop]: 'QuadrupleTop',
    [LayoutEnum.QuadrupleLeft]: 'QuadrupleLeft',
} as const;
const KiwiToLayoutEnum = {
    Single: LayoutEnum.Single,
    DoubleVertical: LayoutEnum.DoubleVertical,
    DoubleHorizontal: LayoutEnum.DoubleHorizontal,
    TripleLeft: LayoutEnum.TripleLeft,
    TripleRight: LayoutEnum.TripleRight,
    TripleBottom: LayoutEnum.TripleBottom,
    TripleTop: LayoutEnum.TripleTop,
    Quadruple: LayoutEnum.Quadruple,
    QuadrupleRight: LayoutEnum.QuadrupleRight,
    QuadrupleBottom: LayoutEnum.QuadrupleBottom,
    QuadrupleTop: LayoutEnum.QuadrupleTop,
    QuadrupleLeft: LayoutEnum.QuadrupleLeft,
} as const;
export function inflateExplorePage(
    payload: ExplorePageInitPayload,
    visualizationsByDataset: Record<DatasetId, VisualizationFragment[]>,
    datasetsByVisualizationIds: Record<string, BkpDatasetFragment>,
    defaultColorBy: string
): ExplorePage | undefined {
    const keys = ['a', 'b', 'c', 'd'] as const;
    const frames = payload.frames
        ? payload.frames.map((f) => inflateVis(f, visualizationsByDataset, datasetsByVisualizationIds, defaultColorBy))
        : [];
    const visFrames: Partial<VisFrameLayout> = frames.reduce((acc, cur, index) => ({ ...acc, [keys[index]]: cur }), {});
    return {
        layout: KiwiToLayoutEnum[payload.layout],
        allowSidebarResize: true,
        currentFrame: 'a',
        type: 'explore',
        visFrames,
        synced: {},
    };
}
function toPoint2D(v: vec2) {
    return { x: v[0], y: v[1] };
}
function toBox(b: box2D): box2d {
    return {
        minCorner: toPoint2D(b.minCorner),
        maxCorner: toPoint2D(b.maxCorner),
    };
}
function encodeCamera(camera: ScatterbrainCamera | undefined): Camera {
    if (camera === undefined) {
        return {};
    }
    if (isSlideCamera(camera)) {
        return {
            center: toPoint2D(camera.center),
            projection: camera.projection === 'cartesian' ? 'CARTESIAN' : 'WEB_IMAGE',
            size: toPoint2D(camera.size),
            slideBounds: toBox(camera.slideBounds),
            gridFeatureId: camera.gridFeature,
            hideUnselected: camera.hideUnselected,
            offsetIndex: camera.slideOffsetIndex ?? 0,
        };
    }
    return {
        center: toPoint2D(camera.center),
        projection: camera.projection === 'cartesian' ? 'CARTESIAN' : 'WEB_IMAGE',
        size: toPoint2D(camera.size),
    };
}
export function makeKiwiPayload(page: ExplorePage): ExplorePageInitPayload {
    return {
        layout: LayoutEnumToKiwi[page.layout],
        frames: Object.values(page.visFrames).map((v) => {
            const camera: Camera = encodeCamera(v.camera);
            const colorBy: ColorSettings = {
                isTransparent: v.colorProperties.isTransparent,
                transparency: v.colorProperties.transparency,
                mode: v.colorProperties.type,
                index: v.colorProperties.type === 'QUANTITATIVE' ? v.colorProperties.index : undefined,
                range: v.colorProperties.type === 'QUANTITATIVE' ? v.colorProperties.range : undefined,
                color: v.colorProperties.type === 'QUANTITATIVE' ? v.colorProperties.color : undefined,
                value: v.colorProperties.type === 'METADATA' ? v.colorProperties.category : v.colorProperties.value,
            };
            const metadataFilters: FilterCategory[] = Object.entries(v.metadataFilters).map(
                ([cat, selected]) => ({
                    categoryId: cat,
                    selectedValues: Object.entries(selected)
                        .filter(([_value, checked]) => checked)
                        .map(([value, _checked]) => value),
                    type: 'METADATA',
                }),
                {}
            );
            const numericFilters: QuantitativeFilter[] = Object.values(v.quantitativeFilters)
                .filter((filter) => filter.type === CellFilterType.Metadata)
                .map((filter: NumericFilter) => ({
                    symbol: filter.value,
                    range: filter.range,
                }));
            const geneFilters: QuantitativeFilter[] = Object.values(v.quantitativeFilters)
                .filter((filter) => filter.type === CellFilterType.Gene)
                .map(
                    (filterValue: GeneFilter) => ({
                        symbol: filterValue.symbol,
                        index: filterValue.index,
                        range: filterValue.range,
                    }),
                    {}
                );

            const symbols = Object.values(v.ux.genes.symbols).map((geneUx) => ({ symbol: geneUx.symbol }));

            const annotation = {
                isInFront: v.annotations.isInFront,
                referenceId: v.annotations.selectedId,
                featureTypeReferenceId: v.annotations.selectedFeatureTypeId,
                fill: {
                    option: v.annotations.fill.colorOption.toLocaleUpperCase() as ColorOption,
                    color: v.annotations.fill.selectedColor,
                    opacity: v.annotations.fill.opacity,
                },
                stroke: {
                    option: v.annotations.stroke.colorOption.toLocaleUpperCase() as ColorOption,
                    color: v.annotations.stroke.selectedColor,
                    opacity: v.annotations.stroke.opacity,
                },
            };

            const initMe: Visualization = {
                camera,
                colorBy,
                projectId: v.projectId,
                dataCollectionId: v.dataCollectionId,
                metadataFilters,
                quantitativeFilters: [...numericFilters, ...geneFilters],
                plotId: v.datasetId,
                visualizationId: v.visualizationId,
                genes: symbols,
                annotation,
            };
            return initMe;
        }),
    };
}

export function encodeAsKiwiQuery(page: ExplorePage): string {
    const p = makeKiwiPayload(page);
    const codec: Schema = getUrlCodec();
    const bytes = codec.encodeExplorePageInitPayload(p);
    const str = bytesToBase64(bytes);

    return str;
}
export function decodeKiwiPayload(urlSafeEncodedPayload: string) {
    const codec: Schema = getUrlCodec();
    const bytes = base64ToBytes(urlSafeEncodedPayload);

    const pageInit = codec.decodeExplorePageInitPayload(bytes);
    return pageInit;
}
