/**
 * return this function in the default case of a switch statement that you'd like to be exhaustive
 * this will cause you to have a typescript error if TS concludes that the default case is reachable.
 * This is a handy way to safeguard against future additions (for example, to enums)
 * so you dont get a nasty surprise in code you didn't search that references your growing enum
 * @param switchVariable a variable or expression that you've passed to a switch
 * Do note that this function never returns and ALWAYS THROWS AN ERROR AT RUNTIME IF ITS HIT
 */
export function exhaustiveSwitchGuard(switchVariable: never): never {
    throw new Error(`unhandled case ${switchVariable} in switch!`);
}
